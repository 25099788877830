/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 */

/* @tailwind preflight; */

/**
 * This injects any component classes registered by plugins.
 */

.tw-container {
  width: 100%;
}

@media (min-width: 576px) {
  .tw-container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .tw-container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .tw-container {
    max-width: 1200px;
  }
}

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file. It also injects any utility classes registered by plugins.
 */

.tw-list-reset {
  list-style: none;
  padding: 0;
}

.tw-appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.tw-bg-fixed {
  background-attachment: fixed;
}

.tw-bg-local {
  background-attachment: local;
}

.tw-bg-scroll {
  background-attachment: scroll;
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-primary {
  background-color: #ac826d;
}

.tw-bg-text {
  background-color: #453f3f;
}

.tw-bg-black {
  background-color: #000;
}

.tw-bg-grey-darkest {
  background-color: #3d4852;
}

.tw-bg-grey-darker {
  background-color: #606f7b;
}

.tw-bg-grey-dark {
  background-color: #8795a1;
}

.tw-bg-grey {
  background-color: #b8c2cc;
}

.tw-bg-grey-light {
  background-color: #a1b3bc;
}

.tw-bg-grey-lighter {
  background-color: #d8dfe3;
}

.tw-bg-grey-lightest {
  background-color: #f8fafc;
}

.tw-bg-grey-grayish {
  background-color: #56666c;
}

.tw-bg-white {
  background-color: #fff;
}

.tw-bg-red-darkest {
  background-color: #3b0d0c;
}

.tw-bg-red-darker {
  background-color: #621b18;
}

.tw-bg-red-dark {
  background-color: #cc1f1a;
}

.tw-bg-red {
  background-color: #de5050;
}

.tw-bg-red-light {
  background-color: #fed5dc;
}

.tw-bg-red-lighter {
  background-color: #f9acaa;
}

.tw-bg-red-lightest {
  background-color: #fcebea;
}

.tw-bg-orange-darkest {
  background-color: #462a16;
}

.tw-bg-orange-darker {
  background-color: #613b1f;
}

.tw-bg-orange-dark {
  background-color: #de751f;
}

.tw-bg-orange {
  background-color: #ffbf00;
}

.tw-bg-orange-light {
  background-color: #faad63;
}

.tw-bg-orange-lighter {
  background-color: #fcd9b6;
}

.tw-bg-orange-lightest {
  background-color: #fff5eb;
}

.tw-bg-yellow-darkest {
  background-color: #453411;
}

.tw-bg-yellow-darker {
  background-color: #684f1d;
}

.tw-bg-yellow-dark {
  background-color: #f2d024;
}

.tw-bg-yellow {
  background-color: #f8e71c;
}

.tw-bg-yellow-light {
  background-color: #fff382;
}

.tw-bg-yellow-lighter {
  background-color: #fff9c2;
}

.tw-bg-yellow-lightest {
  background-color: #fcfbeb;
}

.tw-bg-green-darkest {
  background-color: #0f2f21;
}

.tw-bg-green-darker {
  background-color: #1a4731;
}

.tw-bg-green-dark {
  background-color: #1f9d55;
}

.tw-bg-green {
  background-color: #38c172;
}

.tw-bg-green-light {
  background-color: #51d88a;
}

.tw-bg-green-lighter {
  background-color: #a2f5bf;
}

.tw-bg-green-lightest {
  background-color: #e3fcec;
}

.tw-bg-teal-darkest {
  background-color: #0d3331;
}

.tw-bg-teal-darker {
  background-color: #20504f;
}

.tw-bg-teal-dark {
  background-color: #38a89d;
}

.tw-bg-teal {
  background-color: #17c7aa;
}

.tw-bg-teal-light {
  background-color: #64d5ca;
}

.tw-bg-teal-lighter {
  background-color: #a0f0ed;
}

.tw-bg-teal-lightest {
  background-color: #e8fffe;
}

.tw-bg-blue-darkest {
  background-color: #12283a;
}

.tw-bg-blue-darker {
  background-color: #1c3d5a;
}

.tw-bg-blue-dark {
  background-color: #2779bd;
}

.tw-bg-blue {
  background-color: #2ac1e7;
}

.tw-bg-blue-light {
  background-color: #d4f2fa;
}

.tw-bg-blue-lighter {
  background-color: #bcdefa;
}

.tw-bg-blue-lightest {
  background-color: #eff8ff;
}

.tw-bg-indigo-darkest {
  background-color: #191e38;
}

.tw-bg-indigo-darker {
  background-color: #2f365f;
}

.tw-bg-indigo-dark {
  background-color: #5661b3;
}

.tw-bg-indigo {
  background-color: #6574cd;
}

.tw-bg-indigo-light {
  background-color: #7886d7;
}

.tw-bg-indigo-lighter {
  background-color: #b2b7ff;
}

.tw-bg-indigo-lightest {
  background-color: #e6e8ff;
}

.tw-bg-purple-darkest {
  background-color: #21183c;
}

.tw-bg-purple-darker {
  background-color: #382b5f;
}

.tw-bg-purple-dark {
  background-color: #794acf;
}

.tw-bg-purple {
  background-color: #9561e2;
}

.tw-bg-purple-light {
  background-color: #a779e9;
}

.tw-bg-purple-lighter {
  background-color: #d6bbfc;
}

.tw-bg-purple-lightest {
  background-color: #f3ebff;
}

.tw-bg-pink-darkest {
  background-color: #451225;
}

.tw-bg-pink-darker {
  background-color: #6f213f;
}

.tw-bg-pink-dark {
  background-color: #eb5286;
}

.tw-bg-pink {
  background-color: #f66d9b;
}

.tw-bg-pink-light {
  background-color: #fa7ea8;
}

.tw-bg-pink-lighter {
  background-color: #ffbbca;
}

.tw-bg-pink-lightest {
  background-color: #ffebef;
}

.hover\:tw-bg-transparent:hover {
  background-color: transparent;
}

.hover\:tw-bg-primary:hover {
  background-color: #ac826d;
}

.hover\:tw-bg-text:hover {
  background-color: #453f3f;
}

.hover\:tw-bg-black:hover {
  background-color: #000;
}

.hover\:tw-bg-grey-darkest:hover {
  background-color: #3d4852;
}

.hover\:tw-bg-grey-darker:hover {
  background-color: #606f7b;
}

.hover\:tw-bg-grey-dark:hover {
  background-color: #8795a1;
}

.hover\:tw-bg-grey:hover {
  background-color: #b8c2cc;
}

.hover\:tw-bg-grey-light:hover {
  background-color: #a1b3bc;
}

.hover\:tw-bg-grey-lighter:hover {
  background-color: #d8dfe3;
}

.hover\:tw-bg-grey-lightest:hover {
  background-color: #f8fafc;
}

.hover\:tw-bg-grey-grayish:hover {
  background-color: #56666c;
}

.hover\:tw-bg-white:hover {
  background-color: #fff;
}

.hover\:tw-bg-red-darkest:hover {
  background-color: #3b0d0c;
}

.hover\:tw-bg-red-darker:hover {
  background-color: #621b18;
}

.hover\:tw-bg-red-dark:hover {
  background-color: #cc1f1a;
}

.hover\:tw-bg-red:hover {
  background-color: #de5050;
}

.hover\:tw-bg-red-light:hover {
  background-color: #fed5dc;
}

.hover\:tw-bg-red-lighter:hover {
  background-color: #f9acaa;
}

.hover\:tw-bg-red-lightest:hover {
  background-color: #fcebea;
}

.hover\:tw-bg-orange-darkest:hover {
  background-color: #462a16;
}

.hover\:tw-bg-orange-darker:hover {
  background-color: #613b1f;
}

.hover\:tw-bg-orange-dark:hover {
  background-color: #de751f;
}

.hover\:tw-bg-orange:hover {
  background-color: #ffbf00;
}

.hover\:tw-bg-orange-light:hover {
  background-color: #faad63;
}

.hover\:tw-bg-orange-lighter:hover {
  background-color: #fcd9b6;
}

.hover\:tw-bg-orange-lightest:hover {
  background-color: #fff5eb;
}

.hover\:tw-bg-yellow-darkest:hover {
  background-color: #453411;
}

.hover\:tw-bg-yellow-darker:hover {
  background-color: #684f1d;
}

.hover\:tw-bg-yellow-dark:hover {
  background-color: #f2d024;
}

.hover\:tw-bg-yellow:hover {
  background-color: #f8e71c;
}

.hover\:tw-bg-yellow-light:hover {
  background-color: #fff382;
}

.hover\:tw-bg-yellow-lighter:hover {
  background-color: #fff9c2;
}

.hover\:tw-bg-yellow-lightest:hover {
  background-color: #fcfbeb;
}

.hover\:tw-bg-green-darkest:hover {
  background-color: #0f2f21;
}

.hover\:tw-bg-green-darker:hover {
  background-color: #1a4731;
}

.hover\:tw-bg-green-dark:hover {
  background-color: #1f9d55;
}

.hover\:tw-bg-green:hover {
  background-color: #38c172;
}

.hover\:tw-bg-green-light:hover {
  background-color: #51d88a;
}

.hover\:tw-bg-green-lighter:hover {
  background-color: #a2f5bf;
}

.hover\:tw-bg-green-lightest:hover {
  background-color: #e3fcec;
}

.hover\:tw-bg-teal-darkest:hover {
  background-color: #0d3331;
}

.hover\:tw-bg-teal-darker:hover {
  background-color: #20504f;
}

.hover\:tw-bg-teal-dark:hover {
  background-color: #38a89d;
}

.hover\:tw-bg-teal:hover {
  background-color: #17c7aa;
}

.hover\:tw-bg-teal-light:hover {
  background-color: #64d5ca;
}

.hover\:tw-bg-teal-lighter:hover {
  background-color: #a0f0ed;
}

.hover\:tw-bg-teal-lightest:hover {
  background-color: #e8fffe;
}

.hover\:tw-bg-blue-darkest:hover {
  background-color: #12283a;
}

.hover\:tw-bg-blue-darker:hover {
  background-color: #1c3d5a;
}

.hover\:tw-bg-blue-dark:hover {
  background-color: #2779bd;
}

.hover\:tw-bg-blue:hover {
  background-color: #2ac1e7;
}

.hover\:tw-bg-blue-light:hover {
  background-color: #d4f2fa;
}

.hover\:tw-bg-blue-lighter:hover {
  background-color: #bcdefa;
}

.hover\:tw-bg-blue-lightest:hover {
  background-color: #eff8ff;
}

.hover\:tw-bg-indigo-darkest:hover {
  background-color: #191e38;
}

.hover\:tw-bg-indigo-darker:hover {
  background-color: #2f365f;
}

.hover\:tw-bg-indigo-dark:hover {
  background-color: #5661b3;
}

.hover\:tw-bg-indigo:hover {
  background-color: #6574cd;
}

.hover\:tw-bg-indigo-light:hover {
  background-color: #7886d7;
}

.hover\:tw-bg-indigo-lighter:hover {
  background-color: #b2b7ff;
}

.hover\:tw-bg-indigo-lightest:hover {
  background-color: #e6e8ff;
}

.hover\:tw-bg-purple-darkest:hover {
  background-color: #21183c;
}

.hover\:tw-bg-purple-darker:hover {
  background-color: #382b5f;
}

.hover\:tw-bg-purple-dark:hover {
  background-color: #794acf;
}

.hover\:tw-bg-purple:hover {
  background-color: #9561e2;
}

.hover\:tw-bg-purple-light:hover {
  background-color: #a779e9;
}

.hover\:tw-bg-purple-lighter:hover {
  background-color: #d6bbfc;
}

.hover\:tw-bg-purple-lightest:hover {
  background-color: #f3ebff;
}

.hover\:tw-bg-pink-darkest:hover {
  background-color: #451225;
}

.hover\:tw-bg-pink-darker:hover {
  background-color: #6f213f;
}

.hover\:tw-bg-pink-dark:hover {
  background-color: #eb5286;
}

.hover\:tw-bg-pink:hover {
  background-color: #f66d9b;
}

.hover\:tw-bg-pink-light:hover {
  background-color: #fa7ea8;
}

.hover\:tw-bg-pink-lighter:hover {
  background-color: #ffbbca;
}

.hover\:tw-bg-pink-lightest:hover {
  background-color: #ffebef;
}

.focus\:tw-bg-transparent:focus {
  background-color: transparent;
}

.focus\:tw-bg-primary:focus {
  background-color: #ac826d;
}

.focus\:tw-bg-text:focus {
  background-color: #453f3f;
}

.focus\:tw-bg-black:focus {
  background-color: #000;
}

.focus\:tw-bg-grey-darkest:focus {
  background-color: #3d4852;
}

.focus\:tw-bg-grey-darker:focus {
  background-color: #606f7b;
}

.focus\:tw-bg-grey-dark:focus {
  background-color: #8795a1;
}

.focus\:tw-bg-grey:focus {
  background-color: #b8c2cc;
}

.focus\:tw-bg-grey-light:focus {
  background-color: #a1b3bc;
}

.focus\:tw-bg-grey-lighter:focus {
  background-color: #d8dfe3;
}

.focus\:tw-bg-grey-lightest:focus {
  background-color: #f8fafc;
}

.focus\:tw-bg-grey-grayish:focus {
  background-color: #56666c;
}

.focus\:tw-bg-white:focus {
  background-color: #fff;
}

.focus\:tw-bg-red-darkest:focus {
  background-color: #3b0d0c;
}

.focus\:tw-bg-red-darker:focus {
  background-color: #621b18;
}

.focus\:tw-bg-red-dark:focus {
  background-color: #cc1f1a;
}

.focus\:tw-bg-red:focus {
  background-color: #de5050;
}

.focus\:tw-bg-red-light:focus {
  background-color: #fed5dc;
}

.focus\:tw-bg-red-lighter:focus {
  background-color: #f9acaa;
}

.focus\:tw-bg-red-lightest:focus {
  background-color: #fcebea;
}

.focus\:tw-bg-orange-darkest:focus {
  background-color: #462a16;
}

.focus\:tw-bg-orange-darker:focus {
  background-color: #613b1f;
}

.focus\:tw-bg-orange-dark:focus {
  background-color: #de751f;
}

.focus\:tw-bg-orange:focus {
  background-color: #ffbf00;
}

.focus\:tw-bg-orange-light:focus {
  background-color: #faad63;
}

.focus\:tw-bg-orange-lighter:focus {
  background-color: #fcd9b6;
}

.focus\:tw-bg-orange-lightest:focus {
  background-color: #fff5eb;
}

.focus\:tw-bg-yellow-darkest:focus {
  background-color: #453411;
}

.focus\:tw-bg-yellow-darker:focus {
  background-color: #684f1d;
}

.focus\:tw-bg-yellow-dark:focus {
  background-color: #f2d024;
}

.focus\:tw-bg-yellow:focus {
  background-color: #f8e71c;
}

.focus\:tw-bg-yellow-light:focus {
  background-color: #fff382;
}

.focus\:tw-bg-yellow-lighter:focus {
  background-color: #fff9c2;
}

.focus\:tw-bg-yellow-lightest:focus {
  background-color: #fcfbeb;
}

.focus\:tw-bg-green-darkest:focus {
  background-color: #0f2f21;
}

.focus\:tw-bg-green-darker:focus {
  background-color: #1a4731;
}

.focus\:tw-bg-green-dark:focus {
  background-color: #1f9d55;
}

.focus\:tw-bg-green:focus {
  background-color: #38c172;
}

.focus\:tw-bg-green-light:focus {
  background-color: #51d88a;
}

.focus\:tw-bg-green-lighter:focus {
  background-color: #a2f5bf;
}

.focus\:tw-bg-green-lightest:focus {
  background-color: #e3fcec;
}

.focus\:tw-bg-teal-darkest:focus {
  background-color: #0d3331;
}

.focus\:tw-bg-teal-darker:focus {
  background-color: #20504f;
}

.focus\:tw-bg-teal-dark:focus {
  background-color: #38a89d;
}

.focus\:tw-bg-teal:focus {
  background-color: #17c7aa;
}

.focus\:tw-bg-teal-light:focus {
  background-color: #64d5ca;
}

.focus\:tw-bg-teal-lighter:focus {
  background-color: #a0f0ed;
}

.focus\:tw-bg-teal-lightest:focus {
  background-color: #e8fffe;
}

.focus\:tw-bg-blue-darkest:focus {
  background-color: #12283a;
}

.focus\:tw-bg-blue-darker:focus {
  background-color: #1c3d5a;
}

.focus\:tw-bg-blue-dark:focus {
  background-color: #2779bd;
}

.focus\:tw-bg-blue:focus {
  background-color: #2ac1e7;
}

.focus\:tw-bg-blue-light:focus {
  background-color: #d4f2fa;
}

.focus\:tw-bg-blue-lighter:focus {
  background-color: #bcdefa;
}

.focus\:tw-bg-blue-lightest:focus {
  background-color: #eff8ff;
}

.focus\:tw-bg-indigo-darkest:focus {
  background-color: #191e38;
}

.focus\:tw-bg-indigo-darker:focus {
  background-color: #2f365f;
}

.focus\:tw-bg-indigo-dark:focus {
  background-color: #5661b3;
}

.focus\:tw-bg-indigo:focus {
  background-color: #6574cd;
}

.focus\:tw-bg-indigo-light:focus {
  background-color: #7886d7;
}

.focus\:tw-bg-indigo-lighter:focus {
  background-color: #b2b7ff;
}

.focus\:tw-bg-indigo-lightest:focus {
  background-color: #e6e8ff;
}

.focus\:tw-bg-purple-darkest:focus {
  background-color: #21183c;
}

.focus\:tw-bg-purple-darker:focus {
  background-color: #382b5f;
}

.focus\:tw-bg-purple-dark:focus {
  background-color: #794acf;
}

.focus\:tw-bg-purple:focus {
  background-color: #9561e2;
}

.focus\:tw-bg-purple-light:focus {
  background-color: #a779e9;
}

.focus\:tw-bg-purple-lighter:focus {
  background-color: #d6bbfc;
}

.focus\:tw-bg-purple-lightest:focus {
  background-color: #f3ebff;
}

.focus\:tw-bg-pink-darkest:focus {
  background-color: #451225;
}

.focus\:tw-bg-pink-darker:focus {
  background-color: #6f213f;
}

.focus\:tw-bg-pink-dark:focus {
  background-color: #eb5286;
}

.focus\:tw-bg-pink:focus {
  background-color: #f66d9b;
}

.focus\:tw-bg-pink-light:focus {
  background-color: #fa7ea8;
}

.focus\:tw-bg-pink-lighter:focus {
  background-color: #ffbbca;
}

.focus\:tw-bg-pink-lightest:focus {
  background-color: #ffebef;
}

.tw-bg-bottom {
  background-position: bottom;
}

.tw-bg-center {
  background-position: center;
}

.tw-bg-left {
  background-position: left;
}

.tw-bg-left-bottom {
  background-position: left bottom;
}

.tw-bg-left-top {
  background-position: left top;
}

.tw-bg-right {
  background-position: right;
}

.tw-bg-right-bottom {
  background-position: right bottom;
}

.tw-bg-right-top {
  background-position: right top;
}

.tw-bg-top {
  background-position: top;
}

.tw-bg-repeat {
  background-repeat: repeat;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat;
}

.tw-bg-repeat-x {
  background-repeat: repeat-x;
}

.tw-bg-repeat-y {
  background-repeat: repeat-y;
}

.tw-bg-auto {
  background-size: auto;
}

.tw-bg-cover {
  background-size: cover;
}

.tw-bg-contain {
  background-size: contain;
}

.tw-border-collapse {
  border-collapse: collapse;
}

.tw-border-separate {
  border-collapse: separate;
}

.tw-border-transparent {
  border-color: transparent;
}

.tw-border-primary {
  border-color: #ac826d;
}

.tw-border-text {
  border-color: #453f3f;
}

.tw-border-black {
  border-color: #000;
}

.tw-border-grey-darkest {
  border-color: #3d4852;
}

.tw-border-grey-darker {
  border-color: #606f7b;
}

.tw-border-grey-dark {
  border-color: #8795a1;
}

.tw-border-grey {
  border-color: #b8c2cc;
}

.tw-border-grey-light {
  border-color: #a1b3bc;
}

.tw-border-grey-lighter {
  border-color: #d8dfe3;
}

.tw-border-grey-lightest {
  border-color: #f8fafc;
}

.tw-border-grey-grayish {
  border-color: #56666c;
}

.tw-border-white {
  border-color: #fff;
}

.tw-border-red-darkest {
  border-color: #3b0d0c;
}

.tw-border-red-darker {
  border-color: #621b18;
}

.tw-border-red-dark {
  border-color: #cc1f1a;
}

.tw-border-red {
  border-color: #de5050;
}

.tw-border-red-light {
  border-color: #fed5dc;
}

.tw-border-red-lighter {
  border-color: #f9acaa;
}

.tw-border-red-lightest {
  border-color: #fcebea;
}

.tw-border-orange-darkest {
  border-color: #462a16;
}

.tw-border-orange-darker {
  border-color: #613b1f;
}

.tw-border-orange-dark {
  border-color: #de751f;
}

.tw-border-orange {
  border-color: #ffbf00;
}

.tw-border-orange-light {
  border-color: #faad63;
}

.tw-border-orange-lighter {
  border-color: #fcd9b6;
}

.tw-border-orange-lightest {
  border-color: #fff5eb;
}

.tw-border-yellow-darkest {
  border-color: #453411;
}

.tw-border-yellow-darker {
  border-color: #684f1d;
}

.tw-border-yellow-dark {
  border-color: #f2d024;
}

.tw-border-yellow {
  border-color: #f8e71c;
}

.tw-border-yellow-light {
  border-color: #fff382;
}

.tw-border-yellow-lighter {
  border-color: #fff9c2;
}

.tw-border-yellow-lightest {
  border-color: #fcfbeb;
}

.tw-border-green-darkest {
  border-color: #0f2f21;
}

.tw-border-green-darker {
  border-color: #1a4731;
}

.tw-border-green-dark {
  border-color: #1f9d55;
}

.tw-border-green {
  border-color: #38c172;
}

.tw-border-green-light {
  border-color: #51d88a;
}

.tw-border-green-lighter {
  border-color: #a2f5bf;
}

.tw-border-green-lightest {
  border-color: #e3fcec;
}

.tw-border-teal-darkest {
  border-color: #0d3331;
}

.tw-border-teal-darker {
  border-color: #20504f;
}

.tw-border-teal-dark {
  border-color: #38a89d;
}

.tw-border-teal {
  border-color: #17c7aa;
}

.tw-border-teal-light {
  border-color: #64d5ca;
}

.tw-border-teal-lighter {
  border-color: #a0f0ed;
}

.tw-border-teal-lightest {
  border-color: #e8fffe;
}

.tw-border-blue-darkest {
  border-color: #12283a;
}

.tw-border-blue-darker {
  border-color: #1c3d5a;
}

.tw-border-blue-dark {
  border-color: #2779bd;
}

.tw-border-blue {
  border-color: #2ac1e7;
}

.tw-border-blue-light {
  border-color: #d4f2fa;
}

.tw-border-blue-lighter {
  border-color: #bcdefa;
}

.tw-border-blue-lightest {
  border-color: #eff8ff;
}

.tw-border-indigo-darkest {
  border-color: #191e38;
}

.tw-border-indigo-darker {
  border-color: #2f365f;
}

.tw-border-indigo-dark {
  border-color: #5661b3;
}

.tw-border-indigo {
  border-color: #6574cd;
}

.tw-border-indigo-light {
  border-color: #7886d7;
}

.tw-border-indigo-lighter {
  border-color: #b2b7ff;
}

.tw-border-indigo-lightest {
  border-color: #e6e8ff;
}

.tw-border-purple-darkest {
  border-color: #21183c;
}

.tw-border-purple-darker {
  border-color: #382b5f;
}

.tw-border-purple-dark {
  border-color: #794acf;
}

.tw-border-purple {
  border-color: #9561e2;
}

.tw-border-purple-light {
  border-color: #a779e9;
}

.tw-border-purple-lighter {
  border-color: #d6bbfc;
}

.tw-border-purple-lightest {
  border-color: #f3ebff;
}

.tw-border-pink-darkest {
  border-color: #451225;
}

.tw-border-pink-darker {
  border-color: #6f213f;
}

.tw-border-pink-dark {
  border-color: #eb5286;
}

.tw-border-pink {
  border-color: #f66d9b;
}

.tw-border-pink-light {
  border-color: #fa7ea8;
}

.tw-border-pink-lighter {
  border-color: #ffbbca;
}

.tw-border-pink-lightest {
  border-color: #ffebef;
}

.hover\:tw-border-transparent:hover {
  border-color: transparent;
}

.hover\:tw-border-primary:hover {
  border-color: #ac826d;
}

.hover\:tw-border-text:hover {
  border-color: #453f3f;
}

.hover\:tw-border-black:hover {
  border-color: #000;
}

.hover\:tw-border-grey-darkest:hover {
  border-color: #3d4852;
}

.hover\:tw-border-grey-darker:hover {
  border-color: #606f7b;
}

.hover\:tw-border-grey-dark:hover {
  border-color: #8795a1;
}

.hover\:tw-border-grey:hover {
  border-color: #b8c2cc;
}

.hover\:tw-border-grey-light:hover {
  border-color: #a1b3bc;
}

.hover\:tw-border-grey-lighter:hover {
  border-color: #d8dfe3;
}

.hover\:tw-border-grey-lightest:hover {
  border-color: #f8fafc;
}

.hover\:tw-border-grey-grayish:hover {
  border-color: #56666c;
}

.hover\:tw-border-white:hover {
  border-color: #fff;
}

.hover\:tw-border-red-darkest:hover {
  border-color: #3b0d0c;
}

.hover\:tw-border-red-darker:hover {
  border-color: #621b18;
}

.hover\:tw-border-red-dark:hover {
  border-color: #cc1f1a;
}

.hover\:tw-border-red:hover {
  border-color: #de5050;
}

.hover\:tw-border-red-light:hover {
  border-color: #fed5dc;
}

.hover\:tw-border-red-lighter:hover {
  border-color: #f9acaa;
}

.hover\:tw-border-red-lightest:hover {
  border-color: #fcebea;
}

.hover\:tw-border-orange-darkest:hover {
  border-color: #462a16;
}

.hover\:tw-border-orange-darker:hover {
  border-color: #613b1f;
}

.hover\:tw-border-orange-dark:hover {
  border-color: #de751f;
}

.hover\:tw-border-orange:hover {
  border-color: #ffbf00;
}

.hover\:tw-border-orange-light:hover {
  border-color: #faad63;
}

.hover\:tw-border-orange-lighter:hover {
  border-color: #fcd9b6;
}

.hover\:tw-border-orange-lightest:hover {
  border-color: #fff5eb;
}

.hover\:tw-border-yellow-darkest:hover {
  border-color: #453411;
}

.hover\:tw-border-yellow-darker:hover {
  border-color: #684f1d;
}

.hover\:tw-border-yellow-dark:hover {
  border-color: #f2d024;
}

.hover\:tw-border-yellow:hover {
  border-color: #f8e71c;
}

.hover\:tw-border-yellow-light:hover {
  border-color: #fff382;
}

.hover\:tw-border-yellow-lighter:hover {
  border-color: #fff9c2;
}

.hover\:tw-border-yellow-lightest:hover {
  border-color: #fcfbeb;
}

.hover\:tw-border-green-darkest:hover {
  border-color: #0f2f21;
}

.hover\:tw-border-green-darker:hover {
  border-color: #1a4731;
}

.hover\:tw-border-green-dark:hover {
  border-color: #1f9d55;
}

.hover\:tw-border-green:hover {
  border-color: #38c172;
}

.hover\:tw-border-green-light:hover {
  border-color: #51d88a;
}

.hover\:tw-border-green-lighter:hover {
  border-color: #a2f5bf;
}

.hover\:tw-border-green-lightest:hover {
  border-color: #e3fcec;
}

.hover\:tw-border-teal-darkest:hover {
  border-color: #0d3331;
}

.hover\:tw-border-teal-darker:hover {
  border-color: #20504f;
}

.hover\:tw-border-teal-dark:hover {
  border-color: #38a89d;
}

.hover\:tw-border-teal:hover {
  border-color: #17c7aa;
}

.hover\:tw-border-teal-light:hover {
  border-color: #64d5ca;
}

.hover\:tw-border-teal-lighter:hover {
  border-color: #a0f0ed;
}

.hover\:tw-border-teal-lightest:hover {
  border-color: #e8fffe;
}

.hover\:tw-border-blue-darkest:hover {
  border-color: #12283a;
}

.hover\:tw-border-blue-darker:hover {
  border-color: #1c3d5a;
}

.hover\:tw-border-blue-dark:hover {
  border-color: #2779bd;
}

.hover\:tw-border-blue:hover {
  border-color: #2ac1e7;
}

.hover\:tw-border-blue-light:hover {
  border-color: #d4f2fa;
}

.hover\:tw-border-blue-lighter:hover {
  border-color: #bcdefa;
}

.hover\:tw-border-blue-lightest:hover {
  border-color: #eff8ff;
}

.hover\:tw-border-indigo-darkest:hover {
  border-color: #191e38;
}

.hover\:tw-border-indigo-darker:hover {
  border-color: #2f365f;
}

.hover\:tw-border-indigo-dark:hover {
  border-color: #5661b3;
}

.hover\:tw-border-indigo:hover {
  border-color: #6574cd;
}

.hover\:tw-border-indigo-light:hover {
  border-color: #7886d7;
}

.hover\:tw-border-indigo-lighter:hover {
  border-color: #b2b7ff;
}

.hover\:tw-border-indigo-lightest:hover {
  border-color: #e6e8ff;
}

.hover\:tw-border-purple-darkest:hover {
  border-color: #21183c;
}

.hover\:tw-border-purple-darker:hover {
  border-color: #382b5f;
}

.hover\:tw-border-purple-dark:hover {
  border-color: #794acf;
}

.hover\:tw-border-purple:hover {
  border-color: #9561e2;
}

.hover\:tw-border-purple-light:hover {
  border-color: #a779e9;
}

.hover\:tw-border-purple-lighter:hover {
  border-color: #d6bbfc;
}

.hover\:tw-border-purple-lightest:hover {
  border-color: #f3ebff;
}

.hover\:tw-border-pink-darkest:hover {
  border-color: #451225;
}

.hover\:tw-border-pink-darker:hover {
  border-color: #6f213f;
}

.hover\:tw-border-pink-dark:hover {
  border-color: #eb5286;
}

.hover\:tw-border-pink:hover {
  border-color: #f66d9b;
}

.hover\:tw-border-pink-light:hover {
  border-color: #fa7ea8;
}

.hover\:tw-border-pink-lighter:hover {
  border-color: #ffbbca;
}

.hover\:tw-border-pink-lightest:hover {
  border-color: #ffebef;
}

.focus\:tw-border-transparent:focus {
  border-color: transparent;
}

.focus\:tw-border-primary:focus {
  border-color: #ac826d;
}

.focus\:tw-border-text:focus {
  border-color: #453f3f;
}

.focus\:tw-border-black:focus {
  border-color: #000;
}

.focus\:tw-border-grey-darkest:focus {
  border-color: #3d4852;
}

.focus\:tw-border-grey-darker:focus {
  border-color: #606f7b;
}

.focus\:tw-border-grey-dark:focus {
  border-color: #8795a1;
}

.focus\:tw-border-grey:focus {
  border-color: #b8c2cc;
}

.focus\:tw-border-grey-light:focus {
  border-color: #a1b3bc;
}

.focus\:tw-border-grey-lighter:focus {
  border-color: #d8dfe3;
}

.focus\:tw-border-grey-lightest:focus {
  border-color: #f8fafc;
}

.focus\:tw-border-grey-grayish:focus {
  border-color: #56666c;
}

.focus\:tw-border-white:focus {
  border-color: #fff;
}

.focus\:tw-border-red-darkest:focus {
  border-color: #3b0d0c;
}

.focus\:tw-border-red-darker:focus {
  border-color: #621b18;
}

.focus\:tw-border-red-dark:focus {
  border-color: #cc1f1a;
}

.focus\:tw-border-red:focus {
  border-color: #de5050;
}

.focus\:tw-border-red-light:focus {
  border-color: #fed5dc;
}

.focus\:tw-border-red-lighter:focus {
  border-color: #f9acaa;
}

.focus\:tw-border-red-lightest:focus {
  border-color: #fcebea;
}

.focus\:tw-border-orange-darkest:focus {
  border-color: #462a16;
}

.focus\:tw-border-orange-darker:focus {
  border-color: #613b1f;
}

.focus\:tw-border-orange-dark:focus {
  border-color: #de751f;
}

.focus\:tw-border-orange:focus {
  border-color: #ffbf00;
}

.focus\:tw-border-orange-light:focus {
  border-color: #faad63;
}

.focus\:tw-border-orange-lighter:focus {
  border-color: #fcd9b6;
}

.focus\:tw-border-orange-lightest:focus {
  border-color: #fff5eb;
}

.focus\:tw-border-yellow-darkest:focus {
  border-color: #453411;
}

.focus\:tw-border-yellow-darker:focus {
  border-color: #684f1d;
}

.focus\:tw-border-yellow-dark:focus {
  border-color: #f2d024;
}

.focus\:tw-border-yellow:focus {
  border-color: #f8e71c;
}

.focus\:tw-border-yellow-light:focus {
  border-color: #fff382;
}

.focus\:tw-border-yellow-lighter:focus {
  border-color: #fff9c2;
}

.focus\:tw-border-yellow-lightest:focus {
  border-color: #fcfbeb;
}

.focus\:tw-border-green-darkest:focus {
  border-color: #0f2f21;
}

.focus\:tw-border-green-darker:focus {
  border-color: #1a4731;
}

.focus\:tw-border-green-dark:focus {
  border-color: #1f9d55;
}

.focus\:tw-border-green:focus {
  border-color: #38c172;
}

.focus\:tw-border-green-light:focus {
  border-color: #51d88a;
}

.focus\:tw-border-green-lighter:focus {
  border-color: #a2f5bf;
}

.focus\:tw-border-green-lightest:focus {
  border-color: #e3fcec;
}

.focus\:tw-border-teal-darkest:focus {
  border-color: #0d3331;
}

.focus\:tw-border-teal-darker:focus {
  border-color: #20504f;
}

.focus\:tw-border-teal-dark:focus {
  border-color: #38a89d;
}

.focus\:tw-border-teal:focus {
  border-color: #17c7aa;
}

.focus\:tw-border-teal-light:focus {
  border-color: #64d5ca;
}

.focus\:tw-border-teal-lighter:focus {
  border-color: #a0f0ed;
}

.focus\:tw-border-teal-lightest:focus {
  border-color: #e8fffe;
}

.focus\:tw-border-blue-darkest:focus {
  border-color: #12283a;
}

.focus\:tw-border-blue-darker:focus {
  border-color: #1c3d5a;
}

.focus\:tw-border-blue-dark:focus {
  border-color: #2779bd;
}

.focus\:tw-border-blue:focus {
  border-color: #2ac1e7;
}

.focus\:tw-border-blue-light:focus {
  border-color: #d4f2fa;
}

.focus\:tw-border-blue-lighter:focus {
  border-color: #bcdefa;
}

.focus\:tw-border-blue-lightest:focus {
  border-color: #eff8ff;
}

.focus\:tw-border-indigo-darkest:focus {
  border-color: #191e38;
}

.focus\:tw-border-indigo-darker:focus {
  border-color: #2f365f;
}

.focus\:tw-border-indigo-dark:focus {
  border-color: #5661b3;
}

.focus\:tw-border-indigo:focus {
  border-color: #6574cd;
}

.focus\:tw-border-indigo-light:focus {
  border-color: #7886d7;
}

.focus\:tw-border-indigo-lighter:focus {
  border-color: #b2b7ff;
}

.focus\:tw-border-indigo-lightest:focus {
  border-color: #e6e8ff;
}

.focus\:tw-border-purple-darkest:focus {
  border-color: #21183c;
}

.focus\:tw-border-purple-darker:focus {
  border-color: #382b5f;
}

.focus\:tw-border-purple-dark:focus {
  border-color: #794acf;
}

.focus\:tw-border-purple:focus {
  border-color: #9561e2;
}

.focus\:tw-border-purple-light:focus {
  border-color: #a779e9;
}

.focus\:tw-border-purple-lighter:focus {
  border-color: #d6bbfc;
}

.focus\:tw-border-purple-lightest:focus {
  border-color: #f3ebff;
}

.focus\:tw-border-pink-darkest:focus {
  border-color: #451225;
}

.focus\:tw-border-pink-darker:focus {
  border-color: #6f213f;
}

.focus\:tw-border-pink-dark:focus {
  border-color: #eb5286;
}

.focus\:tw-border-pink:focus {
  border-color: #f66d9b;
}

.focus\:tw-border-pink-light:focus {
  border-color: #fa7ea8;
}

.focus\:tw-border-pink-lighter:focus {
  border-color: #ffbbca;
}

.focus\:tw-border-pink-lightest:focus {
  border-color: #ffebef;
}

.tw-rounded-none {
  border-radius: 0;
}

.tw-rounded-sm {
  border-radius: .125rem;
}

.tw-rounded {
  border-radius: .25rem;
}

.tw-rounded-lg {
  border-radius: .5rem;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tw-rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tw-rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.tw-rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.tw-rounded-b-sm {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.tw-rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.tw-rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.tw-rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.tw-rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.tw-rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.tw-rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.tw-rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.tw-rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.tw-rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.tw-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.tw-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.tw-rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-tl-none {
  border-top-left-radius: 0;
}

.tw-rounded-tr-none {
  border-top-right-radius: 0;
}

.tw-rounded-br-none {
  border-bottom-right-radius: 0;
}

.tw-rounded-bl-none {
  border-bottom-left-radius: 0;
}

.tw-rounded-tl-sm {
  border-top-left-radius: .125rem;
}

.tw-rounded-tr-sm {
  border-top-right-radius: .125rem;
}

.tw-rounded-br-sm {
  border-bottom-right-radius: .125rem;
}

.tw-rounded-bl-sm {
  border-bottom-left-radius: .125rem;
}

.tw-rounded-tl {
  border-top-left-radius: .25rem;
}

.tw-rounded-tr {
  border-top-right-radius: .25rem;
}

.tw-rounded-br {
  border-bottom-right-radius: .25rem;
}

.tw-rounded-bl {
  border-bottom-left-radius: .25rem;
}

.tw-rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.tw-rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.tw-rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.tw-rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.tw-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.tw-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.tw-rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.tw-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.tw-border-solid {
  border-style: solid;
}

.tw-border-dashed {
  border-style: dashed;
}

.tw-border-dotted {
  border-style: dotted;
}

.tw-border-none {
  border-style: none;
}

.tw-border-0 {
  border-width: 0;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-4 {
  border-width: 4px;
}

.tw-border-8 {
  border-width: 8px;
}

.tw-border {
  border-width: 1px;
}

.tw-border-t-0 {
  border-top-width: 0;
}

.tw-border-r-0 {
  border-right-width: 0;
}

.tw-border-b-0 {
  border-bottom-width: 0;
}

.tw-border-l-0 {
  border-left-width: 0;
}

.tw-border-t-2 {
  border-top-width: 2px;
}

.tw-border-r-2 {
  border-right-width: 2px;
}

.tw-border-b-2 {
  border-bottom-width: 2px;
}

.tw-border-l-2 {
  border-left-width: 2px;
}

.tw-border-t-4 {
  border-top-width: 4px;
}

.tw-border-r-4 {
  border-right-width: 4px;
}

.tw-border-b-4 {
  border-bottom-width: 4px;
}

.tw-border-l-4 {
  border-left-width: 4px;
}

.tw-border-t-8 {
  border-top-width: 8px;
}

.tw-border-r-8 {
  border-right-width: 8px;
}

.tw-border-b-8 {
  border-bottom-width: 8px;
}

.tw-border-l-8 {
  border-left-width: 8px;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-r {
  border-right-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-l {
  border-left-width: 1px;
}

.tw-cursor-auto {
  cursor: auto;
}

.tw-cursor-default {
  cursor: default;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-cursor-wait {
  cursor: wait;
}

.tw-cursor-move {
  cursor: move;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-inline {
  display: inline;
}

.tw-table {
  display: table;
}

.tw-table-row {
  display: table-row;
}

.tw-table-cell {
  display: table-cell;
}

.tw-hidden {
  display: none;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-flex-row {
  flex-direction: row;
}

.tw-flex-row-reverse {
  flex-direction: row-reverse;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-col-reverse {
  flex-direction: column-reverse;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.tw-flex-no-wrap {
  flex-wrap: nowrap;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.tw-items-center {
  align-items: center;
}

.tw-items-baseline {
  align-items: baseline;
}

.tw-items-stretch {
  align-items: stretch;
}

.tw-self-auto {
  align-self: auto;
}

.tw-self-start {
  align-self: flex-start;
}

.tw-self-end {
  align-self: flex-end;
}

.tw-self-center {
  align-self: center;
}

.tw-self-stretch {
  align-self: stretch;
}

.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.tw-content-center {
  align-content: center;
}

.tw-content-start {
  align-content: flex-start;
}

.tw-content-end {
  align-content: flex-end;
}

.tw-content-between {
  align-content: space-between;
}

.tw-content-around {
  align-content: space-around;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-flex-auto {
  flex: 1 1 auto;
}

.tw-flex-initial {
  flex: 0 1 auto;
}

.tw-flex-none {
  flex: none;
}

.tw-flex-grow {
  flex-grow: 1;
}

.tw-flex-shrink {
  flex-shrink: 1;
}

.tw-flex-no-grow {
  flex-grow: 0;
}

.tw-flex-no-shrink {
  flex-shrink: 0;
}

.tw-float-right {
  float: right;
}

.tw-float-left {
  float: left;
}

.tw-float-none {
  float: none;
}

.tw-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.tw-font-sans {
  font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.tw-font-serif {
  font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
}

.tw-font-mono {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.tw-font-hairline {
  font-weight: 100;
}

.tw-font-thin {
  font-weight: 200;
}

.tw-font-light {
  font-weight: 300;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-black {
  font-weight: 900;
}

.hover\:tw-font-hairline:hover {
  font-weight: 100;
}

.hover\:tw-font-thin:hover {
  font-weight: 200;
}

.hover\:tw-font-light:hover {
  font-weight: 300;
}

.hover\:tw-font-normal:hover {
  font-weight: 400;
}

.hover\:tw-font-medium:hover {
  font-weight: 500;
}

.hover\:tw-font-semibold:hover {
  font-weight: 600;
}

.hover\:tw-font-bold:hover {
  font-weight: 700;
}

.hover\:tw-font-extrabold:hover {
  font-weight: 800;
}

.hover\:tw-font-black:hover {
  font-weight: 900;
}

.focus\:tw-font-hairline:focus {
  font-weight: 100;
}

.focus\:tw-font-thin:focus {
  font-weight: 200;
}

.focus\:tw-font-light:focus {
  font-weight: 300;
}

.focus\:tw-font-normal:focus {
  font-weight: 400;
}

.focus\:tw-font-medium:focus {
  font-weight: 500;
}

.focus\:tw-font-semibold:focus {
  font-weight: 600;
}

.focus\:tw-font-bold:focus {
  font-weight: 700;
}

.focus\:tw-font-extrabold:focus {
  font-weight: 800;
}

.focus\:tw-font-black:focus {
  font-weight: 900;
}

.tw-h-1 {
  height: .25rem;
}

.tw-h-2 {
  height: .5rem;
}

.tw-h-3 {
  height: .75rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-10 {
  height: 2.5rem;
}

.tw-h-12 {
  height: 3rem;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-24 {
  height: 6rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-h-48 {
  height: 12rem;
}

.tw-h-64 {
  height: 16rem;
}

.tw-h-auto {
  height: auto;
}

.tw-h-px {
  height: 1px;
}

.tw-h-full {
  height: 100%;
}

.tw-h-screen {
  height: 100vh;
}

.tw-leading-none {
  line-height: 1;
}

.tw-leading-tight {
  line-height: 1.25;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-leading-loose {
  line-height: 2;
}

.tw-m-0 {
  margin: 0;
}

.tw-m-1 {
  margin: .25rem;
}

.tw-m-2 {
  margin: .5rem;
}

.tw-m-3 {
  margin: .75rem;
}

.tw-m-4 {
  margin: 1rem;
}

.tw-m-5 {
  margin: 1.25rem;
}

.tw-m-6 {
  margin: 1.5rem;
}

.tw-m-8 {
  margin: 2rem;
}

.tw-m-10 {
  margin: 2.5rem;
}

.tw-m-12 {
  margin: 3rem;
}

.tw-m-16 {
  margin: 4rem;
}

.tw-m-20 {
  margin: 5rem;
}

.tw-m-24 {
  margin: 6rem;
}

.tw-m-32 {
  margin: 8rem;
}

.tw-m-auto {
  margin: auto;
}

.tw-m-px {
  margin: 1px;
}

.tw-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.tw-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.tw-mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tw-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.tw-mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.tw-my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.tw-mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.tw-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tw-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tw-mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.tw-my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.tw-mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.tw-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tw-mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.tw-my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.tw-mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.tw-my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.tw-mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.tw-my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.tw-mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.tw-my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.tw-mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.tw-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.tw-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.tw-mt-0 {
  margin-top: 0;
}

.tw-mr-0 {
  margin-right: 0;
}

.tw-mb-0 {
  margin-bottom: 0;
}

.tw-ml-0 {
  margin-left: 0;
}

.tw-mt-1 {
  margin-top: .25rem;
}

.tw-mr-1 {
  margin-right: .25rem;
}

.tw-mb-1 {
  margin-bottom: .25rem;
}

.tw-ml-1 {
  margin-left: .25rem;
}

.tw-mt-2 {
  margin-top: .5rem;
}

.tw-mr-2 {
  margin-right: .5rem;
}

.tw-mb-2 {
  margin-bottom: .5rem;
}

.tw-ml-2 {
  margin-left: .5rem;
}

.tw-mt-3 {
  margin-top: .75rem;
}

.tw-mr-3 {
  margin-right: .75rem;
}

.tw-mb-3 {
  margin-bottom: .75rem;
}

.tw-ml-3 {
  margin-left: .75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mr-4 {
  margin-right: 1rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-ml-4 {
  margin-left: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mr-5 {
  margin-right: 1.25rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-ml-5 {
  margin-left: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mr-6 {
  margin-right: 1.5rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-ml-6 {
  margin-left: 1.5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mr-8 {
  margin-right: 2rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-ml-8 {
  margin-left: 2rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-mr-10 {
  margin-right: 2.5rem;
}

.tw-mb-10 {
  margin-bottom: 2.5rem;
}

.tw-ml-10 {
  margin-left: 2.5rem;
}

.tw-mt-12 {
  margin-top: 3rem;
}

.tw-mr-12 {
  margin-right: 3rem;
}

.tw-mb-12 {
  margin-bottom: 3rem;
}

.tw-ml-12 {
  margin-left: 3rem;
}

.tw-mt-16 {
  margin-top: 4rem;
}

.tw-mr-16 {
  margin-right: 4rem;
}

.tw-mb-16 {
  margin-bottom: 4rem;
}

.tw-ml-16 {
  margin-left: 4rem;
}

.tw-mt-20 {
  margin-top: 5rem;
}

.tw-mr-20 {
  margin-right: 5rem;
}

.tw-mb-20 {
  margin-bottom: 5rem;
}

.tw-ml-20 {
  margin-left: 5rem;
}

.tw-mt-24 {
  margin-top: 6rem;
}

.tw-mr-24 {
  margin-right: 6rem;
}

.tw-mb-24 {
  margin-bottom: 6rem;
}

.tw-ml-24 {
  margin-left: 6rem;
}

.tw-mt-32 {
  margin-top: 8rem;
}

.tw-mr-32 {
  margin-right: 8rem;
}

.tw-mb-32 {
  margin-bottom: 8rem;
}

.tw-ml-32 {
  margin-left: 8rem;
}

.tw-mt-auto {
  margin-top: auto;
}

.tw-mr-auto {
  margin-right: auto;
}

.tw-mb-auto {
  margin-bottom: auto;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mt-px {
  margin-top: 1px;
}

.tw-mr-px {
  margin-right: 1px;
}

.tw-mb-px {
  margin-bottom: 1px;
}

.tw-ml-px {
  margin-left: 1px;
}

.tw-max-h-full {
  max-height: 100%;
}

.tw-max-h-screen {
  max-height: 100vh;
}

.tw-max-w-xs {
  max-width: 20rem;
}

.tw-max-w-sm {
  max-width: 30rem;
}

.tw-max-w-md {
  max-width: 40rem;
}

.tw-max-w-lg {
  max-width: 50rem;
}

.tw-max-w-xl {
  max-width: 60rem;
}

.tw-max-w-2xl {
  max-width: 70rem;
}

.tw-max-w-3xl {
  max-width: 80rem;
}

.tw-max-w-4xl {
  max-width: 90rem;
}

.tw-max-w-5xl {
  max-width: 100rem;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-min-h-0 {
  min-height: 0;
}

.tw-min-h-full {
  min-height: 100%;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.tw-min-w-0 {
  min-width: 0;
}

.tw-min-w-full {
  min-width: 100%;
}

.tw--m-0 {
  margin: 0;
}

.tw--m-1 {
  margin: -0.25rem;
}

.tw--m-2 {
  margin: -0.5rem;
}

.tw--m-3 {
  margin: -0.75rem;
}

.tw--m-4 {
  margin: -1rem;
}

.tw--m-5 {
  margin: -1.25rem;
}

.tw--m-6 {
  margin: -1.5rem;
}

.tw--m-8 {
  margin: -2rem;
}

.tw--m-10 {
  margin: -2.5rem;
}

.tw--m-12 {
  margin: -3rem;
}

.tw--m-16 {
  margin: -4rem;
}

.tw--m-20 {
  margin: -5rem;
}

.tw--m-24 {
  margin: -6rem;
}

.tw--m-32 {
  margin: -8rem;
}

.tw--m-px {
  margin: -1px;
}

.tw--my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.tw--mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.tw--my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.tw--mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.tw--my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.tw--mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.tw--my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.tw--mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.tw--my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.tw--mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.tw--my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.tw--mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.tw--my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.tw--mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.tw--my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.tw--mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.tw--my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.tw--mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.tw--my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.tw--mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.tw--my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.tw--mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.tw--my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.tw--mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.tw--my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.tw--mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.tw--my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.tw--mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.tw--my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.tw--mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.tw--mt-0 {
  margin-top: 0;
}

.tw--mr-0 {
  margin-right: 0;
}

.tw--mb-0 {
  margin-bottom: 0;
}

.tw--ml-0 {
  margin-left: 0;
}

.tw--mt-1 {
  margin-top: -0.25rem;
}

.tw--mr-1 {
  margin-right: -0.25rem;
}

.tw--mb-1 {
  margin-bottom: -0.25rem;
}

.tw--ml-1 {
  margin-left: -0.25rem;
}

.tw--mt-2 {
  margin-top: -0.5rem;
}

.tw--mr-2 {
  margin-right: -0.5rem;
}

.tw--mb-2 {
  margin-bottom: -0.5rem;
}

.tw--ml-2 {
  margin-left: -0.5rem;
}

.tw--mt-3 {
  margin-top: -0.75rem;
}

.tw--mr-3 {
  margin-right: -0.75rem;
}

.tw--mb-3 {
  margin-bottom: -0.75rem;
}

.tw--ml-3 {
  margin-left: -0.75rem;
}

.tw--mt-4 {
  margin-top: -1rem;
}

.tw--mr-4 {
  margin-right: -1rem;
}

.tw--mb-4 {
  margin-bottom: -1rem;
}

.tw--ml-4 {
  margin-left: -1rem;
}

.tw--mt-5 {
  margin-top: -1.25rem;
}

.tw--mr-5 {
  margin-right: -1.25rem;
}

.tw--mb-5 {
  margin-bottom: -1.25rem;
}

.tw--ml-5 {
  margin-left: -1.25rem;
}

.tw--mt-6 {
  margin-top: -1.5rem;
}

.tw--mr-6 {
  margin-right: -1.5rem;
}

.tw--mb-6 {
  margin-bottom: -1.5rem;
}

.tw--ml-6 {
  margin-left: -1.5rem;
}

.tw--mt-8 {
  margin-top: -2rem;
}

.tw--mr-8 {
  margin-right: -2rem;
}

.tw--mb-8 {
  margin-bottom: -2rem;
}

.tw--ml-8 {
  margin-left: -2rem;
}

.tw--mt-10 {
  margin-top: -2.5rem;
}

.tw--mr-10 {
  margin-right: -2.5rem;
}

.tw--mb-10 {
  margin-bottom: -2.5rem;
}

.tw--ml-10 {
  margin-left: -2.5rem;
}

.tw--mt-12 {
  margin-top: -3rem;
}

.tw--mr-12 {
  margin-right: -3rem;
}

.tw--mb-12 {
  margin-bottom: -3rem;
}

.tw--ml-12 {
  margin-left: -3rem;
}

.tw--mt-16 {
  margin-top: -4rem;
}

.tw--mr-16 {
  margin-right: -4rem;
}

.tw--mb-16 {
  margin-bottom: -4rem;
}

.tw--ml-16 {
  margin-left: -4rem;
}

.tw--mt-20 {
  margin-top: -5rem;
}

.tw--mr-20 {
  margin-right: -5rem;
}

.tw--mb-20 {
  margin-bottom: -5rem;
}

.tw--ml-20 {
  margin-left: -5rem;
}

.tw--mt-24 {
  margin-top: -6rem;
}

.tw--mr-24 {
  margin-right: -6rem;
}

.tw--mb-24 {
  margin-bottom: -6rem;
}

.tw--ml-24 {
  margin-left: -6rem;
}

.tw--mt-32 {
  margin-top: -8rem;
}

.tw--mr-32 {
  margin-right: -8rem;
}

.tw--mb-32 {
  margin-bottom: -8rem;
}

.tw--ml-32 {
  margin-left: -8rem;
}

.tw--mt-px {
  margin-top: -1px;
}

.tw--mr-px {
  margin-right: -1px;
}

.tw--mb-px {
  margin-bottom: -1px;
}

.tw--ml-px {
  margin-left: -1px;
}

.tw-opacity-0 {
  opacity: 0;
}

.tw-opacity-25 {
  opacity: .25;
}

.tw-opacity-50 {
  opacity: .5;
}

.tw-opacity-75 {
  opacity: .75;
}

.tw-opacity-100 {
  opacity: 1;
}

.tw-outline-none {
  outline: 0;
}

.focus\:tw-outline-none:focus {
  outline: 0;
}

.tw-overflow-auto {
  overflow: auto;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-visible {
  overflow: visible;
}

.tw-overflow-scroll {
  overflow: scroll;
}

.tw-overflow-x-auto {
  overflow-x: auto;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-x-hidden {
  overflow-x: hidden;
}

.tw-overflow-y-hidden {
  overflow-y: hidden;
}

.tw-overflow-x-visible {
  overflow-x: visible;
}

.tw-overflow-y-visible {
  overflow-y: visible;
}

.tw-overflow-x-scroll {
  overflow-x: scroll;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.tw-scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.tw-p-0 {
  padding: 0;
}

.tw-p-1 {
  padding: .25rem;
}

.tw-p-2 {
  padding: .5rem;
}

.tw-p-3 {
  padding: .75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-12 {
  padding: 3rem;
}

.tw-p-16 {
  padding: 4rem;
}

.tw-p-20 {
  padding: 5rem;
}

.tw-p-24 {
  padding: 6rem;
}

.tw-p-32 {
  padding: 8rem;
}

.tw-p-px {
  padding: 1px;
}

.tw-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.tw-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.tw-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.tw-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tw-py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.tw-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.tw-px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.tw-py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.tw-px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.tw-py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.tw-px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.tw-py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tw-px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.tw-pt-0 {
  padding-top: 0;
}

.tw-pr-0 {
  padding-right: 0;
}

.tw-pb-0 {
  padding-bottom: 0;
}

.tw-pl-0 {
  padding-left: 0;
}

.tw-pt-1 {
  padding-top: .25rem;
}

.tw-pr-1 {
  padding-right: .25rem;
}

.tw-pb-1 {
  padding-bottom: .25rem;
}

.tw-pl-1 {
  padding-left: .25rem;
}

.tw-pt-2 {
  padding-top: .5rem;
}

.tw-pr-2 {
  padding-right: .5rem;
}

.tw-pb-2 {
  padding-bottom: .5rem;
}

.tw-pl-2 {
  padding-left: .5rem;
}

.tw-pt-3 {
  padding-top: .75rem;
}

.tw-pr-3 {
  padding-right: .75rem;
}

.tw-pb-3 {
  padding-bottom: .75rem;
}

.tw-pl-3 {
  padding-left: .75rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pl-4 {
  padding-left: 1rem;
}

.tw-pt-5 {
  padding-top: 1.25rem;
}

.tw-pr-5 {
  padding-right: 1.25rem;
}

.tw-pb-5 {
  padding-bottom: 1.25rem;
}

.tw-pl-5 {
  padding-left: 1.25rem;
}

.tw-pt-6 {
  padding-top: 1.5rem;
}

.tw-pr-6 {
  padding-right: 1.5rem;
}

.tw-pb-6 {
  padding-bottom: 1.5rem;
}

.tw-pl-6 {
  padding-left: 1.5rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-pr-8 {
  padding-right: 2rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}

.tw-pl-8 {
  padding-left: 2rem;
}

.tw-pt-10 {
  padding-top: 2.5rem;
}

.tw-pr-10 {
  padding-right: 2.5rem;
}

.tw-pb-10 {
  padding-bottom: 2.5rem;
}

.tw-pl-10 {
  padding-left: 2.5rem;
}

.tw-pt-12 {
  padding-top: 3rem;
}

.tw-pr-12 {
  padding-right: 3rem;
}

.tw-pb-12 {
  padding-bottom: 3rem;
}

.tw-pl-12 {
  padding-left: 3rem;
}

.tw-pt-16 {
  padding-top: 4rem;
}

.tw-pr-16 {
  padding-right: 4rem;
}

.tw-pb-16 {
  padding-bottom: 4rem;
}

.tw-pl-16 {
  padding-left: 4rem;
}

.tw-pt-20 {
  padding-top: 5rem;
}

.tw-pr-20 {
  padding-right: 5rem;
}

.tw-pb-20 {
  padding-bottom: 5rem;
}

.tw-pl-20 {
  padding-left: 5rem;
}

.tw-pt-24 {
  padding-top: 6rem;
}

.tw-pr-24 {
  padding-right: 6rem;
}

.tw-pb-24 {
  padding-bottom: 6rem;
}

.tw-pl-24 {
  padding-left: 6rem;
}

.tw-pt-32 {
  padding-top: 8rem;
}

.tw-pr-32 {
  padding-right: 8rem;
}

.tw-pb-32 {
  padding-bottom: 8rem;
}

.tw-pl-32 {
  padding-left: 8rem;
}

.tw-pt-px {
  padding-top: 1px;
}

.tw-pr-px {
  padding-right: 1px;
}

.tw-pb-px {
  padding-bottom: 1px;
}

.tw-pl-px {
  padding-left: 1px;
}

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-pointer-events-auto {
  pointer-events: auto;
}

.tw-static {
  position: static;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.tw-pin-none {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.tw-pin {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.tw-pin-y {
  top: 0;
  bottom: 0;
}

.tw-pin-x {
  right: 0;
  left: 0;
}

.tw-pin-t {
  top: 0;
}

.tw-pin-r {
  right: 0;
}

.tw-pin-b {
  bottom: 0;
}

.tw-pin-l {
  left: 0;
}

.tw-resize-none {
  resize: none;
}

.tw-resize-y {
  resize: vertical;
}

.tw-resize-x {
  resize: horizontal;
}

.tw-resize {
  resize: both;
}

.tw-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.tw-shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.tw-shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}

.tw-shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
}

.tw-shadow-outline {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
}

.tw-shadow-none {
  box-shadow: none;
}

.hover\:tw-shadow:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.hover\:tw-shadow-md:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.hover\:tw-shadow-lg:hover {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}

.hover\:tw-shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
}

.hover\:tw-shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
}

.hover\:tw-shadow-none:hover {
  box-shadow: none;
}

.focus\:tw-shadow:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.focus\:tw-shadow-md:focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.focus\:tw-shadow-lg:focus {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}

.focus\:tw-shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
}

.focus\:tw-shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
}

.focus\:tw-shadow-none:focus {
  box-shadow: none;
}

.tw-fill-current {
  fill: currentColor;
}

.tw-stroke-current {
  stroke: currentColor;
}

.tw-table-auto {
  table-layout: auto;
}

.tw-table-fixed {
  table-layout: fixed;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-right {
  text-align: right;
}

.tw-text-justify {
  text-align: justify;
}

.tw-text-transparent {
  color: transparent;
}

.tw-text-primary {
  color: #ac826d;
}

.tw-text-text {
  color: #453f3f;
}

.tw-text-black {
  color: #000;
}

.tw-text-grey-darkest {
  color: #3d4852;
}

.tw-text-grey-darker {
  color: #606f7b;
}

.tw-text-grey-dark {
  color: #8795a1;
}

.tw-text-grey {
  color: #b8c2cc;
}

.tw-text-grey-light {
  color: #a1b3bc;
}

.tw-text-grey-lighter {
  color: #d8dfe3;
}

.tw-text-grey-lightest {
  color: #f8fafc;
}

.tw-text-grey-grayish {
  color: #56666c;
}

.tw-text-white {
  color: #fff;
}

.tw-text-red-darkest {
  color: #3b0d0c;
}

.tw-text-red-darker {
  color: #621b18;
}

.tw-text-red-dark {
  color: #cc1f1a;
}

.tw-text-red {
  color: #de5050;
}

.tw-text-red-light {
  color: #fed5dc;
}

.tw-text-red-lighter {
  color: #f9acaa;
}

.tw-text-red-lightest {
  color: #fcebea;
}

.tw-text-orange-darkest {
  color: #462a16;
}

.tw-text-orange-darker {
  color: #613b1f;
}

.tw-text-orange-dark {
  color: #de751f;
}

.tw-text-orange {
  color: #ffbf00;
}

.tw-text-orange-light {
  color: #faad63;
}

.tw-text-orange-lighter {
  color: #fcd9b6;
}

.tw-text-orange-lightest {
  color: #fff5eb;
}

.tw-text-yellow-darkest {
  color: #453411;
}

.tw-text-yellow-darker {
  color: #684f1d;
}

.tw-text-yellow-dark {
  color: #f2d024;
}

.tw-text-yellow {
  color: #f8e71c;
}

.tw-text-yellow-light {
  color: #fff382;
}

.tw-text-yellow-lighter {
  color: #fff9c2;
}

.tw-text-yellow-lightest {
  color: #fcfbeb;
}

.tw-text-green-darkest {
  color: #0f2f21;
}

.tw-text-green-darker {
  color: #1a4731;
}

.tw-text-green-dark {
  color: #1f9d55;
}

.tw-text-green {
  color: #38c172;
}

.tw-text-green-light {
  color: #51d88a;
}

.tw-text-green-lighter {
  color: #a2f5bf;
}

.tw-text-green-lightest {
  color: #e3fcec;
}

.tw-text-teal-darkest {
  color: #0d3331;
}

.tw-text-teal-darker {
  color: #20504f;
}

.tw-text-teal-dark {
  color: #38a89d;
}

.tw-text-teal {
  color: #17c7aa;
}

.tw-text-teal-light {
  color: #64d5ca;
}

.tw-text-teal-lighter {
  color: #a0f0ed;
}

.tw-text-teal-lightest {
  color: #e8fffe;
}

.tw-text-blue-darkest {
  color: #12283a;
}

.tw-text-blue-darker {
  color: #1c3d5a;
}

.tw-text-blue-dark {
  color: #2779bd;
}

.tw-text-blue {
  color: #2ac1e7;
}

.tw-text-blue-light {
  color: #d4f2fa;
}

.tw-text-blue-lighter {
  color: #bcdefa;
}

.tw-text-blue-lightest {
  color: #eff8ff;
}

.tw-text-indigo-darkest {
  color: #191e38;
}

.tw-text-indigo-darker {
  color: #2f365f;
}

.tw-text-indigo-dark {
  color: #5661b3;
}

.tw-text-indigo {
  color: #6574cd;
}

.tw-text-indigo-light {
  color: #7886d7;
}

.tw-text-indigo-lighter {
  color: #b2b7ff;
}

.tw-text-indigo-lightest {
  color: #e6e8ff;
}

.tw-text-purple-darkest {
  color: #21183c;
}

.tw-text-purple-darker {
  color: #382b5f;
}

.tw-text-purple-dark {
  color: #794acf;
}

.tw-text-purple {
  color: #9561e2;
}

.tw-text-purple-light {
  color: #a779e9;
}

.tw-text-purple-lighter {
  color: #d6bbfc;
}

.tw-text-purple-lightest {
  color: #f3ebff;
}

.tw-text-pink-darkest {
  color: #451225;
}

.tw-text-pink-darker {
  color: #6f213f;
}

.tw-text-pink-dark {
  color: #eb5286;
}

.tw-text-pink {
  color: #f66d9b;
}

.tw-text-pink-light {
  color: #fa7ea8;
}

.tw-text-pink-lighter {
  color: #ffbbca;
}

.tw-text-pink-lightest {
  color: #ffebef;
}

.hover\:tw-text-transparent:hover {
  color: transparent;
}

.hover\:tw-text-primary:hover {
  color: #ac826d;
}

.hover\:tw-text-text:hover {
  color: #453f3f;
}

.hover\:tw-text-black:hover {
  color: #000;
}

.hover\:tw-text-grey-darkest:hover {
  color: #3d4852;
}

.hover\:tw-text-grey-darker:hover {
  color: #606f7b;
}

.hover\:tw-text-grey-dark:hover {
  color: #8795a1;
}

.hover\:tw-text-grey:hover {
  color: #b8c2cc;
}

.hover\:tw-text-grey-light:hover {
  color: #a1b3bc;
}

.hover\:tw-text-grey-lighter:hover {
  color: #d8dfe3;
}

.hover\:tw-text-grey-lightest:hover {
  color: #f8fafc;
}

.hover\:tw-text-grey-grayish:hover {
  color: #56666c;
}

.hover\:tw-text-white:hover {
  color: #fff;
}

.hover\:tw-text-red-darkest:hover {
  color: #3b0d0c;
}

.hover\:tw-text-red-darker:hover {
  color: #621b18;
}

.hover\:tw-text-red-dark:hover {
  color: #cc1f1a;
}

.hover\:tw-text-red:hover {
  color: #de5050;
}

.hover\:tw-text-red-light:hover {
  color: #fed5dc;
}

.hover\:tw-text-red-lighter:hover {
  color: #f9acaa;
}

.hover\:tw-text-red-lightest:hover {
  color: #fcebea;
}

.hover\:tw-text-orange-darkest:hover {
  color: #462a16;
}

.hover\:tw-text-orange-darker:hover {
  color: #613b1f;
}

.hover\:tw-text-orange-dark:hover {
  color: #de751f;
}

.hover\:tw-text-orange:hover {
  color: #ffbf00;
}

.hover\:tw-text-orange-light:hover {
  color: #faad63;
}

.hover\:tw-text-orange-lighter:hover {
  color: #fcd9b6;
}

.hover\:tw-text-orange-lightest:hover {
  color: #fff5eb;
}

.hover\:tw-text-yellow-darkest:hover {
  color: #453411;
}

.hover\:tw-text-yellow-darker:hover {
  color: #684f1d;
}

.hover\:tw-text-yellow-dark:hover {
  color: #f2d024;
}

.hover\:tw-text-yellow:hover {
  color: #f8e71c;
}

.hover\:tw-text-yellow-light:hover {
  color: #fff382;
}

.hover\:tw-text-yellow-lighter:hover {
  color: #fff9c2;
}

.hover\:tw-text-yellow-lightest:hover {
  color: #fcfbeb;
}

.hover\:tw-text-green-darkest:hover {
  color: #0f2f21;
}

.hover\:tw-text-green-darker:hover {
  color: #1a4731;
}

.hover\:tw-text-green-dark:hover {
  color: #1f9d55;
}

.hover\:tw-text-green:hover {
  color: #38c172;
}

.hover\:tw-text-green-light:hover {
  color: #51d88a;
}

.hover\:tw-text-green-lighter:hover {
  color: #a2f5bf;
}

.hover\:tw-text-green-lightest:hover {
  color: #e3fcec;
}

.hover\:tw-text-teal-darkest:hover {
  color: #0d3331;
}

.hover\:tw-text-teal-darker:hover {
  color: #20504f;
}

.hover\:tw-text-teal-dark:hover {
  color: #38a89d;
}

.hover\:tw-text-teal:hover {
  color: #17c7aa;
}

.hover\:tw-text-teal-light:hover {
  color: #64d5ca;
}

.hover\:tw-text-teal-lighter:hover {
  color: #a0f0ed;
}

.hover\:tw-text-teal-lightest:hover {
  color: #e8fffe;
}

.hover\:tw-text-blue-darkest:hover {
  color: #12283a;
}

.hover\:tw-text-blue-darker:hover {
  color: #1c3d5a;
}

.hover\:tw-text-blue-dark:hover {
  color: #2779bd;
}

.hover\:tw-text-blue:hover {
  color: #2ac1e7;
}

.hover\:tw-text-blue-light:hover {
  color: #d4f2fa;
}

.hover\:tw-text-blue-lighter:hover {
  color: #bcdefa;
}

.hover\:tw-text-blue-lightest:hover {
  color: #eff8ff;
}

.hover\:tw-text-indigo-darkest:hover {
  color: #191e38;
}

.hover\:tw-text-indigo-darker:hover {
  color: #2f365f;
}

.hover\:tw-text-indigo-dark:hover {
  color: #5661b3;
}

.hover\:tw-text-indigo:hover {
  color: #6574cd;
}

.hover\:tw-text-indigo-light:hover {
  color: #7886d7;
}

.hover\:tw-text-indigo-lighter:hover {
  color: #b2b7ff;
}

.hover\:tw-text-indigo-lightest:hover {
  color: #e6e8ff;
}

.hover\:tw-text-purple-darkest:hover {
  color: #21183c;
}

.hover\:tw-text-purple-darker:hover {
  color: #382b5f;
}

.hover\:tw-text-purple-dark:hover {
  color: #794acf;
}

.hover\:tw-text-purple:hover {
  color: #9561e2;
}

.hover\:tw-text-purple-light:hover {
  color: #a779e9;
}

.hover\:tw-text-purple-lighter:hover {
  color: #d6bbfc;
}

.hover\:tw-text-purple-lightest:hover {
  color: #f3ebff;
}

.hover\:tw-text-pink-darkest:hover {
  color: #451225;
}

.hover\:tw-text-pink-darker:hover {
  color: #6f213f;
}

.hover\:tw-text-pink-dark:hover {
  color: #eb5286;
}

.hover\:tw-text-pink:hover {
  color: #f66d9b;
}

.hover\:tw-text-pink-light:hover {
  color: #fa7ea8;
}

.hover\:tw-text-pink-lighter:hover {
  color: #ffbbca;
}

.hover\:tw-text-pink-lightest:hover {
  color: #ffebef;
}

.focus\:tw-text-transparent:focus {
  color: transparent;
}

.focus\:tw-text-primary:focus {
  color: #ac826d;
}

.focus\:tw-text-text:focus {
  color: #453f3f;
}

.focus\:tw-text-black:focus {
  color: #000;
}

.focus\:tw-text-grey-darkest:focus {
  color: #3d4852;
}

.focus\:tw-text-grey-darker:focus {
  color: #606f7b;
}

.focus\:tw-text-grey-dark:focus {
  color: #8795a1;
}

.focus\:tw-text-grey:focus {
  color: #b8c2cc;
}

.focus\:tw-text-grey-light:focus {
  color: #a1b3bc;
}

.focus\:tw-text-grey-lighter:focus {
  color: #d8dfe3;
}

.focus\:tw-text-grey-lightest:focus {
  color: #f8fafc;
}

.focus\:tw-text-grey-grayish:focus {
  color: #56666c;
}

.focus\:tw-text-white:focus {
  color: #fff;
}

.focus\:tw-text-red-darkest:focus {
  color: #3b0d0c;
}

.focus\:tw-text-red-darker:focus {
  color: #621b18;
}

.focus\:tw-text-red-dark:focus {
  color: #cc1f1a;
}

.focus\:tw-text-red:focus {
  color: #de5050;
}

.focus\:tw-text-red-light:focus {
  color: #fed5dc;
}

.focus\:tw-text-red-lighter:focus {
  color: #f9acaa;
}

.focus\:tw-text-red-lightest:focus {
  color: #fcebea;
}

.focus\:tw-text-orange-darkest:focus {
  color: #462a16;
}

.focus\:tw-text-orange-darker:focus {
  color: #613b1f;
}

.focus\:tw-text-orange-dark:focus {
  color: #de751f;
}

.focus\:tw-text-orange:focus {
  color: #ffbf00;
}

.focus\:tw-text-orange-light:focus {
  color: #faad63;
}

.focus\:tw-text-orange-lighter:focus {
  color: #fcd9b6;
}

.focus\:tw-text-orange-lightest:focus {
  color: #fff5eb;
}

.focus\:tw-text-yellow-darkest:focus {
  color: #453411;
}

.focus\:tw-text-yellow-darker:focus {
  color: #684f1d;
}

.focus\:tw-text-yellow-dark:focus {
  color: #f2d024;
}

.focus\:tw-text-yellow:focus {
  color: #f8e71c;
}

.focus\:tw-text-yellow-light:focus {
  color: #fff382;
}

.focus\:tw-text-yellow-lighter:focus {
  color: #fff9c2;
}

.focus\:tw-text-yellow-lightest:focus {
  color: #fcfbeb;
}

.focus\:tw-text-green-darkest:focus {
  color: #0f2f21;
}

.focus\:tw-text-green-darker:focus {
  color: #1a4731;
}

.focus\:tw-text-green-dark:focus {
  color: #1f9d55;
}

.focus\:tw-text-green:focus {
  color: #38c172;
}

.focus\:tw-text-green-light:focus {
  color: #51d88a;
}

.focus\:tw-text-green-lighter:focus {
  color: #a2f5bf;
}

.focus\:tw-text-green-lightest:focus {
  color: #e3fcec;
}

.focus\:tw-text-teal-darkest:focus {
  color: #0d3331;
}

.focus\:tw-text-teal-darker:focus {
  color: #20504f;
}

.focus\:tw-text-teal-dark:focus {
  color: #38a89d;
}

.focus\:tw-text-teal:focus {
  color: #17c7aa;
}

.focus\:tw-text-teal-light:focus {
  color: #64d5ca;
}

.focus\:tw-text-teal-lighter:focus {
  color: #a0f0ed;
}

.focus\:tw-text-teal-lightest:focus {
  color: #e8fffe;
}

.focus\:tw-text-blue-darkest:focus {
  color: #12283a;
}

.focus\:tw-text-blue-darker:focus {
  color: #1c3d5a;
}

.focus\:tw-text-blue-dark:focus {
  color: #2779bd;
}

.focus\:tw-text-blue:focus {
  color: #2ac1e7;
}

.focus\:tw-text-blue-light:focus {
  color: #d4f2fa;
}

.focus\:tw-text-blue-lighter:focus {
  color: #bcdefa;
}

.focus\:tw-text-blue-lightest:focus {
  color: #eff8ff;
}

.focus\:tw-text-indigo-darkest:focus {
  color: #191e38;
}

.focus\:tw-text-indigo-darker:focus {
  color: #2f365f;
}

.focus\:tw-text-indigo-dark:focus {
  color: #5661b3;
}

.focus\:tw-text-indigo:focus {
  color: #6574cd;
}

.focus\:tw-text-indigo-light:focus {
  color: #7886d7;
}

.focus\:tw-text-indigo-lighter:focus {
  color: #b2b7ff;
}

.focus\:tw-text-indigo-lightest:focus {
  color: #e6e8ff;
}

.focus\:tw-text-purple-darkest:focus {
  color: #21183c;
}

.focus\:tw-text-purple-darker:focus {
  color: #382b5f;
}

.focus\:tw-text-purple-dark:focus {
  color: #794acf;
}

.focus\:tw-text-purple:focus {
  color: #9561e2;
}

.focus\:tw-text-purple-light:focus {
  color: #a779e9;
}

.focus\:tw-text-purple-lighter:focus {
  color: #d6bbfc;
}

.focus\:tw-text-purple-lightest:focus {
  color: #f3ebff;
}

.focus\:tw-text-pink-darkest:focus {
  color: #451225;
}

.focus\:tw-text-pink-darker:focus {
  color: #6f213f;
}

.focus\:tw-text-pink-dark:focus {
  color: #eb5286;
}

.focus\:tw-text-pink:focus {
  color: #f66d9b;
}

.focus\:tw-text-pink-light:focus {
  color: #fa7ea8;
}

.focus\:tw-text-pink-lighter:focus {
  color: #ffbbca;
}

.focus\:tw-text-pink-lightest:focus {
  color: #ffebef;
}

.tw-text-xsss {
  font-size: 8px;
}

.tw-text-xss {
  font-size: 10px;
}

.tw-text-xs {
  font-size: .75rem;
}

.tw-text-sm {
  font-size: .875rem;
}

.tw-text-base {
  font-size: 1rem;
}

.tw-text-lg {
  font-size: 1.125rem;
}

.tw-text-xl {
  font-size: 1.25rem;
}

.tw-text-2xl {
  font-size: 1.5rem;
}

.tw-text-3xl {
  font-size: 1.875rem;
}

.tw-text-4xl {
  font-size: 2.25rem;
}

.tw-text-5xl {
  font-size: 3rem;
}

.tw-italic {
  font-style: italic;
}

.tw-roman {
  font-style: normal;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-lowercase {
  text-transform: lowercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-normal-case {
  text-transform: none;
}

.tw-underline {
  text-decoration: underline;
}

.tw-line-through {
  text-decoration: line-through;
}

.tw-no-underline {
  text-decoration: none;
}

.tw-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tw-subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.hover\:tw-italic:hover {
  font-style: italic;
}

.hover\:tw-roman:hover {
  font-style: normal;
}

.hover\:tw-uppercase:hover {
  text-transform: uppercase;
}

.hover\:tw-lowercase:hover {
  text-transform: lowercase;
}

.hover\:tw-capitalize:hover {
  text-transform: capitalize;
}

.hover\:tw-normal-case:hover {
  text-transform: none;
}

.hover\:tw-underline:hover {
  text-decoration: underline;
}

.hover\:tw-line-through:hover {
  text-decoration: line-through;
}

.hover\:tw-no-underline:hover {
  text-decoration: none;
}

.hover\:tw-antialiased:hover {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hover\:tw-subpixel-antialiased:hover {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.focus\:tw-italic:focus {
  font-style: italic;
}

.focus\:tw-roman:focus {
  font-style: normal;
}

.focus\:tw-uppercase:focus {
  text-transform: uppercase;
}

.focus\:tw-lowercase:focus {
  text-transform: lowercase;
}

.focus\:tw-capitalize:focus {
  text-transform: capitalize;
}

.focus\:tw-normal-case:focus {
  text-transform: none;
}

.focus\:tw-underline:focus {
  text-decoration: underline;
}

.focus\:tw-line-through:focus {
  text-decoration: line-through;
}

.focus\:tw-no-underline:focus {
  text-decoration: none;
}

.focus\:tw-antialiased:focus {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.focus\:tw-subpixel-antialiased:focus {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.tw-tracking-tight {
  letter-spacing: -0.05em;
}

.tw-tracking-normal {
  letter-spacing: 0;
}

.tw-tracking-wide {
  letter-spacing: .05em;
}

.tw-select-none {
  -webkit-user-select: none;
          user-select: none;
}

.tw-select-text {
  -webkit-user-select: text;
          user-select: text;
}

.tw-align-baseline {
  vertical-align: baseline;
}

.tw-align-top {
  vertical-align: top;
}

.tw-align-middle {
  vertical-align: middle;
}

.tw-align-bottom {
  vertical-align: bottom;
}

.tw-align-text-top {
  vertical-align: text-top;
}

.tw-align-text-bottom {
  vertical-align: text-bottom;
}

.tw-visible {
  visibility: visible;
}

.tw-invisible {
  visibility: hidden;
}

.tw-whitespace-normal {
  white-space: normal;
}

.tw-whitespace-no-wrap {
  white-space: nowrap;
}

.tw-whitespace-pre {
  white-space: pre;
}

.tw-whitespace-pre-line {
  white-space: pre-line;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-words {
  word-wrap: break-word;
}

.tw-break-normal {
  word-wrap: normal;
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tw-w-1 {
  width: .25rem;
}

.tw-w-2 {
  width: .5rem;
}

.tw-w-3 {
  width: .75rem;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-10 {
  width: 2.5rem;
}

.tw-w-12 {
  width: 3rem;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-24 {
  width: 6rem;
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-48 {
  width: 12rem;
}

.tw-w-64 {
  width: 16rem;
}

.tw-w-auto {
  width: auto;
}

.tw-w-px {
  width: 1px;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-1\/3 {
  width: 33.33333%;
}

.tw-w-2\/3 {
  width: 66.66667%;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-3\/4 {
  width: 75%;
}

.tw-w-1\/5 {
  width: 20%;
}

.tw-w-2\/5 {
  width: 40%;
}

.tw-w-3\/5 {
  width: 60%;
}

.tw-w-4\/5 {
  width: 80%;
}

.tw-w-1\/6 {
  width: 16.66667%;
}

.tw-w-5\/6 {
  width: 83.33333%;
}

.tw-w-full {
  width: 100%;
}

.tw-w-screen {
  width: 100vw;
}

.tw-z-0 {
  z-index: 0;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-20 {
  z-index: 20;
}

.tw-z-30 {
  z-index: 30;
}

.tw-z-40 {
  z-index: 40;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-auto {
  z-index: auto;
}

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */

@media (min-width: 576px) {
  .sm\:tw-list-reset {
    list-style: none;
    padding: 0;
  }

  .sm\:tw-appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .sm\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .sm\:tw-bg-local {
    background-attachment: local;
  }

  .sm\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .sm\:tw-bg-transparent {
    background-color: transparent;
  }

  .sm\:tw-bg-primary {
    background-color: #ac826d;
  }

  .sm\:tw-bg-text {
    background-color: #453f3f;
  }

  .sm\:tw-bg-black {
    background-color: #000;
  }

  .sm\:tw-bg-grey-darkest {
    background-color: #3d4852;
  }

  .sm\:tw-bg-grey-darker {
    background-color: #606f7b;
  }

  .sm\:tw-bg-grey-dark {
    background-color: #8795a1;
  }

  .sm\:tw-bg-grey {
    background-color: #b8c2cc;
  }

  .sm\:tw-bg-grey-light {
    background-color: #a1b3bc;
  }

  .sm\:tw-bg-grey-lighter {
    background-color: #d8dfe3;
  }

  .sm\:tw-bg-grey-lightest {
    background-color: #f8fafc;
  }

  .sm\:tw-bg-grey-grayish {
    background-color: #56666c;
  }

  .sm\:tw-bg-white {
    background-color: #fff;
  }

  .sm\:tw-bg-red-darkest {
    background-color: #3b0d0c;
  }

  .sm\:tw-bg-red-darker {
    background-color: #621b18;
  }

  .sm\:tw-bg-red-dark {
    background-color: #cc1f1a;
  }

  .sm\:tw-bg-red {
    background-color: #de5050;
  }

  .sm\:tw-bg-red-light {
    background-color: #fed5dc;
  }

  .sm\:tw-bg-red-lighter {
    background-color: #f9acaa;
  }

  .sm\:tw-bg-red-lightest {
    background-color: #fcebea;
  }

  .sm\:tw-bg-orange-darkest {
    background-color: #462a16;
  }

  .sm\:tw-bg-orange-darker {
    background-color: #613b1f;
  }

  .sm\:tw-bg-orange-dark {
    background-color: #de751f;
  }

  .sm\:tw-bg-orange {
    background-color: #ffbf00;
  }

  .sm\:tw-bg-orange-light {
    background-color: #faad63;
  }

  .sm\:tw-bg-orange-lighter {
    background-color: #fcd9b6;
  }

  .sm\:tw-bg-orange-lightest {
    background-color: #fff5eb;
  }

  .sm\:tw-bg-yellow-darkest {
    background-color: #453411;
  }

  .sm\:tw-bg-yellow-darker {
    background-color: #684f1d;
  }

  .sm\:tw-bg-yellow-dark {
    background-color: #f2d024;
  }

  .sm\:tw-bg-yellow {
    background-color: #f8e71c;
  }

  .sm\:tw-bg-yellow-light {
    background-color: #fff382;
  }

  .sm\:tw-bg-yellow-lighter {
    background-color: #fff9c2;
  }

  .sm\:tw-bg-yellow-lightest {
    background-color: #fcfbeb;
  }

  .sm\:tw-bg-green-darkest {
    background-color: #0f2f21;
  }

  .sm\:tw-bg-green-darker {
    background-color: #1a4731;
  }

  .sm\:tw-bg-green-dark {
    background-color: #1f9d55;
  }

  .sm\:tw-bg-green {
    background-color: #38c172;
  }

  .sm\:tw-bg-green-light {
    background-color: #51d88a;
  }

  .sm\:tw-bg-green-lighter {
    background-color: #a2f5bf;
  }

  .sm\:tw-bg-green-lightest {
    background-color: #e3fcec;
  }

  .sm\:tw-bg-teal-darkest {
    background-color: #0d3331;
  }

  .sm\:tw-bg-teal-darker {
    background-color: #20504f;
  }

  .sm\:tw-bg-teal-dark {
    background-color: #38a89d;
  }

  .sm\:tw-bg-teal {
    background-color: #17c7aa;
  }

  .sm\:tw-bg-teal-light {
    background-color: #64d5ca;
  }

  .sm\:tw-bg-teal-lighter {
    background-color: #a0f0ed;
  }

  .sm\:tw-bg-teal-lightest {
    background-color: #e8fffe;
  }

  .sm\:tw-bg-blue-darkest {
    background-color: #12283a;
  }

  .sm\:tw-bg-blue-darker {
    background-color: #1c3d5a;
  }

  .sm\:tw-bg-blue-dark {
    background-color: #2779bd;
  }

  .sm\:tw-bg-blue {
    background-color: #2ac1e7;
  }

  .sm\:tw-bg-blue-light {
    background-color: #d4f2fa;
  }

  .sm\:tw-bg-blue-lighter {
    background-color: #bcdefa;
  }

  .sm\:tw-bg-blue-lightest {
    background-color: #eff8ff;
  }

  .sm\:tw-bg-indigo-darkest {
    background-color: #191e38;
  }

  .sm\:tw-bg-indigo-darker {
    background-color: #2f365f;
  }

  .sm\:tw-bg-indigo-dark {
    background-color: #5661b3;
  }

  .sm\:tw-bg-indigo {
    background-color: #6574cd;
  }

  .sm\:tw-bg-indigo-light {
    background-color: #7886d7;
  }

  .sm\:tw-bg-indigo-lighter {
    background-color: #b2b7ff;
  }

  .sm\:tw-bg-indigo-lightest {
    background-color: #e6e8ff;
  }

  .sm\:tw-bg-purple-darkest {
    background-color: #21183c;
  }

  .sm\:tw-bg-purple-darker {
    background-color: #382b5f;
  }

  .sm\:tw-bg-purple-dark {
    background-color: #794acf;
  }

  .sm\:tw-bg-purple {
    background-color: #9561e2;
  }

  .sm\:tw-bg-purple-light {
    background-color: #a779e9;
  }

  .sm\:tw-bg-purple-lighter {
    background-color: #d6bbfc;
  }

  .sm\:tw-bg-purple-lightest {
    background-color: #f3ebff;
  }

  .sm\:tw-bg-pink-darkest {
    background-color: #451225;
  }

  .sm\:tw-bg-pink-darker {
    background-color: #6f213f;
  }

  .sm\:tw-bg-pink-dark {
    background-color: #eb5286;
  }

  .sm\:tw-bg-pink {
    background-color: #f66d9b;
  }

  .sm\:tw-bg-pink-light {
    background-color: #fa7ea8;
  }

  .sm\:tw-bg-pink-lighter {
    background-color: #ffbbca;
  }

  .sm\:tw-bg-pink-lightest {
    background-color: #ffebef;
  }

  .sm\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .sm\:hover\:tw-bg-primary:hover {
    background-color: #ac826d;
  }

  .sm\:hover\:tw-bg-text:hover {
    background-color: #453f3f;
  }

  .sm\:hover\:tw-bg-black:hover {
    background-color: #000;
  }

  .sm\:hover\:tw-bg-grey-darkest:hover {
    background-color: #3d4852;
  }

  .sm\:hover\:tw-bg-grey-darker:hover {
    background-color: #606f7b;
  }

  .sm\:hover\:tw-bg-grey-dark:hover {
    background-color: #8795a1;
  }

  .sm\:hover\:tw-bg-grey:hover {
    background-color: #b8c2cc;
  }

  .sm\:hover\:tw-bg-grey-light:hover {
    background-color: #a1b3bc;
  }

  .sm\:hover\:tw-bg-grey-lighter:hover {
    background-color: #d8dfe3;
  }

  .sm\:hover\:tw-bg-grey-lightest:hover {
    background-color: #f8fafc;
  }

  .sm\:hover\:tw-bg-grey-grayish:hover {
    background-color: #56666c;
  }

  .sm\:hover\:tw-bg-white:hover {
    background-color: #fff;
  }

  .sm\:hover\:tw-bg-red-darkest:hover {
    background-color: #3b0d0c;
  }

  .sm\:hover\:tw-bg-red-darker:hover {
    background-color: #621b18;
  }

  .sm\:hover\:tw-bg-red-dark:hover {
    background-color: #cc1f1a;
  }

  .sm\:hover\:tw-bg-red:hover {
    background-color: #de5050;
  }

  .sm\:hover\:tw-bg-red-light:hover {
    background-color: #fed5dc;
  }

  .sm\:hover\:tw-bg-red-lighter:hover {
    background-color: #f9acaa;
  }

  .sm\:hover\:tw-bg-red-lightest:hover {
    background-color: #fcebea;
  }

  .sm\:hover\:tw-bg-orange-darkest:hover {
    background-color: #462a16;
  }

  .sm\:hover\:tw-bg-orange-darker:hover {
    background-color: #613b1f;
  }

  .sm\:hover\:tw-bg-orange-dark:hover {
    background-color: #de751f;
  }

  .sm\:hover\:tw-bg-orange:hover {
    background-color: #ffbf00;
  }

  .sm\:hover\:tw-bg-orange-light:hover {
    background-color: #faad63;
  }

  .sm\:hover\:tw-bg-orange-lighter:hover {
    background-color: #fcd9b6;
  }

  .sm\:hover\:tw-bg-orange-lightest:hover {
    background-color: #fff5eb;
  }

  .sm\:hover\:tw-bg-yellow-darkest:hover {
    background-color: #453411;
  }

  .sm\:hover\:tw-bg-yellow-darker:hover {
    background-color: #684f1d;
  }

  .sm\:hover\:tw-bg-yellow-dark:hover {
    background-color: #f2d024;
  }

  .sm\:hover\:tw-bg-yellow:hover {
    background-color: #f8e71c;
  }

  .sm\:hover\:tw-bg-yellow-light:hover {
    background-color: #fff382;
  }

  .sm\:hover\:tw-bg-yellow-lighter:hover {
    background-color: #fff9c2;
  }

  .sm\:hover\:tw-bg-yellow-lightest:hover {
    background-color: #fcfbeb;
  }

  .sm\:hover\:tw-bg-green-darkest:hover {
    background-color: #0f2f21;
  }

  .sm\:hover\:tw-bg-green-darker:hover {
    background-color: #1a4731;
  }

  .sm\:hover\:tw-bg-green-dark:hover {
    background-color: #1f9d55;
  }

  .sm\:hover\:tw-bg-green:hover {
    background-color: #38c172;
  }

  .sm\:hover\:tw-bg-green-light:hover {
    background-color: #51d88a;
  }

  .sm\:hover\:tw-bg-green-lighter:hover {
    background-color: #a2f5bf;
  }

  .sm\:hover\:tw-bg-green-lightest:hover {
    background-color: #e3fcec;
  }

  .sm\:hover\:tw-bg-teal-darkest:hover {
    background-color: #0d3331;
  }

  .sm\:hover\:tw-bg-teal-darker:hover {
    background-color: #20504f;
  }

  .sm\:hover\:tw-bg-teal-dark:hover {
    background-color: #38a89d;
  }

  .sm\:hover\:tw-bg-teal:hover {
    background-color: #17c7aa;
  }

  .sm\:hover\:tw-bg-teal-light:hover {
    background-color: #64d5ca;
  }

  .sm\:hover\:tw-bg-teal-lighter:hover {
    background-color: #a0f0ed;
  }

  .sm\:hover\:tw-bg-teal-lightest:hover {
    background-color: #e8fffe;
  }

  .sm\:hover\:tw-bg-blue-darkest:hover {
    background-color: #12283a;
  }

  .sm\:hover\:tw-bg-blue-darker:hover {
    background-color: #1c3d5a;
  }

  .sm\:hover\:tw-bg-blue-dark:hover {
    background-color: #2779bd;
  }

  .sm\:hover\:tw-bg-blue:hover {
    background-color: #2ac1e7;
  }

  .sm\:hover\:tw-bg-blue-light:hover {
    background-color: #d4f2fa;
  }

  .sm\:hover\:tw-bg-blue-lighter:hover {
    background-color: #bcdefa;
  }

  .sm\:hover\:tw-bg-blue-lightest:hover {
    background-color: #eff8ff;
  }

  .sm\:hover\:tw-bg-indigo-darkest:hover {
    background-color: #191e38;
  }

  .sm\:hover\:tw-bg-indigo-darker:hover {
    background-color: #2f365f;
  }

  .sm\:hover\:tw-bg-indigo-dark:hover {
    background-color: #5661b3;
  }

  .sm\:hover\:tw-bg-indigo:hover {
    background-color: #6574cd;
  }

  .sm\:hover\:tw-bg-indigo-light:hover {
    background-color: #7886d7;
  }

  .sm\:hover\:tw-bg-indigo-lighter:hover {
    background-color: #b2b7ff;
  }

  .sm\:hover\:tw-bg-indigo-lightest:hover {
    background-color: #e6e8ff;
  }

  .sm\:hover\:tw-bg-purple-darkest:hover {
    background-color: #21183c;
  }

  .sm\:hover\:tw-bg-purple-darker:hover {
    background-color: #382b5f;
  }

  .sm\:hover\:tw-bg-purple-dark:hover {
    background-color: #794acf;
  }

  .sm\:hover\:tw-bg-purple:hover {
    background-color: #9561e2;
  }

  .sm\:hover\:tw-bg-purple-light:hover {
    background-color: #a779e9;
  }

  .sm\:hover\:tw-bg-purple-lighter:hover {
    background-color: #d6bbfc;
  }

  .sm\:hover\:tw-bg-purple-lightest:hover {
    background-color: #f3ebff;
  }

  .sm\:hover\:tw-bg-pink-darkest:hover {
    background-color: #451225;
  }

  .sm\:hover\:tw-bg-pink-darker:hover {
    background-color: #6f213f;
  }

  .sm\:hover\:tw-bg-pink-dark:hover {
    background-color: #eb5286;
  }

  .sm\:hover\:tw-bg-pink:hover {
    background-color: #f66d9b;
  }

  .sm\:hover\:tw-bg-pink-light:hover {
    background-color: #fa7ea8;
  }

  .sm\:hover\:tw-bg-pink-lighter:hover {
    background-color: #ffbbca;
  }

  .sm\:hover\:tw-bg-pink-lightest:hover {
    background-color: #ffebef;
  }

  .sm\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .sm\:focus\:tw-bg-primary:focus {
    background-color: #ac826d;
  }

  .sm\:focus\:tw-bg-text:focus {
    background-color: #453f3f;
  }

  .sm\:focus\:tw-bg-black:focus {
    background-color: #000;
  }

  .sm\:focus\:tw-bg-grey-darkest:focus {
    background-color: #3d4852;
  }

  .sm\:focus\:tw-bg-grey-darker:focus {
    background-color: #606f7b;
  }

  .sm\:focus\:tw-bg-grey-dark:focus {
    background-color: #8795a1;
  }

  .sm\:focus\:tw-bg-grey:focus {
    background-color: #b8c2cc;
  }

  .sm\:focus\:tw-bg-grey-light:focus {
    background-color: #a1b3bc;
  }

  .sm\:focus\:tw-bg-grey-lighter:focus {
    background-color: #d8dfe3;
  }

  .sm\:focus\:tw-bg-grey-lightest:focus {
    background-color: #f8fafc;
  }

  .sm\:focus\:tw-bg-grey-grayish:focus {
    background-color: #56666c;
  }

  .sm\:focus\:tw-bg-white:focus {
    background-color: #fff;
  }

  .sm\:focus\:tw-bg-red-darkest:focus {
    background-color: #3b0d0c;
  }

  .sm\:focus\:tw-bg-red-darker:focus {
    background-color: #621b18;
  }

  .sm\:focus\:tw-bg-red-dark:focus {
    background-color: #cc1f1a;
  }

  .sm\:focus\:tw-bg-red:focus {
    background-color: #de5050;
  }

  .sm\:focus\:tw-bg-red-light:focus {
    background-color: #fed5dc;
  }

  .sm\:focus\:tw-bg-red-lighter:focus {
    background-color: #f9acaa;
  }

  .sm\:focus\:tw-bg-red-lightest:focus {
    background-color: #fcebea;
  }

  .sm\:focus\:tw-bg-orange-darkest:focus {
    background-color: #462a16;
  }

  .sm\:focus\:tw-bg-orange-darker:focus {
    background-color: #613b1f;
  }

  .sm\:focus\:tw-bg-orange-dark:focus {
    background-color: #de751f;
  }

  .sm\:focus\:tw-bg-orange:focus {
    background-color: #ffbf00;
  }

  .sm\:focus\:tw-bg-orange-light:focus {
    background-color: #faad63;
  }

  .sm\:focus\:tw-bg-orange-lighter:focus {
    background-color: #fcd9b6;
  }

  .sm\:focus\:tw-bg-orange-lightest:focus {
    background-color: #fff5eb;
  }

  .sm\:focus\:tw-bg-yellow-darkest:focus {
    background-color: #453411;
  }

  .sm\:focus\:tw-bg-yellow-darker:focus {
    background-color: #684f1d;
  }

  .sm\:focus\:tw-bg-yellow-dark:focus {
    background-color: #f2d024;
  }

  .sm\:focus\:tw-bg-yellow:focus {
    background-color: #f8e71c;
  }

  .sm\:focus\:tw-bg-yellow-light:focus {
    background-color: #fff382;
  }

  .sm\:focus\:tw-bg-yellow-lighter:focus {
    background-color: #fff9c2;
  }

  .sm\:focus\:tw-bg-yellow-lightest:focus {
    background-color: #fcfbeb;
  }

  .sm\:focus\:tw-bg-green-darkest:focus {
    background-color: #0f2f21;
  }

  .sm\:focus\:tw-bg-green-darker:focus {
    background-color: #1a4731;
  }

  .sm\:focus\:tw-bg-green-dark:focus {
    background-color: #1f9d55;
  }

  .sm\:focus\:tw-bg-green:focus {
    background-color: #38c172;
  }

  .sm\:focus\:tw-bg-green-light:focus {
    background-color: #51d88a;
  }

  .sm\:focus\:tw-bg-green-lighter:focus {
    background-color: #a2f5bf;
  }

  .sm\:focus\:tw-bg-green-lightest:focus {
    background-color: #e3fcec;
  }

  .sm\:focus\:tw-bg-teal-darkest:focus {
    background-color: #0d3331;
  }

  .sm\:focus\:tw-bg-teal-darker:focus {
    background-color: #20504f;
  }

  .sm\:focus\:tw-bg-teal-dark:focus {
    background-color: #38a89d;
  }

  .sm\:focus\:tw-bg-teal:focus {
    background-color: #17c7aa;
  }

  .sm\:focus\:tw-bg-teal-light:focus {
    background-color: #64d5ca;
  }

  .sm\:focus\:tw-bg-teal-lighter:focus {
    background-color: #a0f0ed;
  }

  .sm\:focus\:tw-bg-teal-lightest:focus {
    background-color: #e8fffe;
  }

  .sm\:focus\:tw-bg-blue-darkest:focus {
    background-color: #12283a;
  }

  .sm\:focus\:tw-bg-blue-darker:focus {
    background-color: #1c3d5a;
  }

  .sm\:focus\:tw-bg-blue-dark:focus {
    background-color: #2779bd;
  }

  .sm\:focus\:tw-bg-blue:focus {
    background-color: #2ac1e7;
  }

  .sm\:focus\:tw-bg-blue-light:focus {
    background-color: #d4f2fa;
  }

  .sm\:focus\:tw-bg-blue-lighter:focus {
    background-color: #bcdefa;
  }

  .sm\:focus\:tw-bg-blue-lightest:focus {
    background-color: #eff8ff;
  }

  .sm\:focus\:tw-bg-indigo-darkest:focus {
    background-color: #191e38;
  }

  .sm\:focus\:tw-bg-indigo-darker:focus {
    background-color: #2f365f;
  }

  .sm\:focus\:tw-bg-indigo-dark:focus {
    background-color: #5661b3;
  }

  .sm\:focus\:tw-bg-indigo:focus {
    background-color: #6574cd;
  }

  .sm\:focus\:tw-bg-indigo-light:focus {
    background-color: #7886d7;
  }

  .sm\:focus\:tw-bg-indigo-lighter:focus {
    background-color: #b2b7ff;
  }

  .sm\:focus\:tw-bg-indigo-lightest:focus {
    background-color: #e6e8ff;
  }

  .sm\:focus\:tw-bg-purple-darkest:focus {
    background-color: #21183c;
  }

  .sm\:focus\:tw-bg-purple-darker:focus {
    background-color: #382b5f;
  }

  .sm\:focus\:tw-bg-purple-dark:focus {
    background-color: #794acf;
  }

  .sm\:focus\:tw-bg-purple:focus {
    background-color: #9561e2;
  }

  .sm\:focus\:tw-bg-purple-light:focus {
    background-color: #a779e9;
  }

  .sm\:focus\:tw-bg-purple-lighter:focus {
    background-color: #d6bbfc;
  }

  .sm\:focus\:tw-bg-purple-lightest:focus {
    background-color: #f3ebff;
  }

  .sm\:focus\:tw-bg-pink-darkest:focus {
    background-color: #451225;
  }

  .sm\:focus\:tw-bg-pink-darker:focus {
    background-color: #6f213f;
  }

  .sm\:focus\:tw-bg-pink-dark:focus {
    background-color: #eb5286;
  }

  .sm\:focus\:tw-bg-pink:focus {
    background-color: #f66d9b;
  }

  .sm\:focus\:tw-bg-pink-light:focus {
    background-color: #fa7ea8;
  }

  .sm\:focus\:tw-bg-pink-lighter:focus {
    background-color: #ffbbca;
  }

  .sm\:focus\:tw-bg-pink-lightest:focus {
    background-color: #ffebef;
  }

  .sm\:tw-bg-bottom {
    background-position: bottom;
  }

  .sm\:tw-bg-center {
    background-position: center;
  }

  .sm\:tw-bg-left {
    background-position: left;
  }

  .sm\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .sm\:tw-bg-left-top {
    background-position: left top;
  }

  .sm\:tw-bg-right {
    background-position: right;
  }

  .sm\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .sm\:tw-bg-right-top {
    background-position: right top;
  }

  .sm\:tw-bg-top {
    background-position: top;
  }

  .sm\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .sm\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:tw-bg-auto {
    background-size: auto;
  }

  .sm\:tw-bg-cover {
    background-size: cover;
  }

  .sm\:tw-bg-contain {
    background-size: contain;
  }

  .sm\:tw-border-transparent {
    border-color: transparent;
  }

  .sm\:tw-border-primary {
    border-color: #ac826d;
  }

  .sm\:tw-border-text {
    border-color: #453f3f;
  }

  .sm\:tw-border-black {
    border-color: #000;
  }

  .sm\:tw-border-grey-darkest {
    border-color: #3d4852;
  }

  .sm\:tw-border-grey-darker {
    border-color: #606f7b;
  }

  .sm\:tw-border-grey-dark {
    border-color: #8795a1;
  }

  .sm\:tw-border-grey {
    border-color: #b8c2cc;
  }

  .sm\:tw-border-grey-light {
    border-color: #a1b3bc;
  }

  .sm\:tw-border-grey-lighter {
    border-color: #d8dfe3;
  }

  .sm\:tw-border-grey-lightest {
    border-color: #f8fafc;
  }

  .sm\:tw-border-grey-grayish {
    border-color: #56666c;
  }

  .sm\:tw-border-white {
    border-color: #fff;
  }

  .sm\:tw-border-red-darkest {
    border-color: #3b0d0c;
  }

  .sm\:tw-border-red-darker {
    border-color: #621b18;
  }

  .sm\:tw-border-red-dark {
    border-color: #cc1f1a;
  }

  .sm\:tw-border-red {
    border-color: #de5050;
  }

  .sm\:tw-border-red-light {
    border-color: #fed5dc;
  }

  .sm\:tw-border-red-lighter {
    border-color: #f9acaa;
  }

  .sm\:tw-border-red-lightest {
    border-color: #fcebea;
  }

  .sm\:tw-border-orange-darkest {
    border-color: #462a16;
  }

  .sm\:tw-border-orange-darker {
    border-color: #613b1f;
  }

  .sm\:tw-border-orange-dark {
    border-color: #de751f;
  }

  .sm\:tw-border-orange {
    border-color: #ffbf00;
  }

  .sm\:tw-border-orange-light {
    border-color: #faad63;
  }

  .sm\:tw-border-orange-lighter {
    border-color: #fcd9b6;
  }

  .sm\:tw-border-orange-lightest {
    border-color: #fff5eb;
  }

  .sm\:tw-border-yellow-darkest {
    border-color: #453411;
  }

  .sm\:tw-border-yellow-darker {
    border-color: #684f1d;
  }

  .sm\:tw-border-yellow-dark {
    border-color: #f2d024;
  }

  .sm\:tw-border-yellow {
    border-color: #f8e71c;
  }

  .sm\:tw-border-yellow-light {
    border-color: #fff382;
  }

  .sm\:tw-border-yellow-lighter {
    border-color: #fff9c2;
  }

  .sm\:tw-border-yellow-lightest {
    border-color: #fcfbeb;
  }

  .sm\:tw-border-green-darkest {
    border-color: #0f2f21;
  }

  .sm\:tw-border-green-darker {
    border-color: #1a4731;
  }

  .sm\:tw-border-green-dark {
    border-color: #1f9d55;
  }

  .sm\:tw-border-green {
    border-color: #38c172;
  }

  .sm\:tw-border-green-light {
    border-color: #51d88a;
  }

  .sm\:tw-border-green-lighter {
    border-color: #a2f5bf;
  }

  .sm\:tw-border-green-lightest {
    border-color: #e3fcec;
  }

  .sm\:tw-border-teal-darkest {
    border-color: #0d3331;
  }

  .sm\:tw-border-teal-darker {
    border-color: #20504f;
  }

  .sm\:tw-border-teal-dark {
    border-color: #38a89d;
  }

  .sm\:tw-border-teal {
    border-color: #17c7aa;
  }

  .sm\:tw-border-teal-light {
    border-color: #64d5ca;
  }

  .sm\:tw-border-teal-lighter {
    border-color: #a0f0ed;
  }

  .sm\:tw-border-teal-lightest {
    border-color: #e8fffe;
  }

  .sm\:tw-border-blue-darkest {
    border-color: #12283a;
  }

  .sm\:tw-border-blue-darker {
    border-color: #1c3d5a;
  }

  .sm\:tw-border-blue-dark {
    border-color: #2779bd;
  }

  .sm\:tw-border-blue {
    border-color: #2ac1e7;
  }

  .sm\:tw-border-blue-light {
    border-color: #d4f2fa;
  }

  .sm\:tw-border-blue-lighter {
    border-color: #bcdefa;
  }

  .sm\:tw-border-blue-lightest {
    border-color: #eff8ff;
  }

  .sm\:tw-border-indigo-darkest {
    border-color: #191e38;
  }

  .sm\:tw-border-indigo-darker {
    border-color: #2f365f;
  }

  .sm\:tw-border-indigo-dark {
    border-color: #5661b3;
  }

  .sm\:tw-border-indigo {
    border-color: #6574cd;
  }

  .sm\:tw-border-indigo-light {
    border-color: #7886d7;
  }

  .sm\:tw-border-indigo-lighter {
    border-color: #b2b7ff;
  }

  .sm\:tw-border-indigo-lightest {
    border-color: #e6e8ff;
  }

  .sm\:tw-border-purple-darkest {
    border-color: #21183c;
  }

  .sm\:tw-border-purple-darker {
    border-color: #382b5f;
  }

  .sm\:tw-border-purple-dark {
    border-color: #794acf;
  }

  .sm\:tw-border-purple {
    border-color: #9561e2;
  }

  .sm\:tw-border-purple-light {
    border-color: #a779e9;
  }

  .sm\:tw-border-purple-lighter {
    border-color: #d6bbfc;
  }

  .sm\:tw-border-purple-lightest {
    border-color: #f3ebff;
  }

  .sm\:tw-border-pink-darkest {
    border-color: #451225;
  }

  .sm\:tw-border-pink-darker {
    border-color: #6f213f;
  }

  .sm\:tw-border-pink-dark {
    border-color: #eb5286;
  }

  .sm\:tw-border-pink {
    border-color: #f66d9b;
  }

  .sm\:tw-border-pink-light {
    border-color: #fa7ea8;
  }

  .sm\:tw-border-pink-lighter {
    border-color: #ffbbca;
  }

  .sm\:tw-border-pink-lightest {
    border-color: #ffebef;
  }

  .sm\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .sm\:hover\:tw-border-primary:hover {
    border-color: #ac826d;
  }

  .sm\:hover\:tw-border-text:hover {
    border-color: #453f3f;
  }

  .sm\:hover\:tw-border-black:hover {
    border-color: #000;
  }

  .sm\:hover\:tw-border-grey-darkest:hover {
    border-color: #3d4852;
  }

  .sm\:hover\:tw-border-grey-darker:hover {
    border-color: #606f7b;
  }

  .sm\:hover\:tw-border-grey-dark:hover {
    border-color: #8795a1;
  }

  .sm\:hover\:tw-border-grey:hover {
    border-color: #b8c2cc;
  }

  .sm\:hover\:tw-border-grey-light:hover {
    border-color: #a1b3bc;
  }

  .sm\:hover\:tw-border-grey-lighter:hover {
    border-color: #d8dfe3;
  }

  .sm\:hover\:tw-border-grey-lightest:hover {
    border-color: #f8fafc;
  }

  .sm\:hover\:tw-border-grey-grayish:hover {
    border-color: #56666c;
  }

  .sm\:hover\:tw-border-white:hover {
    border-color: #fff;
  }

  .sm\:hover\:tw-border-red-darkest:hover {
    border-color: #3b0d0c;
  }

  .sm\:hover\:tw-border-red-darker:hover {
    border-color: #621b18;
  }

  .sm\:hover\:tw-border-red-dark:hover {
    border-color: #cc1f1a;
  }

  .sm\:hover\:tw-border-red:hover {
    border-color: #de5050;
  }

  .sm\:hover\:tw-border-red-light:hover {
    border-color: #fed5dc;
  }

  .sm\:hover\:tw-border-red-lighter:hover {
    border-color: #f9acaa;
  }

  .sm\:hover\:tw-border-red-lightest:hover {
    border-color: #fcebea;
  }

  .sm\:hover\:tw-border-orange-darkest:hover {
    border-color: #462a16;
  }

  .sm\:hover\:tw-border-orange-darker:hover {
    border-color: #613b1f;
  }

  .sm\:hover\:tw-border-orange-dark:hover {
    border-color: #de751f;
  }

  .sm\:hover\:tw-border-orange:hover {
    border-color: #ffbf00;
  }

  .sm\:hover\:tw-border-orange-light:hover {
    border-color: #faad63;
  }

  .sm\:hover\:tw-border-orange-lighter:hover {
    border-color: #fcd9b6;
  }

  .sm\:hover\:tw-border-orange-lightest:hover {
    border-color: #fff5eb;
  }

  .sm\:hover\:tw-border-yellow-darkest:hover {
    border-color: #453411;
  }

  .sm\:hover\:tw-border-yellow-darker:hover {
    border-color: #684f1d;
  }

  .sm\:hover\:tw-border-yellow-dark:hover {
    border-color: #f2d024;
  }

  .sm\:hover\:tw-border-yellow:hover {
    border-color: #f8e71c;
  }

  .sm\:hover\:tw-border-yellow-light:hover {
    border-color: #fff382;
  }

  .sm\:hover\:tw-border-yellow-lighter:hover {
    border-color: #fff9c2;
  }

  .sm\:hover\:tw-border-yellow-lightest:hover {
    border-color: #fcfbeb;
  }

  .sm\:hover\:tw-border-green-darkest:hover {
    border-color: #0f2f21;
  }

  .sm\:hover\:tw-border-green-darker:hover {
    border-color: #1a4731;
  }

  .sm\:hover\:tw-border-green-dark:hover {
    border-color: #1f9d55;
  }

  .sm\:hover\:tw-border-green:hover {
    border-color: #38c172;
  }

  .sm\:hover\:tw-border-green-light:hover {
    border-color: #51d88a;
  }

  .sm\:hover\:tw-border-green-lighter:hover {
    border-color: #a2f5bf;
  }

  .sm\:hover\:tw-border-green-lightest:hover {
    border-color: #e3fcec;
  }

  .sm\:hover\:tw-border-teal-darkest:hover {
    border-color: #0d3331;
  }

  .sm\:hover\:tw-border-teal-darker:hover {
    border-color: #20504f;
  }

  .sm\:hover\:tw-border-teal-dark:hover {
    border-color: #38a89d;
  }

  .sm\:hover\:tw-border-teal:hover {
    border-color: #17c7aa;
  }

  .sm\:hover\:tw-border-teal-light:hover {
    border-color: #64d5ca;
  }

  .sm\:hover\:tw-border-teal-lighter:hover {
    border-color: #a0f0ed;
  }

  .sm\:hover\:tw-border-teal-lightest:hover {
    border-color: #e8fffe;
  }

  .sm\:hover\:tw-border-blue-darkest:hover {
    border-color: #12283a;
  }

  .sm\:hover\:tw-border-blue-darker:hover {
    border-color: #1c3d5a;
  }

  .sm\:hover\:tw-border-blue-dark:hover {
    border-color: #2779bd;
  }

  .sm\:hover\:tw-border-blue:hover {
    border-color: #2ac1e7;
  }

  .sm\:hover\:tw-border-blue-light:hover {
    border-color: #d4f2fa;
  }

  .sm\:hover\:tw-border-blue-lighter:hover {
    border-color: #bcdefa;
  }

  .sm\:hover\:tw-border-blue-lightest:hover {
    border-color: #eff8ff;
  }

  .sm\:hover\:tw-border-indigo-darkest:hover {
    border-color: #191e38;
  }

  .sm\:hover\:tw-border-indigo-darker:hover {
    border-color: #2f365f;
  }

  .sm\:hover\:tw-border-indigo-dark:hover {
    border-color: #5661b3;
  }

  .sm\:hover\:tw-border-indigo:hover {
    border-color: #6574cd;
  }

  .sm\:hover\:tw-border-indigo-light:hover {
    border-color: #7886d7;
  }

  .sm\:hover\:tw-border-indigo-lighter:hover {
    border-color: #b2b7ff;
  }

  .sm\:hover\:tw-border-indigo-lightest:hover {
    border-color: #e6e8ff;
  }

  .sm\:hover\:tw-border-purple-darkest:hover {
    border-color: #21183c;
  }

  .sm\:hover\:tw-border-purple-darker:hover {
    border-color: #382b5f;
  }

  .sm\:hover\:tw-border-purple-dark:hover {
    border-color: #794acf;
  }

  .sm\:hover\:tw-border-purple:hover {
    border-color: #9561e2;
  }

  .sm\:hover\:tw-border-purple-light:hover {
    border-color: #a779e9;
  }

  .sm\:hover\:tw-border-purple-lighter:hover {
    border-color: #d6bbfc;
  }

  .sm\:hover\:tw-border-purple-lightest:hover {
    border-color: #f3ebff;
  }

  .sm\:hover\:tw-border-pink-darkest:hover {
    border-color: #451225;
  }

  .sm\:hover\:tw-border-pink-darker:hover {
    border-color: #6f213f;
  }

  .sm\:hover\:tw-border-pink-dark:hover {
    border-color: #eb5286;
  }

  .sm\:hover\:tw-border-pink:hover {
    border-color: #f66d9b;
  }

  .sm\:hover\:tw-border-pink-light:hover {
    border-color: #fa7ea8;
  }

  .sm\:hover\:tw-border-pink-lighter:hover {
    border-color: #ffbbca;
  }

  .sm\:hover\:tw-border-pink-lightest:hover {
    border-color: #ffebef;
  }

  .sm\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .sm\:focus\:tw-border-primary:focus {
    border-color: #ac826d;
  }

  .sm\:focus\:tw-border-text:focus {
    border-color: #453f3f;
  }

  .sm\:focus\:tw-border-black:focus {
    border-color: #000;
  }

  .sm\:focus\:tw-border-grey-darkest:focus {
    border-color: #3d4852;
  }

  .sm\:focus\:tw-border-grey-darker:focus {
    border-color: #606f7b;
  }

  .sm\:focus\:tw-border-grey-dark:focus {
    border-color: #8795a1;
  }

  .sm\:focus\:tw-border-grey:focus {
    border-color: #b8c2cc;
  }

  .sm\:focus\:tw-border-grey-light:focus {
    border-color: #a1b3bc;
  }

  .sm\:focus\:tw-border-grey-lighter:focus {
    border-color: #d8dfe3;
  }

  .sm\:focus\:tw-border-grey-lightest:focus {
    border-color: #f8fafc;
  }

  .sm\:focus\:tw-border-grey-grayish:focus {
    border-color: #56666c;
  }

  .sm\:focus\:tw-border-white:focus {
    border-color: #fff;
  }

  .sm\:focus\:tw-border-red-darkest:focus {
    border-color: #3b0d0c;
  }

  .sm\:focus\:tw-border-red-darker:focus {
    border-color: #621b18;
  }

  .sm\:focus\:tw-border-red-dark:focus {
    border-color: #cc1f1a;
  }

  .sm\:focus\:tw-border-red:focus {
    border-color: #de5050;
  }

  .sm\:focus\:tw-border-red-light:focus {
    border-color: #fed5dc;
  }

  .sm\:focus\:tw-border-red-lighter:focus {
    border-color: #f9acaa;
  }

  .sm\:focus\:tw-border-red-lightest:focus {
    border-color: #fcebea;
  }

  .sm\:focus\:tw-border-orange-darkest:focus {
    border-color: #462a16;
  }

  .sm\:focus\:tw-border-orange-darker:focus {
    border-color: #613b1f;
  }

  .sm\:focus\:tw-border-orange-dark:focus {
    border-color: #de751f;
  }

  .sm\:focus\:tw-border-orange:focus {
    border-color: #ffbf00;
  }

  .sm\:focus\:tw-border-orange-light:focus {
    border-color: #faad63;
  }

  .sm\:focus\:tw-border-orange-lighter:focus {
    border-color: #fcd9b6;
  }

  .sm\:focus\:tw-border-orange-lightest:focus {
    border-color: #fff5eb;
  }

  .sm\:focus\:tw-border-yellow-darkest:focus {
    border-color: #453411;
  }

  .sm\:focus\:tw-border-yellow-darker:focus {
    border-color: #684f1d;
  }

  .sm\:focus\:tw-border-yellow-dark:focus {
    border-color: #f2d024;
  }

  .sm\:focus\:tw-border-yellow:focus {
    border-color: #f8e71c;
  }

  .sm\:focus\:tw-border-yellow-light:focus {
    border-color: #fff382;
  }

  .sm\:focus\:tw-border-yellow-lighter:focus {
    border-color: #fff9c2;
  }

  .sm\:focus\:tw-border-yellow-lightest:focus {
    border-color: #fcfbeb;
  }

  .sm\:focus\:tw-border-green-darkest:focus {
    border-color: #0f2f21;
  }

  .sm\:focus\:tw-border-green-darker:focus {
    border-color: #1a4731;
  }

  .sm\:focus\:tw-border-green-dark:focus {
    border-color: #1f9d55;
  }

  .sm\:focus\:tw-border-green:focus {
    border-color: #38c172;
  }

  .sm\:focus\:tw-border-green-light:focus {
    border-color: #51d88a;
  }

  .sm\:focus\:tw-border-green-lighter:focus {
    border-color: #a2f5bf;
  }

  .sm\:focus\:tw-border-green-lightest:focus {
    border-color: #e3fcec;
  }

  .sm\:focus\:tw-border-teal-darkest:focus {
    border-color: #0d3331;
  }

  .sm\:focus\:tw-border-teal-darker:focus {
    border-color: #20504f;
  }

  .sm\:focus\:tw-border-teal-dark:focus {
    border-color: #38a89d;
  }

  .sm\:focus\:tw-border-teal:focus {
    border-color: #17c7aa;
  }

  .sm\:focus\:tw-border-teal-light:focus {
    border-color: #64d5ca;
  }

  .sm\:focus\:tw-border-teal-lighter:focus {
    border-color: #a0f0ed;
  }

  .sm\:focus\:tw-border-teal-lightest:focus {
    border-color: #e8fffe;
  }

  .sm\:focus\:tw-border-blue-darkest:focus {
    border-color: #12283a;
  }

  .sm\:focus\:tw-border-blue-darker:focus {
    border-color: #1c3d5a;
  }

  .sm\:focus\:tw-border-blue-dark:focus {
    border-color: #2779bd;
  }

  .sm\:focus\:tw-border-blue:focus {
    border-color: #2ac1e7;
  }

  .sm\:focus\:tw-border-blue-light:focus {
    border-color: #d4f2fa;
  }

  .sm\:focus\:tw-border-blue-lighter:focus {
    border-color: #bcdefa;
  }

  .sm\:focus\:tw-border-blue-lightest:focus {
    border-color: #eff8ff;
  }

  .sm\:focus\:tw-border-indigo-darkest:focus {
    border-color: #191e38;
  }

  .sm\:focus\:tw-border-indigo-darker:focus {
    border-color: #2f365f;
  }

  .sm\:focus\:tw-border-indigo-dark:focus {
    border-color: #5661b3;
  }

  .sm\:focus\:tw-border-indigo:focus {
    border-color: #6574cd;
  }

  .sm\:focus\:tw-border-indigo-light:focus {
    border-color: #7886d7;
  }

  .sm\:focus\:tw-border-indigo-lighter:focus {
    border-color: #b2b7ff;
  }

  .sm\:focus\:tw-border-indigo-lightest:focus {
    border-color: #e6e8ff;
  }

  .sm\:focus\:tw-border-purple-darkest:focus {
    border-color: #21183c;
  }

  .sm\:focus\:tw-border-purple-darker:focus {
    border-color: #382b5f;
  }

  .sm\:focus\:tw-border-purple-dark:focus {
    border-color: #794acf;
  }

  .sm\:focus\:tw-border-purple:focus {
    border-color: #9561e2;
  }

  .sm\:focus\:tw-border-purple-light:focus {
    border-color: #a779e9;
  }

  .sm\:focus\:tw-border-purple-lighter:focus {
    border-color: #d6bbfc;
  }

  .sm\:focus\:tw-border-purple-lightest:focus {
    border-color: #f3ebff;
  }

  .sm\:focus\:tw-border-pink-darkest:focus {
    border-color: #451225;
  }

  .sm\:focus\:tw-border-pink-darker:focus {
    border-color: #6f213f;
  }

  .sm\:focus\:tw-border-pink-dark:focus {
    border-color: #eb5286;
  }

  .sm\:focus\:tw-border-pink:focus {
    border-color: #f66d9b;
  }

  .sm\:focus\:tw-border-pink-light:focus {
    border-color: #fa7ea8;
  }

  .sm\:focus\:tw-border-pink-lighter:focus {
    border-color: #ffbbca;
  }

  .sm\:focus\:tw-border-pink-lightest:focus {
    border-color: #ffebef;
  }

  .sm\:tw-rounded-none {
    border-radius: 0;
  }

  .sm\:tw-rounded-sm {
    border-radius: .125rem;
  }

  .sm\:tw-rounded {
    border-radius: .25rem;
  }

  .sm\:tw-rounded-lg {
    border-radius: .5rem;
  }

  .sm\:tw-rounded-full {
    border-radius: 9999px;
  }

  .sm\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .sm\:tw-rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm\:tw-rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:tw-rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:tw-rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .sm\:tw-rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm\:tw-rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:tw-rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:tw-rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .sm\:tw-rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm\:tw-rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:tw-rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .sm\:tw-rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .sm\:tw-rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .sm\:tw-rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .sm\:tw-rounded-tl {
    border-top-left-radius: .25rem;
  }

  .sm\:tw-rounded-tr {
    border-top-right-radius: .25rem;
  }

  .sm\:tw-rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .sm\:tw-rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .sm\:tw-rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .sm\:tw-rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .sm\:tw-rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .sm\:tw-rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .sm\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-border-solid {
    border-style: solid;
  }

  .sm\:tw-border-dashed {
    border-style: dashed;
  }

  .sm\:tw-border-dotted {
    border-style: dotted;
  }

  .sm\:tw-border-none {
    border-style: none;
  }

  .sm\:tw-border-0 {
    border-width: 0;
  }

  .sm\:tw-border-2 {
    border-width: 2px;
  }

  .sm\:tw-border-4 {
    border-width: 4px;
  }

  .sm\:tw-border-8 {
    border-width: 8px;
  }

  .sm\:tw-border {
    border-width: 1px;
  }

  .sm\:tw-border-t-0 {
    border-top-width: 0;
  }

  .sm\:tw-border-r-0 {
    border-right-width: 0;
  }

  .sm\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:tw-border-l-0 {
    border-left-width: 0;
  }

  .sm\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .sm\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .sm\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .sm\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .sm\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .sm\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .sm\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .sm\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .sm\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .sm\:tw-border-t {
    border-top-width: 1px;
  }

  .sm\:tw-border-r {
    border-right-width: 1px;
  }

  .sm\:tw-border-b {
    border-bottom-width: 1px;
  }

  .sm\:tw-border-l {
    border-left-width: 1px;
  }

  .sm\:tw-cursor-auto {
    cursor: auto;
  }

  .sm\:tw-cursor-default {
    cursor: default;
  }

  .sm\:tw-cursor-pointer {
    cursor: pointer;
  }

  .sm\:tw-cursor-wait {
    cursor: wait;
  }

  .sm\:tw-cursor-move {
    cursor: move;
  }

  .sm\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:tw-block {
    display: block;
  }

  .sm\:tw-inline-block {
    display: inline-block;
  }

  .sm\:tw-inline {
    display: inline;
  }

  .sm\:tw-table {
    display: table;
  }

  .sm\:tw-table-row {
    display: table-row;
  }

  .sm\:tw-table-cell {
    display: table-cell;
  }

  .sm\:tw-hidden {
    display: none;
  }

  .sm\:tw-flex {
    display: flex;
  }

  .sm\:tw-inline-flex {
    display: inline-flex;
  }

  .sm\:tw-flex-row {
    flex-direction: row;
  }

  .sm\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:tw-flex-col {
    flex-direction: column;
  }

  .sm\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .sm\:tw-items-start {
    align-items: flex-start;
  }

  .sm\:tw-items-end {
    align-items: flex-end;
  }

  .sm\:tw-items-center {
    align-items: center;
  }

  .sm\:tw-items-baseline {
    align-items: baseline;
  }

  .sm\:tw-items-stretch {
    align-items: stretch;
  }

  .sm\:tw-self-auto {
    align-self: auto;
  }

  .sm\:tw-self-start {
    align-self: flex-start;
  }

  .sm\:tw-self-end {
    align-self: flex-end;
  }

  .sm\:tw-self-center {
    align-self: center;
  }

  .sm\:tw-self-stretch {
    align-self: stretch;
  }

  .sm\:tw-justify-start {
    justify-content: flex-start;
  }

  .sm\:tw-justify-end {
    justify-content: flex-end;
  }

  .sm\:tw-justify-center {
    justify-content: center;
  }

  .sm\:tw-justify-between {
    justify-content: space-between;
  }

  .sm\:tw-justify-around {
    justify-content: space-around;
  }

  .sm\:tw-content-center {
    align-content: center;
  }

  .sm\:tw-content-start {
    align-content: flex-start;
  }

  .sm\:tw-content-end {
    align-content: flex-end;
  }

  .sm\:tw-content-between {
    align-content: space-between;
  }

  .sm\:tw-content-around {
    align-content: space-around;
  }

  .sm\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .sm\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .sm\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .sm\:tw-flex-none {
    flex: none;
  }

  .sm\:tw-flex-grow {
    flex-grow: 1;
  }

  .sm\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .sm\:tw-flex-no-grow {
    flex-grow: 0;
  }

  .sm\:tw-flex-no-shrink {
    flex-shrink: 0;
  }

  .sm\:tw-float-right {
    float: right;
  }

  .sm\:tw-float-left {
    float: left;
  }

  .sm\:tw-float-none {
    float: none;
  }

  .sm\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .sm\:tw-font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .sm\:tw-font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
  }

  .sm\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .sm\:tw-font-hairline {
    font-weight: 100;
  }

  .sm\:tw-font-thin {
    font-weight: 200;
  }

  .sm\:tw-font-light {
    font-weight: 300;
  }

  .sm\:tw-font-normal {
    font-weight: 400;
  }

  .sm\:tw-font-medium {
    font-weight: 500;
  }

  .sm\:tw-font-semibold {
    font-weight: 600;
  }

  .sm\:tw-font-bold {
    font-weight: 700;
  }

  .sm\:tw-font-extrabold {
    font-weight: 800;
  }

  .sm\:tw-font-black {
    font-weight: 900;
  }

  .sm\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .sm\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .sm\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .sm\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .sm\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .sm\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .sm\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .sm\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .sm\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .sm\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .sm\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .sm\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .sm\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .sm\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .sm\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .sm\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .sm\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .sm\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .sm\:tw-h-1 {
    height: .25rem;
  }

  .sm\:tw-h-2 {
    height: .5rem;
  }

  .sm\:tw-h-3 {
    height: .75rem;
  }

  .sm\:tw-h-4 {
    height: 1rem;
  }

  .sm\:tw-h-5 {
    height: 1.25rem;
  }

  .sm\:tw-h-6 {
    height: 1.5rem;
  }

  .sm\:tw-h-8 {
    height: 2rem;
  }

  .sm\:tw-h-10 {
    height: 2.5rem;
  }

  .sm\:tw-h-12 {
    height: 3rem;
  }

  .sm\:tw-h-16 {
    height: 4rem;
  }

  .sm\:tw-h-24 {
    height: 6rem;
  }

  .sm\:tw-h-32 {
    height: 8rem;
  }

  .sm\:tw-h-48 {
    height: 12rem;
  }

  .sm\:tw-h-64 {
    height: 16rem;
  }

  .sm\:tw-h-auto {
    height: auto;
  }

  .sm\:tw-h-px {
    height: 1px;
  }

  .sm\:tw-h-full {
    height: 100%;
  }

  .sm\:tw-h-screen {
    height: 100vh;
  }

  .sm\:tw-leading-none {
    line-height: 1;
  }

  .sm\:tw-leading-tight {
    line-height: 1.25;
  }

  .sm\:tw-leading-normal {
    line-height: 1.5;
  }

  .sm\:tw-leading-loose {
    line-height: 2;
  }

  .sm\:tw-m-0 {
    margin: 0;
  }

  .sm\:tw-m-1 {
    margin: .25rem;
  }

  .sm\:tw-m-2 {
    margin: .5rem;
  }

  .sm\:tw-m-3 {
    margin: .75rem;
  }

  .sm\:tw-m-4 {
    margin: 1rem;
  }

  .sm\:tw-m-5 {
    margin: 1.25rem;
  }

  .sm\:tw-m-6 {
    margin: 1.5rem;
  }

  .sm\:tw-m-8 {
    margin: 2rem;
  }

  .sm\:tw-m-10 {
    margin: 2.5rem;
  }

  .sm\:tw-m-12 {
    margin: 3rem;
  }

  .sm\:tw-m-16 {
    margin: 4rem;
  }

  .sm\:tw-m-20 {
    margin: 5rem;
  }

  .sm\:tw-m-24 {
    margin: 6rem;
  }

  .sm\:tw-m-32 {
    margin: 8rem;
  }

  .sm\:tw-m-auto {
    margin: auto;
  }

  .sm\:tw-m-px {
    margin: 1px;
  }

  .sm\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:tw-my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm\:tw-mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:tw-my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm\:tw-mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm\:tw-my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .sm\:tw-mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:tw-mt-0 {
    margin-top: 0;
  }

  .sm\:tw-mr-0 {
    margin-right: 0;
  }

  .sm\:tw-mb-0 {
    margin-bottom: 0;
  }

  .sm\:tw-ml-0 {
    margin-left: 0;
  }

  .sm\:tw-mt-1 {
    margin-top: .25rem;
  }

  .sm\:tw-mr-1 {
    margin-right: .25rem;
  }

  .sm\:tw-mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:tw-ml-1 {
    margin-left: .25rem;
  }

  .sm\:tw-mt-2 {
    margin-top: .5rem;
  }

  .sm\:tw-mr-2 {
    margin-right: .5rem;
  }

  .sm\:tw-mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:tw-ml-2 {
    margin-left: .5rem;
  }

  .sm\:tw-mt-3 {
    margin-top: .75rem;
  }

  .sm\:tw-mr-3 {
    margin-right: .75rem;
  }

  .sm\:tw-mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:tw-ml-3 {
    margin-left: .75rem;
  }

  .sm\:tw-mt-4 {
    margin-top: 1rem;
  }

  .sm\:tw-mr-4 {
    margin-right: 1rem;
  }

  .sm\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:tw-ml-4 {
    margin-left: 1rem;
  }

  .sm\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:tw-mt-8 {
    margin-top: 2rem;
  }

  .sm\:tw-mr-8 {
    margin-right: 2rem;
  }

  .sm\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:tw-ml-8 {
    margin-left: 2rem;
  }

  .sm\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:tw-mt-12 {
    margin-top: 3rem;
  }

  .sm\:tw-mr-12 {
    margin-right: 3rem;
  }

  .sm\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:tw-ml-12 {
    margin-left: 3rem;
  }

  .sm\:tw-mt-16 {
    margin-top: 4rem;
  }

  .sm\:tw-mr-16 {
    margin-right: 4rem;
  }

  .sm\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:tw-ml-16 {
    margin-left: 4rem;
  }

  .sm\:tw-mt-20 {
    margin-top: 5rem;
  }

  .sm\:tw-mr-20 {
    margin-right: 5rem;
  }

  .sm\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:tw-ml-20 {
    margin-left: 5rem;
  }

  .sm\:tw-mt-24 {
    margin-top: 6rem;
  }

  .sm\:tw-mr-24 {
    margin-right: 6rem;
  }

  .sm\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:tw-ml-24 {
    margin-left: 6rem;
  }

  .sm\:tw-mt-32 {
    margin-top: 8rem;
  }

  .sm\:tw-mr-32 {
    margin-right: 8rem;
  }

  .sm\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:tw-ml-32 {
    margin-left: 8rem;
  }

  .sm\:tw-mt-auto {
    margin-top: auto;
  }

  .sm\:tw-mr-auto {
    margin-right: auto;
  }

  .sm\:tw-mb-auto {
    margin-bottom: auto;
  }

  .sm\:tw-ml-auto {
    margin-left: auto;
  }

  .sm\:tw-mt-px {
    margin-top: 1px;
  }

  .sm\:tw-mr-px {
    margin-right: 1px;
  }

  .sm\:tw-mb-px {
    margin-bottom: 1px;
  }

  .sm\:tw-ml-px {
    margin-left: 1px;
  }

  .sm\:tw-max-h-full {
    max-height: 100%;
  }

  .sm\:tw-max-h-screen {
    max-height: 100vh;
  }

  .sm\:tw-max-w-xs {
    max-width: 20rem;
  }

  .sm\:tw-max-w-sm {
    max-width: 30rem;
  }

  .sm\:tw-max-w-md {
    max-width: 40rem;
  }

  .sm\:tw-max-w-lg {
    max-width: 50rem;
  }

  .sm\:tw-max-w-xl {
    max-width: 60rem;
  }

  .sm\:tw-max-w-2xl {
    max-width: 70rem;
  }

  .sm\:tw-max-w-3xl {
    max-width: 80rem;
  }

  .sm\:tw-max-w-4xl {
    max-width: 90rem;
  }

  .sm\:tw-max-w-5xl {
    max-width: 100rem;
  }

  .sm\:tw-max-w-full {
    max-width: 100%;
  }

  .sm\:tw-min-h-0 {
    min-height: 0;
  }

  .sm\:tw-min-h-full {
    min-height: 100%;
  }

  .sm\:tw-min-h-screen {
    min-height: 100vh;
  }

  .sm\:tw-min-w-0 {
    min-width: 0;
  }

  .sm\:tw-min-w-full {
    min-width: 100%;
  }

  .sm\:tw--m-0 {
    margin: 0;
  }

  .sm\:tw--m-1 {
    margin: -0.25rem;
  }

  .sm\:tw--m-2 {
    margin: -0.5rem;
  }

  .sm\:tw--m-3 {
    margin: -0.75rem;
  }

  .sm\:tw--m-4 {
    margin: -1rem;
  }

  .sm\:tw--m-5 {
    margin: -1.25rem;
  }

  .sm\:tw--m-6 {
    margin: -1.5rem;
  }

  .sm\:tw--m-8 {
    margin: -2rem;
  }

  .sm\:tw--m-10 {
    margin: -2.5rem;
  }

  .sm\:tw--m-12 {
    margin: -3rem;
  }

  .sm\:tw--m-16 {
    margin: -4rem;
  }

  .sm\:tw--m-20 {
    margin: -5rem;
  }

  .sm\:tw--m-24 {
    margin: -6rem;
  }

  .sm\:tw--m-32 {
    margin: -8rem;
  }

  .sm\:tw--m-px {
    margin: -1px;
  }

  .sm\:tw--my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:tw--mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .sm\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .sm\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .sm\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .sm\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:tw--mt-0 {
    margin-top: 0;
  }

  .sm\:tw--mr-0 {
    margin-right: 0;
  }

  .sm\:tw--mb-0 {
    margin-bottom: 0;
  }

  .sm\:tw--ml-0 {
    margin-left: 0;
  }

  .sm\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .sm\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .sm\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .sm\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .sm\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .sm\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .sm\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .sm\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .sm\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .sm\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .sm\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .sm\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .sm\:tw--mt-4 {
    margin-top: -1rem;
  }

  .sm\:tw--mr-4 {
    margin-right: -1rem;
  }

  .sm\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:tw--ml-4 {
    margin-left: -1rem;
  }

  .sm\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:tw--mt-8 {
    margin-top: -2rem;
  }

  .sm\:tw--mr-8 {
    margin-right: -2rem;
  }

  .sm\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:tw--ml-8 {
    margin-left: -2rem;
  }

  .sm\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:tw--mt-12 {
    margin-top: -3rem;
  }

  .sm\:tw--mr-12 {
    margin-right: -3rem;
  }

  .sm\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:tw--ml-12 {
    margin-left: -3rem;
  }

  .sm\:tw--mt-16 {
    margin-top: -4rem;
  }

  .sm\:tw--mr-16 {
    margin-right: -4rem;
  }

  .sm\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:tw--ml-16 {
    margin-left: -4rem;
  }

  .sm\:tw--mt-20 {
    margin-top: -5rem;
  }

  .sm\:tw--mr-20 {
    margin-right: -5rem;
  }

  .sm\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:tw--ml-20 {
    margin-left: -5rem;
  }

  .sm\:tw--mt-24 {
    margin-top: -6rem;
  }

  .sm\:tw--mr-24 {
    margin-right: -6rem;
  }

  .sm\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:tw--ml-24 {
    margin-left: -6rem;
  }

  .sm\:tw--mt-32 {
    margin-top: -8rem;
  }

  .sm\:tw--mr-32 {
    margin-right: -8rem;
  }

  .sm\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:tw--ml-32 {
    margin-left: -8rem;
  }

  .sm\:tw--mt-px {
    margin-top: -1px;
  }

  .sm\:tw--mr-px {
    margin-right: -1px;
  }

  .sm\:tw--mb-px {
    margin-bottom: -1px;
  }

  .sm\:tw--ml-px {
    margin-left: -1px;
  }

  .sm\:tw-opacity-0 {
    opacity: 0;
  }

  .sm\:tw-opacity-25 {
    opacity: .25;
  }

  .sm\:tw-opacity-50 {
    opacity: .5;
  }

  .sm\:tw-opacity-75 {
    opacity: .75;
  }

  .sm\:tw-opacity-100 {
    opacity: 1;
  }

  .sm\:tw-overflow-auto {
    overflow: auto;
  }

  .sm\:tw-overflow-hidden {
    overflow: hidden;
  }

  .sm\:tw-overflow-visible {
    overflow: visible;
  }

  .sm\:tw-overflow-scroll {
    overflow: scroll;
  }

  .sm\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .sm\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .sm\:tw-p-0 {
    padding: 0;
  }

  .sm\:tw-p-1 {
    padding: .25rem;
  }

  .sm\:tw-p-2 {
    padding: .5rem;
  }

  .sm\:tw-p-3 {
    padding: .75rem;
  }

  .sm\:tw-p-4 {
    padding: 1rem;
  }

  .sm\:tw-p-5 {
    padding: 1.25rem;
  }

  .sm\:tw-p-6 {
    padding: 1.5rem;
  }

  .sm\:tw-p-8 {
    padding: 2rem;
  }

  .sm\:tw-p-10 {
    padding: 2.5rem;
  }

  .sm\:tw-p-12 {
    padding: 3rem;
  }

  .sm\:tw-p-16 {
    padding: 4rem;
  }

  .sm\:tw-p-20 {
    padding: 5rem;
  }

  .sm\:tw-p-24 {
    padding: 6rem;
  }

  .sm\:tw-p-32 {
    padding: 8rem;
  }

  .sm\:tw-p-px {
    padding: 1px;
  }

  .sm\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:tw-py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:tw-px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:tw-px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:tw-py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:tw-px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:tw-pt-0 {
    padding-top: 0;
  }

  .sm\:tw-pr-0 {
    padding-right: 0;
  }

  .sm\:tw-pb-0 {
    padding-bottom: 0;
  }

  .sm\:tw-pl-0 {
    padding-left: 0;
  }

  .sm\:tw-pt-1 {
    padding-top: .25rem;
  }

  .sm\:tw-pr-1 {
    padding-right: .25rem;
  }

  .sm\:tw-pb-1 {
    padding-bottom: .25rem;
  }

  .sm\:tw-pl-1 {
    padding-left: .25rem;
  }

  .sm\:tw-pt-2 {
    padding-top: .5rem;
  }

  .sm\:tw-pr-2 {
    padding-right: .5rem;
  }

  .sm\:tw-pb-2 {
    padding-bottom: .5rem;
  }

  .sm\:tw-pl-2 {
    padding-left: .5rem;
  }

  .sm\:tw-pt-3 {
    padding-top: .75rem;
  }

  .sm\:tw-pr-3 {
    padding-right: .75rem;
  }

  .sm\:tw-pb-3 {
    padding-bottom: .75rem;
  }

  .sm\:tw-pl-3 {
    padding-left: .75rem;
  }

  .sm\:tw-pt-4 {
    padding-top: 1rem;
  }

  .sm\:tw-pr-4 {
    padding-right: 1rem;
  }

  .sm\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:tw-pl-4 {
    padding-left: 1rem;
  }

  .sm\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:tw-pt-8 {
    padding-top: 2rem;
  }

  .sm\:tw-pr-8 {
    padding-right: 2rem;
  }

  .sm\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:tw-pl-8 {
    padding-left: 2rem;
  }

  .sm\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:tw-pt-12 {
    padding-top: 3rem;
  }

  .sm\:tw-pr-12 {
    padding-right: 3rem;
  }

  .sm\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:tw-pl-12 {
    padding-left: 3rem;
  }

  .sm\:tw-pt-16 {
    padding-top: 4rem;
  }

  .sm\:tw-pr-16 {
    padding-right: 4rem;
  }

  .sm\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:tw-pl-16 {
    padding-left: 4rem;
  }

  .sm\:tw-pt-20 {
    padding-top: 5rem;
  }

  .sm\:tw-pr-20 {
    padding-right: 5rem;
  }

  .sm\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:tw-pl-20 {
    padding-left: 5rem;
  }

  .sm\:tw-pt-24 {
    padding-top: 6rem;
  }

  .sm\:tw-pr-24 {
    padding-right: 6rem;
  }

  .sm\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:tw-pl-24 {
    padding-left: 6rem;
  }

  .sm\:tw-pt-32 {
    padding-top: 8rem;
  }

  .sm\:tw-pr-32 {
    padding-right: 8rem;
  }

  .sm\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:tw-pl-32 {
    padding-left: 8rem;
  }

  .sm\:tw-pt-px {
    padding-top: 1px;
  }

  .sm\:tw-pr-px {
    padding-right: 1px;
  }

  .sm\:tw-pb-px {
    padding-bottom: 1px;
  }

  .sm\:tw-pl-px {
    padding-left: 1px;
  }

  .sm\:tw-pointer-events-none {
    pointer-events: none;
  }

  .sm\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:tw-static {
    position: static;
  }

  .sm\:tw-fixed {
    position: fixed;
  }

  .sm\:tw-absolute {
    position: absolute;
  }

  .sm\:tw-relative {
    position: relative;
  }

  .sm\:tw-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .sm\:tw-pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:tw-pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:tw-pin-y {
    top: 0;
    bottom: 0;
  }

  .sm\:tw-pin-x {
    right: 0;
    left: 0;
  }

  .sm\:tw-pin-t {
    top: 0;
  }

  .sm\:tw-pin-r {
    right: 0;
  }

  .sm\:tw-pin-b {
    bottom: 0;
  }

  .sm\:tw-pin-l {
    left: 0;
  }

  .sm\:tw-resize-none {
    resize: none;
  }

  .sm\:tw-resize-y {
    resize: vertical;
  }

  .sm\:tw-resize-x {
    resize: horizontal;
  }

  .sm\:tw-resize {
    resize: both;
  }

  .sm\:tw-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .sm\:tw-shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .sm\:tw-shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .sm\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .sm\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .sm\:tw-shadow-none {
    box-shadow: none;
  }

  .sm\:hover\:tw-shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .sm\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .sm\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .sm\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .sm\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .sm\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .sm\:focus\:tw-shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .sm\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .sm\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .sm\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .sm\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .sm\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .sm\:tw-table-auto {
    table-layout: auto;
  }

  .sm\:tw-table-fixed {
    table-layout: fixed;
  }

  .sm\:tw-text-left {
    text-align: left;
  }

  .sm\:tw-text-center {
    text-align: center;
  }

  .sm\:tw-text-right {
    text-align: right;
  }

  .sm\:tw-text-justify {
    text-align: justify;
  }

  .sm\:tw-text-transparent {
    color: transparent;
  }

  .sm\:tw-text-primary {
    color: #ac826d;
  }

  .sm\:tw-text-text {
    color: #453f3f;
  }

  .sm\:tw-text-black {
    color: #000;
  }

  .sm\:tw-text-grey-darkest {
    color: #3d4852;
  }

  .sm\:tw-text-grey-darker {
    color: #606f7b;
  }

  .sm\:tw-text-grey-dark {
    color: #8795a1;
  }

  .sm\:tw-text-grey {
    color: #b8c2cc;
  }

  .sm\:tw-text-grey-light {
    color: #a1b3bc;
  }

  .sm\:tw-text-grey-lighter {
    color: #d8dfe3;
  }

  .sm\:tw-text-grey-lightest {
    color: #f8fafc;
  }

  .sm\:tw-text-grey-grayish {
    color: #56666c;
  }

  .sm\:tw-text-white {
    color: #fff;
  }

  .sm\:tw-text-red-darkest {
    color: #3b0d0c;
  }

  .sm\:tw-text-red-darker {
    color: #621b18;
  }

  .sm\:tw-text-red-dark {
    color: #cc1f1a;
  }

  .sm\:tw-text-red {
    color: #de5050;
  }

  .sm\:tw-text-red-light {
    color: #fed5dc;
  }

  .sm\:tw-text-red-lighter {
    color: #f9acaa;
  }

  .sm\:tw-text-red-lightest {
    color: #fcebea;
  }

  .sm\:tw-text-orange-darkest {
    color: #462a16;
  }

  .sm\:tw-text-orange-darker {
    color: #613b1f;
  }

  .sm\:tw-text-orange-dark {
    color: #de751f;
  }

  .sm\:tw-text-orange {
    color: #ffbf00;
  }

  .sm\:tw-text-orange-light {
    color: #faad63;
  }

  .sm\:tw-text-orange-lighter {
    color: #fcd9b6;
  }

  .sm\:tw-text-orange-lightest {
    color: #fff5eb;
  }

  .sm\:tw-text-yellow-darkest {
    color: #453411;
  }

  .sm\:tw-text-yellow-darker {
    color: #684f1d;
  }

  .sm\:tw-text-yellow-dark {
    color: #f2d024;
  }

  .sm\:tw-text-yellow {
    color: #f8e71c;
  }

  .sm\:tw-text-yellow-light {
    color: #fff382;
  }

  .sm\:tw-text-yellow-lighter {
    color: #fff9c2;
  }

  .sm\:tw-text-yellow-lightest {
    color: #fcfbeb;
  }

  .sm\:tw-text-green-darkest {
    color: #0f2f21;
  }

  .sm\:tw-text-green-darker {
    color: #1a4731;
  }

  .sm\:tw-text-green-dark {
    color: #1f9d55;
  }

  .sm\:tw-text-green {
    color: #38c172;
  }

  .sm\:tw-text-green-light {
    color: #51d88a;
  }

  .sm\:tw-text-green-lighter {
    color: #a2f5bf;
  }

  .sm\:tw-text-green-lightest {
    color: #e3fcec;
  }

  .sm\:tw-text-teal-darkest {
    color: #0d3331;
  }

  .sm\:tw-text-teal-darker {
    color: #20504f;
  }

  .sm\:tw-text-teal-dark {
    color: #38a89d;
  }

  .sm\:tw-text-teal {
    color: #17c7aa;
  }

  .sm\:tw-text-teal-light {
    color: #64d5ca;
  }

  .sm\:tw-text-teal-lighter {
    color: #a0f0ed;
  }

  .sm\:tw-text-teal-lightest {
    color: #e8fffe;
  }

  .sm\:tw-text-blue-darkest {
    color: #12283a;
  }

  .sm\:tw-text-blue-darker {
    color: #1c3d5a;
  }

  .sm\:tw-text-blue-dark {
    color: #2779bd;
  }

  .sm\:tw-text-blue {
    color: #2ac1e7;
  }

  .sm\:tw-text-blue-light {
    color: #d4f2fa;
  }

  .sm\:tw-text-blue-lighter {
    color: #bcdefa;
  }

  .sm\:tw-text-blue-lightest {
    color: #eff8ff;
  }

  .sm\:tw-text-indigo-darkest {
    color: #191e38;
  }

  .sm\:tw-text-indigo-darker {
    color: #2f365f;
  }

  .sm\:tw-text-indigo-dark {
    color: #5661b3;
  }

  .sm\:tw-text-indigo {
    color: #6574cd;
  }

  .sm\:tw-text-indigo-light {
    color: #7886d7;
  }

  .sm\:tw-text-indigo-lighter {
    color: #b2b7ff;
  }

  .sm\:tw-text-indigo-lightest {
    color: #e6e8ff;
  }

  .sm\:tw-text-purple-darkest {
    color: #21183c;
  }

  .sm\:tw-text-purple-darker {
    color: #382b5f;
  }

  .sm\:tw-text-purple-dark {
    color: #794acf;
  }

  .sm\:tw-text-purple {
    color: #9561e2;
  }

  .sm\:tw-text-purple-light {
    color: #a779e9;
  }

  .sm\:tw-text-purple-lighter {
    color: #d6bbfc;
  }

  .sm\:tw-text-purple-lightest {
    color: #f3ebff;
  }

  .sm\:tw-text-pink-darkest {
    color: #451225;
  }

  .sm\:tw-text-pink-darker {
    color: #6f213f;
  }

  .sm\:tw-text-pink-dark {
    color: #eb5286;
  }

  .sm\:tw-text-pink {
    color: #f66d9b;
  }

  .sm\:tw-text-pink-light {
    color: #fa7ea8;
  }

  .sm\:tw-text-pink-lighter {
    color: #ffbbca;
  }

  .sm\:tw-text-pink-lightest {
    color: #ffebef;
  }

  .sm\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .sm\:hover\:tw-text-primary:hover {
    color: #ac826d;
  }

  .sm\:hover\:tw-text-text:hover {
    color: #453f3f;
  }

  .sm\:hover\:tw-text-black:hover {
    color: #000;
  }

  .sm\:hover\:tw-text-grey-darkest:hover {
    color: #3d4852;
  }

  .sm\:hover\:tw-text-grey-darker:hover {
    color: #606f7b;
  }

  .sm\:hover\:tw-text-grey-dark:hover {
    color: #8795a1;
  }

  .sm\:hover\:tw-text-grey:hover {
    color: #b8c2cc;
  }

  .sm\:hover\:tw-text-grey-light:hover {
    color: #a1b3bc;
  }

  .sm\:hover\:tw-text-grey-lighter:hover {
    color: #d8dfe3;
  }

  .sm\:hover\:tw-text-grey-lightest:hover {
    color: #f8fafc;
  }

  .sm\:hover\:tw-text-grey-grayish:hover {
    color: #56666c;
  }

  .sm\:hover\:tw-text-white:hover {
    color: #fff;
  }

  .sm\:hover\:tw-text-red-darkest:hover {
    color: #3b0d0c;
  }

  .sm\:hover\:tw-text-red-darker:hover {
    color: #621b18;
  }

  .sm\:hover\:tw-text-red-dark:hover {
    color: #cc1f1a;
  }

  .sm\:hover\:tw-text-red:hover {
    color: #de5050;
  }

  .sm\:hover\:tw-text-red-light:hover {
    color: #fed5dc;
  }

  .sm\:hover\:tw-text-red-lighter:hover {
    color: #f9acaa;
  }

  .sm\:hover\:tw-text-red-lightest:hover {
    color: #fcebea;
  }

  .sm\:hover\:tw-text-orange-darkest:hover {
    color: #462a16;
  }

  .sm\:hover\:tw-text-orange-darker:hover {
    color: #613b1f;
  }

  .sm\:hover\:tw-text-orange-dark:hover {
    color: #de751f;
  }

  .sm\:hover\:tw-text-orange:hover {
    color: #ffbf00;
  }

  .sm\:hover\:tw-text-orange-light:hover {
    color: #faad63;
  }

  .sm\:hover\:tw-text-orange-lighter:hover {
    color: #fcd9b6;
  }

  .sm\:hover\:tw-text-orange-lightest:hover {
    color: #fff5eb;
  }

  .sm\:hover\:tw-text-yellow-darkest:hover {
    color: #453411;
  }

  .sm\:hover\:tw-text-yellow-darker:hover {
    color: #684f1d;
  }

  .sm\:hover\:tw-text-yellow-dark:hover {
    color: #f2d024;
  }

  .sm\:hover\:tw-text-yellow:hover {
    color: #f8e71c;
  }

  .sm\:hover\:tw-text-yellow-light:hover {
    color: #fff382;
  }

  .sm\:hover\:tw-text-yellow-lighter:hover {
    color: #fff9c2;
  }

  .sm\:hover\:tw-text-yellow-lightest:hover {
    color: #fcfbeb;
  }

  .sm\:hover\:tw-text-green-darkest:hover {
    color: #0f2f21;
  }

  .sm\:hover\:tw-text-green-darker:hover {
    color: #1a4731;
  }

  .sm\:hover\:tw-text-green-dark:hover {
    color: #1f9d55;
  }

  .sm\:hover\:tw-text-green:hover {
    color: #38c172;
  }

  .sm\:hover\:tw-text-green-light:hover {
    color: #51d88a;
  }

  .sm\:hover\:tw-text-green-lighter:hover {
    color: #a2f5bf;
  }

  .sm\:hover\:tw-text-green-lightest:hover {
    color: #e3fcec;
  }

  .sm\:hover\:tw-text-teal-darkest:hover {
    color: #0d3331;
  }

  .sm\:hover\:tw-text-teal-darker:hover {
    color: #20504f;
  }

  .sm\:hover\:tw-text-teal-dark:hover {
    color: #38a89d;
  }

  .sm\:hover\:tw-text-teal:hover {
    color: #17c7aa;
  }

  .sm\:hover\:tw-text-teal-light:hover {
    color: #64d5ca;
  }

  .sm\:hover\:tw-text-teal-lighter:hover {
    color: #a0f0ed;
  }

  .sm\:hover\:tw-text-teal-lightest:hover {
    color: #e8fffe;
  }

  .sm\:hover\:tw-text-blue-darkest:hover {
    color: #12283a;
  }

  .sm\:hover\:tw-text-blue-darker:hover {
    color: #1c3d5a;
  }

  .sm\:hover\:tw-text-blue-dark:hover {
    color: #2779bd;
  }

  .sm\:hover\:tw-text-blue:hover {
    color: #2ac1e7;
  }

  .sm\:hover\:tw-text-blue-light:hover {
    color: #d4f2fa;
  }

  .sm\:hover\:tw-text-blue-lighter:hover {
    color: #bcdefa;
  }

  .sm\:hover\:tw-text-blue-lightest:hover {
    color: #eff8ff;
  }

  .sm\:hover\:tw-text-indigo-darkest:hover {
    color: #191e38;
  }

  .sm\:hover\:tw-text-indigo-darker:hover {
    color: #2f365f;
  }

  .sm\:hover\:tw-text-indigo-dark:hover {
    color: #5661b3;
  }

  .sm\:hover\:tw-text-indigo:hover {
    color: #6574cd;
  }

  .sm\:hover\:tw-text-indigo-light:hover {
    color: #7886d7;
  }

  .sm\:hover\:tw-text-indigo-lighter:hover {
    color: #b2b7ff;
  }

  .sm\:hover\:tw-text-indigo-lightest:hover {
    color: #e6e8ff;
  }

  .sm\:hover\:tw-text-purple-darkest:hover {
    color: #21183c;
  }

  .sm\:hover\:tw-text-purple-darker:hover {
    color: #382b5f;
  }

  .sm\:hover\:tw-text-purple-dark:hover {
    color: #794acf;
  }

  .sm\:hover\:tw-text-purple:hover {
    color: #9561e2;
  }

  .sm\:hover\:tw-text-purple-light:hover {
    color: #a779e9;
  }

  .sm\:hover\:tw-text-purple-lighter:hover {
    color: #d6bbfc;
  }

  .sm\:hover\:tw-text-purple-lightest:hover {
    color: #f3ebff;
  }

  .sm\:hover\:tw-text-pink-darkest:hover {
    color: #451225;
  }

  .sm\:hover\:tw-text-pink-darker:hover {
    color: #6f213f;
  }

  .sm\:hover\:tw-text-pink-dark:hover {
    color: #eb5286;
  }

  .sm\:hover\:tw-text-pink:hover {
    color: #f66d9b;
  }

  .sm\:hover\:tw-text-pink-light:hover {
    color: #fa7ea8;
  }

  .sm\:hover\:tw-text-pink-lighter:hover {
    color: #ffbbca;
  }

  .sm\:hover\:tw-text-pink-lightest:hover {
    color: #ffebef;
  }

  .sm\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .sm\:focus\:tw-text-primary:focus {
    color: #ac826d;
  }

  .sm\:focus\:tw-text-text:focus {
    color: #453f3f;
  }

  .sm\:focus\:tw-text-black:focus {
    color: #000;
  }

  .sm\:focus\:tw-text-grey-darkest:focus {
    color: #3d4852;
  }

  .sm\:focus\:tw-text-grey-darker:focus {
    color: #606f7b;
  }

  .sm\:focus\:tw-text-grey-dark:focus {
    color: #8795a1;
  }

  .sm\:focus\:tw-text-grey:focus {
    color: #b8c2cc;
  }

  .sm\:focus\:tw-text-grey-light:focus {
    color: #a1b3bc;
  }

  .sm\:focus\:tw-text-grey-lighter:focus {
    color: #d8dfe3;
  }

  .sm\:focus\:tw-text-grey-lightest:focus {
    color: #f8fafc;
  }

  .sm\:focus\:tw-text-grey-grayish:focus {
    color: #56666c;
  }

  .sm\:focus\:tw-text-white:focus {
    color: #fff;
  }

  .sm\:focus\:tw-text-red-darkest:focus {
    color: #3b0d0c;
  }

  .sm\:focus\:tw-text-red-darker:focus {
    color: #621b18;
  }

  .sm\:focus\:tw-text-red-dark:focus {
    color: #cc1f1a;
  }

  .sm\:focus\:tw-text-red:focus {
    color: #de5050;
  }

  .sm\:focus\:tw-text-red-light:focus {
    color: #fed5dc;
  }

  .sm\:focus\:tw-text-red-lighter:focus {
    color: #f9acaa;
  }

  .sm\:focus\:tw-text-red-lightest:focus {
    color: #fcebea;
  }

  .sm\:focus\:tw-text-orange-darkest:focus {
    color: #462a16;
  }

  .sm\:focus\:tw-text-orange-darker:focus {
    color: #613b1f;
  }

  .sm\:focus\:tw-text-orange-dark:focus {
    color: #de751f;
  }

  .sm\:focus\:tw-text-orange:focus {
    color: #ffbf00;
  }

  .sm\:focus\:tw-text-orange-light:focus {
    color: #faad63;
  }

  .sm\:focus\:tw-text-orange-lighter:focus {
    color: #fcd9b6;
  }

  .sm\:focus\:tw-text-orange-lightest:focus {
    color: #fff5eb;
  }

  .sm\:focus\:tw-text-yellow-darkest:focus {
    color: #453411;
  }

  .sm\:focus\:tw-text-yellow-darker:focus {
    color: #684f1d;
  }

  .sm\:focus\:tw-text-yellow-dark:focus {
    color: #f2d024;
  }

  .sm\:focus\:tw-text-yellow:focus {
    color: #f8e71c;
  }

  .sm\:focus\:tw-text-yellow-light:focus {
    color: #fff382;
  }

  .sm\:focus\:tw-text-yellow-lighter:focus {
    color: #fff9c2;
  }

  .sm\:focus\:tw-text-yellow-lightest:focus {
    color: #fcfbeb;
  }

  .sm\:focus\:tw-text-green-darkest:focus {
    color: #0f2f21;
  }

  .sm\:focus\:tw-text-green-darker:focus {
    color: #1a4731;
  }

  .sm\:focus\:tw-text-green-dark:focus {
    color: #1f9d55;
  }

  .sm\:focus\:tw-text-green:focus {
    color: #38c172;
  }

  .sm\:focus\:tw-text-green-light:focus {
    color: #51d88a;
  }

  .sm\:focus\:tw-text-green-lighter:focus {
    color: #a2f5bf;
  }

  .sm\:focus\:tw-text-green-lightest:focus {
    color: #e3fcec;
  }

  .sm\:focus\:tw-text-teal-darkest:focus {
    color: #0d3331;
  }

  .sm\:focus\:tw-text-teal-darker:focus {
    color: #20504f;
  }

  .sm\:focus\:tw-text-teal-dark:focus {
    color: #38a89d;
  }

  .sm\:focus\:tw-text-teal:focus {
    color: #17c7aa;
  }

  .sm\:focus\:tw-text-teal-light:focus {
    color: #64d5ca;
  }

  .sm\:focus\:tw-text-teal-lighter:focus {
    color: #a0f0ed;
  }

  .sm\:focus\:tw-text-teal-lightest:focus {
    color: #e8fffe;
  }

  .sm\:focus\:tw-text-blue-darkest:focus {
    color: #12283a;
  }

  .sm\:focus\:tw-text-blue-darker:focus {
    color: #1c3d5a;
  }

  .sm\:focus\:tw-text-blue-dark:focus {
    color: #2779bd;
  }

  .sm\:focus\:tw-text-blue:focus {
    color: #2ac1e7;
  }

  .sm\:focus\:tw-text-blue-light:focus {
    color: #d4f2fa;
  }

  .sm\:focus\:tw-text-blue-lighter:focus {
    color: #bcdefa;
  }

  .sm\:focus\:tw-text-blue-lightest:focus {
    color: #eff8ff;
  }

  .sm\:focus\:tw-text-indigo-darkest:focus {
    color: #191e38;
  }

  .sm\:focus\:tw-text-indigo-darker:focus {
    color: #2f365f;
  }

  .sm\:focus\:tw-text-indigo-dark:focus {
    color: #5661b3;
  }

  .sm\:focus\:tw-text-indigo:focus {
    color: #6574cd;
  }

  .sm\:focus\:tw-text-indigo-light:focus {
    color: #7886d7;
  }

  .sm\:focus\:tw-text-indigo-lighter:focus {
    color: #b2b7ff;
  }

  .sm\:focus\:tw-text-indigo-lightest:focus {
    color: #e6e8ff;
  }

  .sm\:focus\:tw-text-purple-darkest:focus {
    color: #21183c;
  }

  .sm\:focus\:tw-text-purple-darker:focus {
    color: #382b5f;
  }

  .sm\:focus\:tw-text-purple-dark:focus {
    color: #794acf;
  }

  .sm\:focus\:tw-text-purple:focus {
    color: #9561e2;
  }

  .sm\:focus\:tw-text-purple-light:focus {
    color: #a779e9;
  }

  .sm\:focus\:tw-text-purple-lighter:focus {
    color: #d6bbfc;
  }

  .sm\:focus\:tw-text-purple-lightest:focus {
    color: #f3ebff;
  }

  .sm\:focus\:tw-text-pink-darkest:focus {
    color: #451225;
  }

  .sm\:focus\:tw-text-pink-darker:focus {
    color: #6f213f;
  }

  .sm\:focus\:tw-text-pink-dark:focus {
    color: #eb5286;
  }

  .sm\:focus\:tw-text-pink:focus {
    color: #f66d9b;
  }

  .sm\:focus\:tw-text-pink-light:focus {
    color: #fa7ea8;
  }

  .sm\:focus\:tw-text-pink-lighter:focus {
    color: #ffbbca;
  }

  .sm\:focus\:tw-text-pink-lightest:focus {
    color: #ffebef;
  }

  .sm\:tw-text-xsss {
    font-size: 8px;
  }

  .sm\:tw-text-xss {
    font-size: 10px;
  }

  .sm\:tw-text-xs {
    font-size: .75rem;
  }

  .sm\:tw-text-sm {
    font-size: .875rem;
  }

  .sm\:tw-text-base {
    font-size: 1rem;
  }

  .sm\:tw-text-lg {
    font-size: 1.125rem;
  }

  .sm\:tw-text-xl {
    font-size: 1.25rem;
  }

  .sm\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .sm\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .sm\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .sm\:tw-text-5xl {
    font-size: 3rem;
  }

  .sm\:tw-italic {
    font-style: italic;
  }

  .sm\:tw-roman {
    font-style: normal;
  }

  .sm\:tw-uppercase {
    text-transform: uppercase;
  }

  .sm\:tw-lowercase {
    text-transform: lowercase;
  }

  .sm\:tw-capitalize {
    text-transform: capitalize;
  }

  .sm\:tw-normal-case {
    text-transform: none;
  }

  .sm\:tw-underline {
    text-decoration: underline;
  }

  .sm\:tw-line-through {
    text-decoration: line-through;
  }

  .sm\:tw-no-underline {
    text-decoration: none;
  }

  .sm\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:hover\:tw-italic:hover {
    font-style: italic;
  }

  .sm\:hover\:tw-roman:hover {
    font-style: normal;
  }

  .sm\:hover\:tw-uppercase:hover {
    text-transform: uppercase;
  }

  .sm\:hover\:tw-lowercase:hover {
    text-transform: lowercase;
  }

  .sm\:hover\:tw-capitalize:hover {
    text-transform: capitalize;
  }

  .sm\:hover\:tw-normal-case:hover {
    text-transform: none;
  }

  .sm\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .sm\:hover\:tw-antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:hover\:tw-subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:focus\:tw-italic:focus {
    font-style: italic;
  }

  .sm\:focus\:tw-roman:focus {
    font-style: normal;
  }

  .sm\:focus\:tw-uppercase:focus {
    text-transform: uppercase;
  }

  .sm\:focus\:tw-lowercase:focus {
    text-transform: lowercase;
  }

  .sm\:focus\:tw-capitalize:focus {
    text-transform: capitalize;
  }

  .sm\:focus\:tw-normal-case:focus {
    text-transform: none;
  }

  .sm\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .sm\:focus\:tw-antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:focus\:tw-subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:tw-tracking-tight {
    letter-spacing: -0.05em;
  }

  .sm\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .sm\:tw-tracking-wide {
    letter-spacing: .05em;
  }

  .sm\:tw-select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .sm\:tw-select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .sm\:tw-align-baseline {
    vertical-align: baseline;
  }

  .sm\:tw-align-top {
    vertical-align: top;
  }

  .sm\:tw-align-middle {
    vertical-align: middle;
  }

  .sm\:tw-align-bottom {
    vertical-align: bottom;
  }

  .sm\:tw-align-text-top {
    vertical-align: text-top;
  }

  .sm\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:tw-visible {
    visibility: visible;
  }

  .sm\:tw-invisible {
    visibility: hidden;
  }

  .sm\:tw-whitespace-normal {
    white-space: normal;
  }

  .sm\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .sm\:tw-whitespace-pre {
    white-space: pre;
  }

  .sm\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:tw-break-words {
    word-wrap: break-word;
  }

  .sm\:tw-break-normal {
    word-wrap: normal;
  }

  .sm\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sm\:tw-w-1 {
    width: .25rem;
  }

  .sm\:tw-w-2 {
    width: .5rem;
  }

  .sm\:tw-w-3 {
    width: .75rem;
  }

  .sm\:tw-w-4 {
    width: 1rem;
  }

  .sm\:tw-w-5 {
    width: 1.25rem;
  }

  .sm\:tw-w-6 {
    width: 1.5rem;
  }

  .sm\:tw-w-8 {
    width: 2rem;
  }

  .sm\:tw-w-10 {
    width: 2.5rem;
  }

  .sm\:tw-w-12 {
    width: 3rem;
  }

  .sm\:tw-w-16 {
    width: 4rem;
  }

  .sm\:tw-w-24 {
    width: 6rem;
  }

  .sm\:tw-w-32 {
    width: 8rem;
  }

  .sm\:tw-w-48 {
    width: 12rem;
  }

  .sm\:tw-w-64 {
    width: 16rem;
  }

  .sm\:tw-w-auto {
    width: auto;
  }

  .sm\:tw-w-px {
    width: 1px;
  }

  .sm\:tw-w-1\/2 {
    width: 50%;
  }

  .sm\:tw-w-1\/3 {
    width: 33.33333%;
  }

  .sm\:tw-w-2\/3 {
    width: 66.66667%;
  }

  .sm\:tw-w-1\/4 {
    width: 25%;
  }

  .sm\:tw-w-3\/4 {
    width: 75%;
  }

  .sm\:tw-w-1\/5 {
    width: 20%;
  }

  .sm\:tw-w-2\/5 {
    width: 40%;
  }

  .sm\:tw-w-3\/5 {
    width: 60%;
  }

  .sm\:tw-w-4\/5 {
    width: 80%;
  }

  .sm\:tw-w-1\/6 {
    width: 16.66667%;
  }

  .sm\:tw-w-5\/6 {
    width: 83.33333%;
  }

  .sm\:tw-w-full {
    width: 100%;
  }

  .sm\:tw-w-screen {
    width: 100vw;
  }

  .sm\:tw-z-0 {
    z-index: 0;
  }

  .sm\:tw-z-10 {
    z-index: 10;
  }

  .sm\:tw-z-20 {
    z-index: 20;
  }

  .sm\:tw-z-30 {
    z-index: 30;
  }

  .sm\:tw-z-40 {
    z-index: 40;
  }

  .sm\:tw-z-50 {
    z-index: 50;
  }

  .sm\:tw-z-auto {
    z-index: auto;
  }
}

@media (min-width: 768px) {
  .md\:tw-list-reset {
    list-style: none;
    padding: 0;
  }

  .md\:tw-appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .md\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .md\:tw-bg-local {
    background-attachment: local;
  }

  .md\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .md\:tw-bg-transparent {
    background-color: transparent;
  }

  .md\:tw-bg-primary {
    background-color: #ac826d;
  }

  .md\:tw-bg-text {
    background-color: #453f3f;
  }

  .md\:tw-bg-black {
    background-color: #000;
  }

  .md\:tw-bg-grey-darkest {
    background-color: #3d4852;
  }

  .md\:tw-bg-grey-darker {
    background-color: #606f7b;
  }

  .md\:tw-bg-grey-dark {
    background-color: #8795a1;
  }

  .md\:tw-bg-grey {
    background-color: #b8c2cc;
  }

  .md\:tw-bg-grey-light {
    background-color: #a1b3bc;
  }

  .md\:tw-bg-grey-lighter {
    background-color: #d8dfe3;
  }

  .md\:tw-bg-grey-lightest {
    background-color: #f8fafc;
  }

  .md\:tw-bg-grey-grayish {
    background-color: #56666c;
  }

  .md\:tw-bg-white {
    background-color: #fff;
  }

  .md\:tw-bg-red-darkest {
    background-color: #3b0d0c;
  }

  .md\:tw-bg-red-darker {
    background-color: #621b18;
  }

  .md\:tw-bg-red-dark {
    background-color: #cc1f1a;
  }

  .md\:tw-bg-red {
    background-color: #de5050;
  }

  .md\:tw-bg-red-light {
    background-color: #fed5dc;
  }

  .md\:tw-bg-red-lighter {
    background-color: #f9acaa;
  }

  .md\:tw-bg-red-lightest {
    background-color: #fcebea;
  }

  .md\:tw-bg-orange-darkest {
    background-color: #462a16;
  }

  .md\:tw-bg-orange-darker {
    background-color: #613b1f;
  }

  .md\:tw-bg-orange-dark {
    background-color: #de751f;
  }

  .md\:tw-bg-orange {
    background-color: #ffbf00;
  }

  .md\:tw-bg-orange-light {
    background-color: #faad63;
  }

  .md\:tw-bg-orange-lighter {
    background-color: #fcd9b6;
  }

  .md\:tw-bg-orange-lightest {
    background-color: #fff5eb;
  }

  .md\:tw-bg-yellow-darkest {
    background-color: #453411;
  }

  .md\:tw-bg-yellow-darker {
    background-color: #684f1d;
  }

  .md\:tw-bg-yellow-dark {
    background-color: #f2d024;
  }

  .md\:tw-bg-yellow {
    background-color: #f8e71c;
  }

  .md\:tw-bg-yellow-light {
    background-color: #fff382;
  }

  .md\:tw-bg-yellow-lighter {
    background-color: #fff9c2;
  }

  .md\:tw-bg-yellow-lightest {
    background-color: #fcfbeb;
  }

  .md\:tw-bg-green-darkest {
    background-color: #0f2f21;
  }

  .md\:tw-bg-green-darker {
    background-color: #1a4731;
  }

  .md\:tw-bg-green-dark {
    background-color: #1f9d55;
  }

  .md\:tw-bg-green {
    background-color: #38c172;
  }

  .md\:tw-bg-green-light {
    background-color: #51d88a;
  }

  .md\:tw-bg-green-lighter {
    background-color: #a2f5bf;
  }

  .md\:tw-bg-green-lightest {
    background-color: #e3fcec;
  }

  .md\:tw-bg-teal-darkest {
    background-color: #0d3331;
  }

  .md\:tw-bg-teal-darker {
    background-color: #20504f;
  }

  .md\:tw-bg-teal-dark {
    background-color: #38a89d;
  }

  .md\:tw-bg-teal {
    background-color: #17c7aa;
  }

  .md\:tw-bg-teal-light {
    background-color: #64d5ca;
  }

  .md\:tw-bg-teal-lighter {
    background-color: #a0f0ed;
  }

  .md\:tw-bg-teal-lightest {
    background-color: #e8fffe;
  }

  .md\:tw-bg-blue-darkest {
    background-color: #12283a;
  }

  .md\:tw-bg-blue-darker {
    background-color: #1c3d5a;
  }

  .md\:tw-bg-blue-dark {
    background-color: #2779bd;
  }

  .md\:tw-bg-blue {
    background-color: #2ac1e7;
  }

  .md\:tw-bg-blue-light {
    background-color: #d4f2fa;
  }

  .md\:tw-bg-blue-lighter {
    background-color: #bcdefa;
  }

  .md\:tw-bg-blue-lightest {
    background-color: #eff8ff;
  }

  .md\:tw-bg-indigo-darkest {
    background-color: #191e38;
  }

  .md\:tw-bg-indigo-darker {
    background-color: #2f365f;
  }

  .md\:tw-bg-indigo-dark {
    background-color: #5661b3;
  }

  .md\:tw-bg-indigo {
    background-color: #6574cd;
  }

  .md\:tw-bg-indigo-light {
    background-color: #7886d7;
  }

  .md\:tw-bg-indigo-lighter {
    background-color: #b2b7ff;
  }

  .md\:tw-bg-indigo-lightest {
    background-color: #e6e8ff;
  }

  .md\:tw-bg-purple-darkest {
    background-color: #21183c;
  }

  .md\:tw-bg-purple-darker {
    background-color: #382b5f;
  }

  .md\:tw-bg-purple-dark {
    background-color: #794acf;
  }

  .md\:tw-bg-purple {
    background-color: #9561e2;
  }

  .md\:tw-bg-purple-light {
    background-color: #a779e9;
  }

  .md\:tw-bg-purple-lighter {
    background-color: #d6bbfc;
  }

  .md\:tw-bg-purple-lightest {
    background-color: #f3ebff;
  }

  .md\:tw-bg-pink-darkest {
    background-color: #451225;
  }

  .md\:tw-bg-pink-darker {
    background-color: #6f213f;
  }

  .md\:tw-bg-pink-dark {
    background-color: #eb5286;
  }

  .md\:tw-bg-pink {
    background-color: #f66d9b;
  }

  .md\:tw-bg-pink-light {
    background-color: #fa7ea8;
  }

  .md\:tw-bg-pink-lighter {
    background-color: #ffbbca;
  }

  .md\:tw-bg-pink-lightest {
    background-color: #ffebef;
  }

  .md\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .md\:hover\:tw-bg-primary:hover {
    background-color: #ac826d;
  }

  .md\:hover\:tw-bg-text:hover {
    background-color: #453f3f;
  }

  .md\:hover\:tw-bg-black:hover {
    background-color: #000;
  }

  .md\:hover\:tw-bg-grey-darkest:hover {
    background-color: #3d4852;
  }

  .md\:hover\:tw-bg-grey-darker:hover {
    background-color: #606f7b;
  }

  .md\:hover\:tw-bg-grey-dark:hover {
    background-color: #8795a1;
  }

  .md\:hover\:tw-bg-grey:hover {
    background-color: #b8c2cc;
  }

  .md\:hover\:tw-bg-grey-light:hover {
    background-color: #a1b3bc;
  }

  .md\:hover\:tw-bg-grey-lighter:hover {
    background-color: #d8dfe3;
  }

  .md\:hover\:tw-bg-grey-lightest:hover {
    background-color: #f8fafc;
  }

  .md\:hover\:tw-bg-grey-grayish:hover {
    background-color: #56666c;
  }

  .md\:hover\:tw-bg-white:hover {
    background-color: #fff;
  }

  .md\:hover\:tw-bg-red-darkest:hover {
    background-color: #3b0d0c;
  }

  .md\:hover\:tw-bg-red-darker:hover {
    background-color: #621b18;
  }

  .md\:hover\:tw-bg-red-dark:hover {
    background-color: #cc1f1a;
  }

  .md\:hover\:tw-bg-red:hover {
    background-color: #de5050;
  }

  .md\:hover\:tw-bg-red-light:hover {
    background-color: #fed5dc;
  }

  .md\:hover\:tw-bg-red-lighter:hover {
    background-color: #f9acaa;
  }

  .md\:hover\:tw-bg-red-lightest:hover {
    background-color: #fcebea;
  }

  .md\:hover\:tw-bg-orange-darkest:hover {
    background-color: #462a16;
  }

  .md\:hover\:tw-bg-orange-darker:hover {
    background-color: #613b1f;
  }

  .md\:hover\:tw-bg-orange-dark:hover {
    background-color: #de751f;
  }

  .md\:hover\:tw-bg-orange:hover {
    background-color: #ffbf00;
  }

  .md\:hover\:tw-bg-orange-light:hover {
    background-color: #faad63;
  }

  .md\:hover\:tw-bg-orange-lighter:hover {
    background-color: #fcd9b6;
  }

  .md\:hover\:tw-bg-orange-lightest:hover {
    background-color: #fff5eb;
  }

  .md\:hover\:tw-bg-yellow-darkest:hover {
    background-color: #453411;
  }

  .md\:hover\:tw-bg-yellow-darker:hover {
    background-color: #684f1d;
  }

  .md\:hover\:tw-bg-yellow-dark:hover {
    background-color: #f2d024;
  }

  .md\:hover\:tw-bg-yellow:hover {
    background-color: #f8e71c;
  }

  .md\:hover\:tw-bg-yellow-light:hover {
    background-color: #fff382;
  }

  .md\:hover\:tw-bg-yellow-lighter:hover {
    background-color: #fff9c2;
  }

  .md\:hover\:tw-bg-yellow-lightest:hover {
    background-color: #fcfbeb;
  }

  .md\:hover\:tw-bg-green-darkest:hover {
    background-color: #0f2f21;
  }

  .md\:hover\:tw-bg-green-darker:hover {
    background-color: #1a4731;
  }

  .md\:hover\:tw-bg-green-dark:hover {
    background-color: #1f9d55;
  }

  .md\:hover\:tw-bg-green:hover {
    background-color: #38c172;
  }

  .md\:hover\:tw-bg-green-light:hover {
    background-color: #51d88a;
  }

  .md\:hover\:tw-bg-green-lighter:hover {
    background-color: #a2f5bf;
  }

  .md\:hover\:tw-bg-green-lightest:hover {
    background-color: #e3fcec;
  }

  .md\:hover\:tw-bg-teal-darkest:hover {
    background-color: #0d3331;
  }

  .md\:hover\:tw-bg-teal-darker:hover {
    background-color: #20504f;
  }

  .md\:hover\:tw-bg-teal-dark:hover {
    background-color: #38a89d;
  }

  .md\:hover\:tw-bg-teal:hover {
    background-color: #17c7aa;
  }

  .md\:hover\:tw-bg-teal-light:hover {
    background-color: #64d5ca;
  }

  .md\:hover\:tw-bg-teal-lighter:hover {
    background-color: #a0f0ed;
  }

  .md\:hover\:tw-bg-teal-lightest:hover {
    background-color: #e8fffe;
  }

  .md\:hover\:tw-bg-blue-darkest:hover {
    background-color: #12283a;
  }

  .md\:hover\:tw-bg-blue-darker:hover {
    background-color: #1c3d5a;
  }

  .md\:hover\:tw-bg-blue-dark:hover {
    background-color: #2779bd;
  }

  .md\:hover\:tw-bg-blue:hover {
    background-color: #2ac1e7;
  }

  .md\:hover\:tw-bg-blue-light:hover {
    background-color: #d4f2fa;
  }

  .md\:hover\:tw-bg-blue-lighter:hover {
    background-color: #bcdefa;
  }

  .md\:hover\:tw-bg-blue-lightest:hover {
    background-color: #eff8ff;
  }

  .md\:hover\:tw-bg-indigo-darkest:hover {
    background-color: #191e38;
  }

  .md\:hover\:tw-bg-indigo-darker:hover {
    background-color: #2f365f;
  }

  .md\:hover\:tw-bg-indigo-dark:hover {
    background-color: #5661b3;
  }

  .md\:hover\:tw-bg-indigo:hover {
    background-color: #6574cd;
  }

  .md\:hover\:tw-bg-indigo-light:hover {
    background-color: #7886d7;
  }

  .md\:hover\:tw-bg-indigo-lighter:hover {
    background-color: #b2b7ff;
  }

  .md\:hover\:tw-bg-indigo-lightest:hover {
    background-color: #e6e8ff;
  }

  .md\:hover\:tw-bg-purple-darkest:hover {
    background-color: #21183c;
  }

  .md\:hover\:tw-bg-purple-darker:hover {
    background-color: #382b5f;
  }

  .md\:hover\:tw-bg-purple-dark:hover {
    background-color: #794acf;
  }

  .md\:hover\:tw-bg-purple:hover {
    background-color: #9561e2;
  }

  .md\:hover\:tw-bg-purple-light:hover {
    background-color: #a779e9;
  }

  .md\:hover\:tw-bg-purple-lighter:hover {
    background-color: #d6bbfc;
  }

  .md\:hover\:tw-bg-purple-lightest:hover {
    background-color: #f3ebff;
  }

  .md\:hover\:tw-bg-pink-darkest:hover {
    background-color: #451225;
  }

  .md\:hover\:tw-bg-pink-darker:hover {
    background-color: #6f213f;
  }

  .md\:hover\:tw-bg-pink-dark:hover {
    background-color: #eb5286;
  }

  .md\:hover\:tw-bg-pink:hover {
    background-color: #f66d9b;
  }

  .md\:hover\:tw-bg-pink-light:hover {
    background-color: #fa7ea8;
  }

  .md\:hover\:tw-bg-pink-lighter:hover {
    background-color: #ffbbca;
  }

  .md\:hover\:tw-bg-pink-lightest:hover {
    background-color: #ffebef;
  }

  .md\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .md\:focus\:tw-bg-primary:focus {
    background-color: #ac826d;
  }

  .md\:focus\:tw-bg-text:focus {
    background-color: #453f3f;
  }

  .md\:focus\:tw-bg-black:focus {
    background-color: #000;
  }

  .md\:focus\:tw-bg-grey-darkest:focus {
    background-color: #3d4852;
  }

  .md\:focus\:tw-bg-grey-darker:focus {
    background-color: #606f7b;
  }

  .md\:focus\:tw-bg-grey-dark:focus {
    background-color: #8795a1;
  }

  .md\:focus\:tw-bg-grey:focus {
    background-color: #b8c2cc;
  }

  .md\:focus\:tw-bg-grey-light:focus {
    background-color: #a1b3bc;
  }

  .md\:focus\:tw-bg-grey-lighter:focus {
    background-color: #d8dfe3;
  }

  .md\:focus\:tw-bg-grey-lightest:focus {
    background-color: #f8fafc;
  }

  .md\:focus\:tw-bg-grey-grayish:focus {
    background-color: #56666c;
  }

  .md\:focus\:tw-bg-white:focus {
    background-color: #fff;
  }

  .md\:focus\:tw-bg-red-darkest:focus {
    background-color: #3b0d0c;
  }

  .md\:focus\:tw-bg-red-darker:focus {
    background-color: #621b18;
  }

  .md\:focus\:tw-bg-red-dark:focus {
    background-color: #cc1f1a;
  }

  .md\:focus\:tw-bg-red:focus {
    background-color: #de5050;
  }

  .md\:focus\:tw-bg-red-light:focus {
    background-color: #fed5dc;
  }

  .md\:focus\:tw-bg-red-lighter:focus {
    background-color: #f9acaa;
  }

  .md\:focus\:tw-bg-red-lightest:focus {
    background-color: #fcebea;
  }

  .md\:focus\:tw-bg-orange-darkest:focus {
    background-color: #462a16;
  }

  .md\:focus\:tw-bg-orange-darker:focus {
    background-color: #613b1f;
  }

  .md\:focus\:tw-bg-orange-dark:focus {
    background-color: #de751f;
  }

  .md\:focus\:tw-bg-orange:focus {
    background-color: #ffbf00;
  }

  .md\:focus\:tw-bg-orange-light:focus {
    background-color: #faad63;
  }

  .md\:focus\:tw-bg-orange-lighter:focus {
    background-color: #fcd9b6;
  }

  .md\:focus\:tw-bg-orange-lightest:focus {
    background-color: #fff5eb;
  }

  .md\:focus\:tw-bg-yellow-darkest:focus {
    background-color: #453411;
  }

  .md\:focus\:tw-bg-yellow-darker:focus {
    background-color: #684f1d;
  }

  .md\:focus\:tw-bg-yellow-dark:focus {
    background-color: #f2d024;
  }

  .md\:focus\:tw-bg-yellow:focus {
    background-color: #f8e71c;
  }

  .md\:focus\:tw-bg-yellow-light:focus {
    background-color: #fff382;
  }

  .md\:focus\:tw-bg-yellow-lighter:focus {
    background-color: #fff9c2;
  }

  .md\:focus\:tw-bg-yellow-lightest:focus {
    background-color: #fcfbeb;
  }

  .md\:focus\:tw-bg-green-darkest:focus {
    background-color: #0f2f21;
  }

  .md\:focus\:tw-bg-green-darker:focus {
    background-color: #1a4731;
  }

  .md\:focus\:tw-bg-green-dark:focus {
    background-color: #1f9d55;
  }

  .md\:focus\:tw-bg-green:focus {
    background-color: #38c172;
  }

  .md\:focus\:tw-bg-green-light:focus {
    background-color: #51d88a;
  }

  .md\:focus\:tw-bg-green-lighter:focus {
    background-color: #a2f5bf;
  }

  .md\:focus\:tw-bg-green-lightest:focus {
    background-color: #e3fcec;
  }

  .md\:focus\:tw-bg-teal-darkest:focus {
    background-color: #0d3331;
  }

  .md\:focus\:tw-bg-teal-darker:focus {
    background-color: #20504f;
  }

  .md\:focus\:tw-bg-teal-dark:focus {
    background-color: #38a89d;
  }

  .md\:focus\:tw-bg-teal:focus {
    background-color: #17c7aa;
  }

  .md\:focus\:tw-bg-teal-light:focus {
    background-color: #64d5ca;
  }

  .md\:focus\:tw-bg-teal-lighter:focus {
    background-color: #a0f0ed;
  }

  .md\:focus\:tw-bg-teal-lightest:focus {
    background-color: #e8fffe;
  }

  .md\:focus\:tw-bg-blue-darkest:focus {
    background-color: #12283a;
  }

  .md\:focus\:tw-bg-blue-darker:focus {
    background-color: #1c3d5a;
  }

  .md\:focus\:tw-bg-blue-dark:focus {
    background-color: #2779bd;
  }

  .md\:focus\:tw-bg-blue:focus {
    background-color: #2ac1e7;
  }

  .md\:focus\:tw-bg-blue-light:focus {
    background-color: #d4f2fa;
  }

  .md\:focus\:tw-bg-blue-lighter:focus {
    background-color: #bcdefa;
  }

  .md\:focus\:tw-bg-blue-lightest:focus {
    background-color: #eff8ff;
  }

  .md\:focus\:tw-bg-indigo-darkest:focus {
    background-color: #191e38;
  }

  .md\:focus\:tw-bg-indigo-darker:focus {
    background-color: #2f365f;
  }

  .md\:focus\:tw-bg-indigo-dark:focus {
    background-color: #5661b3;
  }

  .md\:focus\:tw-bg-indigo:focus {
    background-color: #6574cd;
  }

  .md\:focus\:tw-bg-indigo-light:focus {
    background-color: #7886d7;
  }

  .md\:focus\:tw-bg-indigo-lighter:focus {
    background-color: #b2b7ff;
  }

  .md\:focus\:tw-bg-indigo-lightest:focus {
    background-color: #e6e8ff;
  }

  .md\:focus\:tw-bg-purple-darkest:focus {
    background-color: #21183c;
  }

  .md\:focus\:tw-bg-purple-darker:focus {
    background-color: #382b5f;
  }

  .md\:focus\:tw-bg-purple-dark:focus {
    background-color: #794acf;
  }

  .md\:focus\:tw-bg-purple:focus {
    background-color: #9561e2;
  }

  .md\:focus\:tw-bg-purple-light:focus {
    background-color: #a779e9;
  }

  .md\:focus\:tw-bg-purple-lighter:focus {
    background-color: #d6bbfc;
  }

  .md\:focus\:tw-bg-purple-lightest:focus {
    background-color: #f3ebff;
  }

  .md\:focus\:tw-bg-pink-darkest:focus {
    background-color: #451225;
  }

  .md\:focus\:tw-bg-pink-darker:focus {
    background-color: #6f213f;
  }

  .md\:focus\:tw-bg-pink-dark:focus {
    background-color: #eb5286;
  }

  .md\:focus\:tw-bg-pink:focus {
    background-color: #f66d9b;
  }

  .md\:focus\:tw-bg-pink-light:focus {
    background-color: #fa7ea8;
  }

  .md\:focus\:tw-bg-pink-lighter:focus {
    background-color: #ffbbca;
  }

  .md\:focus\:tw-bg-pink-lightest:focus {
    background-color: #ffebef;
  }

  .md\:tw-bg-bottom {
    background-position: bottom;
  }

  .md\:tw-bg-center {
    background-position: center;
  }

  .md\:tw-bg-left {
    background-position: left;
  }

  .md\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .md\:tw-bg-left-top {
    background-position: left top;
  }

  .md\:tw-bg-right {
    background-position: right;
  }

  .md\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .md\:tw-bg-right-top {
    background-position: right top;
  }

  .md\:tw-bg-top {
    background-position: top;
  }

  .md\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .md\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:tw-bg-auto {
    background-size: auto;
  }

  .md\:tw-bg-cover {
    background-size: cover;
  }

  .md\:tw-bg-contain {
    background-size: contain;
  }

  .md\:tw-border-transparent {
    border-color: transparent;
  }

  .md\:tw-border-primary {
    border-color: #ac826d;
  }

  .md\:tw-border-text {
    border-color: #453f3f;
  }

  .md\:tw-border-black {
    border-color: #000;
  }

  .md\:tw-border-grey-darkest {
    border-color: #3d4852;
  }

  .md\:tw-border-grey-darker {
    border-color: #606f7b;
  }

  .md\:tw-border-grey-dark {
    border-color: #8795a1;
  }

  .md\:tw-border-grey {
    border-color: #b8c2cc;
  }

  .md\:tw-border-grey-light {
    border-color: #a1b3bc;
  }

  .md\:tw-border-grey-lighter {
    border-color: #d8dfe3;
  }

  .md\:tw-border-grey-lightest {
    border-color: #f8fafc;
  }

  .md\:tw-border-grey-grayish {
    border-color: #56666c;
  }

  .md\:tw-border-white {
    border-color: #fff;
  }

  .md\:tw-border-red-darkest {
    border-color: #3b0d0c;
  }

  .md\:tw-border-red-darker {
    border-color: #621b18;
  }

  .md\:tw-border-red-dark {
    border-color: #cc1f1a;
  }

  .md\:tw-border-red {
    border-color: #de5050;
  }

  .md\:tw-border-red-light {
    border-color: #fed5dc;
  }

  .md\:tw-border-red-lighter {
    border-color: #f9acaa;
  }

  .md\:tw-border-red-lightest {
    border-color: #fcebea;
  }

  .md\:tw-border-orange-darkest {
    border-color: #462a16;
  }

  .md\:tw-border-orange-darker {
    border-color: #613b1f;
  }

  .md\:tw-border-orange-dark {
    border-color: #de751f;
  }

  .md\:tw-border-orange {
    border-color: #ffbf00;
  }

  .md\:tw-border-orange-light {
    border-color: #faad63;
  }

  .md\:tw-border-orange-lighter {
    border-color: #fcd9b6;
  }

  .md\:tw-border-orange-lightest {
    border-color: #fff5eb;
  }

  .md\:tw-border-yellow-darkest {
    border-color: #453411;
  }

  .md\:tw-border-yellow-darker {
    border-color: #684f1d;
  }

  .md\:tw-border-yellow-dark {
    border-color: #f2d024;
  }

  .md\:tw-border-yellow {
    border-color: #f8e71c;
  }

  .md\:tw-border-yellow-light {
    border-color: #fff382;
  }

  .md\:tw-border-yellow-lighter {
    border-color: #fff9c2;
  }

  .md\:tw-border-yellow-lightest {
    border-color: #fcfbeb;
  }

  .md\:tw-border-green-darkest {
    border-color: #0f2f21;
  }

  .md\:tw-border-green-darker {
    border-color: #1a4731;
  }

  .md\:tw-border-green-dark {
    border-color: #1f9d55;
  }

  .md\:tw-border-green {
    border-color: #38c172;
  }

  .md\:tw-border-green-light {
    border-color: #51d88a;
  }

  .md\:tw-border-green-lighter {
    border-color: #a2f5bf;
  }

  .md\:tw-border-green-lightest {
    border-color: #e3fcec;
  }

  .md\:tw-border-teal-darkest {
    border-color: #0d3331;
  }

  .md\:tw-border-teal-darker {
    border-color: #20504f;
  }

  .md\:tw-border-teal-dark {
    border-color: #38a89d;
  }

  .md\:tw-border-teal {
    border-color: #17c7aa;
  }

  .md\:tw-border-teal-light {
    border-color: #64d5ca;
  }

  .md\:tw-border-teal-lighter {
    border-color: #a0f0ed;
  }

  .md\:tw-border-teal-lightest {
    border-color: #e8fffe;
  }

  .md\:tw-border-blue-darkest {
    border-color: #12283a;
  }

  .md\:tw-border-blue-darker {
    border-color: #1c3d5a;
  }

  .md\:tw-border-blue-dark {
    border-color: #2779bd;
  }

  .md\:tw-border-blue {
    border-color: #2ac1e7;
  }

  .md\:tw-border-blue-light {
    border-color: #d4f2fa;
  }

  .md\:tw-border-blue-lighter {
    border-color: #bcdefa;
  }

  .md\:tw-border-blue-lightest {
    border-color: #eff8ff;
  }

  .md\:tw-border-indigo-darkest {
    border-color: #191e38;
  }

  .md\:tw-border-indigo-darker {
    border-color: #2f365f;
  }

  .md\:tw-border-indigo-dark {
    border-color: #5661b3;
  }

  .md\:tw-border-indigo {
    border-color: #6574cd;
  }

  .md\:tw-border-indigo-light {
    border-color: #7886d7;
  }

  .md\:tw-border-indigo-lighter {
    border-color: #b2b7ff;
  }

  .md\:tw-border-indigo-lightest {
    border-color: #e6e8ff;
  }

  .md\:tw-border-purple-darkest {
    border-color: #21183c;
  }

  .md\:tw-border-purple-darker {
    border-color: #382b5f;
  }

  .md\:tw-border-purple-dark {
    border-color: #794acf;
  }

  .md\:tw-border-purple {
    border-color: #9561e2;
  }

  .md\:tw-border-purple-light {
    border-color: #a779e9;
  }

  .md\:tw-border-purple-lighter {
    border-color: #d6bbfc;
  }

  .md\:tw-border-purple-lightest {
    border-color: #f3ebff;
  }

  .md\:tw-border-pink-darkest {
    border-color: #451225;
  }

  .md\:tw-border-pink-darker {
    border-color: #6f213f;
  }

  .md\:tw-border-pink-dark {
    border-color: #eb5286;
  }

  .md\:tw-border-pink {
    border-color: #f66d9b;
  }

  .md\:tw-border-pink-light {
    border-color: #fa7ea8;
  }

  .md\:tw-border-pink-lighter {
    border-color: #ffbbca;
  }

  .md\:tw-border-pink-lightest {
    border-color: #ffebef;
  }

  .md\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .md\:hover\:tw-border-primary:hover {
    border-color: #ac826d;
  }

  .md\:hover\:tw-border-text:hover {
    border-color: #453f3f;
  }

  .md\:hover\:tw-border-black:hover {
    border-color: #000;
  }

  .md\:hover\:tw-border-grey-darkest:hover {
    border-color: #3d4852;
  }

  .md\:hover\:tw-border-grey-darker:hover {
    border-color: #606f7b;
  }

  .md\:hover\:tw-border-grey-dark:hover {
    border-color: #8795a1;
  }

  .md\:hover\:tw-border-grey:hover {
    border-color: #b8c2cc;
  }

  .md\:hover\:tw-border-grey-light:hover {
    border-color: #a1b3bc;
  }

  .md\:hover\:tw-border-grey-lighter:hover {
    border-color: #d8dfe3;
  }

  .md\:hover\:tw-border-grey-lightest:hover {
    border-color: #f8fafc;
  }

  .md\:hover\:tw-border-grey-grayish:hover {
    border-color: #56666c;
  }

  .md\:hover\:tw-border-white:hover {
    border-color: #fff;
  }

  .md\:hover\:tw-border-red-darkest:hover {
    border-color: #3b0d0c;
  }

  .md\:hover\:tw-border-red-darker:hover {
    border-color: #621b18;
  }

  .md\:hover\:tw-border-red-dark:hover {
    border-color: #cc1f1a;
  }

  .md\:hover\:tw-border-red:hover {
    border-color: #de5050;
  }

  .md\:hover\:tw-border-red-light:hover {
    border-color: #fed5dc;
  }

  .md\:hover\:tw-border-red-lighter:hover {
    border-color: #f9acaa;
  }

  .md\:hover\:tw-border-red-lightest:hover {
    border-color: #fcebea;
  }

  .md\:hover\:tw-border-orange-darkest:hover {
    border-color: #462a16;
  }

  .md\:hover\:tw-border-orange-darker:hover {
    border-color: #613b1f;
  }

  .md\:hover\:tw-border-orange-dark:hover {
    border-color: #de751f;
  }

  .md\:hover\:tw-border-orange:hover {
    border-color: #ffbf00;
  }

  .md\:hover\:tw-border-orange-light:hover {
    border-color: #faad63;
  }

  .md\:hover\:tw-border-orange-lighter:hover {
    border-color: #fcd9b6;
  }

  .md\:hover\:tw-border-orange-lightest:hover {
    border-color: #fff5eb;
  }

  .md\:hover\:tw-border-yellow-darkest:hover {
    border-color: #453411;
  }

  .md\:hover\:tw-border-yellow-darker:hover {
    border-color: #684f1d;
  }

  .md\:hover\:tw-border-yellow-dark:hover {
    border-color: #f2d024;
  }

  .md\:hover\:tw-border-yellow:hover {
    border-color: #f8e71c;
  }

  .md\:hover\:tw-border-yellow-light:hover {
    border-color: #fff382;
  }

  .md\:hover\:tw-border-yellow-lighter:hover {
    border-color: #fff9c2;
  }

  .md\:hover\:tw-border-yellow-lightest:hover {
    border-color: #fcfbeb;
  }

  .md\:hover\:tw-border-green-darkest:hover {
    border-color: #0f2f21;
  }

  .md\:hover\:tw-border-green-darker:hover {
    border-color: #1a4731;
  }

  .md\:hover\:tw-border-green-dark:hover {
    border-color: #1f9d55;
  }

  .md\:hover\:tw-border-green:hover {
    border-color: #38c172;
  }

  .md\:hover\:tw-border-green-light:hover {
    border-color: #51d88a;
  }

  .md\:hover\:tw-border-green-lighter:hover {
    border-color: #a2f5bf;
  }

  .md\:hover\:tw-border-green-lightest:hover {
    border-color: #e3fcec;
  }

  .md\:hover\:tw-border-teal-darkest:hover {
    border-color: #0d3331;
  }

  .md\:hover\:tw-border-teal-darker:hover {
    border-color: #20504f;
  }

  .md\:hover\:tw-border-teal-dark:hover {
    border-color: #38a89d;
  }

  .md\:hover\:tw-border-teal:hover {
    border-color: #17c7aa;
  }

  .md\:hover\:tw-border-teal-light:hover {
    border-color: #64d5ca;
  }

  .md\:hover\:tw-border-teal-lighter:hover {
    border-color: #a0f0ed;
  }

  .md\:hover\:tw-border-teal-lightest:hover {
    border-color: #e8fffe;
  }

  .md\:hover\:tw-border-blue-darkest:hover {
    border-color: #12283a;
  }

  .md\:hover\:tw-border-blue-darker:hover {
    border-color: #1c3d5a;
  }

  .md\:hover\:tw-border-blue-dark:hover {
    border-color: #2779bd;
  }

  .md\:hover\:tw-border-blue:hover {
    border-color: #2ac1e7;
  }

  .md\:hover\:tw-border-blue-light:hover {
    border-color: #d4f2fa;
  }

  .md\:hover\:tw-border-blue-lighter:hover {
    border-color: #bcdefa;
  }

  .md\:hover\:tw-border-blue-lightest:hover {
    border-color: #eff8ff;
  }

  .md\:hover\:tw-border-indigo-darkest:hover {
    border-color: #191e38;
  }

  .md\:hover\:tw-border-indigo-darker:hover {
    border-color: #2f365f;
  }

  .md\:hover\:tw-border-indigo-dark:hover {
    border-color: #5661b3;
  }

  .md\:hover\:tw-border-indigo:hover {
    border-color: #6574cd;
  }

  .md\:hover\:tw-border-indigo-light:hover {
    border-color: #7886d7;
  }

  .md\:hover\:tw-border-indigo-lighter:hover {
    border-color: #b2b7ff;
  }

  .md\:hover\:tw-border-indigo-lightest:hover {
    border-color: #e6e8ff;
  }

  .md\:hover\:tw-border-purple-darkest:hover {
    border-color: #21183c;
  }

  .md\:hover\:tw-border-purple-darker:hover {
    border-color: #382b5f;
  }

  .md\:hover\:tw-border-purple-dark:hover {
    border-color: #794acf;
  }

  .md\:hover\:tw-border-purple:hover {
    border-color: #9561e2;
  }

  .md\:hover\:tw-border-purple-light:hover {
    border-color: #a779e9;
  }

  .md\:hover\:tw-border-purple-lighter:hover {
    border-color: #d6bbfc;
  }

  .md\:hover\:tw-border-purple-lightest:hover {
    border-color: #f3ebff;
  }

  .md\:hover\:tw-border-pink-darkest:hover {
    border-color: #451225;
  }

  .md\:hover\:tw-border-pink-darker:hover {
    border-color: #6f213f;
  }

  .md\:hover\:tw-border-pink-dark:hover {
    border-color: #eb5286;
  }

  .md\:hover\:tw-border-pink:hover {
    border-color: #f66d9b;
  }

  .md\:hover\:tw-border-pink-light:hover {
    border-color: #fa7ea8;
  }

  .md\:hover\:tw-border-pink-lighter:hover {
    border-color: #ffbbca;
  }

  .md\:hover\:tw-border-pink-lightest:hover {
    border-color: #ffebef;
  }

  .md\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .md\:focus\:tw-border-primary:focus {
    border-color: #ac826d;
  }

  .md\:focus\:tw-border-text:focus {
    border-color: #453f3f;
  }

  .md\:focus\:tw-border-black:focus {
    border-color: #000;
  }

  .md\:focus\:tw-border-grey-darkest:focus {
    border-color: #3d4852;
  }

  .md\:focus\:tw-border-grey-darker:focus {
    border-color: #606f7b;
  }

  .md\:focus\:tw-border-grey-dark:focus {
    border-color: #8795a1;
  }

  .md\:focus\:tw-border-grey:focus {
    border-color: #b8c2cc;
  }

  .md\:focus\:tw-border-grey-light:focus {
    border-color: #a1b3bc;
  }

  .md\:focus\:tw-border-grey-lighter:focus {
    border-color: #d8dfe3;
  }

  .md\:focus\:tw-border-grey-lightest:focus {
    border-color: #f8fafc;
  }

  .md\:focus\:tw-border-grey-grayish:focus {
    border-color: #56666c;
  }

  .md\:focus\:tw-border-white:focus {
    border-color: #fff;
  }

  .md\:focus\:tw-border-red-darkest:focus {
    border-color: #3b0d0c;
  }

  .md\:focus\:tw-border-red-darker:focus {
    border-color: #621b18;
  }

  .md\:focus\:tw-border-red-dark:focus {
    border-color: #cc1f1a;
  }

  .md\:focus\:tw-border-red:focus {
    border-color: #de5050;
  }

  .md\:focus\:tw-border-red-light:focus {
    border-color: #fed5dc;
  }

  .md\:focus\:tw-border-red-lighter:focus {
    border-color: #f9acaa;
  }

  .md\:focus\:tw-border-red-lightest:focus {
    border-color: #fcebea;
  }

  .md\:focus\:tw-border-orange-darkest:focus {
    border-color: #462a16;
  }

  .md\:focus\:tw-border-orange-darker:focus {
    border-color: #613b1f;
  }

  .md\:focus\:tw-border-orange-dark:focus {
    border-color: #de751f;
  }

  .md\:focus\:tw-border-orange:focus {
    border-color: #ffbf00;
  }

  .md\:focus\:tw-border-orange-light:focus {
    border-color: #faad63;
  }

  .md\:focus\:tw-border-orange-lighter:focus {
    border-color: #fcd9b6;
  }

  .md\:focus\:tw-border-orange-lightest:focus {
    border-color: #fff5eb;
  }

  .md\:focus\:tw-border-yellow-darkest:focus {
    border-color: #453411;
  }

  .md\:focus\:tw-border-yellow-darker:focus {
    border-color: #684f1d;
  }

  .md\:focus\:tw-border-yellow-dark:focus {
    border-color: #f2d024;
  }

  .md\:focus\:tw-border-yellow:focus {
    border-color: #f8e71c;
  }

  .md\:focus\:tw-border-yellow-light:focus {
    border-color: #fff382;
  }

  .md\:focus\:tw-border-yellow-lighter:focus {
    border-color: #fff9c2;
  }

  .md\:focus\:tw-border-yellow-lightest:focus {
    border-color: #fcfbeb;
  }

  .md\:focus\:tw-border-green-darkest:focus {
    border-color: #0f2f21;
  }

  .md\:focus\:tw-border-green-darker:focus {
    border-color: #1a4731;
  }

  .md\:focus\:tw-border-green-dark:focus {
    border-color: #1f9d55;
  }

  .md\:focus\:tw-border-green:focus {
    border-color: #38c172;
  }

  .md\:focus\:tw-border-green-light:focus {
    border-color: #51d88a;
  }

  .md\:focus\:tw-border-green-lighter:focus {
    border-color: #a2f5bf;
  }

  .md\:focus\:tw-border-green-lightest:focus {
    border-color: #e3fcec;
  }

  .md\:focus\:tw-border-teal-darkest:focus {
    border-color: #0d3331;
  }

  .md\:focus\:tw-border-teal-darker:focus {
    border-color: #20504f;
  }

  .md\:focus\:tw-border-teal-dark:focus {
    border-color: #38a89d;
  }

  .md\:focus\:tw-border-teal:focus {
    border-color: #17c7aa;
  }

  .md\:focus\:tw-border-teal-light:focus {
    border-color: #64d5ca;
  }

  .md\:focus\:tw-border-teal-lighter:focus {
    border-color: #a0f0ed;
  }

  .md\:focus\:tw-border-teal-lightest:focus {
    border-color: #e8fffe;
  }

  .md\:focus\:tw-border-blue-darkest:focus {
    border-color: #12283a;
  }

  .md\:focus\:tw-border-blue-darker:focus {
    border-color: #1c3d5a;
  }

  .md\:focus\:tw-border-blue-dark:focus {
    border-color: #2779bd;
  }

  .md\:focus\:tw-border-blue:focus {
    border-color: #2ac1e7;
  }

  .md\:focus\:tw-border-blue-light:focus {
    border-color: #d4f2fa;
  }

  .md\:focus\:tw-border-blue-lighter:focus {
    border-color: #bcdefa;
  }

  .md\:focus\:tw-border-blue-lightest:focus {
    border-color: #eff8ff;
  }

  .md\:focus\:tw-border-indigo-darkest:focus {
    border-color: #191e38;
  }

  .md\:focus\:tw-border-indigo-darker:focus {
    border-color: #2f365f;
  }

  .md\:focus\:tw-border-indigo-dark:focus {
    border-color: #5661b3;
  }

  .md\:focus\:tw-border-indigo:focus {
    border-color: #6574cd;
  }

  .md\:focus\:tw-border-indigo-light:focus {
    border-color: #7886d7;
  }

  .md\:focus\:tw-border-indigo-lighter:focus {
    border-color: #b2b7ff;
  }

  .md\:focus\:tw-border-indigo-lightest:focus {
    border-color: #e6e8ff;
  }

  .md\:focus\:tw-border-purple-darkest:focus {
    border-color: #21183c;
  }

  .md\:focus\:tw-border-purple-darker:focus {
    border-color: #382b5f;
  }

  .md\:focus\:tw-border-purple-dark:focus {
    border-color: #794acf;
  }

  .md\:focus\:tw-border-purple:focus {
    border-color: #9561e2;
  }

  .md\:focus\:tw-border-purple-light:focus {
    border-color: #a779e9;
  }

  .md\:focus\:tw-border-purple-lighter:focus {
    border-color: #d6bbfc;
  }

  .md\:focus\:tw-border-purple-lightest:focus {
    border-color: #f3ebff;
  }

  .md\:focus\:tw-border-pink-darkest:focus {
    border-color: #451225;
  }

  .md\:focus\:tw-border-pink-darker:focus {
    border-color: #6f213f;
  }

  .md\:focus\:tw-border-pink-dark:focus {
    border-color: #eb5286;
  }

  .md\:focus\:tw-border-pink:focus {
    border-color: #f66d9b;
  }

  .md\:focus\:tw-border-pink-light:focus {
    border-color: #fa7ea8;
  }

  .md\:focus\:tw-border-pink-lighter:focus {
    border-color: #ffbbca;
  }

  .md\:focus\:tw-border-pink-lightest:focus {
    border-color: #ffebef;
  }

  .md\:tw-rounded-none {
    border-radius: 0;
  }

  .md\:tw-rounded-sm {
    border-radius: .125rem;
  }

  .md\:tw-rounded {
    border-radius: .25rem;
  }

  .md\:tw-rounded-lg {
    border-radius: .5rem;
  }

  .md\:tw-rounded-full {
    border-radius: 9999px;
  }

  .md\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .md\:tw-rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md\:tw-rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:tw-rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:tw-rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .md\:tw-rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md\:tw-rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:tw-rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:tw-rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md\:tw-rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:tw-rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:tw-rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .md\:tw-rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .md\:tw-rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .md\:tw-rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .md\:tw-rounded-tl {
    border-top-left-radius: .25rem;
  }

  .md\:tw-rounded-tr {
    border-top-right-radius: .25rem;
  }

  .md\:tw-rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .md\:tw-rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .md\:tw-rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .md\:tw-rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .md\:tw-rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .md\:tw-rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .md\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-border-solid {
    border-style: solid;
  }

  .md\:tw-border-dashed {
    border-style: dashed;
  }

  .md\:tw-border-dotted {
    border-style: dotted;
  }

  .md\:tw-border-none {
    border-style: none;
  }

  .md\:tw-border-0 {
    border-width: 0;
  }

  .md\:tw-border-2 {
    border-width: 2px;
  }

  .md\:tw-border-4 {
    border-width: 4px;
  }

  .md\:tw-border-8 {
    border-width: 8px;
  }

  .md\:tw-border {
    border-width: 1px;
  }

  .md\:tw-border-t-0 {
    border-top-width: 0;
  }

  .md\:tw-border-r-0 {
    border-right-width: 0;
  }

  .md\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .md\:tw-border-l-0 {
    border-left-width: 0;
  }

  .md\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .md\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .md\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .md\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .md\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .md\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .md\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .md\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .md\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .md\:tw-border-t {
    border-top-width: 1px;
  }

  .md\:tw-border-r {
    border-right-width: 1px;
  }

  .md\:tw-border-b {
    border-bottom-width: 1px;
  }

  .md\:tw-border-l {
    border-left-width: 1px;
  }

  .md\:tw-cursor-auto {
    cursor: auto;
  }

  .md\:tw-cursor-default {
    cursor: default;
  }

  .md\:tw-cursor-pointer {
    cursor: pointer;
  }

  .md\:tw-cursor-wait {
    cursor: wait;
  }

  .md\:tw-cursor-move {
    cursor: move;
  }

  .md\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:tw-block {
    display: block;
  }

  .md\:tw-inline-block {
    display: inline-block;
  }

  .md\:tw-inline {
    display: inline;
  }

  .md\:tw-table {
    display: table;
  }

  .md\:tw-table-row {
    display: table-row;
  }

  .md\:tw-table-cell {
    display: table-cell;
  }

  .md\:tw-hidden {
    display: none;
  }

  .md\:tw-flex {
    display: flex;
  }

  .md\:tw-inline-flex {
    display: inline-flex;
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:tw-flex-col {
    flex-direction: column;
  }

  .md\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .md\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .md\:tw-items-start {
    align-items: flex-start;
  }

  .md\:tw-items-end {
    align-items: flex-end;
  }

  .md\:tw-items-center {
    align-items: center;
  }

  .md\:tw-items-baseline {
    align-items: baseline;
  }

  .md\:tw-items-stretch {
    align-items: stretch;
  }

  .md\:tw-self-auto {
    align-self: auto;
  }

  .md\:tw-self-start {
    align-self: flex-start;
  }

  .md\:tw-self-end {
    align-self: flex-end;
  }

  .md\:tw-self-center {
    align-self: center;
  }

  .md\:tw-self-stretch {
    align-self: stretch;
  }

  .md\:tw-justify-start {
    justify-content: flex-start;
  }

  .md\:tw-justify-end {
    justify-content: flex-end;
  }

  .md\:tw-justify-center {
    justify-content: center;
  }

  .md\:tw-justify-between {
    justify-content: space-between;
  }

  .md\:tw-justify-around {
    justify-content: space-around;
  }

  .md\:tw-content-center {
    align-content: center;
  }

  .md\:tw-content-start {
    align-content: flex-start;
  }

  .md\:tw-content-end {
    align-content: flex-end;
  }

  .md\:tw-content-between {
    align-content: space-between;
  }

  .md\:tw-content-around {
    align-content: space-around;
  }

  .md\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .md\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .md\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .md\:tw-flex-none {
    flex: none;
  }

  .md\:tw-flex-grow {
    flex-grow: 1;
  }

  .md\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .md\:tw-flex-no-grow {
    flex-grow: 0;
  }

  .md\:tw-flex-no-shrink {
    flex-shrink: 0;
  }

  .md\:tw-float-right {
    float: right;
  }

  .md\:tw-float-left {
    float: left;
  }

  .md\:tw-float-none {
    float: none;
  }

  .md\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .md\:tw-font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .md\:tw-font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
  }

  .md\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .md\:tw-font-hairline {
    font-weight: 100;
  }

  .md\:tw-font-thin {
    font-weight: 200;
  }

  .md\:tw-font-light {
    font-weight: 300;
  }

  .md\:tw-font-normal {
    font-weight: 400;
  }

  .md\:tw-font-medium {
    font-weight: 500;
  }

  .md\:tw-font-semibold {
    font-weight: 600;
  }

  .md\:tw-font-bold {
    font-weight: 700;
  }

  .md\:tw-font-extrabold {
    font-weight: 800;
  }

  .md\:tw-font-black {
    font-weight: 900;
  }

  .md\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .md\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .md\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .md\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .md\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .md\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .md\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .md\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .md\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .md\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .md\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .md\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .md\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .md\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .md\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .md\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .md\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .md\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .md\:tw-h-1 {
    height: .25rem;
  }

  .md\:tw-h-2 {
    height: .5rem;
  }

  .md\:tw-h-3 {
    height: .75rem;
  }

  .md\:tw-h-4 {
    height: 1rem;
  }

  .md\:tw-h-5 {
    height: 1.25rem;
  }

  .md\:tw-h-6 {
    height: 1.5rem;
  }

  .md\:tw-h-8 {
    height: 2rem;
  }

  .md\:tw-h-10 {
    height: 2.5rem;
  }

  .md\:tw-h-12 {
    height: 3rem;
  }

  .md\:tw-h-16 {
    height: 4rem;
  }

  .md\:tw-h-24 {
    height: 6rem;
  }

  .md\:tw-h-32 {
    height: 8rem;
  }

  .md\:tw-h-48 {
    height: 12rem;
  }

  .md\:tw-h-64 {
    height: 16rem;
  }

  .md\:tw-h-auto {
    height: auto;
  }

  .md\:tw-h-px {
    height: 1px;
  }

  .md\:tw-h-full {
    height: 100%;
  }

  .md\:tw-h-screen {
    height: 100vh;
  }

  .md\:tw-leading-none {
    line-height: 1;
  }

  .md\:tw-leading-tight {
    line-height: 1.25;
  }

  .md\:tw-leading-normal {
    line-height: 1.5;
  }

  .md\:tw-leading-loose {
    line-height: 2;
  }

  .md\:tw-m-0 {
    margin: 0;
  }

  .md\:tw-m-1 {
    margin: .25rem;
  }

  .md\:tw-m-2 {
    margin: .5rem;
  }

  .md\:tw-m-3 {
    margin: .75rem;
  }

  .md\:tw-m-4 {
    margin: 1rem;
  }

  .md\:tw-m-5 {
    margin: 1.25rem;
  }

  .md\:tw-m-6 {
    margin: 1.5rem;
  }

  .md\:tw-m-8 {
    margin: 2rem;
  }

  .md\:tw-m-10 {
    margin: 2.5rem;
  }

  .md\:tw-m-12 {
    margin: 3rem;
  }

  .md\:tw-m-16 {
    margin: 4rem;
  }

  .md\:tw-m-20 {
    margin: 5rem;
  }

  .md\:tw-m-24 {
    margin: 6rem;
  }

  .md\:tw-m-32 {
    margin: 8rem;
  }

  .md\:tw-m-auto {
    margin: auto;
  }

  .md\:tw-m-px {
    margin: 1px;
  }

  .md\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:tw-my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md\:tw-mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .md\:tw-my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md\:tw-mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:tw-my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:tw-mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:tw-mt-0 {
    margin-top: 0;
  }

  .md\:tw-mr-0 {
    margin-right: 0;
  }

  .md\:tw-mb-0 {
    margin-bottom: 0;
  }

  .md\:tw-ml-0 {
    margin-left: 0;
  }

  .md\:tw-mt-1 {
    margin-top: .25rem;
  }

  .md\:tw-mr-1 {
    margin-right: .25rem;
  }

  .md\:tw-mb-1 {
    margin-bottom: .25rem;
  }

  .md\:tw-ml-1 {
    margin-left: .25rem;
  }

  .md\:tw-mt-2 {
    margin-top: .5rem;
  }

  .md\:tw-mr-2 {
    margin-right: .5rem;
  }

  .md\:tw-mb-2 {
    margin-bottom: .5rem;
  }

  .md\:tw-ml-2 {
    margin-left: .5rem;
  }

  .md\:tw-mt-3 {
    margin-top: .75rem;
  }

  .md\:tw-mr-3 {
    margin-right: .75rem;
  }

  .md\:tw-mb-3 {
    margin-bottom: .75rem;
  }

  .md\:tw-ml-3 {
    margin-left: .75rem;
  }

  .md\:tw-mt-4 {
    margin-top: 1rem;
  }

  .md\:tw-mr-4 {
    margin-right: 1rem;
  }

  .md\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .md\:tw-ml-4 {
    margin-left: 1rem;
  }

  .md\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .md\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .md\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .md\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .md\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .md\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .md\:tw-mt-8 {
    margin-top: 2rem;
  }

  .md\:tw-mr-8 {
    margin-right: 2rem;
  }

  .md\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .md\:tw-ml-8 {
    margin-left: 2rem;
  }

  .md\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .md\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .md\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .md\:tw-mt-12 {
    margin-top: 3rem;
  }

  .md\:tw-mr-12 {
    margin-right: 3rem;
  }

  .md\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .md\:tw-ml-12 {
    margin-left: 3rem;
  }

  .md\:tw-mt-16 {
    margin-top: 4rem;
  }

  .md\:tw-mr-16 {
    margin-right: 4rem;
  }

  .md\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .md\:tw-ml-16 {
    margin-left: 4rem;
  }

  .md\:tw-mt-20 {
    margin-top: 5rem;
  }

  .md\:tw-mr-20 {
    margin-right: 5rem;
  }

  .md\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .md\:tw-ml-20 {
    margin-left: 5rem;
  }

  .md\:tw-mt-24 {
    margin-top: 6rem;
  }

  .md\:tw-mr-24 {
    margin-right: 6rem;
  }

  .md\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .md\:tw-ml-24 {
    margin-left: 6rem;
  }

  .md\:tw-mt-32 {
    margin-top: 8rem;
  }

  .md\:tw-mr-32 {
    margin-right: 8rem;
  }

  .md\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .md\:tw-ml-32 {
    margin-left: 8rem;
  }

  .md\:tw-mt-auto {
    margin-top: auto;
  }

  .md\:tw-mr-auto {
    margin-right: auto;
  }

  .md\:tw-mb-auto {
    margin-bottom: auto;
  }

  .md\:tw-ml-auto {
    margin-left: auto;
  }

  .md\:tw-mt-px {
    margin-top: 1px;
  }

  .md\:tw-mr-px {
    margin-right: 1px;
  }

  .md\:tw-mb-px {
    margin-bottom: 1px;
  }

  .md\:tw-ml-px {
    margin-left: 1px;
  }

  .md\:tw-max-h-full {
    max-height: 100%;
  }

  .md\:tw-max-h-screen {
    max-height: 100vh;
  }

  .md\:tw-max-w-xs {
    max-width: 20rem;
  }

  .md\:tw-max-w-sm {
    max-width: 30rem;
  }

  .md\:tw-max-w-md {
    max-width: 40rem;
  }

  .md\:tw-max-w-lg {
    max-width: 50rem;
  }

  .md\:tw-max-w-xl {
    max-width: 60rem;
  }

  .md\:tw-max-w-2xl {
    max-width: 70rem;
  }

  .md\:tw-max-w-3xl {
    max-width: 80rem;
  }

  .md\:tw-max-w-4xl {
    max-width: 90rem;
  }

  .md\:tw-max-w-5xl {
    max-width: 100rem;
  }

  .md\:tw-max-w-full {
    max-width: 100%;
  }

  .md\:tw-min-h-0 {
    min-height: 0;
  }

  .md\:tw-min-h-full {
    min-height: 100%;
  }

  .md\:tw-min-h-screen {
    min-height: 100vh;
  }

  .md\:tw-min-w-0 {
    min-width: 0;
  }

  .md\:tw-min-w-full {
    min-width: 100%;
  }

  .md\:tw--m-0 {
    margin: 0;
  }

  .md\:tw--m-1 {
    margin: -0.25rem;
  }

  .md\:tw--m-2 {
    margin: -0.5rem;
  }

  .md\:tw--m-3 {
    margin: -0.75rem;
  }

  .md\:tw--m-4 {
    margin: -1rem;
  }

  .md\:tw--m-5 {
    margin: -1.25rem;
  }

  .md\:tw--m-6 {
    margin: -1.5rem;
  }

  .md\:tw--m-8 {
    margin: -2rem;
  }

  .md\:tw--m-10 {
    margin: -2.5rem;
  }

  .md\:tw--m-12 {
    margin: -3rem;
  }

  .md\:tw--m-16 {
    margin: -4rem;
  }

  .md\:tw--m-20 {
    margin: -5rem;
  }

  .md\:tw--m-24 {
    margin: -6rem;
  }

  .md\:tw--m-32 {
    margin: -8rem;
  }

  .md\:tw--m-px {
    margin: -1px;
  }

  .md\:tw--my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:tw--mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .md\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .md\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .md\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .md\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:tw--mt-0 {
    margin-top: 0;
  }

  .md\:tw--mr-0 {
    margin-right: 0;
  }

  .md\:tw--mb-0 {
    margin-bottom: 0;
  }

  .md\:tw--ml-0 {
    margin-left: 0;
  }

  .md\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .md\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .md\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .md\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .md\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .md\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .md\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .md\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .md\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .md\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .md\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .md\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .md\:tw--mt-4 {
    margin-top: -1rem;
  }

  .md\:tw--mr-4 {
    margin-right: -1rem;
  }

  .md\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .md\:tw--ml-4 {
    margin-left: -1rem;
  }

  .md\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .md\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .md\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .md\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .md\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .md\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .md\:tw--mt-8 {
    margin-top: -2rem;
  }

  .md\:tw--mr-8 {
    margin-right: -2rem;
  }

  .md\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .md\:tw--ml-8 {
    margin-left: -2rem;
  }

  .md\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .md\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .md\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .md\:tw--mt-12 {
    margin-top: -3rem;
  }

  .md\:tw--mr-12 {
    margin-right: -3rem;
  }

  .md\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .md\:tw--ml-12 {
    margin-left: -3rem;
  }

  .md\:tw--mt-16 {
    margin-top: -4rem;
  }

  .md\:tw--mr-16 {
    margin-right: -4rem;
  }

  .md\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .md\:tw--ml-16 {
    margin-left: -4rem;
  }

  .md\:tw--mt-20 {
    margin-top: -5rem;
  }

  .md\:tw--mr-20 {
    margin-right: -5rem;
  }

  .md\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .md\:tw--ml-20 {
    margin-left: -5rem;
  }

  .md\:tw--mt-24 {
    margin-top: -6rem;
  }

  .md\:tw--mr-24 {
    margin-right: -6rem;
  }

  .md\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .md\:tw--ml-24 {
    margin-left: -6rem;
  }

  .md\:tw--mt-32 {
    margin-top: -8rem;
  }

  .md\:tw--mr-32 {
    margin-right: -8rem;
  }

  .md\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .md\:tw--ml-32 {
    margin-left: -8rem;
  }

  .md\:tw--mt-px {
    margin-top: -1px;
  }

  .md\:tw--mr-px {
    margin-right: -1px;
  }

  .md\:tw--mb-px {
    margin-bottom: -1px;
  }

  .md\:tw--ml-px {
    margin-left: -1px;
  }

  .md\:tw-opacity-0 {
    opacity: 0;
  }

  .md\:tw-opacity-25 {
    opacity: .25;
  }

  .md\:tw-opacity-50 {
    opacity: .5;
  }

  .md\:tw-opacity-75 {
    opacity: .75;
  }

  .md\:tw-opacity-100 {
    opacity: 1;
  }

  .md\:tw-overflow-auto {
    overflow: auto;
  }

  .md\:tw-overflow-hidden {
    overflow: hidden;
  }

  .md\:tw-overflow-visible {
    overflow: visible;
  }

  .md\:tw-overflow-scroll {
    overflow: scroll;
  }

  .md\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .md\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .md\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .md\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .md\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .md\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .md\:tw-p-0 {
    padding: 0;
  }

  .md\:tw-p-1 {
    padding: .25rem;
  }

  .md\:tw-p-2 {
    padding: .5rem;
  }

  .md\:tw-p-3 {
    padding: .75rem;
  }

  .md\:tw-p-4 {
    padding: 1rem;
  }

  .md\:tw-p-5 {
    padding: 1.25rem;
  }

  .md\:tw-p-6 {
    padding: 1.5rem;
  }

  .md\:tw-p-8 {
    padding: 2rem;
  }

  .md\:tw-p-10 {
    padding: 2.5rem;
  }

  .md\:tw-p-12 {
    padding: 3rem;
  }

  .md\:tw-p-16 {
    padding: 4rem;
  }

  .md\:tw-p-20 {
    padding: 5rem;
  }

  .md\:tw-p-24 {
    padding: 6rem;
  }

  .md\:tw-p-32 {
    padding: 8rem;
  }

  .md\:tw-p-px {
    padding: 1px;
  }

  .md\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:tw-py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .md\:tw-px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .md\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:tw-px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:tw-py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:tw-px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:tw-pt-0 {
    padding-top: 0;
  }

  .md\:tw-pr-0 {
    padding-right: 0;
  }

  .md\:tw-pb-0 {
    padding-bottom: 0;
  }

  .md\:tw-pl-0 {
    padding-left: 0;
  }

  .md\:tw-pt-1 {
    padding-top: .25rem;
  }

  .md\:tw-pr-1 {
    padding-right: .25rem;
  }

  .md\:tw-pb-1 {
    padding-bottom: .25rem;
  }

  .md\:tw-pl-1 {
    padding-left: .25rem;
  }

  .md\:tw-pt-2 {
    padding-top: .5rem;
  }

  .md\:tw-pr-2 {
    padding-right: .5rem;
  }

  .md\:tw-pb-2 {
    padding-bottom: .5rem;
  }

  .md\:tw-pl-2 {
    padding-left: .5rem;
  }

  .md\:tw-pt-3 {
    padding-top: .75rem;
  }

  .md\:tw-pr-3 {
    padding-right: .75rem;
  }

  .md\:tw-pb-3 {
    padding-bottom: .75rem;
  }

  .md\:tw-pl-3 {
    padding-left: .75rem;
  }

  .md\:tw-pt-4 {
    padding-top: 1rem;
  }

  .md\:tw-pr-4 {
    padding-right: 1rem;
  }

  .md\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .md\:tw-pl-4 {
    padding-left: 1rem;
  }

  .md\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .md\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .md\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .md\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .md\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .md\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .md\:tw-pt-8 {
    padding-top: 2rem;
  }

  .md\:tw-pr-8 {
    padding-right: 2rem;
  }

  .md\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .md\:tw-pl-8 {
    padding-left: 2rem;
  }

  .md\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .md\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .md\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .md\:tw-pt-12 {
    padding-top: 3rem;
  }

  .md\:tw-pr-12 {
    padding-right: 3rem;
  }

  .md\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .md\:tw-pl-12 {
    padding-left: 3rem;
  }

  .md\:tw-pt-16 {
    padding-top: 4rem;
  }

  .md\:tw-pr-16 {
    padding-right: 4rem;
  }

  .md\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .md\:tw-pl-16 {
    padding-left: 4rem;
  }

  .md\:tw-pt-20 {
    padding-top: 5rem;
  }

  .md\:tw-pr-20 {
    padding-right: 5rem;
  }

  .md\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .md\:tw-pl-20 {
    padding-left: 5rem;
  }

  .md\:tw-pt-24 {
    padding-top: 6rem;
  }

  .md\:tw-pr-24 {
    padding-right: 6rem;
  }

  .md\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .md\:tw-pl-24 {
    padding-left: 6rem;
  }

  .md\:tw-pt-32 {
    padding-top: 8rem;
  }

  .md\:tw-pr-32 {
    padding-right: 8rem;
  }

  .md\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .md\:tw-pl-32 {
    padding-left: 8rem;
  }

  .md\:tw-pt-px {
    padding-top: 1px;
  }

  .md\:tw-pr-px {
    padding-right: 1px;
  }

  .md\:tw-pb-px {
    padding-bottom: 1px;
  }

  .md\:tw-pl-px {
    padding-left: 1px;
  }

  .md\:tw-pointer-events-none {
    pointer-events: none;
  }

  .md\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .md\:tw-static {
    position: static;
  }

  .md\:tw-fixed {
    position: fixed;
  }

  .md\:tw-absolute {
    position: absolute;
  }

  .md\:tw-relative {
    position: relative;
  }

  .md\:tw-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .md\:tw-pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .md\:tw-pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .md\:tw-pin-y {
    top: 0;
    bottom: 0;
  }

  .md\:tw-pin-x {
    right: 0;
    left: 0;
  }

  .md\:tw-pin-t {
    top: 0;
  }

  .md\:tw-pin-r {
    right: 0;
  }

  .md\:tw-pin-b {
    bottom: 0;
  }

  .md\:tw-pin-l {
    left: 0;
  }

  .md\:tw-resize-none {
    resize: none;
  }

  .md\:tw-resize-y {
    resize: vertical;
  }

  .md\:tw-resize-x {
    resize: horizontal;
  }

  .md\:tw-resize {
    resize: both;
  }

  .md\:tw-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .md\:tw-shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .md\:tw-shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .md\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .md\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .md\:tw-shadow-none {
    box-shadow: none;
  }

  .md\:hover\:tw-shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .md\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .md\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .md\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .md\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .md\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .md\:focus\:tw-shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .md\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .md\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .md\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .md\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .md\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .md\:tw-table-auto {
    table-layout: auto;
  }

  .md\:tw-table-fixed {
    table-layout: fixed;
  }

  .md\:tw-text-left {
    text-align: left;
  }

  .md\:tw-text-center {
    text-align: center;
  }

  .md\:tw-text-right {
    text-align: right;
  }

  .md\:tw-text-justify {
    text-align: justify;
  }

  .md\:tw-text-transparent {
    color: transparent;
  }

  .md\:tw-text-primary {
    color: #ac826d;
  }

  .md\:tw-text-text {
    color: #453f3f;
  }

  .md\:tw-text-black {
    color: #000;
  }

  .md\:tw-text-grey-darkest {
    color: #3d4852;
  }

  .md\:tw-text-grey-darker {
    color: #606f7b;
  }

  .md\:tw-text-grey-dark {
    color: #8795a1;
  }

  .md\:tw-text-grey {
    color: #b8c2cc;
  }

  .md\:tw-text-grey-light {
    color: #a1b3bc;
  }

  .md\:tw-text-grey-lighter {
    color: #d8dfe3;
  }

  .md\:tw-text-grey-lightest {
    color: #f8fafc;
  }

  .md\:tw-text-grey-grayish {
    color: #56666c;
  }

  .md\:tw-text-white {
    color: #fff;
  }

  .md\:tw-text-red-darkest {
    color: #3b0d0c;
  }

  .md\:tw-text-red-darker {
    color: #621b18;
  }

  .md\:tw-text-red-dark {
    color: #cc1f1a;
  }

  .md\:tw-text-red {
    color: #de5050;
  }

  .md\:tw-text-red-light {
    color: #fed5dc;
  }

  .md\:tw-text-red-lighter {
    color: #f9acaa;
  }

  .md\:tw-text-red-lightest {
    color: #fcebea;
  }

  .md\:tw-text-orange-darkest {
    color: #462a16;
  }

  .md\:tw-text-orange-darker {
    color: #613b1f;
  }

  .md\:tw-text-orange-dark {
    color: #de751f;
  }

  .md\:tw-text-orange {
    color: #ffbf00;
  }

  .md\:tw-text-orange-light {
    color: #faad63;
  }

  .md\:tw-text-orange-lighter {
    color: #fcd9b6;
  }

  .md\:tw-text-orange-lightest {
    color: #fff5eb;
  }

  .md\:tw-text-yellow-darkest {
    color: #453411;
  }

  .md\:tw-text-yellow-darker {
    color: #684f1d;
  }

  .md\:tw-text-yellow-dark {
    color: #f2d024;
  }

  .md\:tw-text-yellow {
    color: #f8e71c;
  }

  .md\:tw-text-yellow-light {
    color: #fff382;
  }

  .md\:tw-text-yellow-lighter {
    color: #fff9c2;
  }

  .md\:tw-text-yellow-lightest {
    color: #fcfbeb;
  }

  .md\:tw-text-green-darkest {
    color: #0f2f21;
  }

  .md\:tw-text-green-darker {
    color: #1a4731;
  }

  .md\:tw-text-green-dark {
    color: #1f9d55;
  }

  .md\:tw-text-green {
    color: #38c172;
  }

  .md\:tw-text-green-light {
    color: #51d88a;
  }

  .md\:tw-text-green-lighter {
    color: #a2f5bf;
  }

  .md\:tw-text-green-lightest {
    color: #e3fcec;
  }

  .md\:tw-text-teal-darkest {
    color: #0d3331;
  }

  .md\:tw-text-teal-darker {
    color: #20504f;
  }

  .md\:tw-text-teal-dark {
    color: #38a89d;
  }

  .md\:tw-text-teal {
    color: #17c7aa;
  }

  .md\:tw-text-teal-light {
    color: #64d5ca;
  }

  .md\:tw-text-teal-lighter {
    color: #a0f0ed;
  }

  .md\:tw-text-teal-lightest {
    color: #e8fffe;
  }

  .md\:tw-text-blue-darkest {
    color: #12283a;
  }

  .md\:tw-text-blue-darker {
    color: #1c3d5a;
  }

  .md\:tw-text-blue-dark {
    color: #2779bd;
  }

  .md\:tw-text-blue {
    color: #2ac1e7;
  }

  .md\:tw-text-blue-light {
    color: #d4f2fa;
  }

  .md\:tw-text-blue-lighter {
    color: #bcdefa;
  }

  .md\:tw-text-blue-lightest {
    color: #eff8ff;
  }

  .md\:tw-text-indigo-darkest {
    color: #191e38;
  }

  .md\:tw-text-indigo-darker {
    color: #2f365f;
  }

  .md\:tw-text-indigo-dark {
    color: #5661b3;
  }

  .md\:tw-text-indigo {
    color: #6574cd;
  }

  .md\:tw-text-indigo-light {
    color: #7886d7;
  }

  .md\:tw-text-indigo-lighter {
    color: #b2b7ff;
  }

  .md\:tw-text-indigo-lightest {
    color: #e6e8ff;
  }

  .md\:tw-text-purple-darkest {
    color: #21183c;
  }

  .md\:tw-text-purple-darker {
    color: #382b5f;
  }

  .md\:tw-text-purple-dark {
    color: #794acf;
  }

  .md\:tw-text-purple {
    color: #9561e2;
  }

  .md\:tw-text-purple-light {
    color: #a779e9;
  }

  .md\:tw-text-purple-lighter {
    color: #d6bbfc;
  }

  .md\:tw-text-purple-lightest {
    color: #f3ebff;
  }

  .md\:tw-text-pink-darkest {
    color: #451225;
  }

  .md\:tw-text-pink-darker {
    color: #6f213f;
  }

  .md\:tw-text-pink-dark {
    color: #eb5286;
  }

  .md\:tw-text-pink {
    color: #f66d9b;
  }

  .md\:tw-text-pink-light {
    color: #fa7ea8;
  }

  .md\:tw-text-pink-lighter {
    color: #ffbbca;
  }

  .md\:tw-text-pink-lightest {
    color: #ffebef;
  }

  .md\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .md\:hover\:tw-text-primary:hover {
    color: #ac826d;
  }

  .md\:hover\:tw-text-text:hover {
    color: #453f3f;
  }

  .md\:hover\:tw-text-black:hover {
    color: #000;
  }

  .md\:hover\:tw-text-grey-darkest:hover {
    color: #3d4852;
  }

  .md\:hover\:tw-text-grey-darker:hover {
    color: #606f7b;
  }

  .md\:hover\:tw-text-grey-dark:hover {
    color: #8795a1;
  }

  .md\:hover\:tw-text-grey:hover {
    color: #b8c2cc;
  }

  .md\:hover\:tw-text-grey-light:hover {
    color: #a1b3bc;
  }

  .md\:hover\:tw-text-grey-lighter:hover {
    color: #d8dfe3;
  }

  .md\:hover\:tw-text-grey-lightest:hover {
    color: #f8fafc;
  }

  .md\:hover\:tw-text-grey-grayish:hover {
    color: #56666c;
  }

  .md\:hover\:tw-text-white:hover {
    color: #fff;
  }

  .md\:hover\:tw-text-red-darkest:hover {
    color: #3b0d0c;
  }

  .md\:hover\:tw-text-red-darker:hover {
    color: #621b18;
  }

  .md\:hover\:tw-text-red-dark:hover {
    color: #cc1f1a;
  }

  .md\:hover\:tw-text-red:hover {
    color: #de5050;
  }

  .md\:hover\:tw-text-red-light:hover {
    color: #fed5dc;
  }

  .md\:hover\:tw-text-red-lighter:hover {
    color: #f9acaa;
  }

  .md\:hover\:tw-text-red-lightest:hover {
    color: #fcebea;
  }

  .md\:hover\:tw-text-orange-darkest:hover {
    color: #462a16;
  }

  .md\:hover\:tw-text-orange-darker:hover {
    color: #613b1f;
  }

  .md\:hover\:tw-text-orange-dark:hover {
    color: #de751f;
  }

  .md\:hover\:tw-text-orange:hover {
    color: #ffbf00;
  }

  .md\:hover\:tw-text-orange-light:hover {
    color: #faad63;
  }

  .md\:hover\:tw-text-orange-lighter:hover {
    color: #fcd9b6;
  }

  .md\:hover\:tw-text-orange-lightest:hover {
    color: #fff5eb;
  }

  .md\:hover\:tw-text-yellow-darkest:hover {
    color: #453411;
  }

  .md\:hover\:tw-text-yellow-darker:hover {
    color: #684f1d;
  }

  .md\:hover\:tw-text-yellow-dark:hover {
    color: #f2d024;
  }

  .md\:hover\:tw-text-yellow:hover {
    color: #f8e71c;
  }

  .md\:hover\:tw-text-yellow-light:hover {
    color: #fff382;
  }

  .md\:hover\:tw-text-yellow-lighter:hover {
    color: #fff9c2;
  }

  .md\:hover\:tw-text-yellow-lightest:hover {
    color: #fcfbeb;
  }

  .md\:hover\:tw-text-green-darkest:hover {
    color: #0f2f21;
  }

  .md\:hover\:tw-text-green-darker:hover {
    color: #1a4731;
  }

  .md\:hover\:tw-text-green-dark:hover {
    color: #1f9d55;
  }

  .md\:hover\:tw-text-green:hover {
    color: #38c172;
  }

  .md\:hover\:tw-text-green-light:hover {
    color: #51d88a;
  }

  .md\:hover\:tw-text-green-lighter:hover {
    color: #a2f5bf;
  }

  .md\:hover\:tw-text-green-lightest:hover {
    color: #e3fcec;
  }

  .md\:hover\:tw-text-teal-darkest:hover {
    color: #0d3331;
  }

  .md\:hover\:tw-text-teal-darker:hover {
    color: #20504f;
  }

  .md\:hover\:tw-text-teal-dark:hover {
    color: #38a89d;
  }

  .md\:hover\:tw-text-teal:hover {
    color: #17c7aa;
  }

  .md\:hover\:tw-text-teal-light:hover {
    color: #64d5ca;
  }

  .md\:hover\:tw-text-teal-lighter:hover {
    color: #a0f0ed;
  }

  .md\:hover\:tw-text-teal-lightest:hover {
    color: #e8fffe;
  }

  .md\:hover\:tw-text-blue-darkest:hover {
    color: #12283a;
  }

  .md\:hover\:tw-text-blue-darker:hover {
    color: #1c3d5a;
  }

  .md\:hover\:tw-text-blue-dark:hover {
    color: #2779bd;
  }

  .md\:hover\:tw-text-blue:hover {
    color: #2ac1e7;
  }

  .md\:hover\:tw-text-blue-light:hover {
    color: #d4f2fa;
  }

  .md\:hover\:tw-text-blue-lighter:hover {
    color: #bcdefa;
  }

  .md\:hover\:tw-text-blue-lightest:hover {
    color: #eff8ff;
  }

  .md\:hover\:tw-text-indigo-darkest:hover {
    color: #191e38;
  }

  .md\:hover\:tw-text-indigo-darker:hover {
    color: #2f365f;
  }

  .md\:hover\:tw-text-indigo-dark:hover {
    color: #5661b3;
  }

  .md\:hover\:tw-text-indigo:hover {
    color: #6574cd;
  }

  .md\:hover\:tw-text-indigo-light:hover {
    color: #7886d7;
  }

  .md\:hover\:tw-text-indigo-lighter:hover {
    color: #b2b7ff;
  }

  .md\:hover\:tw-text-indigo-lightest:hover {
    color: #e6e8ff;
  }

  .md\:hover\:tw-text-purple-darkest:hover {
    color: #21183c;
  }

  .md\:hover\:tw-text-purple-darker:hover {
    color: #382b5f;
  }

  .md\:hover\:tw-text-purple-dark:hover {
    color: #794acf;
  }

  .md\:hover\:tw-text-purple:hover {
    color: #9561e2;
  }

  .md\:hover\:tw-text-purple-light:hover {
    color: #a779e9;
  }

  .md\:hover\:tw-text-purple-lighter:hover {
    color: #d6bbfc;
  }

  .md\:hover\:tw-text-purple-lightest:hover {
    color: #f3ebff;
  }

  .md\:hover\:tw-text-pink-darkest:hover {
    color: #451225;
  }

  .md\:hover\:tw-text-pink-darker:hover {
    color: #6f213f;
  }

  .md\:hover\:tw-text-pink-dark:hover {
    color: #eb5286;
  }

  .md\:hover\:tw-text-pink:hover {
    color: #f66d9b;
  }

  .md\:hover\:tw-text-pink-light:hover {
    color: #fa7ea8;
  }

  .md\:hover\:tw-text-pink-lighter:hover {
    color: #ffbbca;
  }

  .md\:hover\:tw-text-pink-lightest:hover {
    color: #ffebef;
  }

  .md\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .md\:focus\:tw-text-primary:focus {
    color: #ac826d;
  }

  .md\:focus\:tw-text-text:focus {
    color: #453f3f;
  }

  .md\:focus\:tw-text-black:focus {
    color: #000;
  }

  .md\:focus\:tw-text-grey-darkest:focus {
    color: #3d4852;
  }

  .md\:focus\:tw-text-grey-darker:focus {
    color: #606f7b;
  }

  .md\:focus\:tw-text-grey-dark:focus {
    color: #8795a1;
  }

  .md\:focus\:tw-text-grey:focus {
    color: #b8c2cc;
  }

  .md\:focus\:tw-text-grey-light:focus {
    color: #a1b3bc;
  }

  .md\:focus\:tw-text-grey-lighter:focus {
    color: #d8dfe3;
  }

  .md\:focus\:tw-text-grey-lightest:focus {
    color: #f8fafc;
  }

  .md\:focus\:tw-text-grey-grayish:focus {
    color: #56666c;
  }

  .md\:focus\:tw-text-white:focus {
    color: #fff;
  }

  .md\:focus\:tw-text-red-darkest:focus {
    color: #3b0d0c;
  }

  .md\:focus\:tw-text-red-darker:focus {
    color: #621b18;
  }

  .md\:focus\:tw-text-red-dark:focus {
    color: #cc1f1a;
  }

  .md\:focus\:tw-text-red:focus {
    color: #de5050;
  }

  .md\:focus\:tw-text-red-light:focus {
    color: #fed5dc;
  }

  .md\:focus\:tw-text-red-lighter:focus {
    color: #f9acaa;
  }

  .md\:focus\:tw-text-red-lightest:focus {
    color: #fcebea;
  }

  .md\:focus\:tw-text-orange-darkest:focus {
    color: #462a16;
  }

  .md\:focus\:tw-text-orange-darker:focus {
    color: #613b1f;
  }

  .md\:focus\:tw-text-orange-dark:focus {
    color: #de751f;
  }

  .md\:focus\:tw-text-orange:focus {
    color: #ffbf00;
  }

  .md\:focus\:tw-text-orange-light:focus {
    color: #faad63;
  }

  .md\:focus\:tw-text-orange-lighter:focus {
    color: #fcd9b6;
  }

  .md\:focus\:tw-text-orange-lightest:focus {
    color: #fff5eb;
  }

  .md\:focus\:tw-text-yellow-darkest:focus {
    color: #453411;
  }

  .md\:focus\:tw-text-yellow-darker:focus {
    color: #684f1d;
  }

  .md\:focus\:tw-text-yellow-dark:focus {
    color: #f2d024;
  }

  .md\:focus\:tw-text-yellow:focus {
    color: #f8e71c;
  }

  .md\:focus\:tw-text-yellow-light:focus {
    color: #fff382;
  }

  .md\:focus\:tw-text-yellow-lighter:focus {
    color: #fff9c2;
  }

  .md\:focus\:tw-text-yellow-lightest:focus {
    color: #fcfbeb;
  }

  .md\:focus\:tw-text-green-darkest:focus {
    color: #0f2f21;
  }

  .md\:focus\:tw-text-green-darker:focus {
    color: #1a4731;
  }

  .md\:focus\:tw-text-green-dark:focus {
    color: #1f9d55;
  }

  .md\:focus\:tw-text-green:focus {
    color: #38c172;
  }

  .md\:focus\:tw-text-green-light:focus {
    color: #51d88a;
  }

  .md\:focus\:tw-text-green-lighter:focus {
    color: #a2f5bf;
  }

  .md\:focus\:tw-text-green-lightest:focus {
    color: #e3fcec;
  }

  .md\:focus\:tw-text-teal-darkest:focus {
    color: #0d3331;
  }

  .md\:focus\:tw-text-teal-darker:focus {
    color: #20504f;
  }

  .md\:focus\:tw-text-teal-dark:focus {
    color: #38a89d;
  }

  .md\:focus\:tw-text-teal:focus {
    color: #17c7aa;
  }

  .md\:focus\:tw-text-teal-light:focus {
    color: #64d5ca;
  }

  .md\:focus\:tw-text-teal-lighter:focus {
    color: #a0f0ed;
  }

  .md\:focus\:tw-text-teal-lightest:focus {
    color: #e8fffe;
  }

  .md\:focus\:tw-text-blue-darkest:focus {
    color: #12283a;
  }

  .md\:focus\:tw-text-blue-darker:focus {
    color: #1c3d5a;
  }

  .md\:focus\:tw-text-blue-dark:focus {
    color: #2779bd;
  }

  .md\:focus\:tw-text-blue:focus {
    color: #2ac1e7;
  }

  .md\:focus\:tw-text-blue-light:focus {
    color: #d4f2fa;
  }

  .md\:focus\:tw-text-blue-lighter:focus {
    color: #bcdefa;
  }

  .md\:focus\:tw-text-blue-lightest:focus {
    color: #eff8ff;
  }

  .md\:focus\:tw-text-indigo-darkest:focus {
    color: #191e38;
  }

  .md\:focus\:tw-text-indigo-darker:focus {
    color: #2f365f;
  }

  .md\:focus\:tw-text-indigo-dark:focus {
    color: #5661b3;
  }

  .md\:focus\:tw-text-indigo:focus {
    color: #6574cd;
  }

  .md\:focus\:tw-text-indigo-light:focus {
    color: #7886d7;
  }

  .md\:focus\:tw-text-indigo-lighter:focus {
    color: #b2b7ff;
  }

  .md\:focus\:tw-text-indigo-lightest:focus {
    color: #e6e8ff;
  }

  .md\:focus\:tw-text-purple-darkest:focus {
    color: #21183c;
  }

  .md\:focus\:tw-text-purple-darker:focus {
    color: #382b5f;
  }

  .md\:focus\:tw-text-purple-dark:focus {
    color: #794acf;
  }

  .md\:focus\:tw-text-purple:focus {
    color: #9561e2;
  }

  .md\:focus\:tw-text-purple-light:focus {
    color: #a779e9;
  }

  .md\:focus\:tw-text-purple-lighter:focus {
    color: #d6bbfc;
  }

  .md\:focus\:tw-text-purple-lightest:focus {
    color: #f3ebff;
  }

  .md\:focus\:tw-text-pink-darkest:focus {
    color: #451225;
  }

  .md\:focus\:tw-text-pink-darker:focus {
    color: #6f213f;
  }

  .md\:focus\:tw-text-pink-dark:focus {
    color: #eb5286;
  }

  .md\:focus\:tw-text-pink:focus {
    color: #f66d9b;
  }

  .md\:focus\:tw-text-pink-light:focus {
    color: #fa7ea8;
  }

  .md\:focus\:tw-text-pink-lighter:focus {
    color: #ffbbca;
  }

  .md\:focus\:tw-text-pink-lightest:focus {
    color: #ffebef;
  }

  .md\:tw-text-xsss {
    font-size: 8px;
  }

  .md\:tw-text-xss {
    font-size: 10px;
  }

  .md\:tw-text-xs {
    font-size: .75rem;
  }

  .md\:tw-text-sm {
    font-size: .875rem;
  }

  .md\:tw-text-base {
    font-size: 1rem;
  }

  .md\:tw-text-lg {
    font-size: 1.125rem;
  }

  .md\:tw-text-xl {
    font-size: 1.25rem;
  }

  .md\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .md\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .md\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .md\:tw-text-5xl {
    font-size: 3rem;
  }

  .md\:tw-italic {
    font-style: italic;
  }

  .md\:tw-roman {
    font-style: normal;
  }

  .md\:tw-uppercase {
    text-transform: uppercase;
  }

  .md\:tw-lowercase {
    text-transform: lowercase;
  }

  .md\:tw-capitalize {
    text-transform: capitalize;
  }

  .md\:tw-normal-case {
    text-transform: none;
  }

  .md\:tw-underline {
    text-decoration: underline;
  }

  .md\:tw-line-through {
    text-decoration: line-through;
  }

  .md\:tw-no-underline {
    text-decoration: none;
  }

  .md\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:hover\:tw-italic:hover {
    font-style: italic;
  }

  .md\:hover\:tw-roman:hover {
    font-style: normal;
  }

  .md\:hover\:tw-uppercase:hover {
    text-transform: uppercase;
  }

  .md\:hover\:tw-lowercase:hover {
    text-transform: lowercase;
  }

  .md\:hover\:tw-capitalize:hover {
    text-transform: capitalize;
  }

  .md\:hover\:tw-normal-case:hover {
    text-transform: none;
  }

  .md\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .md\:hover\:tw-antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:hover\:tw-subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:focus\:tw-italic:focus {
    font-style: italic;
  }

  .md\:focus\:tw-roman:focus {
    font-style: normal;
  }

  .md\:focus\:tw-uppercase:focus {
    text-transform: uppercase;
  }

  .md\:focus\:tw-lowercase:focus {
    text-transform: lowercase;
  }

  .md\:focus\:tw-capitalize:focus {
    text-transform: capitalize;
  }

  .md\:focus\:tw-normal-case:focus {
    text-transform: none;
  }

  .md\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .md\:focus\:tw-antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:focus\:tw-subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:tw-tracking-tight {
    letter-spacing: -0.05em;
  }

  .md\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .md\:tw-tracking-wide {
    letter-spacing: .05em;
  }

  .md\:tw-select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .md\:tw-select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .md\:tw-align-baseline {
    vertical-align: baseline;
  }

  .md\:tw-align-top {
    vertical-align: top;
  }

  .md\:tw-align-middle {
    vertical-align: middle;
  }

  .md\:tw-align-bottom {
    vertical-align: bottom;
  }

  .md\:tw-align-text-top {
    vertical-align: text-top;
  }

  .md\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:tw-visible {
    visibility: visible;
  }

  .md\:tw-invisible {
    visibility: hidden;
  }

  .md\:tw-whitespace-normal {
    white-space: normal;
  }

  .md\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .md\:tw-whitespace-pre {
    white-space: pre;
  }

  .md\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:tw-break-words {
    word-wrap: break-word;
  }

  .md\:tw-break-normal {
    word-wrap: normal;
  }

  .md\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .md\:tw-w-1 {
    width: .25rem;
  }

  .md\:tw-w-2 {
    width: .5rem;
  }

  .md\:tw-w-3 {
    width: .75rem;
  }

  .md\:tw-w-4 {
    width: 1rem;
  }

  .md\:tw-w-5 {
    width: 1.25rem;
  }

  .md\:tw-w-6 {
    width: 1.5rem;
  }

  .md\:tw-w-8 {
    width: 2rem;
  }

  .md\:tw-w-10 {
    width: 2.5rem;
  }

  .md\:tw-w-12 {
    width: 3rem;
  }

  .md\:tw-w-16 {
    width: 4rem;
  }

  .md\:tw-w-24 {
    width: 6rem;
  }

  .md\:tw-w-32 {
    width: 8rem;
  }

  .md\:tw-w-48 {
    width: 12rem;
  }

  .md\:tw-w-64 {
    width: 16rem;
  }

  .md\:tw-w-auto {
    width: auto;
  }

  .md\:tw-w-px {
    width: 1px;
  }

  .md\:tw-w-1\/2 {
    width: 50%;
  }

  .md\:tw-w-1\/3 {
    width: 33.33333%;
  }

  .md\:tw-w-2\/3 {
    width: 66.66667%;
  }

  .md\:tw-w-1\/4 {
    width: 25%;
  }

  .md\:tw-w-3\/4 {
    width: 75%;
  }

  .md\:tw-w-1\/5 {
    width: 20%;
  }

  .md\:tw-w-2\/5 {
    width: 40%;
  }

  .md\:tw-w-3\/5 {
    width: 60%;
  }

  .md\:tw-w-4\/5 {
    width: 80%;
  }

  .md\:tw-w-1\/6 {
    width: 16.66667%;
  }

  .md\:tw-w-5\/6 {
    width: 83.33333%;
  }

  .md\:tw-w-full {
    width: 100%;
  }

  .md\:tw-w-screen {
    width: 100vw;
  }

  .md\:tw-z-0 {
    z-index: 0;
  }

  .md\:tw-z-10 {
    z-index: 10;
  }

  .md\:tw-z-20 {
    z-index: 20;
  }

  .md\:tw-z-30 {
    z-index: 30;
  }

  .md\:tw-z-40 {
    z-index: 40;
  }

  .md\:tw-z-50 {
    z-index: 50;
  }

  .md\:tw-z-auto {
    z-index: auto;
  }
}

@media (min-width: 992px) {
  .lg\:tw-list-reset {
    list-style: none;
    padding: 0;
  }

  .lg\:tw-appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .lg\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .lg\:tw-bg-local {
    background-attachment: local;
  }

  .lg\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .lg\:tw-bg-transparent {
    background-color: transparent;
  }

  .lg\:tw-bg-primary {
    background-color: #ac826d;
  }

  .lg\:tw-bg-text {
    background-color: #453f3f;
  }

  .lg\:tw-bg-black {
    background-color: #000;
  }

  .lg\:tw-bg-grey-darkest {
    background-color: #3d4852;
  }

  .lg\:tw-bg-grey-darker {
    background-color: #606f7b;
  }

  .lg\:tw-bg-grey-dark {
    background-color: #8795a1;
  }

  .lg\:tw-bg-grey {
    background-color: #b8c2cc;
  }

  .lg\:tw-bg-grey-light {
    background-color: #a1b3bc;
  }

  .lg\:tw-bg-grey-lighter {
    background-color: #d8dfe3;
  }

  .lg\:tw-bg-grey-lightest {
    background-color: #f8fafc;
  }

  .lg\:tw-bg-grey-grayish {
    background-color: #56666c;
  }

  .lg\:tw-bg-white {
    background-color: #fff;
  }

  .lg\:tw-bg-red-darkest {
    background-color: #3b0d0c;
  }

  .lg\:tw-bg-red-darker {
    background-color: #621b18;
  }

  .lg\:tw-bg-red-dark {
    background-color: #cc1f1a;
  }

  .lg\:tw-bg-red {
    background-color: #de5050;
  }

  .lg\:tw-bg-red-light {
    background-color: #fed5dc;
  }

  .lg\:tw-bg-red-lighter {
    background-color: #f9acaa;
  }

  .lg\:tw-bg-red-lightest {
    background-color: #fcebea;
  }

  .lg\:tw-bg-orange-darkest {
    background-color: #462a16;
  }

  .lg\:tw-bg-orange-darker {
    background-color: #613b1f;
  }

  .lg\:tw-bg-orange-dark {
    background-color: #de751f;
  }

  .lg\:tw-bg-orange {
    background-color: #ffbf00;
  }

  .lg\:tw-bg-orange-light {
    background-color: #faad63;
  }

  .lg\:tw-bg-orange-lighter {
    background-color: #fcd9b6;
  }

  .lg\:tw-bg-orange-lightest {
    background-color: #fff5eb;
  }

  .lg\:tw-bg-yellow-darkest {
    background-color: #453411;
  }

  .lg\:tw-bg-yellow-darker {
    background-color: #684f1d;
  }

  .lg\:tw-bg-yellow-dark {
    background-color: #f2d024;
  }

  .lg\:tw-bg-yellow {
    background-color: #f8e71c;
  }

  .lg\:tw-bg-yellow-light {
    background-color: #fff382;
  }

  .lg\:tw-bg-yellow-lighter {
    background-color: #fff9c2;
  }

  .lg\:tw-bg-yellow-lightest {
    background-color: #fcfbeb;
  }

  .lg\:tw-bg-green-darkest {
    background-color: #0f2f21;
  }

  .lg\:tw-bg-green-darker {
    background-color: #1a4731;
  }

  .lg\:tw-bg-green-dark {
    background-color: #1f9d55;
  }

  .lg\:tw-bg-green {
    background-color: #38c172;
  }

  .lg\:tw-bg-green-light {
    background-color: #51d88a;
  }

  .lg\:tw-bg-green-lighter {
    background-color: #a2f5bf;
  }

  .lg\:tw-bg-green-lightest {
    background-color: #e3fcec;
  }

  .lg\:tw-bg-teal-darkest {
    background-color: #0d3331;
  }

  .lg\:tw-bg-teal-darker {
    background-color: #20504f;
  }

  .lg\:tw-bg-teal-dark {
    background-color: #38a89d;
  }

  .lg\:tw-bg-teal {
    background-color: #17c7aa;
  }

  .lg\:tw-bg-teal-light {
    background-color: #64d5ca;
  }

  .lg\:tw-bg-teal-lighter {
    background-color: #a0f0ed;
  }

  .lg\:tw-bg-teal-lightest {
    background-color: #e8fffe;
  }

  .lg\:tw-bg-blue-darkest {
    background-color: #12283a;
  }

  .lg\:tw-bg-blue-darker {
    background-color: #1c3d5a;
  }

  .lg\:tw-bg-blue-dark {
    background-color: #2779bd;
  }

  .lg\:tw-bg-blue {
    background-color: #2ac1e7;
  }

  .lg\:tw-bg-blue-light {
    background-color: #d4f2fa;
  }

  .lg\:tw-bg-blue-lighter {
    background-color: #bcdefa;
  }

  .lg\:tw-bg-blue-lightest {
    background-color: #eff8ff;
  }

  .lg\:tw-bg-indigo-darkest {
    background-color: #191e38;
  }

  .lg\:tw-bg-indigo-darker {
    background-color: #2f365f;
  }

  .lg\:tw-bg-indigo-dark {
    background-color: #5661b3;
  }

  .lg\:tw-bg-indigo {
    background-color: #6574cd;
  }

  .lg\:tw-bg-indigo-light {
    background-color: #7886d7;
  }

  .lg\:tw-bg-indigo-lighter {
    background-color: #b2b7ff;
  }

  .lg\:tw-bg-indigo-lightest {
    background-color: #e6e8ff;
  }

  .lg\:tw-bg-purple-darkest {
    background-color: #21183c;
  }

  .lg\:tw-bg-purple-darker {
    background-color: #382b5f;
  }

  .lg\:tw-bg-purple-dark {
    background-color: #794acf;
  }

  .lg\:tw-bg-purple {
    background-color: #9561e2;
  }

  .lg\:tw-bg-purple-light {
    background-color: #a779e9;
  }

  .lg\:tw-bg-purple-lighter {
    background-color: #d6bbfc;
  }

  .lg\:tw-bg-purple-lightest {
    background-color: #f3ebff;
  }

  .lg\:tw-bg-pink-darkest {
    background-color: #451225;
  }

  .lg\:tw-bg-pink-darker {
    background-color: #6f213f;
  }

  .lg\:tw-bg-pink-dark {
    background-color: #eb5286;
  }

  .lg\:tw-bg-pink {
    background-color: #f66d9b;
  }

  .lg\:tw-bg-pink-light {
    background-color: #fa7ea8;
  }

  .lg\:tw-bg-pink-lighter {
    background-color: #ffbbca;
  }

  .lg\:tw-bg-pink-lightest {
    background-color: #ffebef;
  }

  .lg\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:tw-bg-primary:hover {
    background-color: #ac826d;
  }

  .lg\:hover\:tw-bg-text:hover {
    background-color: #453f3f;
  }

  .lg\:hover\:tw-bg-black:hover {
    background-color: #000;
  }

  .lg\:hover\:tw-bg-grey-darkest:hover {
    background-color: #3d4852;
  }

  .lg\:hover\:tw-bg-grey-darker:hover {
    background-color: #606f7b;
  }

  .lg\:hover\:tw-bg-grey-dark:hover {
    background-color: #8795a1;
  }

  .lg\:hover\:tw-bg-grey:hover {
    background-color: #b8c2cc;
  }

  .lg\:hover\:tw-bg-grey-light:hover {
    background-color: #a1b3bc;
  }

  .lg\:hover\:tw-bg-grey-lighter:hover {
    background-color: #d8dfe3;
  }

  .lg\:hover\:tw-bg-grey-lightest:hover {
    background-color: #f8fafc;
  }

  .lg\:hover\:tw-bg-grey-grayish:hover {
    background-color: #56666c;
  }

  .lg\:hover\:tw-bg-white:hover {
    background-color: #fff;
  }

  .lg\:hover\:tw-bg-red-darkest:hover {
    background-color: #3b0d0c;
  }

  .lg\:hover\:tw-bg-red-darker:hover {
    background-color: #621b18;
  }

  .lg\:hover\:tw-bg-red-dark:hover {
    background-color: #cc1f1a;
  }

  .lg\:hover\:tw-bg-red:hover {
    background-color: #de5050;
  }

  .lg\:hover\:tw-bg-red-light:hover {
    background-color: #fed5dc;
  }

  .lg\:hover\:tw-bg-red-lighter:hover {
    background-color: #f9acaa;
  }

  .lg\:hover\:tw-bg-red-lightest:hover {
    background-color: #fcebea;
  }

  .lg\:hover\:tw-bg-orange-darkest:hover {
    background-color: #462a16;
  }

  .lg\:hover\:tw-bg-orange-darker:hover {
    background-color: #613b1f;
  }

  .lg\:hover\:tw-bg-orange-dark:hover {
    background-color: #de751f;
  }

  .lg\:hover\:tw-bg-orange:hover {
    background-color: #ffbf00;
  }

  .lg\:hover\:tw-bg-orange-light:hover {
    background-color: #faad63;
  }

  .lg\:hover\:tw-bg-orange-lighter:hover {
    background-color: #fcd9b6;
  }

  .lg\:hover\:tw-bg-orange-lightest:hover {
    background-color: #fff5eb;
  }

  .lg\:hover\:tw-bg-yellow-darkest:hover {
    background-color: #453411;
  }

  .lg\:hover\:tw-bg-yellow-darker:hover {
    background-color: #684f1d;
  }

  .lg\:hover\:tw-bg-yellow-dark:hover {
    background-color: #f2d024;
  }

  .lg\:hover\:tw-bg-yellow:hover {
    background-color: #f8e71c;
  }

  .lg\:hover\:tw-bg-yellow-light:hover {
    background-color: #fff382;
  }

  .lg\:hover\:tw-bg-yellow-lighter:hover {
    background-color: #fff9c2;
  }

  .lg\:hover\:tw-bg-yellow-lightest:hover {
    background-color: #fcfbeb;
  }

  .lg\:hover\:tw-bg-green-darkest:hover {
    background-color: #0f2f21;
  }

  .lg\:hover\:tw-bg-green-darker:hover {
    background-color: #1a4731;
  }

  .lg\:hover\:tw-bg-green-dark:hover {
    background-color: #1f9d55;
  }

  .lg\:hover\:tw-bg-green:hover {
    background-color: #38c172;
  }

  .lg\:hover\:tw-bg-green-light:hover {
    background-color: #51d88a;
  }

  .lg\:hover\:tw-bg-green-lighter:hover {
    background-color: #a2f5bf;
  }

  .lg\:hover\:tw-bg-green-lightest:hover {
    background-color: #e3fcec;
  }

  .lg\:hover\:tw-bg-teal-darkest:hover {
    background-color: #0d3331;
  }

  .lg\:hover\:tw-bg-teal-darker:hover {
    background-color: #20504f;
  }

  .lg\:hover\:tw-bg-teal-dark:hover {
    background-color: #38a89d;
  }

  .lg\:hover\:tw-bg-teal:hover {
    background-color: #17c7aa;
  }

  .lg\:hover\:tw-bg-teal-light:hover {
    background-color: #64d5ca;
  }

  .lg\:hover\:tw-bg-teal-lighter:hover {
    background-color: #a0f0ed;
  }

  .lg\:hover\:tw-bg-teal-lightest:hover {
    background-color: #e8fffe;
  }

  .lg\:hover\:tw-bg-blue-darkest:hover {
    background-color: #12283a;
  }

  .lg\:hover\:tw-bg-blue-darker:hover {
    background-color: #1c3d5a;
  }

  .lg\:hover\:tw-bg-blue-dark:hover {
    background-color: #2779bd;
  }

  .lg\:hover\:tw-bg-blue:hover {
    background-color: #2ac1e7;
  }

  .lg\:hover\:tw-bg-blue-light:hover {
    background-color: #d4f2fa;
  }

  .lg\:hover\:tw-bg-blue-lighter:hover {
    background-color: #bcdefa;
  }

  .lg\:hover\:tw-bg-blue-lightest:hover {
    background-color: #eff8ff;
  }

  .lg\:hover\:tw-bg-indigo-darkest:hover {
    background-color: #191e38;
  }

  .lg\:hover\:tw-bg-indigo-darker:hover {
    background-color: #2f365f;
  }

  .lg\:hover\:tw-bg-indigo-dark:hover {
    background-color: #5661b3;
  }

  .lg\:hover\:tw-bg-indigo:hover {
    background-color: #6574cd;
  }

  .lg\:hover\:tw-bg-indigo-light:hover {
    background-color: #7886d7;
  }

  .lg\:hover\:tw-bg-indigo-lighter:hover {
    background-color: #b2b7ff;
  }

  .lg\:hover\:tw-bg-indigo-lightest:hover {
    background-color: #e6e8ff;
  }

  .lg\:hover\:tw-bg-purple-darkest:hover {
    background-color: #21183c;
  }

  .lg\:hover\:tw-bg-purple-darker:hover {
    background-color: #382b5f;
  }

  .lg\:hover\:tw-bg-purple-dark:hover {
    background-color: #794acf;
  }

  .lg\:hover\:tw-bg-purple:hover {
    background-color: #9561e2;
  }

  .lg\:hover\:tw-bg-purple-light:hover {
    background-color: #a779e9;
  }

  .lg\:hover\:tw-bg-purple-lighter:hover {
    background-color: #d6bbfc;
  }

  .lg\:hover\:tw-bg-purple-lightest:hover {
    background-color: #f3ebff;
  }

  .lg\:hover\:tw-bg-pink-darkest:hover {
    background-color: #451225;
  }

  .lg\:hover\:tw-bg-pink-darker:hover {
    background-color: #6f213f;
  }

  .lg\:hover\:tw-bg-pink-dark:hover {
    background-color: #eb5286;
  }

  .lg\:hover\:tw-bg-pink:hover {
    background-color: #f66d9b;
  }

  .lg\:hover\:tw-bg-pink-light:hover {
    background-color: #fa7ea8;
  }

  .lg\:hover\:tw-bg-pink-lighter:hover {
    background-color: #ffbbca;
  }

  .lg\:hover\:tw-bg-pink-lightest:hover {
    background-color: #ffebef;
  }

  .lg\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .lg\:focus\:tw-bg-primary:focus {
    background-color: #ac826d;
  }

  .lg\:focus\:tw-bg-text:focus {
    background-color: #453f3f;
  }

  .lg\:focus\:tw-bg-black:focus {
    background-color: #000;
  }

  .lg\:focus\:tw-bg-grey-darkest:focus {
    background-color: #3d4852;
  }

  .lg\:focus\:tw-bg-grey-darker:focus {
    background-color: #606f7b;
  }

  .lg\:focus\:tw-bg-grey-dark:focus {
    background-color: #8795a1;
  }

  .lg\:focus\:tw-bg-grey:focus {
    background-color: #b8c2cc;
  }

  .lg\:focus\:tw-bg-grey-light:focus {
    background-color: #a1b3bc;
  }

  .lg\:focus\:tw-bg-grey-lighter:focus {
    background-color: #d8dfe3;
  }

  .lg\:focus\:tw-bg-grey-lightest:focus {
    background-color: #f8fafc;
  }

  .lg\:focus\:tw-bg-grey-grayish:focus {
    background-color: #56666c;
  }

  .lg\:focus\:tw-bg-white:focus {
    background-color: #fff;
  }

  .lg\:focus\:tw-bg-red-darkest:focus {
    background-color: #3b0d0c;
  }

  .lg\:focus\:tw-bg-red-darker:focus {
    background-color: #621b18;
  }

  .lg\:focus\:tw-bg-red-dark:focus {
    background-color: #cc1f1a;
  }

  .lg\:focus\:tw-bg-red:focus {
    background-color: #de5050;
  }

  .lg\:focus\:tw-bg-red-light:focus {
    background-color: #fed5dc;
  }

  .lg\:focus\:tw-bg-red-lighter:focus {
    background-color: #f9acaa;
  }

  .lg\:focus\:tw-bg-red-lightest:focus {
    background-color: #fcebea;
  }

  .lg\:focus\:tw-bg-orange-darkest:focus {
    background-color: #462a16;
  }

  .lg\:focus\:tw-bg-orange-darker:focus {
    background-color: #613b1f;
  }

  .lg\:focus\:tw-bg-orange-dark:focus {
    background-color: #de751f;
  }

  .lg\:focus\:tw-bg-orange:focus {
    background-color: #ffbf00;
  }

  .lg\:focus\:tw-bg-orange-light:focus {
    background-color: #faad63;
  }

  .lg\:focus\:tw-bg-orange-lighter:focus {
    background-color: #fcd9b6;
  }

  .lg\:focus\:tw-bg-orange-lightest:focus {
    background-color: #fff5eb;
  }

  .lg\:focus\:tw-bg-yellow-darkest:focus {
    background-color: #453411;
  }

  .lg\:focus\:tw-bg-yellow-darker:focus {
    background-color: #684f1d;
  }

  .lg\:focus\:tw-bg-yellow-dark:focus {
    background-color: #f2d024;
  }

  .lg\:focus\:tw-bg-yellow:focus {
    background-color: #f8e71c;
  }

  .lg\:focus\:tw-bg-yellow-light:focus {
    background-color: #fff382;
  }

  .lg\:focus\:tw-bg-yellow-lighter:focus {
    background-color: #fff9c2;
  }

  .lg\:focus\:tw-bg-yellow-lightest:focus {
    background-color: #fcfbeb;
  }

  .lg\:focus\:tw-bg-green-darkest:focus {
    background-color: #0f2f21;
  }

  .lg\:focus\:tw-bg-green-darker:focus {
    background-color: #1a4731;
  }

  .lg\:focus\:tw-bg-green-dark:focus {
    background-color: #1f9d55;
  }

  .lg\:focus\:tw-bg-green:focus {
    background-color: #38c172;
  }

  .lg\:focus\:tw-bg-green-light:focus {
    background-color: #51d88a;
  }

  .lg\:focus\:tw-bg-green-lighter:focus {
    background-color: #a2f5bf;
  }

  .lg\:focus\:tw-bg-green-lightest:focus {
    background-color: #e3fcec;
  }

  .lg\:focus\:tw-bg-teal-darkest:focus {
    background-color: #0d3331;
  }

  .lg\:focus\:tw-bg-teal-darker:focus {
    background-color: #20504f;
  }

  .lg\:focus\:tw-bg-teal-dark:focus {
    background-color: #38a89d;
  }

  .lg\:focus\:tw-bg-teal:focus {
    background-color: #17c7aa;
  }

  .lg\:focus\:tw-bg-teal-light:focus {
    background-color: #64d5ca;
  }

  .lg\:focus\:tw-bg-teal-lighter:focus {
    background-color: #a0f0ed;
  }

  .lg\:focus\:tw-bg-teal-lightest:focus {
    background-color: #e8fffe;
  }

  .lg\:focus\:tw-bg-blue-darkest:focus {
    background-color: #12283a;
  }

  .lg\:focus\:tw-bg-blue-darker:focus {
    background-color: #1c3d5a;
  }

  .lg\:focus\:tw-bg-blue-dark:focus {
    background-color: #2779bd;
  }

  .lg\:focus\:tw-bg-blue:focus {
    background-color: #2ac1e7;
  }

  .lg\:focus\:tw-bg-blue-light:focus {
    background-color: #d4f2fa;
  }

  .lg\:focus\:tw-bg-blue-lighter:focus {
    background-color: #bcdefa;
  }

  .lg\:focus\:tw-bg-blue-lightest:focus {
    background-color: #eff8ff;
  }

  .lg\:focus\:tw-bg-indigo-darkest:focus {
    background-color: #191e38;
  }

  .lg\:focus\:tw-bg-indigo-darker:focus {
    background-color: #2f365f;
  }

  .lg\:focus\:tw-bg-indigo-dark:focus {
    background-color: #5661b3;
  }

  .lg\:focus\:tw-bg-indigo:focus {
    background-color: #6574cd;
  }

  .lg\:focus\:tw-bg-indigo-light:focus {
    background-color: #7886d7;
  }

  .lg\:focus\:tw-bg-indigo-lighter:focus {
    background-color: #b2b7ff;
  }

  .lg\:focus\:tw-bg-indigo-lightest:focus {
    background-color: #e6e8ff;
  }

  .lg\:focus\:tw-bg-purple-darkest:focus {
    background-color: #21183c;
  }

  .lg\:focus\:tw-bg-purple-darker:focus {
    background-color: #382b5f;
  }

  .lg\:focus\:tw-bg-purple-dark:focus {
    background-color: #794acf;
  }

  .lg\:focus\:tw-bg-purple:focus {
    background-color: #9561e2;
  }

  .lg\:focus\:tw-bg-purple-light:focus {
    background-color: #a779e9;
  }

  .lg\:focus\:tw-bg-purple-lighter:focus {
    background-color: #d6bbfc;
  }

  .lg\:focus\:tw-bg-purple-lightest:focus {
    background-color: #f3ebff;
  }

  .lg\:focus\:tw-bg-pink-darkest:focus {
    background-color: #451225;
  }

  .lg\:focus\:tw-bg-pink-darker:focus {
    background-color: #6f213f;
  }

  .lg\:focus\:tw-bg-pink-dark:focus {
    background-color: #eb5286;
  }

  .lg\:focus\:tw-bg-pink:focus {
    background-color: #f66d9b;
  }

  .lg\:focus\:tw-bg-pink-light:focus {
    background-color: #fa7ea8;
  }

  .lg\:focus\:tw-bg-pink-lighter:focus {
    background-color: #ffbbca;
  }

  .lg\:focus\:tw-bg-pink-lightest:focus {
    background-color: #ffebef;
  }

  .lg\:tw-bg-bottom {
    background-position: bottom;
  }

  .lg\:tw-bg-center {
    background-position: center;
  }

  .lg\:tw-bg-left {
    background-position: left;
  }

  .lg\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .lg\:tw-bg-left-top {
    background-position: left top;
  }

  .lg\:tw-bg-right {
    background-position: right;
  }

  .lg\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .lg\:tw-bg-right-top {
    background-position: right top;
  }

  .lg\:tw-bg-top {
    background-position: top;
  }

  .lg\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .lg\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:tw-bg-auto {
    background-size: auto;
  }

  .lg\:tw-bg-cover {
    background-size: cover;
  }

  .lg\:tw-bg-contain {
    background-size: contain;
  }

  .lg\:tw-border-transparent {
    border-color: transparent;
  }

  .lg\:tw-border-primary {
    border-color: #ac826d;
  }

  .lg\:tw-border-text {
    border-color: #453f3f;
  }

  .lg\:tw-border-black {
    border-color: #000;
  }

  .lg\:tw-border-grey-darkest {
    border-color: #3d4852;
  }

  .lg\:tw-border-grey-darker {
    border-color: #606f7b;
  }

  .lg\:tw-border-grey-dark {
    border-color: #8795a1;
  }

  .lg\:tw-border-grey {
    border-color: #b8c2cc;
  }

  .lg\:tw-border-grey-light {
    border-color: #a1b3bc;
  }

  .lg\:tw-border-grey-lighter {
    border-color: #d8dfe3;
  }

  .lg\:tw-border-grey-lightest {
    border-color: #f8fafc;
  }

  .lg\:tw-border-grey-grayish {
    border-color: #56666c;
  }

  .lg\:tw-border-white {
    border-color: #fff;
  }

  .lg\:tw-border-red-darkest {
    border-color: #3b0d0c;
  }

  .lg\:tw-border-red-darker {
    border-color: #621b18;
  }

  .lg\:tw-border-red-dark {
    border-color: #cc1f1a;
  }

  .lg\:tw-border-red {
    border-color: #de5050;
  }

  .lg\:tw-border-red-light {
    border-color: #fed5dc;
  }

  .lg\:tw-border-red-lighter {
    border-color: #f9acaa;
  }

  .lg\:tw-border-red-lightest {
    border-color: #fcebea;
  }

  .lg\:tw-border-orange-darkest {
    border-color: #462a16;
  }

  .lg\:tw-border-orange-darker {
    border-color: #613b1f;
  }

  .lg\:tw-border-orange-dark {
    border-color: #de751f;
  }

  .lg\:tw-border-orange {
    border-color: #ffbf00;
  }

  .lg\:tw-border-orange-light {
    border-color: #faad63;
  }

  .lg\:tw-border-orange-lighter {
    border-color: #fcd9b6;
  }

  .lg\:tw-border-orange-lightest {
    border-color: #fff5eb;
  }

  .lg\:tw-border-yellow-darkest {
    border-color: #453411;
  }

  .lg\:tw-border-yellow-darker {
    border-color: #684f1d;
  }

  .lg\:tw-border-yellow-dark {
    border-color: #f2d024;
  }

  .lg\:tw-border-yellow {
    border-color: #f8e71c;
  }

  .lg\:tw-border-yellow-light {
    border-color: #fff382;
  }

  .lg\:tw-border-yellow-lighter {
    border-color: #fff9c2;
  }

  .lg\:tw-border-yellow-lightest {
    border-color: #fcfbeb;
  }

  .lg\:tw-border-green-darkest {
    border-color: #0f2f21;
  }

  .lg\:tw-border-green-darker {
    border-color: #1a4731;
  }

  .lg\:tw-border-green-dark {
    border-color: #1f9d55;
  }

  .lg\:tw-border-green {
    border-color: #38c172;
  }

  .lg\:tw-border-green-light {
    border-color: #51d88a;
  }

  .lg\:tw-border-green-lighter {
    border-color: #a2f5bf;
  }

  .lg\:tw-border-green-lightest {
    border-color: #e3fcec;
  }

  .lg\:tw-border-teal-darkest {
    border-color: #0d3331;
  }

  .lg\:tw-border-teal-darker {
    border-color: #20504f;
  }

  .lg\:tw-border-teal-dark {
    border-color: #38a89d;
  }

  .lg\:tw-border-teal {
    border-color: #17c7aa;
  }

  .lg\:tw-border-teal-light {
    border-color: #64d5ca;
  }

  .lg\:tw-border-teal-lighter {
    border-color: #a0f0ed;
  }

  .lg\:tw-border-teal-lightest {
    border-color: #e8fffe;
  }

  .lg\:tw-border-blue-darkest {
    border-color: #12283a;
  }

  .lg\:tw-border-blue-darker {
    border-color: #1c3d5a;
  }

  .lg\:tw-border-blue-dark {
    border-color: #2779bd;
  }

  .lg\:tw-border-blue {
    border-color: #2ac1e7;
  }

  .lg\:tw-border-blue-light {
    border-color: #d4f2fa;
  }

  .lg\:tw-border-blue-lighter {
    border-color: #bcdefa;
  }

  .lg\:tw-border-blue-lightest {
    border-color: #eff8ff;
  }

  .lg\:tw-border-indigo-darkest {
    border-color: #191e38;
  }

  .lg\:tw-border-indigo-darker {
    border-color: #2f365f;
  }

  .lg\:tw-border-indigo-dark {
    border-color: #5661b3;
  }

  .lg\:tw-border-indigo {
    border-color: #6574cd;
  }

  .lg\:tw-border-indigo-light {
    border-color: #7886d7;
  }

  .lg\:tw-border-indigo-lighter {
    border-color: #b2b7ff;
  }

  .lg\:tw-border-indigo-lightest {
    border-color: #e6e8ff;
  }

  .lg\:tw-border-purple-darkest {
    border-color: #21183c;
  }

  .lg\:tw-border-purple-darker {
    border-color: #382b5f;
  }

  .lg\:tw-border-purple-dark {
    border-color: #794acf;
  }

  .lg\:tw-border-purple {
    border-color: #9561e2;
  }

  .lg\:tw-border-purple-light {
    border-color: #a779e9;
  }

  .lg\:tw-border-purple-lighter {
    border-color: #d6bbfc;
  }

  .lg\:tw-border-purple-lightest {
    border-color: #f3ebff;
  }

  .lg\:tw-border-pink-darkest {
    border-color: #451225;
  }

  .lg\:tw-border-pink-darker {
    border-color: #6f213f;
  }

  .lg\:tw-border-pink-dark {
    border-color: #eb5286;
  }

  .lg\:tw-border-pink {
    border-color: #f66d9b;
  }

  .lg\:tw-border-pink-light {
    border-color: #fa7ea8;
  }

  .lg\:tw-border-pink-lighter {
    border-color: #ffbbca;
  }

  .lg\:tw-border-pink-lightest {
    border-color: #ffebef;
  }

  .lg\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .lg\:hover\:tw-border-primary:hover {
    border-color: #ac826d;
  }

  .lg\:hover\:tw-border-text:hover {
    border-color: #453f3f;
  }

  .lg\:hover\:tw-border-black:hover {
    border-color: #000;
  }

  .lg\:hover\:tw-border-grey-darkest:hover {
    border-color: #3d4852;
  }

  .lg\:hover\:tw-border-grey-darker:hover {
    border-color: #606f7b;
  }

  .lg\:hover\:tw-border-grey-dark:hover {
    border-color: #8795a1;
  }

  .lg\:hover\:tw-border-grey:hover {
    border-color: #b8c2cc;
  }

  .lg\:hover\:tw-border-grey-light:hover {
    border-color: #a1b3bc;
  }

  .lg\:hover\:tw-border-grey-lighter:hover {
    border-color: #d8dfe3;
  }

  .lg\:hover\:tw-border-grey-lightest:hover {
    border-color: #f8fafc;
  }

  .lg\:hover\:tw-border-grey-grayish:hover {
    border-color: #56666c;
  }

  .lg\:hover\:tw-border-white:hover {
    border-color: #fff;
  }

  .lg\:hover\:tw-border-red-darkest:hover {
    border-color: #3b0d0c;
  }

  .lg\:hover\:tw-border-red-darker:hover {
    border-color: #621b18;
  }

  .lg\:hover\:tw-border-red-dark:hover {
    border-color: #cc1f1a;
  }

  .lg\:hover\:tw-border-red:hover {
    border-color: #de5050;
  }

  .lg\:hover\:tw-border-red-light:hover {
    border-color: #fed5dc;
  }

  .lg\:hover\:tw-border-red-lighter:hover {
    border-color: #f9acaa;
  }

  .lg\:hover\:tw-border-red-lightest:hover {
    border-color: #fcebea;
  }

  .lg\:hover\:tw-border-orange-darkest:hover {
    border-color: #462a16;
  }

  .lg\:hover\:tw-border-orange-darker:hover {
    border-color: #613b1f;
  }

  .lg\:hover\:tw-border-orange-dark:hover {
    border-color: #de751f;
  }

  .lg\:hover\:tw-border-orange:hover {
    border-color: #ffbf00;
  }

  .lg\:hover\:tw-border-orange-light:hover {
    border-color: #faad63;
  }

  .lg\:hover\:tw-border-orange-lighter:hover {
    border-color: #fcd9b6;
  }

  .lg\:hover\:tw-border-orange-lightest:hover {
    border-color: #fff5eb;
  }

  .lg\:hover\:tw-border-yellow-darkest:hover {
    border-color: #453411;
  }

  .lg\:hover\:tw-border-yellow-darker:hover {
    border-color: #684f1d;
  }

  .lg\:hover\:tw-border-yellow-dark:hover {
    border-color: #f2d024;
  }

  .lg\:hover\:tw-border-yellow:hover {
    border-color: #f8e71c;
  }

  .lg\:hover\:tw-border-yellow-light:hover {
    border-color: #fff382;
  }

  .lg\:hover\:tw-border-yellow-lighter:hover {
    border-color: #fff9c2;
  }

  .lg\:hover\:tw-border-yellow-lightest:hover {
    border-color: #fcfbeb;
  }

  .lg\:hover\:tw-border-green-darkest:hover {
    border-color: #0f2f21;
  }

  .lg\:hover\:tw-border-green-darker:hover {
    border-color: #1a4731;
  }

  .lg\:hover\:tw-border-green-dark:hover {
    border-color: #1f9d55;
  }

  .lg\:hover\:tw-border-green:hover {
    border-color: #38c172;
  }

  .lg\:hover\:tw-border-green-light:hover {
    border-color: #51d88a;
  }

  .lg\:hover\:tw-border-green-lighter:hover {
    border-color: #a2f5bf;
  }

  .lg\:hover\:tw-border-green-lightest:hover {
    border-color: #e3fcec;
  }

  .lg\:hover\:tw-border-teal-darkest:hover {
    border-color: #0d3331;
  }

  .lg\:hover\:tw-border-teal-darker:hover {
    border-color: #20504f;
  }

  .lg\:hover\:tw-border-teal-dark:hover {
    border-color: #38a89d;
  }

  .lg\:hover\:tw-border-teal:hover {
    border-color: #17c7aa;
  }

  .lg\:hover\:tw-border-teal-light:hover {
    border-color: #64d5ca;
  }

  .lg\:hover\:tw-border-teal-lighter:hover {
    border-color: #a0f0ed;
  }

  .lg\:hover\:tw-border-teal-lightest:hover {
    border-color: #e8fffe;
  }

  .lg\:hover\:tw-border-blue-darkest:hover {
    border-color: #12283a;
  }

  .lg\:hover\:tw-border-blue-darker:hover {
    border-color: #1c3d5a;
  }

  .lg\:hover\:tw-border-blue-dark:hover {
    border-color: #2779bd;
  }

  .lg\:hover\:tw-border-blue:hover {
    border-color: #2ac1e7;
  }

  .lg\:hover\:tw-border-blue-light:hover {
    border-color: #d4f2fa;
  }

  .lg\:hover\:tw-border-blue-lighter:hover {
    border-color: #bcdefa;
  }

  .lg\:hover\:tw-border-blue-lightest:hover {
    border-color: #eff8ff;
  }

  .lg\:hover\:tw-border-indigo-darkest:hover {
    border-color: #191e38;
  }

  .lg\:hover\:tw-border-indigo-darker:hover {
    border-color: #2f365f;
  }

  .lg\:hover\:tw-border-indigo-dark:hover {
    border-color: #5661b3;
  }

  .lg\:hover\:tw-border-indigo:hover {
    border-color: #6574cd;
  }

  .lg\:hover\:tw-border-indigo-light:hover {
    border-color: #7886d7;
  }

  .lg\:hover\:tw-border-indigo-lighter:hover {
    border-color: #b2b7ff;
  }

  .lg\:hover\:tw-border-indigo-lightest:hover {
    border-color: #e6e8ff;
  }

  .lg\:hover\:tw-border-purple-darkest:hover {
    border-color: #21183c;
  }

  .lg\:hover\:tw-border-purple-darker:hover {
    border-color: #382b5f;
  }

  .lg\:hover\:tw-border-purple-dark:hover {
    border-color: #794acf;
  }

  .lg\:hover\:tw-border-purple:hover {
    border-color: #9561e2;
  }

  .lg\:hover\:tw-border-purple-light:hover {
    border-color: #a779e9;
  }

  .lg\:hover\:tw-border-purple-lighter:hover {
    border-color: #d6bbfc;
  }

  .lg\:hover\:tw-border-purple-lightest:hover {
    border-color: #f3ebff;
  }

  .lg\:hover\:tw-border-pink-darkest:hover {
    border-color: #451225;
  }

  .lg\:hover\:tw-border-pink-darker:hover {
    border-color: #6f213f;
  }

  .lg\:hover\:tw-border-pink-dark:hover {
    border-color: #eb5286;
  }

  .lg\:hover\:tw-border-pink:hover {
    border-color: #f66d9b;
  }

  .lg\:hover\:tw-border-pink-light:hover {
    border-color: #fa7ea8;
  }

  .lg\:hover\:tw-border-pink-lighter:hover {
    border-color: #ffbbca;
  }

  .lg\:hover\:tw-border-pink-lightest:hover {
    border-color: #ffebef;
  }

  .lg\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .lg\:focus\:tw-border-primary:focus {
    border-color: #ac826d;
  }

  .lg\:focus\:tw-border-text:focus {
    border-color: #453f3f;
  }

  .lg\:focus\:tw-border-black:focus {
    border-color: #000;
  }

  .lg\:focus\:tw-border-grey-darkest:focus {
    border-color: #3d4852;
  }

  .lg\:focus\:tw-border-grey-darker:focus {
    border-color: #606f7b;
  }

  .lg\:focus\:tw-border-grey-dark:focus {
    border-color: #8795a1;
  }

  .lg\:focus\:tw-border-grey:focus {
    border-color: #b8c2cc;
  }

  .lg\:focus\:tw-border-grey-light:focus {
    border-color: #a1b3bc;
  }

  .lg\:focus\:tw-border-grey-lighter:focus {
    border-color: #d8dfe3;
  }

  .lg\:focus\:tw-border-grey-lightest:focus {
    border-color: #f8fafc;
  }

  .lg\:focus\:tw-border-grey-grayish:focus {
    border-color: #56666c;
  }

  .lg\:focus\:tw-border-white:focus {
    border-color: #fff;
  }

  .lg\:focus\:tw-border-red-darkest:focus {
    border-color: #3b0d0c;
  }

  .lg\:focus\:tw-border-red-darker:focus {
    border-color: #621b18;
  }

  .lg\:focus\:tw-border-red-dark:focus {
    border-color: #cc1f1a;
  }

  .lg\:focus\:tw-border-red:focus {
    border-color: #de5050;
  }

  .lg\:focus\:tw-border-red-light:focus {
    border-color: #fed5dc;
  }

  .lg\:focus\:tw-border-red-lighter:focus {
    border-color: #f9acaa;
  }

  .lg\:focus\:tw-border-red-lightest:focus {
    border-color: #fcebea;
  }

  .lg\:focus\:tw-border-orange-darkest:focus {
    border-color: #462a16;
  }

  .lg\:focus\:tw-border-orange-darker:focus {
    border-color: #613b1f;
  }

  .lg\:focus\:tw-border-orange-dark:focus {
    border-color: #de751f;
  }

  .lg\:focus\:tw-border-orange:focus {
    border-color: #ffbf00;
  }

  .lg\:focus\:tw-border-orange-light:focus {
    border-color: #faad63;
  }

  .lg\:focus\:tw-border-orange-lighter:focus {
    border-color: #fcd9b6;
  }

  .lg\:focus\:tw-border-orange-lightest:focus {
    border-color: #fff5eb;
  }

  .lg\:focus\:tw-border-yellow-darkest:focus {
    border-color: #453411;
  }

  .lg\:focus\:tw-border-yellow-darker:focus {
    border-color: #684f1d;
  }

  .lg\:focus\:tw-border-yellow-dark:focus {
    border-color: #f2d024;
  }

  .lg\:focus\:tw-border-yellow:focus {
    border-color: #f8e71c;
  }

  .lg\:focus\:tw-border-yellow-light:focus {
    border-color: #fff382;
  }

  .lg\:focus\:tw-border-yellow-lighter:focus {
    border-color: #fff9c2;
  }

  .lg\:focus\:tw-border-yellow-lightest:focus {
    border-color: #fcfbeb;
  }

  .lg\:focus\:tw-border-green-darkest:focus {
    border-color: #0f2f21;
  }

  .lg\:focus\:tw-border-green-darker:focus {
    border-color: #1a4731;
  }

  .lg\:focus\:tw-border-green-dark:focus {
    border-color: #1f9d55;
  }

  .lg\:focus\:tw-border-green:focus {
    border-color: #38c172;
  }

  .lg\:focus\:tw-border-green-light:focus {
    border-color: #51d88a;
  }

  .lg\:focus\:tw-border-green-lighter:focus {
    border-color: #a2f5bf;
  }

  .lg\:focus\:tw-border-green-lightest:focus {
    border-color: #e3fcec;
  }

  .lg\:focus\:tw-border-teal-darkest:focus {
    border-color: #0d3331;
  }

  .lg\:focus\:tw-border-teal-darker:focus {
    border-color: #20504f;
  }

  .lg\:focus\:tw-border-teal-dark:focus {
    border-color: #38a89d;
  }

  .lg\:focus\:tw-border-teal:focus {
    border-color: #17c7aa;
  }

  .lg\:focus\:tw-border-teal-light:focus {
    border-color: #64d5ca;
  }

  .lg\:focus\:tw-border-teal-lighter:focus {
    border-color: #a0f0ed;
  }

  .lg\:focus\:tw-border-teal-lightest:focus {
    border-color: #e8fffe;
  }

  .lg\:focus\:tw-border-blue-darkest:focus {
    border-color: #12283a;
  }

  .lg\:focus\:tw-border-blue-darker:focus {
    border-color: #1c3d5a;
  }

  .lg\:focus\:tw-border-blue-dark:focus {
    border-color: #2779bd;
  }

  .lg\:focus\:tw-border-blue:focus {
    border-color: #2ac1e7;
  }

  .lg\:focus\:tw-border-blue-light:focus {
    border-color: #d4f2fa;
  }

  .lg\:focus\:tw-border-blue-lighter:focus {
    border-color: #bcdefa;
  }

  .lg\:focus\:tw-border-blue-lightest:focus {
    border-color: #eff8ff;
  }

  .lg\:focus\:tw-border-indigo-darkest:focus {
    border-color: #191e38;
  }

  .lg\:focus\:tw-border-indigo-darker:focus {
    border-color: #2f365f;
  }

  .lg\:focus\:tw-border-indigo-dark:focus {
    border-color: #5661b3;
  }

  .lg\:focus\:tw-border-indigo:focus {
    border-color: #6574cd;
  }

  .lg\:focus\:tw-border-indigo-light:focus {
    border-color: #7886d7;
  }

  .lg\:focus\:tw-border-indigo-lighter:focus {
    border-color: #b2b7ff;
  }

  .lg\:focus\:tw-border-indigo-lightest:focus {
    border-color: #e6e8ff;
  }

  .lg\:focus\:tw-border-purple-darkest:focus {
    border-color: #21183c;
  }

  .lg\:focus\:tw-border-purple-darker:focus {
    border-color: #382b5f;
  }

  .lg\:focus\:tw-border-purple-dark:focus {
    border-color: #794acf;
  }

  .lg\:focus\:tw-border-purple:focus {
    border-color: #9561e2;
  }

  .lg\:focus\:tw-border-purple-light:focus {
    border-color: #a779e9;
  }

  .lg\:focus\:tw-border-purple-lighter:focus {
    border-color: #d6bbfc;
  }

  .lg\:focus\:tw-border-purple-lightest:focus {
    border-color: #f3ebff;
  }

  .lg\:focus\:tw-border-pink-darkest:focus {
    border-color: #451225;
  }

  .lg\:focus\:tw-border-pink-darker:focus {
    border-color: #6f213f;
  }

  .lg\:focus\:tw-border-pink-dark:focus {
    border-color: #eb5286;
  }

  .lg\:focus\:tw-border-pink:focus {
    border-color: #f66d9b;
  }

  .lg\:focus\:tw-border-pink-light:focus {
    border-color: #fa7ea8;
  }

  .lg\:focus\:tw-border-pink-lighter:focus {
    border-color: #ffbbca;
  }

  .lg\:focus\:tw-border-pink-lightest:focus {
    border-color: #ffebef;
  }

  .lg\:tw-rounded-none {
    border-radius: 0;
  }

  .lg\:tw-rounded-sm {
    border-radius: .125rem;
  }

  .lg\:tw-rounded {
    border-radius: .25rem;
  }

  .lg\:tw-rounded-lg {
    border-radius: .5rem;
  }

  .lg\:tw-rounded-full {
    border-radius: 9999px;
  }

  .lg\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .lg\:tw-rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg\:tw-rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:tw-rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:tw-rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .lg\:tw-rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg\:tw-rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:tw-rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:tw-rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg\:tw-rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg\:tw-rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:tw-rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .lg\:tw-rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .lg\:tw-rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .lg\:tw-rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .lg\:tw-rounded-tl {
    border-top-left-radius: .25rem;
  }

  .lg\:tw-rounded-tr {
    border-top-right-radius: .25rem;
  }

  .lg\:tw-rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .lg\:tw-rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .lg\:tw-rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .lg\:tw-rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .lg\:tw-rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .lg\:tw-rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .lg\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-border-solid {
    border-style: solid;
  }

  .lg\:tw-border-dashed {
    border-style: dashed;
  }

  .lg\:tw-border-dotted {
    border-style: dotted;
  }

  .lg\:tw-border-none {
    border-style: none;
  }

  .lg\:tw-border-0 {
    border-width: 0;
  }

  .lg\:tw-border-2 {
    border-width: 2px;
  }

  .lg\:tw-border-4 {
    border-width: 4px;
  }

  .lg\:tw-border-8 {
    border-width: 8px;
  }

  .lg\:tw-border {
    border-width: 1px;
  }

  .lg\:tw-border-t-0 {
    border-top-width: 0;
  }

  .lg\:tw-border-r-0 {
    border-right-width: 0;
  }

  .lg\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:tw-border-l-0 {
    border-left-width: 0;
  }

  .lg\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .lg\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .lg\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .lg\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .lg\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .lg\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .lg\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .lg\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .lg\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .lg\:tw-border-t {
    border-top-width: 1px;
  }

  .lg\:tw-border-r {
    border-right-width: 1px;
  }

  .lg\:tw-border-b {
    border-bottom-width: 1px;
  }

  .lg\:tw-border-l {
    border-left-width: 1px;
  }

  .lg\:tw-cursor-auto {
    cursor: auto;
  }

  .lg\:tw-cursor-default {
    cursor: default;
  }

  .lg\:tw-cursor-pointer {
    cursor: pointer;
  }

  .lg\:tw-cursor-wait {
    cursor: wait;
  }

  .lg\:tw-cursor-move {
    cursor: move;
  }

  .lg\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:tw-block {
    display: block;
  }

  .lg\:tw-inline-block {
    display: inline-block;
  }

  .lg\:tw-inline {
    display: inline;
  }

  .lg\:tw-table {
    display: table;
  }

  .lg\:tw-table-row {
    display: table-row;
  }

  .lg\:tw-table-cell {
    display: table-cell;
  }

  .lg\:tw-hidden {
    display: none;
  }

  .lg\:tw-flex {
    display: flex;
  }

  .lg\:tw-inline-flex {
    display: inline-flex;
  }

  .lg\:tw-flex-row {
    flex-direction: row;
  }

  .lg\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:tw-flex-col {
    flex-direction: column;
  }

  .lg\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .lg\:tw-items-start {
    align-items: flex-start;
  }

  .lg\:tw-items-end {
    align-items: flex-end;
  }

  .lg\:tw-items-center {
    align-items: center;
  }

  .lg\:tw-items-baseline {
    align-items: baseline;
  }

  .lg\:tw-items-stretch {
    align-items: stretch;
  }

  .lg\:tw-self-auto {
    align-self: auto;
  }

  .lg\:tw-self-start {
    align-self: flex-start;
  }

  .lg\:tw-self-end {
    align-self: flex-end;
  }

  .lg\:tw-self-center {
    align-self: center;
  }

  .lg\:tw-self-stretch {
    align-self: stretch;
  }

  .lg\:tw-justify-start {
    justify-content: flex-start;
  }

  .lg\:tw-justify-end {
    justify-content: flex-end;
  }

  .lg\:tw-justify-center {
    justify-content: center;
  }

  .lg\:tw-justify-between {
    justify-content: space-between;
  }

  .lg\:tw-justify-around {
    justify-content: space-around;
  }

  .lg\:tw-content-center {
    align-content: center;
  }

  .lg\:tw-content-start {
    align-content: flex-start;
  }

  .lg\:tw-content-end {
    align-content: flex-end;
  }

  .lg\:tw-content-between {
    align-content: space-between;
  }

  .lg\:tw-content-around {
    align-content: space-around;
  }

  .lg\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .lg\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .lg\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .lg\:tw-flex-none {
    flex: none;
  }

  .lg\:tw-flex-grow {
    flex-grow: 1;
  }

  .lg\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .lg\:tw-flex-no-grow {
    flex-grow: 0;
  }

  .lg\:tw-flex-no-shrink {
    flex-shrink: 0;
  }

  .lg\:tw-float-right {
    float: right;
  }

  .lg\:tw-float-left {
    float: left;
  }

  .lg\:tw-float-none {
    float: none;
  }

  .lg\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .lg\:tw-font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .lg\:tw-font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
  }

  .lg\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .lg\:tw-font-hairline {
    font-weight: 100;
  }

  .lg\:tw-font-thin {
    font-weight: 200;
  }

  .lg\:tw-font-light {
    font-weight: 300;
  }

  .lg\:tw-font-normal {
    font-weight: 400;
  }

  .lg\:tw-font-medium {
    font-weight: 500;
  }

  .lg\:tw-font-semibold {
    font-weight: 600;
  }

  .lg\:tw-font-bold {
    font-weight: 700;
  }

  .lg\:tw-font-extrabold {
    font-weight: 800;
  }

  .lg\:tw-font-black {
    font-weight: 900;
  }

  .lg\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .lg\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .lg\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .lg\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .lg\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .lg\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .lg\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .lg\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .lg\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .lg\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .lg\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .lg\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .lg\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .lg\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .lg\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .lg\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .lg\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .lg\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .lg\:tw-h-1 {
    height: .25rem;
  }

  .lg\:tw-h-2 {
    height: .5rem;
  }

  .lg\:tw-h-3 {
    height: .75rem;
  }

  .lg\:tw-h-4 {
    height: 1rem;
  }

  .lg\:tw-h-5 {
    height: 1.25rem;
  }

  .lg\:tw-h-6 {
    height: 1.5rem;
  }

  .lg\:tw-h-8 {
    height: 2rem;
  }

  .lg\:tw-h-10 {
    height: 2.5rem;
  }

  .lg\:tw-h-12 {
    height: 3rem;
  }

  .lg\:tw-h-16 {
    height: 4rem;
  }

  .lg\:tw-h-24 {
    height: 6rem;
  }

  .lg\:tw-h-32 {
    height: 8rem;
  }

  .lg\:tw-h-48 {
    height: 12rem;
  }

  .lg\:tw-h-64 {
    height: 16rem;
  }

  .lg\:tw-h-auto {
    height: auto;
  }

  .lg\:tw-h-px {
    height: 1px;
  }

  .lg\:tw-h-full {
    height: 100%;
  }

  .lg\:tw-h-screen {
    height: 100vh;
  }

  .lg\:tw-leading-none {
    line-height: 1;
  }

  .lg\:tw-leading-tight {
    line-height: 1.25;
  }

  .lg\:tw-leading-normal {
    line-height: 1.5;
  }

  .lg\:tw-leading-loose {
    line-height: 2;
  }

  .lg\:tw-m-0 {
    margin: 0;
  }

  .lg\:tw-m-1 {
    margin: .25rem;
  }

  .lg\:tw-m-2 {
    margin: .5rem;
  }

  .lg\:tw-m-3 {
    margin: .75rem;
  }

  .lg\:tw-m-4 {
    margin: 1rem;
  }

  .lg\:tw-m-5 {
    margin: 1.25rem;
  }

  .lg\:tw-m-6 {
    margin: 1.5rem;
  }

  .lg\:tw-m-8 {
    margin: 2rem;
  }

  .lg\:tw-m-10 {
    margin: 2.5rem;
  }

  .lg\:tw-m-12 {
    margin: 3rem;
  }

  .lg\:tw-m-16 {
    margin: 4rem;
  }

  .lg\:tw-m-20 {
    margin: 5rem;
  }

  .lg\:tw-m-24 {
    margin: 6rem;
  }

  .lg\:tw-m-32 {
    margin: 8rem;
  }

  .lg\:tw-m-auto {
    margin: auto;
  }

  .lg\:tw-m-px {
    margin: 1px;
  }

  .lg\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:tw-my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .lg\:tw-mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .lg\:tw-my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg\:tw-mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:tw-my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:tw-mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:tw-mt-0 {
    margin-top: 0;
  }

  .lg\:tw-mr-0 {
    margin-right: 0;
  }

  .lg\:tw-mb-0 {
    margin-bottom: 0;
  }

  .lg\:tw-ml-0 {
    margin-left: 0;
  }

  .lg\:tw-mt-1 {
    margin-top: .25rem;
  }

  .lg\:tw-mr-1 {
    margin-right: .25rem;
  }

  .lg\:tw-mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:tw-ml-1 {
    margin-left: .25rem;
  }

  .lg\:tw-mt-2 {
    margin-top: .5rem;
  }

  .lg\:tw-mr-2 {
    margin-right: .5rem;
  }

  .lg\:tw-mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:tw-ml-2 {
    margin-left: .5rem;
  }

  .lg\:tw-mt-3 {
    margin-top: .75rem;
  }

  .lg\:tw-mr-3 {
    margin-right: .75rem;
  }

  .lg\:tw-mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:tw-ml-3 {
    margin-left: .75rem;
  }

  .lg\:tw-mt-4 {
    margin-top: 1rem;
  }

  .lg\:tw-mr-4 {
    margin-right: 1rem;
  }

  .lg\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:tw-ml-4 {
    margin-left: 1rem;
  }

  .lg\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:tw-mt-8 {
    margin-top: 2rem;
  }

  .lg\:tw-mr-8 {
    margin-right: 2rem;
  }

  .lg\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:tw-ml-8 {
    margin-left: 2rem;
  }

  .lg\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:tw-mt-12 {
    margin-top: 3rem;
  }

  .lg\:tw-mr-12 {
    margin-right: 3rem;
  }

  .lg\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:tw-ml-12 {
    margin-left: 3rem;
  }

  .lg\:tw-mt-16 {
    margin-top: 4rem;
  }

  .lg\:tw-mr-16 {
    margin-right: 4rem;
  }

  .lg\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:tw-ml-16 {
    margin-left: 4rem;
  }

  .lg\:tw-mt-20 {
    margin-top: 5rem;
  }

  .lg\:tw-mr-20 {
    margin-right: 5rem;
  }

  .lg\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:tw-ml-20 {
    margin-left: 5rem;
  }

  .lg\:tw-mt-24 {
    margin-top: 6rem;
  }

  .lg\:tw-mr-24 {
    margin-right: 6rem;
  }

  .lg\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:tw-ml-24 {
    margin-left: 6rem;
  }

  .lg\:tw-mt-32 {
    margin-top: 8rem;
  }

  .lg\:tw-mr-32 {
    margin-right: 8rem;
  }

  .lg\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:tw-ml-32 {
    margin-left: 8rem;
  }

  .lg\:tw-mt-auto {
    margin-top: auto;
  }

  .lg\:tw-mr-auto {
    margin-right: auto;
  }

  .lg\:tw-mb-auto {
    margin-bottom: auto;
  }

  .lg\:tw-ml-auto {
    margin-left: auto;
  }

  .lg\:tw-mt-px {
    margin-top: 1px;
  }

  .lg\:tw-mr-px {
    margin-right: 1px;
  }

  .lg\:tw-mb-px {
    margin-bottom: 1px;
  }

  .lg\:tw-ml-px {
    margin-left: 1px;
  }

  .lg\:tw-max-h-full {
    max-height: 100%;
  }

  .lg\:tw-max-h-screen {
    max-height: 100vh;
  }

  .lg\:tw-max-w-xs {
    max-width: 20rem;
  }

  .lg\:tw-max-w-sm {
    max-width: 30rem;
  }

  .lg\:tw-max-w-md {
    max-width: 40rem;
  }

  .lg\:tw-max-w-lg {
    max-width: 50rem;
  }

  .lg\:tw-max-w-xl {
    max-width: 60rem;
  }

  .lg\:tw-max-w-2xl {
    max-width: 70rem;
  }

  .lg\:tw-max-w-3xl {
    max-width: 80rem;
  }

  .lg\:tw-max-w-4xl {
    max-width: 90rem;
  }

  .lg\:tw-max-w-5xl {
    max-width: 100rem;
  }

  .lg\:tw-max-w-full {
    max-width: 100%;
  }

  .lg\:tw-min-h-0 {
    min-height: 0;
  }

  .lg\:tw-min-h-full {
    min-height: 100%;
  }

  .lg\:tw-min-h-screen {
    min-height: 100vh;
  }

  .lg\:tw-min-w-0 {
    min-width: 0;
  }

  .lg\:tw-min-w-full {
    min-width: 100%;
  }

  .lg\:tw--m-0 {
    margin: 0;
  }

  .lg\:tw--m-1 {
    margin: -0.25rem;
  }

  .lg\:tw--m-2 {
    margin: -0.5rem;
  }

  .lg\:tw--m-3 {
    margin: -0.75rem;
  }

  .lg\:tw--m-4 {
    margin: -1rem;
  }

  .lg\:tw--m-5 {
    margin: -1.25rem;
  }

  .lg\:tw--m-6 {
    margin: -1.5rem;
  }

  .lg\:tw--m-8 {
    margin: -2rem;
  }

  .lg\:tw--m-10 {
    margin: -2.5rem;
  }

  .lg\:tw--m-12 {
    margin: -3rem;
  }

  .lg\:tw--m-16 {
    margin: -4rem;
  }

  .lg\:tw--m-20 {
    margin: -5rem;
  }

  .lg\:tw--m-24 {
    margin: -6rem;
  }

  .lg\:tw--m-32 {
    margin: -8rem;
  }

  .lg\:tw--m-px {
    margin: -1px;
  }

  .lg\:tw--my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw--mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:tw--mt-0 {
    margin-top: 0;
  }

  .lg\:tw--mr-0 {
    margin-right: 0;
  }

  .lg\:tw--mb-0 {
    margin-bottom: 0;
  }

  .lg\:tw--ml-0 {
    margin-left: 0;
  }

  .lg\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .lg\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .lg\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .lg\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .lg\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .lg\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .lg\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .lg\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .lg\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .lg\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .lg\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .lg\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .lg\:tw--mt-4 {
    margin-top: -1rem;
  }

  .lg\:tw--mr-4 {
    margin-right: -1rem;
  }

  .lg\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:tw--ml-4 {
    margin-left: -1rem;
  }

  .lg\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:tw--mt-8 {
    margin-top: -2rem;
  }

  .lg\:tw--mr-8 {
    margin-right: -2rem;
  }

  .lg\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:tw--ml-8 {
    margin-left: -2rem;
  }

  .lg\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:tw--mt-12 {
    margin-top: -3rem;
  }

  .lg\:tw--mr-12 {
    margin-right: -3rem;
  }

  .lg\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:tw--ml-12 {
    margin-left: -3rem;
  }

  .lg\:tw--mt-16 {
    margin-top: -4rem;
  }

  .lg\:tw--mr-16 {
    margin-right: -4rem;
  }

  .lg\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:tw--ml-16 {
    margin-left: -4rem;
  }

  .lg\:tw--mt-20 {
    margin-top: -5rem;
  }

  .lg\:tw--mr-20 {
    margin-right: -5rem;
  }

  .lg\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:tw--ml-20 {
    margin-left: -5rem;
  }

  .lg\:tw--mt-24 {
    margin-top: -6rem;
  }

  .lg\:tw--mr-24 {
    margin-right: -6rem;
  }

  .lg\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:tw--ml-24 {
    margin-left: -6rem;
  }

  .lg\:tw--mt-32 {
    margin-top: -8rem;
  }

  .lg\:tw--mr-32 {
    margin-right: -8rem;
  }

  .lg\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:tw--ml-32 {
    margin-left: -8rem;
  }

  .lg\:tw--mt-px {
    margin-top: -1px;
  }

  .lg\:tw--mr-px {
    margin-right: -1px;
  }

  .lg\:tw--mb-px {
    margin-bottom: -1px;
  }

  .lg\:tw--ml-px {
    margin-left: -1px;
  }

  .lg\:tw-opacity-0 {
    opacity: 0;
  }

  .lg\:tw-opacity-25 {
    opacity: .25;
  }

  .lg\:tw-opacity-50 {
    opacity: .5;
  }

  .lg\:tw-opacity-75 {
    opacity: .75;
  }

  .lg\:tw-opacity-100 {
    opacity: 1;
  }

  .lg\:tw-overflow-auto {
    overflow: auto;
  }

  .lg\:tw-overflow-hidden {
    overflow: hidden;
  }

  .lg\:tw-overflow-visible {
    overflow: visible;
  }

  .lg\:tw-overflow-scroll {
    overflow: scroll;
  }

  .lg\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .lg\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .lg\:tw-p-0 {
    padding: 0;
  }

  .lg\:tw-p-1 {
    padding: .25rem;
  }

  .lg\:tw-p-2 {
    padding: .5rem;
  }

  .lg\:tw-p-3 {
    padding: .75rem;
  }

  .lg\:tw-p-4 {
    padding: 1rem;
  }

  .lg\:tw-p-5 {
    padding: 1.25rem;
  }

  .lg\:tw-p-6 {
    padding: 1.5rem;
  }

  .lg\:tw-p-8 {
    padding: 2rem;
  }

  .lg\:tw-p-10 {
    padding: 2.5rem;
  }

  .lg\:tw-p-12 {
    padding: 3rem;
  }

  .lg\:tw-p-16 {
    padding: 4rem;
  }

  .lg\:tw-p-20 {
    padding: 5rem;
  }

  .lg\:tw-p-24 {
    padding: 6rem;
  }

  .lg\:tw-p-32 {
    padding: 8rem;
  }

  .lg\:tw-p-px {
    padding: 1px;
  }

  .lg\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:tw-py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:tw-px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:tw-px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:tw-py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:tw-px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:tw-pt-0 {
    padding-top: 0;
  }

  .lg\:tw-pr-0 {
    padding-right: 0;
  }

  .lg\:tw-pb-0 {
    padding-bottom: 0;
  }

  .lg\:tw-pl-0 {
    padding-left: 0;
  }

  .lg\:tw-pt-1 {
    padding-top: .25rem;
  }

  .lg\:tw-pr-1 {
    padding-right: .25rem;
  }

  .lg\:tw-pb-1 {
    padding-bottom: .25rem;
  }

  .lg\:tw-pl-1 {
    padding-left: .25rem;
  }

  .lg\:tw-pt-2 {
    padding-top: .5rem;
  }

  .lg\:tw-pr-2 {
    padding-right: .5rem;
  }

  .lg\:tw-pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:tw-pl-2 {
    padding-left: .5rem;
  }

  .lg\:tw-pt-3 {
    padding-top: .75rem;
  }

  .lg\:tw-pr-3 {
    padding-right: .75rem;
  }

  .lg\:tw-pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:tw-pl-3 {
    padding-left: .75rem;
  }

  .lg\:tw-pt-4 {
    padding-top: 1rem;
  }

  .lg\:tw-pr-4 {
    padding-right: 1rem;
  }

  .lg\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:tw-pl-4 {
    padding-left: 1rem;
  }

  .lg\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:tw-pt-8 {
    padding-top: 2rem;
  }

  .lg\:tw-pr-8 {
    padding-right: 2rem;
  }

  .lg\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:tw-pl-8 {
    padding-left: 2rem;
  }

  .lg\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:tw-pt-12 {
    padding-top: 3rem;
  }

  .lg\:tw-pr-12 {
    padding-right: 3rem;
  }

  .lg\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:tw-pl-12 {
    padding-left: 3rem;
  }

  .lg\:tw-pt-16 {
    padding-top: 4rem;
  }

  .lg\:tw-pr-16 {
    padding-right: 4rem;
  }

  .lg\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:tw-pl-16 {
    padding-left: 4rem;
  }

  .lg\:tw-pt-20 {
    padding-top: 5rem;
  }

  .lg\:tw-pr-20 {
    padding-right: 5rem;
  }

  .lg\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:tw-pl-20 {
    padding-left: 5rem;
  }

  .lg\:tw-pt-24 {
    padding-top: 6rem;
  }

  .lg\:tw-pr-24 {
    padding-right: 6rem;
  }

  .lg\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:tw-pl-24 {
    padding-left: 6rem;
  }

  .lg\:tw-pt-32 {
    padding-top: 8rem;
  }

  .lg\:tw-pr-32 {
    padding-right: 8rem;
  }

  .lg\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:tw-pl-32 {
    padding-left: 8rem;
  }

  .lg\:tw-pt-px {
    padding-top: 1px;
  }

  .lg\:tw-pr-px {
    padding-right: 1px;
  }

  .lg\:tw-pb-px {
    padding-bottom: 1px;
  }

  .lg\:tw-pl-px {
    padding-left: 1px;
  }

  .lg\:tw-pointer-events-none {
    pointer-events: none;
  }

  .lg\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:tw-static {
    position: static;
  }

  .lg\:tw-fixed {
    position: fixed;
  }

  .lg\:tw-absolute {
    position: absolute;
  }

  .lg\:tw-relative {
    position: relative;
  }

  .lg\:tw-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .lg\:tw-pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:tw-pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:tw-pin-y {
    top: 0;
    bottom: 0;
  }

  .lg\:tw-pin-x {
    right: 0;
    left: 0;
  }

  .lg\:tw-pin-t {
    top: 0;
  }

  .lg\:tw-pin-r {
    right: 0;
  }

  .lg\:tw-pin-b {
    bottom: 0;
  }

  .lg\:tw-pin-l {
    left: 0;
  }

  .lg\:tw-resize-none {
    resize: none;
  }

  .lg\:tw-resize-y {
    resize: vertical;
  }

  .lg\:tw-resize-x {
    resize: horizontal;
  }

  .lg\:tw-resize {
    resize: both;
  }

  .lg\:tw-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .lg\:tw-shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .lg\:tw-shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .lg\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .lg\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .lg\:tw-shadow-none {
    box-shadow: none;
  }

  .lg\:hover\:tw-shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .lg\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .lg\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .lg\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .lg\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .lg\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .lg\:focus\:tw-shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .lg\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .lg\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .lg\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .lg\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .lg\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .lg\:tw-table-auto {
    table-layout: auto;
  }

  .lg\:tw-table-fixed {
    table-layout: fixed;
  }

  .lg\:tw-text-left {
    text-align: left;
  }

  .lg\:tw-text-center {
    text-align: center;
  }

  .lg\:tw-text-right {
    text-align: right;
  }

  .lg\:tw-text-justify {
    text-align: justify;
  }

  .lg\:tw-text-transparent {
    color: transparent;
  }

  .lg\:tw-text-primary {
    color: #ac826d;
  }

  .lg\:tw-text-text {
    color: #453f3f;
  }

  .lg\:tw-text-black {
    color: #000;
  }

  .lg\:tw-text-grey-darkest {
    color: #3d4852;
  }

  .lg\:tw-text-grey-darker {
    color: #606f7b;
  }

  .lg\:tw-text-grey-dark {
    color: #8795a1;
  }

  .lg\:tw-text-grey {
    color: #b8c2cc;
  }

  .lg\:tw-text-grey-light {
    color: #a1b3bc;
  }

  .lg\:tw-text-grey-lighter {
    color: #d8dfe3;
  }

  .lg\:tw-text-grey-lightest {
    color: #f8fafc;
  }

  .lg\:tw-text-grey-grayish {
    color: #56666c;
  }

  .lg\:tw-text-white {
    color: #fff;
  }

  .lg\:tw-text-red-darkest {
    color: #3b0d0c;
  }

  .lg\:tw-text-red-darker {
    color: #621b18;
  }

  .lg\:tw-text-red-dark {
    color: #cc1f1a;
  }

  .lg\:tw-text-red {
    color: #de5050;
  }

  .lg\:tw-text-red-light {
    color: #fed5dc;
  }

  .lg\:tw-text-red-lighter {
    color: #f9acaa;
  }

  .lg\:tw-text-red-lightest {
    color: #fcebea;
  }

  .lg\:tw-text-orange-darkest {
    color: #462a16;
  }

  .lg\:tw-text-orange-darker {
    color: #613b1f;
  }

  .lg\:tw-text-orange-dark {
    color: #de751f;
  }

  .lg\:tw-text-orange {
    color: #ffbf00;
  }

  .lg\:tw-text-orange-light {
    color: #faad63;
  }

  .lg\:tw-text-orange-lighter {
    color: #fcd9b6;
  }

  .lg\:tw-text-orange-lightest {
    color: #fff5eb;
  }

  .lg\:tw-text-yellow-darkest {
    color: #453411;
  }

  .lg\:tw-text-yellow-darker {
    color: #684f1d;
  }

  .lg\:tw-text-yellow-dark {
    color: #f2d024;
  }

  .lg\:tw-text-yellow {
    color: #f8e71c;
  }

  .lg\:tw-text-yellow-light {
    color: #fff382;
  }

  .lg\:tw-text-yellow-lighter {
    color: #fff9c2;
  }

  .lg\:tw-text-yellow-lightest {
    color: #fcfbeb;
  }

  .lg\:tw-text-green-darkest {
    color: #0f2f21;
  }

  .lg\:tw-text-green-darker {
    color: #1a4731;
  }

  .lg\:tw-text-green-dark {
    color: #1f9d55;
  }

  .lg\:tw-text-green {
    color: #38c172;
  }

  .lg\:tw-text-green-light {
    color: #51d88a;
  }

  .lg\:tw-text-green-lighter {
    color: #a2f5bf;
  }

  .lg\:tw-text-green-lightest {
    color: #e3fcec;
  }

  .lg\:tw-text-teal-darkest {
    color: #0d3331;
  }

  .lg\:tw-text-teal-darker {
    color: #20504f;
  }

  .lg\:tw-text-teal-dark {
    color: #38a89d;
  }

  .lg\:tw-text-teal {
    color: #17c7aa;
  }

  .lg\:tw-text-teal-light {
    color: #64d5ca;
  }

  .lg\:tw-text-teal-lighter {
    color: #a0f0ed;
  }

  .lg\:tw-text-teal-lightest {
    color: #e8fffe;
  }

  .lg\:tw-text-blue-darkest {
    color: #12283a;
  }

  .lg\:tw-text-blue-darker {
    color: #1c3d5a;
  }

  .lg\:tw-text-blue-dark {
    color: #2779bd;
  }

  .lg\:tw-text-blue {
    color: #2ac1e7;
  }

  .lg\:tw-text-blue-light {
    color: #d4f2fa;
  }

  .lg\:tw-text-blue-lighter {
    color: #bcdefa;
  }

  .lg\:tw-text-blue-lightest {
    color: #eff8ff;
  }

  .lg\:tw-text-indigo-darkest {
    color: #191e38;
  }

  .lg\:tw-text-indigo-darker {
    color: #2f365f;
  }

  .lg\:tw-text-indigo-dark {
    color: #5661b3;
  }

  .lg\:tw-text-indigo {
    color: #6574cd;
  }

  .lg\:tw-text-indigo-light {
    color: #7886d7;
  }

  .lg\:tw-text-indigo-lighter {
    color: #b2b7ff;
  }

  .lg\:tw-text-indigo-lightest {
    color: #e6e8ff;
  }

  .lg\:tw-text-purple-darkest {
    color: #21183c;
  }

  .lg\:tw-text-purple-darker {
    color: #382b5f;
  }

  .lg\:tw-text-purple-dark {
    color: #794acf;
  }

  .lg\:tw-text-purple {
    color: #9561e2;
  }

  .lg\:tw-text-purple-light {
    color: #a779e9;
  }

  .lg\:tw-text-purple-lighter {
    color: #d6bbfc;
  }

  .lg\:tw-text-purple-lightest {
    color: #f3ebff;
  }

  .lg\:tw-text-pink-darkest {
    color: #451225;
  }

  .lg\:tw-text-pink-darker {
    color: #6f213f;
  }

  .lg\:tw-text-pink-dark {
    color: #eb5286;
  }

  .lg\:tw-text-pink {
    color: #f66d9b;
  }

  .lg\:tw-text-pink-light {
    color: #fa7ea8;
  }

  .lg\:tw-text-pink-lighter {
    color: #ffbbca;
  }

  .lg\:tw-text-pink-lightest {
    color: #ffebef;
  }

  .lg\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .lg\:hover\:tw-text-primary:hover {
    color: #ac826d;
  }

  .lg\:hover\:tw-text-text:hover {
    color: #453f3f;
  }

  .lg\:hover\:tw-text-black:hover {
    color: #000;
  }

  .lg\:hover\:tw-text-grey-darkest:hover {
    color: #3d4852;
  }

  .lg\:hover\:tw-text-grey-darker:hover {
    color: #606f7b;
  }

  .lg\:hover\:tw-text-grey-dark:hover {
    color: #8795a1;
  }

  .lg\:hover\:tw-text-grey:hover {
    color: #b8c2cc;
  }

  .lg\:hover\:tw-text-grey-light:hover {
    color: #a1b3bc;
  }

  .lg\:hover\:tw-text-grey-lighter:hover {
    color: #d8dfe3;
  }

  .lg\:hover\:tw-text-grey-lightest:hover {
    color: #f8fafc;
  }

  .lg\:hover\:tw-text-grey-grayish:hover {
    color: #56666c;
  }

  .lg\:hover\:tw-text-white:hover {
    color: #fff;
  }

  .lg\:hover\:tw-text-red-darkest:hover {
    color: #3b0d0c;
  }

  .lg\:hover\:tw-text-red-darker:hover {
    color: #621b18;
  }

  .lg\:hover\:tw-text-red-dark:hover {
    color: #cc1f1a;
  }

  .lg\:hover\:tw-text-red:hover {
    color: #de5050;
  }

  .lg\:hover\:tw-text-red-light:hover {
    color: #fed5dc;
  }

  .lg\:hover\:tw-text-red-lighter:hover {
    color: #f9acaa;
  }

  .lg\:hover\:tw-text-red-lightest:hover {
    color: #fcebea;
  }

  .lg\:hover\:tw-text-orange-darkest:hover {
    color: #462a16;
  }

  .lg\:hover\:tw-text-orange-darker:hover {
    color: #613b1f;
  }

  .lg\:hover\:tw-text-orange-dark:hover {
    color: #de751f;
  }

  .lg\:hover\:tw-text-orange:hover {
    color: #ffbf00;
  }

  .lg\:hover\:tw-text-orange-light:hover {
    color: #faad63;
  }

  .lg\:hover\:tw-text-orange-lighter:hover {
    color: #fcd9b6;
  }

  .lg\:hover\:tw-text-orange-lightest:hover {
    color: #fff5eb;
  }

  .lg\:hover\:tw-text-yellow-darkest:hover {
    color: #453411;
  }

  .lg\:hover\:tw-text-yellow-darker:hover {
    color: #684f1d;
  }

  .lg\:hover\:tw-text-yellow-dark:hover {
    color: #f2d024;
  }

  .lg\:hover\:tw-text-yellow:hover {
    color: #f8e71c;
  }

  .lg\:hover\:tw-text-yellow-light:hover {
    color: #fff382;
  }

  .lg\:hover\:tw-text-yellow-lighter:hover {
    color: #fff9c2;
  }

  .lg\:hover\:tw-text-yellow-lightest:hover {
    color: #fcfbeb;
  }

  .lg\:hover\:tw-text-green-darkest:hover {
    color: #0f2f21;
  }

  .lg\:hover\:tw-text-green-darker:hover {
    color: #1a4731;
  }

  .lg\:hover\:tw-text-green-dark:hover {
    color: #1f9d55;
  }

  .lg\:hover\:tw-text-green:hover {
    color: #38c172;
  }

  .lg\:hover\:tw-text-green-light:hover {
    color: #51d88a;
  }

  .lg\:hover\:tw-text-green-lighter:hover {
    color: #a2f5bf;
  }

  .lg\:hover\:tw-text-green-lightest:hover {
    color: #e3fcec;
  }

  .lg\:hover\:tw-text-teal-darkest:hover {
    color: #0d3331;
  }

  .lg\:hover\:tw-text-teal-darker:hover {
    color: #20504f;
  }

  .lg\:hover\:tw-text-teal-dark:hover {
    color: #38a89d;
  }

  .lg\:hover\:tw-text-teal:hover {
    color: #17c7aa;
  }

  .lg\:hover\:tw-text-teal-light:hover {
    color: #64d5ca;
  }

  .lg\:hover\:tw-text-teal-lighter:hover {
    color: #a0f0ed;
  }

  .lg\:hover\:tw-text-teal-lightest:hover {
    color: #e8fffe;
  }

  .lg\:hover\:tw-text-blue-darkest:hover {
    color: #12283a;
  }

  .lg\:hover\:tw-text-blue-darker:hover {
    color: #1c3d5a;
  }

  .lg\:hover\:tw-text-blue-dark:hover {
    color: #2779bd;
  }

  .lg\:hover\:tw-text-blue:hover {
    color: #2ac1e7;
  }

  .lg\:hover\:tw-text-blue-light:hover {
    color: #d4f2fa;
  }

  .lg\:hover\:tw-text-blue-lighter:hover {
    color: #bcdefa;
  }

  .lg\:hover\:tw-text-blue-lightest:hover {
    color: #eff8ff;
  }

  .lg\:hover\:tw-text-indigo-darkest:hover {
    color: #191e38;
  }

  .lg\:hover\:tw-text-indigo-darker:hover {
    color: #2f365f;
  }

  .lg\:hover\:tw-text-indigo-dark:hover {
    color: #5661b3;
  }

  .lg\:hover\:tw-text-indigo:hover {
    color: #6574cd;
  }

  .lg\:hover\:tw-text-indigo-light:hover {
    color: #7886d7;
  }

  .lg\:hover\:tw-text-indigo-lighter:hover {
    color: #b2b7ff;
  }

  .lg\:hover\:tw-text-indigo-lightest:hover {
    color: #e6e8ff;
  }

  .lg\:hover\:tw-text-purple-darkest:hover {
    color: #21183c;
  }

  .lg\:hover\:tw-text-purple-darker:hover {
    color: #382b5f;
  }

  .lg\:hover\:tw-text-purple-dark:hover {
    color: #794acf;
  }

  .lg\:hover\:tw-text-purple:hover {
    color: #9561e2;
  }

  .lg\:hover\:tw-text-purple-light:hover {
    color: #a779e9;
  }

  .lg\:hover\:tw-text-purple-lighter:hover {
    color: #d6bbfc;
  }

  .lg\:hover\:tw-text-purple-lightest:hover {
    color: #f3ebff;
  }

  .lg\:hover\:tw-text-pink-darkest:hover {
    color: #451225;
  }

  .lg\:hover\:tw-text-pink-darker:hover {
    color: #6f213f;
  }

  .lg\:hover\:tw-text-pink-dark:hover {
    color: #eb5286;
  }

  .lg\:hover\:tw-text-pink:hover {
    color: #f66d9b;
  }

  .lg\:hover\:tw-text-pink-light:hover {
    color: #fa7ea8;
  }

  .lg\:hover\:tw-text-pink-lighter:hover {
    color: #ffbbca;
  }

  .lg\:hover\:tw-text-pink-lightest:hover {
    color: #ffebef;
  }

  .lg\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .lg\:focus\:tw-text-primary:focus {
    color: #ac826d;
  }

  .lg\:focus\:tw-text-text:focus {
    color: #453f3f;
  }

  .lg\:focus\:tw-text-black:focus {
    color: #000;
  }

  .lg\:focus\:tw-text-grey-darkest:focus {
    color: #3d4852;
  }

  .lg\:focus\:tw-text-grey-darker:focus {
    color: #606f7b;
  }

  .lg\:focus\:tw-text-grey-dark:focus {
    color: #8795a1;
  }

  .lg\:focus\:tw-text-grey:focus {
    color: #b8c2cc;
  }

  .lg\:focus\:tw-text-grey-light:focus {
    color: #a1b3bc;
  }

  .lg\:focus\:tw-text-grey-lighter:focus {
    color: #d8dfe3;
  }

  .lg\:focus\:tw-text-grey-lightest:focus {
    color: #f8fafc;
  }

  .lg\:focus\:tw-text-grey-grayish:focus {
    color: #56666c;
  }

  .lg\:focus\:tw-text-white:focus {
    color: #fff;
  }

  .lg\:focus\:tw-text-red-darkest:focus {
    color: #3b0d0c;
  }

  .lg\:focus\:tw-text-red-darker:focus {
    color: #621b18;
  }

  .lg\:focus\:tw-text-red-dark:focus {
    color: #cc1f1a;
  }

  .lg\:focus\:tw-text-red:focus {
    color: #de5050;
  }

  .lg\:focus\:tw-text-red-light:focus {
    color: #fed5dc;
  }

  .lg\:focus\:tw-text-red-lighter:focus {
    color: #f9acaa;
  }

  .lg\:focus\:tw-text-red-lightest:focus {
    color: #fcebea;
  }

  .lg\:focus\:tw-text-orange-darkest:focus {
    color: #462a16;
  }

  .lg\:focus\:tw-text-orange-darker:focus {
    color: #613b1f;
  }

  .lg\:focus\:tw-text-orange-dark:focus {
    color: #de751f;
  }

  .lg\:focus\:tw-text-orange:focus {
    color: #ffbf00;
  }

  .lg\:focus\:tw-text-orange-light:focus {
    color: #faad63;
  }

  .lg\:focus\:tw-text-orange-lighter:focus {
    color: #fcd9b6;
  }

  .lg\:focus\:tw-text-orange-lightest:focus {
    color: #fff5eb;
  }

  .lg\:focus\:tw-text-yellow-darkest:focus {
    color: #453411;
  }

  .lg\:focus\:tw-text-yellow-darker:focus {
    color: #684f1d;
  }

  .lg\:focus\:tw-text-yellow-dark:focus {
    color: #f2d024;
  }

  .lg\:focus\:tw-text-yellow:focus {
    color: #f8e71c;
  }

  .lg\:focus\:tw-text-yellow-light:focus {
    color: #fff382;
  }

  .lg\:focus\:tw-text-yellow-lighter:focus {
    color: #fff9c2;
  }

  .lg\:focus\:tw-text-yellow-lightest:focus {
    color: #fcfbeb;
  }

  .lg\:focus\:tw-text-green-darkest:focus {
    color: #0f2f21;
  }

  .lg\:focus\:tw-text-green-darker:focus {
    color: #1a4731;
  }

  .lg\:focus\:tw-text-green-dark:focus {
    color: #1f9d55;
  }

  .lg\:focus\:tw-text-green:focus {
    color: #38c172;
  }

  .lg\:focus\:tw-text-green-light:focus {
    color: #51d88a;
  }

  .lg\:focus\:tw-text-green-lighter:focus {
    color: #a2f5bf;
  }

  .lg\:focus\:tw-text-green-lightest:focus {
    color: #e3fcec;
  }

  .lg\:focus\:tw-text-teal-darkest:focus {
    color: #0d3331;
  }

  .lg\:focus\:tw-text-teal-darker:focus {
    color: #20504f;
  }

  .lg\:focus\:tw-text-teal-dark:focus {
    color: #38a89d;
  }

  .lg\:focus\:tw-text-teal:focus {
    color: #17c7aa;
  }

  .lg\:focus\:tw-text-teal-light:focus {
    color: #64d5ca;
  }

  .lg\:focus\:tw-text-teal-lighter:focus {
    color: #a0f0ed;
  }

  .lg\:focus\:tw-text-teal-lightest:focus {
    color: #e8fffe;
  }

  .lg\:focus\:tw-text-blue-darkest:focus {
    color: #12283a;
  }

  .lg\:focus\:tw-text-blue-darker:focus {
    color: #1c3d5a;
  }

  .lg\:focus\:tw-text-blue-dark:focus {
    color: #2779bd;
  }

  .lg\:focus\:tw-text-blue:focus {
    color: #2ac1e7;
  }

  .lg\:focus\:tw-text-blue-light:focus {
    color: #d4f2fa;
  }

  .lg\:focus\:tw-text-blue-lighter:focus {
    color: #bcdefa;
  }

  .lg\:focus\:tw-text-blue-lightest:focus {
    color: #eff8ff;
  }

  .lg\:focus\:tw-text-indigo-darkest:focus {
    color: #191e38;
  }

  .lg\:focus\:tw-text-indigo-darker:focus {
    color: #2f365f;
  }

  .lg\:focus\:tw-text-indigo-dark:focus {
    color: #5661b3;
  }

  .lg\:focus\:tw-text-indigo:focus {
    color: #6574cd;
  }

  .lg\:focus\:tw-text-indigo-light:focus {
    color: #7886d7;
  }

  .lg\:focus\:tw-text-indigo-lighter:focus {
    color: #b2b7ff;
  }

  .lg\:focus\:tw-text-indigo-lightest:focus {
    color: #e6e8ff;
  }

  .lg\:focus\:tw-text-purple-darkest:focus {
    color: #21183c;
  }

  .lg\:focus\:tw-text-purple-darker:focus {
    color: #382b5f;
  }

  .lg\:focus\:tw-text-purple-dark:focus {
    color: #794acf;
  }

  .lg\:focus\:tw-text-purple:focus {
    color: #9561e2;
  }

  .lg\:focus\:tw-text-purple-light:focus {
    color: #a779e9;
  }

  .lg\:focus\:tw-text-purple-lighter:focus {
    color: #d6bbfc;
  }

  .lg\:focus\:tw-text-purple-lightest:focus {
    color: #f3ebff;
  }

  .lg\:focus\:tw-text-pink-darkest:focus {
    color: #451225;
  }

  .lg\:focus\:tw-text-pink-darker:focus {
    color: #6f213f;
  }

  .lg\:focus\:tw-text-pink-dark:focus {
    color: #eb5286;
  }

  .lg\:focus\:tw-text-pink:focus {
    color: #f66d9b;
  }

  .lg\:focus\:tw-text-pink-light:focus {
    color: #fa7ea8;
  }

  .lg\:focus\:tw-text-pink-lighter:focus {
    color: #ffbbca;
  }

  .lg\:focus\:tw-text-pink-lightest:focus {
    color: #ffebef;
  }

  .lg\:tw-text-xsss {
    font-size: 8px;
  }

  .lg\:tw-text-xss {
    font-size: 10px;
  }

  .lg\:tw-text-xs {
    font-size: .75rem;
  }

  .lg\:tw-text-sm {
    font-size: .875rem;
  }

  .lg\:tw-text-base {
    font-size: 1rem;
  }

  .lg\:tw-text-lg {
    font-size: 1.125rem;
  }

  .lg\:tw-text-xl {
    font-size: 1.25rem;
  }

  .lg\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .lg\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .lg\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .lg\:tw-text-5xl {
    font-size: 3rem;
  }

  .lg\:tw-italic {
    font-style: italic;
  }

  .lg\:tw-roman {
    font-style: normal;
  }

  .lg\:tw-uppercase {
    text-transform: uppercase;
  }

  .lg\:tw-lowercase {
    text-transform: lowercase;
  }

  .lg\:tw-capitalize {
    text-transform: capitalize;
  }

  .lg\:tw-normal-case {
    text-transform: none;
  }

  .lg\:tw-underline {
    text-decoration: underline;
  }

  .lg\:tw-line-through {
    text-decoration: line-through;
  }

  .lg\:tw-no-underline {
    text-decoration: none;
  }

  .lg\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:hover\:tw-italic:hover {
    font-style: italic;
  }

  .lg\:hover\:tw-roman:hover {
    font-style: normal;
  }

  .lg\:hover\:tw-uppercase:hover {
    text-transform: uppercase;
  }

  .lg\:hover\:tw-lowercase:hover {
    text-transform: lowercase;
  }

  .lg\:hover\:tw-capitalize:hover {
    text-transform: capitalize;
  }

  .lg\:hover\:tw-normal-case:hover {
    text-transform: none;
  }

  .lg\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .lg\:hover\:tw-antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:hover\:tw-subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:focus\:tw-italic:focus {
    font-style: italic;
  }

  .lg\:focus\:tw-roman:focus {
    font-style: normal;
  }

  .lg\:focus\:tw-uppercase:focus {
    text-transform: uppercase;
  }

  .lg\:focus\:tw-lowercase:focus {
    text-transform: lowercase;
  }

  .lg\:focus\:tw-capitalize:focus {
    text-transform: capitalize;
  }

  .lg\:focus\:tw-normal-case:focus {
    text-transform: none;
  }

  .lg\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .lg\:focus\:tw-antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:focus\:tw-subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:tw-tracking-tight {
    letter-spacing: -0.05em;
  }

  .lg\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tw-tracking-wide {
    letter-spacing: .05em;
  }

  .lg\:tw-select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .lg\:tw-select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .lg\:tw-align-baseline {
    vertical-align: baseline;
  }

  .lg\:tw-align-top {
    vertical-align: top;
  }

  .lg\:tw-align-middle {
    vertical-align: middle;
  }

  .lg\:tw-align-bottom {
    vertical-align: bottom;
  }

  .lg\:tw-align-text-top {
    vertical-align: text-top;
  }

  .lg\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:tw-visible {
    visibility: visible;
  }

  .lg\:tw-invisible {
    visibility: hidden;
  }

  .lg\:tw-whitespace-normal {
    white-space: normal;
  }

  .lg\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .lg\:tw-whitespace-pre {
    white-space: pre;
  }

  .lg\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:tw-break-words {
    word-wrap: break-word;
  }

  .lg\:tw-break-normal {
    word-wrap: normal;
  }

  .lg\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:tw-w-1 {
    width: .25rem;
  }

  .lg\:tw-w-2 {
    width: .5rem;
  }

  .lg\:tw-w-3 {
    width: .75rem;
  }

  .lg\:tw-w-4 {
    width: 1rem;
  }

  .lg\:tw-w-5 {
    width: 1.25rem;
  }

  .lg\:tw-w-6 {
    width: 1.5rem;
  }

  .lg\:tw-w-8 {
    width: 2rem;
  }

  .lg\:tw-w-10 {
    width: 2.5rem;
  }

  .lg\:tw-w-12 {
    width: 3rem;
  }

  .lg\:tw-w-16 {
    width: 4rem;
  }

  .lg\:tw-w-24 {
    width: 6rem;
  }

  .lg\:tw-w-32 {
    width: 8rem;
  }

  .lg\:tw-w-48 {
    width: 12rem;
  }

  .lg\:tw-w-64 {
    width: 16rem;
  }

  .lg\:tw-w-auto {
    width: auto;
  }

  .lg\:tw-w-px {
    width: 1px;
  }

  .lg\:tw-w-1\/2 {
    width: 50%;
  }

  .lg\:tw-w-1\/3 {
    width: 33.33333%;
  }

  .lg\:tw-w-2\/3 {
    width: 66.66667%;
  }

  .lg\:tw-w-1\/4 {
    width: 25%;
  }

  .lg\:tw-w-3\/4 {
    width: 75%;
  }

  .lg\:tw-w-1\/5 {
    width: 20%;
  }

  .lg\:tw-w-2\/5 {
    width: 40%;
  }

  .lg\:tw-w-3\/5 {
    width: 60%;
  }

  .lg\:tw-w-4\/5 {
    width: 80%;
  }

  .lg\:tw-w-1\/6 {
    width: 16.66667%;
  }

  .lg\:tw-w-5\/6 {
    width: 83.33333%;
  }

  .lg\:tw-w-full {
    width: 100%;
  }

  .lg\:tw-w-screen {
    width: 100vw;
  }

  .lg\:tw-z-0 {
    z-index: 0;
  }

  .lg\:tw-z-10 {
    z-index: 10;
  }

  .lg\:tw-z-20 {
    z-index: 20;
  }

  .lg\:tw-z-30 {
    z-index: 30;
  }

  .lg\:tw-z-40 {
    z-index: 40;
  }

  .lg\:tw-z-50 {
    z-index: 50;
  }

  .lg\:tw-z-auto {
    z-index: auto;
  }
}

@media (min-width: 1200px) {
  .xl\:tw-list-reset {
    list-style: none;
    padding: 0;
  }

  .xl\:tw-appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .xl\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .xl\:tw-bg-local {
    background-attachment: local;
  }

  .xl\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .xl\:tw-bg-transparent {
    background-color: transparent;
  }

  .xl\:tw-bg-primary {
    background-color: #ac826d;
  }

  .xl\:tw-bg-text {
    background-color: #453f3f;
  }

  .xl\:tw-bg-black {
    background-color: #000;
  }

  .xl\:tw-bg-grey-darkest {
    background-color: #3d4852;
  }

  .xl\:tw-bg-grey-darker {
    background-color: #606f7b;
  }

  .xl\:tw-bg-grey-dark {
    background-color: #8795a1;
  }

  .xl\:tw-bg-grey {
    background-color: #b8c2cc;
  }

  .xl\:tw-bg-grey-light {
    background-color: #a1b3bc;
  }

  .xl\:tw-bg-grey-lighter {
    background-color: #d8dfe3;
  }

  .xl\:tw-bg-grey-lightest {
    background-color: #f8fafc;
  }

  .xl\:tw-bg-grey-grayish {
    background-color: #56666c;
  }

  .xl\:tw-bg-white {
    background-color: #fff;
  }

  .xl\:tw-bg-red-darkest {
    background-color: #3b0d0c;
  }

  .xl\:tw-bg-red-darker {
    background-color: #621b18;
  }

  .xl\:tw-bg-red-dark {
    background-color: #cc1f1a;
  }

  .xl\:tw-bg-red {
    background-color: #de5050;
  }

  .xl\:tw-bg-red-light {
    background-color: #fed5dc;
  }

  .xl\:tw-bg-red-lighter {
    background-color: #f9acaa;
  }

  .xl\:tw-bg-red-lightest {
    background-color: #fcebea;
  }

  .xl\:tw-bg-orange-darkest {
    background-color: #462a16;
  }

  .xl\:tw-bg-orange-darker {
    background-color: #613b1f;
  }

  .xl\:tw-bg-orange-dark {
    background-color: #de751f;
  }

  .xl\:tw-bg-orange {
    background-color: #ffbf00;
  }

  .xl\:tw-bg-orange-light {
    background-color: #faad63;
  }

  .xl\:tw-bg-orange-lighter {
    background-color: #fcd9b6;
  }

  .xl\:tw-bg-orange-lightest {
    background-color: #fff5eb;
  }

  .xl\:tw-bg-yellow-darkest {
    background-color: #453411;
  }

  .xl\:tw-bg-yellow-darker {
    background-color: #684f1d;
  }

  .xl\:tw-bg-yellow-dark {
    background-color: #f2d024;
  }

  .xl\:tw-bg-yellow {
    background-color: #f8e71c;
  }

  .xl\:tw-bg-yellow-light {
    background-color: #fff382;
  }

  .xl\:tw-bg-yellow-lighter {
    background-color: #fff9c2;
  }

  .xl\:tw-bg-yellow-lightest {
    background-color: #fcfbeb;
  }

  .xl\:tw-bg-green-darkest {
    background-color: #0f2f21;
  }

  .xl\:tw-bg-green-darker {
    background-color: #1a4731;
  }

  .xl\:tw-bg-green-dark {
    background-color: #1f9d55;
  }

  .xl\:tw-bg-green {
    background-color: #38c172;
  }

  .xl\:tw-bg-green-light {
    background-color: #51d88a;
  }

  .xl\:tw-bg-green-lighter {
    background-color: #a2f5bf;
  }

  .xl\:tw-bg-green-lightest {
    background-color: #e3fcec;
  }

  .xl\:tw-bg-teal-darkest {
    background-color: #0d3331;
  }

  .xl\:tw-bg-teal-darker {
    background-color: #20504f;
  }

  .xl\:tw-bg-teal-dark {
    background-color: #38a89d;
  }

  .xl\:tw-bg-teal {
    background-color: #17c7aa;
  }

  .xl\:tw-bg-teal-light {
    background-color: #64d5ca;
  }

  .xl\:tw-bg-teal-lighter {
    background-color: #a0f0ed;
  }

  .xl\:tw-bg-teal-lightest {
    background-color: #e8fffe;
  }

  .xl\:tw-bg-blue-darkest {
    background-color: #12283a;
  }

  .xl\:tw-bg-blue-darker {
    background-color: #1c3d5a;
  }

  .xl\:tw-bg-blue-dark {
    background-color: #2779bd;
  }

  .xl\:tw-bg-blue {
    background-color: #2ac1e7;
  }

  .xl\:tw-bg-blue-light {
    background-color: #d4f2fa;
  }

  .xl\:tw-bg-blue-lighter {
    background-color: #bcdefa;
  }

  .xl\:tw-bg-blue-lightest {
    background-color: #eff8ff;
  }

  .xl\:tw-bg-indigo-darkest {
    background-color: #191e38;
  }

  .xl\:tw-bg-indigo-darker {
    background-color: #2f365f;
  }

  .xl\:tw-bg-indigo-dark {
    background-color: #5661b3;
  }

  .xl\:tw-bg-indigo {
    background-color: #6574cd;
  }

  .xl\:tw-bg-indigo-light {
    background-color: #7886d7;
  }

  .xl\:tw-bg-indigo-lighter {
    background-color: #b2b7ff;
  }

  .xl\:tw-bg-indigo-lightest {
    background-color: #e6e8ff;
  }

  .xl\:tw-bg-purple-darkest {
    background-color: #21183c;
  }

  .xl\:tw-bg-purple-darker {
    background-color: #382b5f;
  }

  .xl\:tw-bg-purple-dark {
    background-color: #794acf;
  }

  .xl\:tw-bg-purple {
    background-color: #9561e2;
  }

  .xl\:tw-bg-purple-light {
    background-color: #a779e9;
  }

  .xl\:tw-bg-purple-lighter {
    background-color: #d6bbfc;
  }

  .xl\:tw-bg-purple-lightest {
    background-color: #f3ebff;
  }

  .xl\:tw-bg-pink-darkest {
    background-color: #451225;
  }

  .xl\:tw-bg-pink-darker {
    background-color: #6f213f;
  }

  .xl\:tw-bg-pink-dark {
    background-color: #eb5286;
  }

  .xl\:tw-bg-pink {
    background-color: #f66d9b;
  }

  .xl\:tw-bg-pink-light {
    background-color: #fa7ea8;
  }

  .xl\:tw-bg-pink-lighter {
    background-color: #ffbbca;
  }

  .xl\:tw-bg-pink-lightest {
    background-color: #ffebef;
  }

  .xl\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .xl\:hover\:tw-bg-primary:hover {
    background-color: #ac826d;
  }

  .xl\:hover\:tw-bg-text:hover {
    background-color: #453f3f;
  }

  .xl\:hover\:tw-bg-black:hover {
    background-color: #000;
  }

  .xl\:hover\:tw-bg-grey-darkest:hover {
    background-color: #3d4852;
  }

  .xl\:hover\:tw-bg-grey-darker:hover {
    background-color: #606f7b;
  }

  .xl\:hover\:tw-bg-grey-dark:hover {
    background-color: #8795a1;
  }

  .xl\:hover\:tw-bg-grey:hover {
    background-color: #b8c2cc;
  }

  .xl\:hover\:tw-bg-grey-light:hover {
    background-color: #a1b3bc;
  }

  .xl\:hover\:tw-bg-grey-lighter:hover {
    background-color: #d8dfe3;
  }

  .xl\:hover\:tw-bg-grey-lightest:hover {
    background-color: #f8fafc;
  }

  .xl\:hover\:tw-bg-grey-grayish:hover {
    background-color: #56666c;
  }

  .xl\:hover\:tw-bg-white:hover {
    background-color: #fff;
  }

  .xl\:hover\:tw-bg-red-darkest:hover {
    background-color: #3b0d0c;
  }

  .xl\:hover\:tw-bg-red-darker:hover {
    background-color: #621b18;
  }

  .xl\:hover\:tw-bg-red-dark:hover {
    background-color: #cc1f1a;
  }

  .xl\:hover\:tw-bg-red:hover {
    background-color: #de5050;
  }

  .xl\:hover\:tw-bg-red-light:hover {
    background-color: #fed5dc;
  }

  .xl\:hover\:tw-bg-red-lighter:hover {
    background-color: #f9acaa;
  }

  .xl\:hover\:tw-bg-red-lightest:hover {
    background-color: #fcebea;
  }

  .xl\:hover\:tw-bg-orange-darkest:hover {
    background-color: #462a16;
  }

  .xl\:hover\:tw-bg-orange-darker:hover {
    background-color: #613b1f;
  }

  .xl\:hover\:tw-bg-orange-dark:hover {
    background-color: #de751f;
  }

  .xl\:hover\:tw-bg-orange:hover {
    background-color: #ffbf00;
  }

  .xl\:hover\:tw-bg-orange-light:hover {
    background-color: #faad63;
  }

  .xl\:hover\:tw-bg-orange-lighter:hover {
    background-color: #fcd9b6;
  }

  .xl\:hover\:tw-bg-orange-lightest:hover {
    background-color: #fff5eb;
  }

  .xl\:hover\:tw-bg-yellow-darkest:hover {
    background-color: #453411;
  }

  .xl\:hover\:tw-bg-yellow-darker:hover {
    background-color: #684f1d;
  }

  .xl\:hover\:tw-bg-yellow-dark:hover {
    background-color: #f2d024;
  }

  .xl\:hover\:tw-bg-yellow:hover {
    background-color: #f8e71c;
  }

  .xl\:hover\:tw-bg-yellow-light:hover {
    background-color: #fff382;
  }

  .xl\:hover\:tw-bg-yellow-lighter:hover {
    background-color: #fff9c2;
  }

  .xl\:hover\:tw-bg-yellow-lightest:hover {
    background-color: #fcfbeb;
  }

  .xl\:hover\:tw-bg-green-darkest:hover {
    background-color: #0f2f21;
  }

  .xl\:hover\:tw-bg-green-darker:hover {
    background-color: #1a4731;
  }

  .xl\:hover\:tw-bg-green-dark:hover {
    background-color: #1f9d55;
  }

  .xl\:hover\:tw-bg-green:hover {
    background-color: #38c172;
  }

  .xl\:hover\:tw-bg-green-light:hover {
    background-color: #51d88a;
  }

  .xl\:hover\:tw-bg-green-lighter:hover {
    background-color: #a2f5bf;
  }

  .xl\:hover\:tw-bg-green-lightest:hover {
    background-color: #e3fcec;
  }

  .xl\:hover\:tw-bg-teal-darkest:hover {
    background-color: #0d3331;
  }

  .xl\:hover\:tw-bg-teal-darker:hover {
    background-color: #20504f;
  }

  .xl\:hover\:tw-bg-teal-dark:hover {
    background-color: #38a89d;
  }

  .xl\:hover\:tw-bg-teal:hover {
    background-color: #17c7aa;
  }

  .xl\:hover\:tw-bg-teal-light:hover {
    background-color: #64d5ca;
  }

  .xl\:hover\:tw-bg-teal-lighter:hover {
    background-color: #a0f0ed;
  }

  .xl\:hover\:tw-bg-teal-lightest:hover {
    background-color: #e8fffe;
  }

  .xl\:hover\:tw-bg-blue-darkest:hover {
    background-color: #12283a;
  }

  .xl\:hover\:tw-bg-blue-darker:hover {
    background-color: #1c3d5a;
  }

  .xl\:hover\:tw-bg-blue-dark:hover {
    background-color: #2779bd;
  }

  .xl\:hover\:tw-bg-blue:hover {
    background-color: #2ac1e7;
  }

  .xl\:hover\:tw-bg-blue-light:hover {
    background-color: #d4f2fa;
  }

  .xl\:hover\:tw-bg-blue-lighter:hover {
    background-color: #bcdefa;
  }

  .xl\:hover\:tw-bg-blue-lightest:hover {
    background-color: #eff8ff;
  }

  .xl\:hover\:tw-bg-indigo-darkest:hover {
    background-color: #191e38;
  }

  .xl\:hover\:tw-bg-indigo-darker:hover {
    background-color: #2f365f;
  }

  .xl\:hover\:tw-bg-indigo-dark:hover {
    background-color: #5661b3;
  }

  .xl\:hover\:tw-bg-indigo:hover {
    background-color: #6574cd;
  }

  .xl\:hover\:tw-bg-indigo-light:hover {
    background-color: #7886d7;
  }

  .xl\:hover\:tw-bg-indigo-lighter:hover {
    background-color: #b2b7ff;
  }

  .xl\:hover\:tw-bg-indigo-lightest:hover {
    background-color: #e6e8ff;
  }

  .xl\:hover\:tw-bg-purple-darkest:hover {
    background-color: #21183c;
  }

  .xl\:hover\:tw-bg-purple-darker:hover {
    background-color: #382b5f;
  }

  .xl\:hover\:tw-bg-purple-dark:hover {
    background-color: #794acf;
  }

  .xl\:hover\:tw-bg-purple:hover {
    background-color: #9561e2;
  }

  .xl\:hover\:tw-bg-purple-light:hover {
    background-color: #a779e9;
  }

  .xl\:hover\:tw-bg-purple-lighter:hover {
    background-color: #d6bbfc;
  }

  .xl\:hover\:tw-bg-purple-lightest:hover {
    background-color: #f3ebff;
  }

  .xl\:hover\:tw-bg-pink-darkest:hover {
    background-color: #451225;
  }

  .xl\:hover\:tw-bg-pink-darker:hover {
    background-color: #6f213f;
  }

  .xl\:hover\:tw-bg-pink-dark:hover {
    background-color: #eb5286;
  }

  .xl\:hover\:tw-bg-pink:hover {
    background-color: #f66d9b;
  }

  .xl\:hover\:tw-bg-pink-light:hover {
    background-color: #fa7ea8;
  }

  .xl\:hover\:tw-bg-pink-lighter:hover {
    background-color: #ffbbca;
  }

  .xl\:hover\:tw-bg-pink-lightest:hover {
    background-color: #ffebef;
  }

  .xl\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .xl\:focus\:tw-bg-primary:focus {
    background-color: #ac826d;
  }

  .xl\:focus\:tw-bg-text:focus {
    background-color: #453f3f;
  }

  .xl\:focus\:tw-bg-black:focus {
    background-color: #000;
  }

  .xl\:focus\:tw-bg-grey-darkest:focus {
    background-color: #3d4852;
  }

  .xl\:focus\:tw-bg-grey-darker:focus {
    background-color: #606f7b;
  }

  .xl\:focus\:tw-bg-grey-dark:focus {
    background-color: #8795a1;
  }

  .xl\:focus\:tw-bg-grey:focus {
    background-color: #b8c2cc;
  }

  .xl\:focus\:tw-bg-grey-light:focus {
    background-color: #a1b3bc;
  }

  .xl\:focus\:tw-bg-grey-lighter:focus {
    background-color: #d8dfe3;
  }

  .xl\:focus\:tw-bg-grey-lightest:focus {
    background-color: #f8fafc;
  }

  .xl\:focus\:tw-bg-grey-grayish:focus {
    background-color: #56666c;
  }

  .xl\:focus\:tw-bg-white:focus {
    background-color: #fff;
  }

  .xl\:focus\:tw-bg-red-darkest:focus {
    background-color: #3b0d0c;
  }

  .xl\:focus\:tw-bg-red-darker:focus {
    background-color: #621b18;
  }

  .xl\:focus\:tw-bg-red-dark:focus {
    background-color: #cc1f1a;
  }

  .xl\:focus\:tw-bg-red:focus {
    background-color: #de5050;
  }

  .xl\:focus\:tw-bg-red-light:focus {
    background-color: #fed5dc;
  }

  .xl\:focus\:tw-bg-red-lighter:focus {
    background-color: #f9acaa;
  }

  .xl\:focus\:tw-bg-red-lightest:focus {
    background-color: #fcebea;
  }

  .xl\:focus\:tw-bg-orange-darkest:focus {
    background-color: #462a16;
  }

  .xl\:focus\:tw-bg-orange-darker:focus {
    background-color: #613b1f;
  }

  .xl\:focus\:tw-bg-orange-dark:focus {
    background-color: #de751f;
  }

  .xl\:focus\:tw-bg-orange:focus {
    background-color: #ffbf00;
  }

  .xl\:focus\:tw-bg-orange-light:focus {
    background-color: #faad63;
  }

  .xl\:focus\:tw-bg-orange-lighter:focus {
    background-color: #fcd9b6;
  }

  .xl\:focus\:tw-bg-orange-lightest:focus {
    background-color: #fff5eb;
  }

  .xl\:focus\:tw-bg-yellow-darkest:focus {
    background-color: #453411;
  }

  .xl\:focus\:tw-bg-yellow-darker:focus {
    background-color: #684f1d;
  }

  .xl\:focus\:tw-bg-yellow-dark:focus {
    background-color: #f2d024;
  }

  .xl\:focus\:tw-bg-yellow:focus {
    background-color: #f8e71c;
  }

  .xl\:focus\:tw-bg-yellow-light:focus {
    background-color: #fff382;
  }

  .xl\:focus\:tw-bg-yellow-lighter:focus {
    background-color: #fff9c2;
  }

  .xl\:focus\:tw-bg-yellow-lightest:focus {
    background-color: #fcfbeb;
  }

  .xl\:focus\:tw-bg-green-darkest:focus {
    background-color: #0f2f21;
  }

  .xl\:focus\:tw-bg-green-darker:focus {
    background-color: #1a4731;
  }

  .xl\:focus\:tw-bg-green-dark:focus {
    background-color: #1f9d55;
  }

  .xl\:focus\:tw-bg-green:focus {
    background-color: #38c172;
  }

  .xl\:focus\:tw-bg-green-light:focus {
    background-color: #51d88a;
  }

  .xl\:focus\:tw-bg-green-lighter:focus {
    background-color: #a2f5bf;
  }

  .xl\:focus\:tw-bg-green-lightest:focus {
    background-color: #e3fcec;
  }

  .xl\:focus\:tw-bg-teal-darkest:focus {
    background-color: #0d3331;
  }

  .xl\:focus\:tw-bg-teal-darker:focus {
    background-color: #20504f;
  }

  .xl\:focus\:tw-bg-teal-dark:focus {
    background-color: #38a89d;
  }

  .xl\:focus\:tw-bg-teal:focus {
    background-color: #17c7aa;
  }

  .xl\:focus\:tw-bg-teal-light:focus {
    background-color: #64d5ca;
  }

  .xl\:focus\:tw-bg-teal-lighter:focus {
    background-color: #a0f0ed;
  }

  .xl\:focus\:tw-bg-teal-lightest:focus {
    background-color: #e8fffe;
  }

  .xl\:focus\:tw-bg-blue-darkest:focus {
    background-color: #12283a;
  }

  .xl\:focus\:tw-bg-blue-darker:focus {
    background-color: #1c3d5a;
  }

  .xl\:focus\:tw-bg-blue-dark:focus {
    background-color: #2779bd;
  }

  .xl\:focus\:tw-bg-blue:focus {
    background-color: #2ac1e7;
  }

  .xl\:focus\:tw-bg-blue-light:focus {
    background-color: #d4f2fa;
  }

  .xl\:focus\:tw-bg-blue-lighter:focus {
    background-color: #bcdefa;
  }

  .xl\:focus\:tw-bg-blue-lightest:focus {
    background-color: #eff8ff;
  }

  .xl\:focus\:tw-bg-indigo-darkest:focus {
    background-color: #191e38;
  }

  .xl\:focus\:tw-bg-indigo-darker:focus {
    background-color: #2f365f;
  }

  .xl\:focus\:tw-bg-indigo-dark:focus {
    background-color: #5661b3;
  }

  .xl\:focus\:tw-bg-indigo:focus {
    background-color: #6574cd;
  }

  .xl\:focus\:tw-bg-indigo-light:focus {
    background-color: #7886d7;
  }

  .xl\:focus\:tw-bg-indigo-lighter:focus {
    background-color: #b2b7ff;
  }

  .xl\:focus\:tw-bg-indigo-lightest:focus {
    background-color: #e6e8ff;
  }

  .xl\:focus\:tw-bg-purple-darkest:focus {
    background-color: #21183c;
  }

  .xl\:focus\:tw-bg-purple-darker:focus {
    background-color: #382b5f;
  }

  .xl\:focus\:tw-bg-purple-dark:focus {
    background-color: #794acf;
  }

  .xl\:focus\:tw-bg-purple:focus {
    background-color: #9561e2;
  }

  .xl\:focus\:tw-bg-purple-light:focus {
    background-color: #a779e9;
  }

  .xl\:focus\:tw-bg-purple-lighter:focus {
    background-color: #d6bbfc;
  }

  .xl\:focus\:tw-bg-purple-lightest:focus {
    background-color: #f3ebff;
  }

  .xl\:focus\:tw-bg-pink-darkest:focus {
    background-color: #451225;
  }

  .xl\:focus\:tw-bg-pink-darker:focus {
    background-color: #6f213f;
  }

  .xl\:focus\:tw-bg-pink-dark:focus {
    background-color: #eb5286;
  }

  .xl\:focus\:tw-bg-pink:focus {
    background-color: #f66d9b;
  }

  .xl\:focus\:tw-bg-pink-light:focus {
    background-color: #fa7ea8;
  }

  .xl\:focus\:tw-bg-pink-lighter:focus {
    background-color: #ffbbca;
  }

  .xl\:focus\:tw-bg-pink-lightest:focus {
    background-color: #ffebef;
  }

  .xl\:tw-bg-bottom {
    background-position: bottom;
  }

  .xl\:tw-bg-center {
    background-position: center;
  }

  .xl\:tw-bg-left {
    background-position: left;
  }

  .xl\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .xl\:tw-bg-left-top {
    background-position: left top;
  }

  .xl\:tw-bg-right {
    background-position: right;
  }

  .xl\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .xl\:tw-bg-right-top {
    background-position: right top;
  }

  .xl\:tw-bg-top {
    background-position: top;
  }

  .xl\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .xl\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:tw-bg-auto {
    background-size: auto;
  }

  .xl\:tw-bg-cover {
    background-size: cover;
  }

  .xl\:tw-bg-contain {
    background-size: contain;
  }

  .xl\:tw-border-transparent {
    border-color: transparent;
  }

  .xl\:tw-border-primary {
    border-color: #ac826d;
  }

  .xl\:tw-border-text {
    border-color: #453f3f;
  }

  .xl\:tw-border-black {
    border-color: #000;
  }

  .xl\:tw-border-grey-darkest {
    border-color: #3d4852;
  }

  .xl\:tw-border-grey-darker {
    border-color: #606f7b;
  }

  .xl\:tw-border-grey-dark {
    border-color: #8795a1;
  }

  .xl\:tw-border-grey {
    border-color: #b8c2cc;
  }

  .xl\:tw-border-grey-light {
    border-color: #a1b3bc;
  }

  .xl\:tw-border-grey-lighter {
    border-color: #d8dfe3;
  }

  .xl\:tw-border-grey-lightest {
    border-color: #f8fafc;
  }

  .xl\:tw-border-grey-grayish {
    border-color: #56666c;
  }

  .xl\:tw-border-white {
    border-color: #fff;
  }

  .xl\:tw-border-red-darkest {
    border-color: #3b0d0c;
  }

  .xl\:tw-border-red-darker {
    border-color: #621b18;
  }

  .xl\:tw-border-red-dark {
    border-color: #cc1f1a;
  }

  .xl\:tw-border-red {
    border-color: #de5050;
  }

  .xl\:tw-border-red-light {
    border-color: #fed5dc;
  }

  .xl\:tw-border-red-lighter {
    border-color: #f9acaa;
  }

  .xl\:tw-border-red-lightest {
    border-color: #fcebea;
  }

  .xl\:tw-border-orange-darkest {
    border-color: #462a16;
  }

  .xl\:tw-border-orange-darker {
    border-color: #613b1f;
  }

  .xl\:tw-border-orange-dark {
    border-color: #de751f;
  }

  .xl\:tw-border-orange {
    border-color: #ffbf00;
  }

  .xl\:tw-border-orange-light {
    border-color: #faad63;
  }

  .xl\:tw-border-orange-lighter {
    border-color: #fcd9b6;
  }

  .xl\:tw-border-orange-lightest {
    border-color: #fff5eb;
  }

  .xl\:tw-border-yellow-darkest {
    border-color: #453411;
  }

  .xl\:tw-border-yellow-darker {
    border-color: #684f1d;
  }

  .xl\:tw-border-yellow-dark {
    border-color: #f2d024;
  }

  .xl\:tw-border-yellow {
    border-color: #f8e71c;
  }

  .xl\:tw-border-yellow-light {
    border-color: #fff382;
  }

  .xl\:tw-border-yellow-lighter {
    border-color: #fff9c2;
  }

  .xl\:tw-border-yellow-lightest {
    border-color: #fcfbeb;
  }

  .xl\:tw-border-green-darkest {
    border-color: #0f2f21;
  }

  .xl\:tw-border-green-darker {
    border-color: #1a4731;
  }

  .xl\:tw-border-green-dark {
    border-color: #1f9d55;
  }

  .xl\:tw-border-green {
    border-color: #38c172;
  }

  .xl\:tw-border-green-light {
    border-color: #51d88a;
  }

  .xl\:tw-border-green-lighter {
    border-color: #a2f5bf;
  }

  .xl\:tw-border-green-lightest {
    border-color: #e3fcec;
  }

  .xl\:tw-border-teal-darkest {
    border-color: #0d3331;
  }

  .xl\:tw-border-teal-darker {
    border-color: #20504f;
  }

  .xl\:tw-border-teal-dark {
    border-color: #38a89d;
  }

  .xl\:tw-border-teal {
    border-color: #17c7aa;
  }

  .xl\:tw-border-teal-light {
    border-color: #64d5ca;
  }

  .xl\:tw-border-teal-lighter {
    border-color: #a0f0ed;
  }

  .xl\:tw-border-teal-lightest {
    border-color: #e8fffe;
  }

  .xl\:tw-border-blue-darkest {
    border-color: #12283a;
  }

  .xl\:tw-border-blue-darker {
    border-color: #1c3d5a;
  }

  .xl\:tw-border-blue-dark {
    border-color: #2779bd;
  }

  .xl\:tw-border-blue {
    border-color: #2ac1e7;
  }

  .xl\:tw-border-blue-light {
    border-color: #d4f2fa;
  }

  .xl\:tw-border-blue-lighter {
    border-color: #bcdefa;
  }

  .xl\:tw-border-blue-lightest {
    border-color: #eff8ff;
  }

  .xl\:tw-border-indigo-darkest {
    border-color: #191e38;
  }

  .xl\:tw-border-indigo-darker {
    border-color: #2f365f;
  }

  .xl\:tw-border-indigo-dark {
    border-color: #5661b3;
  }

  .xl\:tw-border-indigo {
    border-color: #6574cd;
  }

  .xl\:tw-border-indigo-light {
    border-color: #7886d7;
  }

  .xl\:tw-border-indigo-lighter {
    border-color: #b2b7ff;
  }

  .xl\:tw-border-indigo-lightest {
    border-color: #e6e8ff;
  }

  .xl\:tw-border-purple-darkest {
    border-color: #21183c;
  }

  .xl\:tw-border-purple-darker {
    border-color: #382b5f;
  }

  .xl\:tw-border-purple-dark {
    border-color: #794acf;
  }

  .xl\:tw-border-purple {
    border-color: #9561e2;
  }

  .xl\:tw-border-purple-light {
    border-color: #a779e9;
  }

  .xl\:tw-border-purple-lighter {
    border-color: #d6bbfc;
  }

  .xl\:tw-border-purple-lightest {
    border-color: #f3ebff;
  }

  .xl\:tw-border-pink-darkest {
    border-color: #451225;
  }

  .xl\:tw-border-pink-darker {
    border-color: #6f213f;
  }

  .xl\:tw-border-pink-dark {
    border-color: #eb5286;
  }

  .xl\:tw-border-pink {
    border-color: #f66d9b;
  }

  .xl\:tw-border-pink-light {
    border-color: #fa7ea8;
  }

  .xl\:tw-border-pink-lighter {
    border-color: #ffbbca;
  }

  .xl\:tw-border-pink-lightest {
    border-color: #ffebef;
  }

  .xl\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .xl\:hover\:tw-border-primary:hover {
    border-color: #ac826d;
  }

  .xl\:hover\:tw-border-text:hover {
    border-color: #453f3f;
  }

  .xl\:hover\:tw-border-black:hover {
    border-color: #000;
  }

  .xl\:hover\:tw-border-grey-darkest:hover {
    border-color: #3d4852;
  }

  .xl\:hover\:tw-border-grey-darker:hover {
    border-color: #606f7b;
  }

  .xl\:hover\:tw-border-grey-dark:hover {
    border-color: #8795a1;
  }

  .xl\:hover\:tw-border-grey:hover {
    border-color: #b8c2cc;
  }

  .xl\:hover\:tw-border-grey-light:hover {
    border-color: #a1b3bc;
  }

  .xl\:hover\:tw-border-grey-lighter:hover {
    border-color: #d8dfe3;
  }

  .xl\:hover\:tw-border-grey-lightest:hover {
    border-color: #f8fafc;
  }

  .xl\:hover\:tw-border-grey-grayish:hover {
    border-color: #56666c;
  }

  .xl\:hover\:tw-border-white:hover {
    border-color: #fff;
  }

  .xl\:hover\:tw-border-red-darkest:hover {
    border-color: #3b0d0c;
  }

  .xl\:hover\:tw-border-red-darker:hover {
    border-color: #621b18;
  }

  .xl\:hover\:tw-border-red-dark:hover {
    border-color: #cc1f1a;
  }

  .xl\:hover\:tw-border-red:hover {
    border-color: #de5050;
  }

  .xl\:hover\:tw-border-red-light:hover {
    border-color: #fed5dc;
  }

  .xl\:hover\:tw-border-red-lighter:hover {
    border-color: #f9acaa;
  }

  .xl\:hover\:tw-border-red-lightest:hover {
    border-color: #fcebea;
  }

  .xl\:hover\:tw-border-orange-darkest:hover {
    border-color: #462a16;
  }

  .xl\:hover\:tw-border-orange-darker:hover {
    border-color: #613b1f;
  }

  .xl\:hover\:tw-border-orange-dark:hover {
    border-color: #de751f;
  }

  .xl\:hover\:tw-border-orange:hover {
    border-color: #ffbf00;
  }

  .xl\:hover\:tw-border-orange-light:hover {
    border-color: #faad63;
  }

  .xl\:hover\:tw-border-orange-lighter:hover {
    border-color: #fcd9b6;
  }

  .xl\:hover\:tw-border-orange-lightest:hover {
    border-color: #fff5eb;
  }

  .xl\:hover\:tw-border-yellow-darkest:hover {
    border-color: #453411;
  }

  .xl\:hover\:tw-border-yellow-darker:hover {
    border-color: #684f1d;
  }

  .xl\:hover\:tw-border-yellow-dark:hover {
    border-color: #f2d024;
  }

  .xl\:hover\:tw-border-yellow:hover {
    border-color: #f8e71c;
  }

  .xl\:hover\:tw-border-yellow-light:hover {
    border-color: #fff382;
  }

  .xl\:hover\:tw-border-yellow-lighter:hover {
    border-color: #fff9c2;
  }

  .xl\:hover\:tw-border-yellow-lightest:hover {
    border-color: #fcfbeb;
  }

  .xl\:hover\:tw-border-green-darkest:hover {
    border-color: #0f2f21;
  }

  .xl\:hover\:tw-border-green-darker:hover {
    border-color: #1a4731;
  }

  .xl\:hover\:tw-border-green-dark:hover {
    border-color: #1f9d55;
  }

  .xl\:hover\:tw-border-green:hover {
    border-color: #38c172;
  }

  .xl\:hover\:tw-border-green-light:hover {
    border-color: #51d88a;
  }

  .xl\:hover\:tw-border-green-lighter:hover {
    border-color: #a2f5bf;
  }

  .xl\:hover\:tw-border-green-lightest:hover {
    border-color: #e3fcec;
  }

  .xl\:hover\:tw-border-teal-darkest:hover {
    border-color: #0d3331;
  }

  .xl\:hover\:tw-border-teal-darker:hover {
    border-color: #20504f;
  }

  .xl\:hover\:tw-border-teal-dark:hover {
    border-color: #38a89d;
  }

  .xl\:hover\:tw-border-teal:hover {
    border-color: #17c7aa;
  }

  .xl\:hover\:tw-border-teal-light:hover {
    border-color: #64d5ca;
  }

  .xl\:hover\:tw-border-teal-lighter:hover {
    border-color: #a0f0ed;
  }

  .xl\:hover\:tw-border-teal-lightest:hover {
    border-color: #e8fffe;
  }

  .xl\:hover\:tw-border-blue-darkest:hover {
    border-color: #12283a;
  }

  .xl\:hover\:tw-border-blue-darker:hover {
    border-color: #1c3d5a;
  }

  .xl\:hover\:tw-border-blue-dark:hover {
    border-color: #2779bd;
  }

  .xl\:hover\:tw-border-blue:hover {
    border-color: #2ac1e7;
  }

  .xl\:hover\:tw-border-blue-light:hover {
    border-color: #d4f2fa;
  }

  .xl\:hover\:tw-border-blue-lighter:hover {
    border-color: #bcdefa;
  }

  .xl\:hover\:tw-border-blue-lightest:hover {
    border-color: #eff8ff;
  }

  .xl\:hover\:tw-border-indigo-darkest:hover {
    border-color: #191e38;
  }

  .xl\:hover\:tw-border-indigo-darker:hover {
    border-color: #2f365f;
  }

  .xl\:hover\:tw-border-indigo-dark:hover {
    border-color: #5661b3;
  }

  .xl\:hover\:tw-border-indigo:hover {
    border-color: #6574cd;
  }

  .xl\:hover\:tw-border-indigo-light:hover {
    border-color: #7886d7;
  }

  .xl\:hover\:tw-border-indigo-lighter:hover {
    border-color: #b2b7ff;
  }

  .xl\:hover\:tw-border-indigo-lightest:hover {
    border-color: #e6e8ff;
  }

  .xl\:hover\:tw-border-purple-darkest:hover {
    border-color: #21183c;
  }

  .xl\:hover\:tw-border-purple-darker:hover {
    border-color: #382b5f;
  }

  .xl\:hover\:tw-border-purple-dark:hover {
    border-color: #794acf;
  }

  .xl\:hover\:tw-border-purple:hover {
    border-color: #9561e2;
  }

  .xl\:hover\:tw-border-purple-light:hover {
    border-color: #a779e9;
  }

  .xl\:hover\:tw-border-purple-lighter:hover {
    border-color: #d6bbfc;
  }

  .xl\:hover\:tw-border-purple-lightest:hover {
    border-color: #f3ebff;
  }

  .xl\:hover\:tw-border-pink-darkest:hover {
    border-color: #451225;
  }

  .xl\:hover\:tw-border-pink-darker:hover {
    border-color: #6f213f;
  }

  .xl\:hover\:tw-border-pink-dark:hover {
    border-color: #eb5286;
  }

  .xl\:hover\:tw-border-pink:hover {
    border-color: #f66d9b;
  }

  .xl\:hover\:tw-border-pink-light:hover {
    border-color: #fa7ea8;
  }

  .xl\:hover\:tw-border-pink-lighter:hover {
    border-color: #ffbbca;
  }

  .xl\:hover\:tw-border-pink-lightest:hover {
    border-color: #ffebef;
  }

  .xl\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .xl\:focus\:tw-border-primary:focus {
    border-color: #ac826d;
  }

  .xl\:focus\:tw-border-text:focus {
    border-color: #453f3f;
  }

  .xl\:focus\:tw-border-black:focus {
    border-color: #000;
  }

  .xl\:focus\:tw-border-grey-darkest:focus {
    border-color: #3d4852;
  }

  .xl\:focus\:tw-border-grey-darker:focus {
    border-color: #606f7b;
  }

  .xl\:focus\:tw-border-grey-dark:focus {
    border-color: #8795a1;
  }

  .xl\:focus\:tw-border-grey:focus {
    border-color: #b8c2cc;
  }

  .xl\:focus\:tw-border-grey-light:focus {
    border-color: #a1b3bc;
  }

  .xl\:focus\:tw-border-grey-lighter:focus {
    border-color: #d8dfe3;
  }

  .xl\:focus\:tw-border-grey-lightest:focus {
    border-color: #f8fafc;
  }

  .xl\:focus\:tw-border-grey-grayish:focus {
    border-color: #56666c;
  }

  .xl\:focus\:tw-border-white:focus {
    border-color: #fff;
  }

  .xl\:focus\:tw-border-red-darkest:focus {
    border-color: #3b0d0c;
  }

  .xl\:focus\:tw-border-red-darker:focus {
    border-color: #621b18;
  }

  .xl\:focus\:tw-border-red-dark:focus {
    border-color: #cc1f1a;
  }

  .xl\:focus\:tw-border-red:focus {
    border-color: #de5050;
  }

  .xl\:focus\:tw-border-red-light:focus {
    border-color: #fed5dc;
  }

  .xl\:focus\:tw-border-red-lighter:focus {
    border-color: #f9acaa;
  }

  .xl\:focus\:tw-border-red-lightest:focus {
    border-color: #fcebea;
  }

  .xl\:focus\:tw-border-orange-darkest:focus {
    border-color: #462a16;
  }

  .xl\:focus\:tw-border-orange-darker:focus {
    border-color: #613b1f;
  }

  .xl\:focus\:tw-border-orange-dark:focus {
    border-color: #de751f;
  }

  .xl\:focus\:tw-border-orange:focus {
    border-color: #ffbf00;
  }

  .xl\:focus\:tw-border-orange-light:focus {
    border-color: #faad63;
  }

  .xl\:focus\:tw-border-orange-lighter:focus {
    border-color: #fcd9b6;
  }

  .xl\:focus\:tw-border-orange-lightest:focus {
    border-color: #fff5eb;
  }

  .xl\:focus\:tw-border-yellow-darkest:focus {
    border-color: #453411;
  }

  .xl\:focus\:tw-border-yellow-darker:focus {
    border-color: #684f1d;
  }

  .xl\:focus\:tw-border-yellow-dark:focus {
    border-color: #f2d024;
  }

  .xl\:focus\:tw-border-yellow:focus {
    border-color: #f8e71c;
  }

  .xl\:focus\:tw-border-yellow-light:focus {
    border-color: #fff382;
  }

  .xl\:focus\:tw-border-yellow-lighter:focus {
    border-color: #fff9c2;
  }

  .xl\:focus\:tw-border-yellow-lightest:focus {
    border-color: #fcfbeb;
  }

  .xl\:focus\:tw-border-green-darkest:focus {
    border-color: #0f2f21;
  }

  .xl\:focus\:tw-border-green-darker:focus {
    border-color: #1a4731;
  }

  .xl\:focus\:tw-border-green-dark:focus {
    border-color: #1f9d55;
  }

  .xl\:focus\:tw-border-green:focus {
    border-color: #38c172;
  }

  .xl\:focus\:tw-border-green-light:focus {
    border-color: #51d88a;
  }

  .xl\:focus\:tw-border-green-lighter:focus {
    border-color: #a2f5bf;
  }

  .xl\:focus\:tw-border-green-lightest:focus {
    border-color: #e3fcec;
  }

  .xl\:focus\:tw-border-teal-darkest:focus {
    border-color: #0d3331;
  }

  .xl\:focus\:tw-border-teal-darker:focus {
    border-color: #20504f;
  }

  .xl\:focus\:tw-border-teal-dark:focus {
    border-color: #38a89d;
  }

  .xl\:focus\:tw-border-teal:focus {
    border-color: #17c7aa;
  }

  .xl\:focus\:tw-border-teal-light:focus {
    border-color: #64d5ca;
  }

  .xl\:focus\:tw-border-teal-lighter:focus {
    border-color: #a0f0ed;
  }

  .xl\:focus\:tw-border-teal-lightest:focus {
    border-color: #e8fffe;
  }

  .xl\:focus\:tw-border-blue-darkest:focus {
    border-color: #12283a;
  }

  .xl\:focus\:tw-border-blue-darker:focus {
    border-color: #1c3d5a;
  }

  .xl\:focus\:tw-border-blue-dark:focus {
    border-color: #2779bd;
  }

  .xl\:focus\:tw-border-blue:focus {
    border-color: #2ac1e7;
  }

  .xl\:focus\:tw-border-blue-light:focus {
    border-color: #d4f2fa;
  }

  .xl\:focus\:tw-border-blue-lighter:focus {
    border-color: #bcdefa;
  }

  .xl\:focus\:tw-border-blue-lightest:focus {
    border-color: #eff8ff;
  }

  .xl\:focus\:tw-border-indigo-darkest:focus {
    border-color: #191e38;
  }

  .xl\:focus\:tw-border-indigo-darker:focus {
    border-color: #2f365f;
  }

  .xl\:focus\:tw-border-indigo-dark:focus {
    border-color: #5661b3;
  }

  .xl\:focus\:tw-border-indigo:focus {
    border-color: #6574cd;
  }

  .xl\:focus\:tw-border-indigo-light:focus {
    border-color: #7886d7;
  }

  .xl\:focus\:tw-border-indigo-lighter:focus {
    border-color: #b2b7ff;
  }

  .xl\:focus\:tw-border-indigo-lightest:focus {
    border-color: #e6e8ff;
  }

  .xl\:focus\:tw-border-purple-darkest:focus {
    border-color: #21183c;
  }

  .xl\:focus\:tw-border-purple-darker:focus {
    border-color: #382b5f;
  }

  .xl\:focus\:tw-border-purple-dark:focus {
    border-color: #794acf;
  }

  .xl\:focus\:tw-border-purple:focus {
    border-color: #9561e2;
  }

  .xl\:focus\:tw-border-purple-light:focus {
    border-color: #a779e9;
  }

  .xl\:focus\:tw-border-purple-lighter:focus {
    border-color: #d6bbfc;
  }

  .xl\:focus\:tw-border-purple-lightest:focus {
    border-color: #f3ebff;
  }

  .xl\:focus\:tw-border-pink-darkest:focus {
    border-color: #451225;
  }

  .xl\:focus\:tw-border-pink-darker:focus {
    border-color: #6f213f;
  }

  .xl\:focus\:tw-border-pink-dark:focus {
    border-color: #eb5286;
  }

  .xl\:focus\:tw-border-pink:focus {
    border-color: #f66d9b;
  }

  .xl\:focus\:tw-border-pink-light:focus {
    border-color: #fa7ea8;
  }

  .xl\:focus\:tw-border-pink-lighter:focus {
    border-color: #ffbbca;
  }

  .xl\:focus\:tw-border-pink-lightest:focus {
    border-color: #ffebef;
  }

  .xl\:tw-rounded-none {
    border-radius: 0;
  }

  .xl\:tw-rounded-sm {
    border-radius: .125rem;
  }

  .xl\:tw-rounded {
    border-radius: .25rem;
  }

  .xl\:tw-rounded-lg {
    border-radius: .5rem;
  }

  .xl\:tw-rounded-full {
    border-radius: 9999px;
  }

  .xl\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .xl\:tw-rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xl\:tw-rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:tw-rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:tw-rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .xl\:tw-rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xl\:tw-rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:tw-rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:tw-rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .xl\:tw-rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xl\:tw-rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:tw-rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .xl\:tw-rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .xl\:tw-rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .xl\:tw-rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .xl\:tw-rounded-tl {
    border-top-left-radius: .25rem;
  }

  .xl\:tw-rounded-tr {
    border-top-right-radius: .25rem;
  }

  .xl\:tw-rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .xl\:tw-rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .xl\:tw-rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .xl\:tw-rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .xl\:tw-rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .xl\:tw-rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .xl\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-border-solid {
    border-style: solid;
  }

  .xl\:tw-border-dashed {
    border-style: dashed;
  }

  .xl\:tw-border-dotted {
    border-style: dotted;
  }

  .xl\:tw-border-none {
    border-style: none;
  }

  .xl\:tw-border-0 {
    border-width: 0;
  }

  .xl\:tw-border-2 {
    border-width: 2px;
  }

  .xl\:tw-border-4 {
    border-width: 4px;
  }

  .xl\:tw-border-8 {
    border-width: 8px;
  }

  .xl\:tw-border {
    border-width: 1px;
  }

  .xl\:tw-border-t-0 {
    border-top-width: 0;
  }

  .xl\:tw-border-r-0 {
    border-right-width: 0;
  }

  .xl\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:tw-border-l-0 {
    border-left-width: 0;
  }

  .xl\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .xl\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .xl\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .xl\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .xl\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .xl\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .xl\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .xl\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .xl\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .xl\:tw-border-t {
    border-top-width: 1px;
  }

  .xl\:tw-border-r {
    border-right-width: 1px;
  }

  .xl\:tw-border-b {
    border-bottom-width: 1px;
  }

  .xl\:tw-border-l {
    border-left-width: 1px;
  }

  .xl\:tw-cursor-auto {
    cursor: auto;
  }

  .xl\:tw-cursor-default {
    cursor: default;
  }

  .xl\:tw-cursor-pointer {
    cursor: pointer;
  }

  .xl\:tw-cursor-wait {
    cursor: wait;
  }

  .xl\:tw-cursor-move {
    cursor: move;
  }

  .xl\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:tw-block {
    display: block;
  }

  .xl\:tw-inline-block {
    display: inline-block;
  }

  .xl\:tw-inline {
    display: inline;
  }

  .xl\:tw-table {
    display: table;
  }

  .xl\:tw-table-row {
    display: table-row;
  }

  .xl\:tw-table-cell {
    display: table-cell;
  }

  .xl\:tw-hidden {
    display: none;
  }

  .xl\:tw-flex {
    display: flex;
  }

  .xl\:tw-inline-flex {
    display: inline-flex;
  }

  .xl\:tw-flex-row {
    flex-direction: row;
  }

  .xl\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:tw-flex-col {
    flex-direction: column;
  }

  .xl\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .xl\:tw-items-start {
    align-items: flex-start;
  }

  .xl\:tw-items-end {
    align-items: flex-end;
  }

  .xl\:tw-items-center {
    align-items: center;
  }

  .xl\:tw-items-baseline {
    align-items: baseline;
  }

  .xl\:tw-items-stretch {
    align-items: stretch;
  }

  .xl\:tw-self-auto {
    align-self: auto;
  }

  .xl\:tw-self-start {
    align-self: flex-start;
  }

  .xl\:tw-self-end {
    align-self: flex-end;
  }

  .xl\:tw-self-center {
    align-self: center;
  }

  .xl\:tw-self-stretch {
    align-self: stretch;
  }

  .xl\:tw-justify-start {
    justify-content: flex-start;
  }

  .xl\:tw-justify-end {
    justify-content: flex-end;
  }

  .xl\:tw-justify-center {
    justify-content: center;
  }

  .xl\:tw-justify-between {
    justify-content: space-between;
  }

  .xl\:tw-justify-around {
    justify-content: space-around;
  }

  .xl\:tw-content-center {
    align-content: center;
  }

  .xl\:tw-content-start {
    align-content: flex-start;
  }

  .xl\:tw-content-end {
    align-content: flex-end;
  }

  .xl\:tw-content-between {
    align-content: space-between;
  }

  .xl\:tw-content-around {
    align-content: space-around;
  }

  .xl\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .xl\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .xl\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .xl\:tw-flex-none {
    flex: none;
  }

  .xl\:tw-flex-grow {
    flex-grow: 1;
  }

  .xl\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .xl\:tw-flex-no-grow {
    flex-grow: 0;
  }

  .xl\:tw-flex-no-shrink {
    flex-shrink: 0;
  }

  .xl\:tw-float-right {
    float: right;
  }

  .xl\:tw-float-left {
    float: left;
  }

  .xl\:tw-float-none {
    float: none;
  }

  .xl\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .xl\:tw-font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .xl\:tw-font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
  }

  .xl\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .xl\:tw-font-hairline {
    font-weight: 100;
  }

  .xl\:tw-font-thin {
    font-weight: 200;
  }

  .xl\:tw-font-light {
    font-weight: 300;
  }

  .xl\:tw-font-normal {
    font-weight: 400;
  }

  .xl\:tw-font-medium {
    font-weight: 500;
  }

  .xl\:tw-font-semibold {
    font-weight: 600;
  }

  .xl\:tw-font-bold {
    font-weight: 700;
  }

  .xl\:tw-font-extrabold {
    font-weight: 800;
  }

  .xl\:tw-font-black {
    font-weight: 900;
  }

  .xl\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .xl\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .xl\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .xl\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .xl\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .xl\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .xl\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .xl\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .xl\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .xl\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .xl\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .xl\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .xl\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .xl\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .xl\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .xl\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .xl\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .xl\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .xl\:tw-h-1 {
    height: .25rem;
  }

  .xl\:tw-h-2 {
    height: .5rem;
  }

  .xl\:tw-h-3 {
    height: .75rem;
  }

  .xl\:tw-h-4 {
    height: 1rem;
  }

  .xl\:tw-h-5 {
    height: 1.25rem;
  }

  .xl\:tw-h-6 {
    height: 1.5rem;
  }

  .xl\:tw-h-8 {
    height: 2rem;
  }

  .xl\:tw-h-10 {
    height: 2.5rem;
  }

  .xl\:tw-h-12 {
    height: 3rem;
  }

  .xl\:tw-h-16 {
    height: 4rem;
  }

  .xl\:tw-h-24 {
    height: 6rem;
  }

  .xl\:tw-h-32 {
    height: 8rem;
  }

  .xl\:tw-h-48 {
    height: 12rem;
  }

  .xl\:tw-h-64 {
    height: 16rem;
  }

  .xl\:tw-h-auto {
    height: auto;
  }

  .xl\:tw-h-px {
    height: 1px;
  }

  .xl\:tw-h-full {
    height: 100%;
  }

  .xl\:tw-h-screen {
    height: 100vh;
  }

  .xl\:tw-leading-none {
    line-height: 1;
  }

  .xl\:tw-leading-tight {
    line-height: 1.25;
  }

  .xl\:tw-leading-normal {
    line-height: 1.5;
  }

  .xl\:tw-leading-loose {
    line-height: 2;
  }

  .xl\:tw-m-0 {
    margin: 0;
  }

  .xl\:tw-m-1 {
    margin: .25rem;
  }

  .xl\:tw-m-2 {
    margin: .5rem;
  }

  .xl\:tw-m-3 {
    margin: .75rem;
  }

  .xl\:tw-m-4 {
    margin: 1rem;
  }

  .xl\:tw-m-5 {
    margin: 1.25rem;
  }

  .xl\:tw-m-6 {
    margin: 1.5rem;
  }

  .xl\:tw-m-8 {
    margin: 2rem;
  }

  .xl\:tw-m-10 {
    margin: 2.5rem;
  }

  .xl\:tw-m-12 {
    margin: 3rem;
  }

  .xl\:tw-m-16 {
    margin: 4rem;
  }

  .xl\:tw-m-20 {
    margin: 5rem;
  }

  .xl\:tw-m-24 {
    margin: 6rem;
  }

  .xl\:tw-m-32 {
    margin: 8rem;
  }

  .xl\:tw-m-auto {
    margin: auto;
  }

  .xl\:tw-m-px {
    margin: 1px;
  }

  .xl\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:tw-my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .xl\:tw-mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .xl\:tw-my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .xl\:tw-mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xl\:tw-my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .xl\:tw-mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .xl\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:tw-mt-0 {
    margin-top: 0;
  }

  .xl\:tw-mr-0 {
    margin-right: 0;
  }

  .xl\:tw-mb-0 {
    margin-bottom: 0;
  }

  .xl\:tw-ml-0 {
    margin-left: 0;
  }

  .xl\:tw-mt-1 {
    margin-top: .25rem;
  }

  .xl\:tw-mr-1 {
    margin-right: .25rem;
  }

  .xl\:tw-mb-1 {
    margin-bottom: .25rem;
  }

  .xl\:tw-ml-1 {
    margin-left: .25rem;
  }

  .xl\:tw-mt-2 {
    margin-top: .5rem;
  }

  .xl\:tw-mr-2 {
    margin-right: .5rem;
  }

  .xl\:tw-mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:tw-ml-2 {
    margin-left: .5rem;
  }

  .xl\:tw-mt-3 {
    margin-top: .75rem;
  }

  .xl\:tw-mr-3 {
    margin-right: .75rem;
  }

  .xl\:tw-mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:tw-ml-3 {
    margin-left: .75rem;
  }

  .xl\:tw-mt-4 {
    margin-top: 1rem;
  }

  .xl\:tw-mr-4 {
    margin-right: 1rem;
  }

  .xl\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:tw-ml-4 {
    margin-left: 1rem;
  }

  .xl\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:tw-mt-8 {
    margin-top: 2rem;
  }

  .xl\:tw-mr-8 {
    margin-right: 2rem;
  }

  .xl\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:tw-ml-8 {
    margin-left: 2rem;
  }

  .xl\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:tw-mt-12 {
    margin-top: 3rem;
  }

  .xl\:tw-mr-12 {
    margin-right: 3rem;
  }

  .xl\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:tw-ml-12 {
    margin-left: 3rem;
  }

  .xl\:tw-mt-16 {
    margin-top: 4rem;
  }

  .xl\:tw-mr-16 {
    margin-right: 4rem;
  }

  .xl\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:tw-ml-16 {
    margin-left: 4rem;
  }

  .xl\:tw-mt-20 {
    margin-top: 5rem;
  }

  .xl\:tw-mr-20 {
    margin-right: 5rem;
  }

  .xl\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:tw-ml-20 {
    margin-left: 5rem;
  }

  .xl\:tw-mt-24 {
    margin-top: 6rem;
  }

  .xl\:tw-mr-24 {
    margin-right: 6rem;
  }

  .xl\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:tw-ml-24 {
    margin-left: 6rem;
  }

  .xl\:tw-mt-32 {
    margin-top: 8rem;
  }

  .xl\:tw-mr-32 {
    margin-right: 8rem;
  }

  .xl\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:tw-ml-32 {
    margin-left: 8rem;
  }

  .xl\:tw-mt-auto {
    margin-top: auto;
  }

  .xl\:tw-mr-auto {
    margin-right: auto;
  }

  .xl\:tw-mb-auto {
    margin-bottom: auto;
  }

  .xl\:tw-ml-auto {
    margin-left: auto;
  }

  .xl\:tw-mt-px {
    margin-top: 1px;
  }

  .xl\:tw-mr-px {
    margin-right: 1px;
  }

  .xl\:tw-mb-px {
    margin-bottom: 1px;
  }

  .xl\:tw-ml-px {
    margin-left: 1px;
  }

  .xl\:tw-max-h-full {
    max-height: 100%;
  }

  .xl\:tw-max-h-screen {
    max-height: 100vh;
  }

  .xl\:tw-max-w-xs {
    max-width: 20rem;
  }

  .xl\:tw-max-w-sm {
    max-width: 30rem;
  }

  .xl\:tw-max-w-md {
    max-width: 40rem;
  }

  .xl\:tw-max-w-lg {
    max-width: 50rem;
  }

  .xl\:tw-max-w-xl {
    max-width: 60rem;
  }

  .xl\:tw-max-w-2xl {
    max-width: 70rem;
  }

  .xl\:tw-max-w-3xl {
    max-width: 80rem;
  }

  .xl\:tw-max-w-4xl {
    max-width: 90rem;
  }

  .xl\:tw-max-w-5xl {
    max-width: 100rem;
  }

  .xl\:tw-max-w-full {
    max-width: 100%;
  }

  .xl\:tw-min-h-0 {
    min-height: 0;
  }

  .xl\:tw-min-h-full {
    min-height: 100%;
  }

  .xl\:tw-min-h-screen {
    min-height: 100vh;
  }

  .xl\:tw-min-w-0 {
    min-width: 0;
  }

  .xl\:tw-min-w-full {
    min-width: 100%;
  }

  .xl\:tw--m-0 {
    margin: 0;
  }

  .xl\:tw--m-1 {
    margin: -0.25rem;
  }

  .xl\:tw--m-2 {
    margin: -0.5rem;
  }

  .xl\:tw--m-3 {
    margin: -0.75rem;
  }

  .xl\:tw--m-4 {
    margin: -1rem;
  }

  .xl\:tw--m-5 {
    margin: -1.25rem;
  }

  .xl\:tw--m-6 {
    margin: -1.5rem;
  }

  .xl\:tw--m-8 {
    margin: -2rem;
  }

  .xl\:tw--m-10 {
    margin: -2.5rem;
  }

  .xl\:tw--m-12 {
    margin: -3rem;
  }

  .xl\:tw--m-16 {
    margin: -4rem;
  }

  .xl\:tw--m-20 {
    margin: -5rem;
  }

  .xl\:tw--m-24 {
    margin: -6rem;
  }

  .xl\:tw--m-32 {
    margin: -8rem;
  }

  .xl\:tw--m-px {
    margin: -1px;
  }

  .xl\:tw--my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:tw--mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xl\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xl\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xl\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xl\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:tw--mt-0 {
    margin-top: 0;
  }

  .xl\:tw--mr-0 {
    margin-right: 0;
  }

  .xl\:tw--mb-0 {
    margin-bottom: 0;
  }

  .xl\:tw--ml-0 {
    margin-left: 0;
  }

  .xl\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .xl\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .xl\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .xl\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .xl\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .xl\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .xl\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .xl\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .xl\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .xl\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .xl\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .xl\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .xl\:tw--mt-4 {
    margin-top: -1rem;
  }

  .xl\:tw--mr-4 {
    margin-right: -1rem;
  }

  .xl\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:tw--ml-4 {
    margin-left: -1rem;
  }

  .xl\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:tw--mt-8 {
    margin-top: -2rem;
  }

  .xl\:tw--mr-8 {
    margin-right: -2rem;
  }

  .xl\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:tw--ml-8 {
    margin-left: -2rem;
  }

  .xl\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:tw--mt-12 {
    margin-top: -3rem;
  }

  .xl\:tw--mr-12 {
    margin-right: -3rem;
  }

  .xl\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:tw--ml-12 {
    margin-left: -3rem;
  }

  .xl\:tw--mt-16 {
    margin-top: -4rem;
  }

  .xl\:tw--mr-16 {
    margin-right: -4rem;
  }

  .xl\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:tw--ml-16 {
    margin-left: -4rem;
  }

  .xl\:tw--mt-20 {
    margin-top: -5rem;
  }

  .xl\:tw--mr-20 {
    margin-right: -5rem;
  }

  .xl\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:tw--ml-20 {
    margin-left: -5rem;
  }

  .xl\:tw--mt-24 {
    margin-top: -6rem;
  }

  .xl\:tw--mr-24 {
    margin-right: -6rem;
  }

  .xl\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:tw--ml-24 {
    margin-left: -6rem;
  }

  .xl\:tw--mt-32 {
    margin-top: -8rem;
  }

  .xl\:tw--mr-32 {
    margin-right: -8rem;
  }

  .xl\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:tw--ml-32 {
    margin-left: -8rem;
  }

  .xl\:tw--mt-px {
    margin-top: -1px;
  }

  .xl\:tw--mr-px {
    margin-right: -1px;
  }

  .xl\:tw--mb-px {
    margin-bottom: -1px;
  }

  .xl\:tw--ml-px {
    margin-left: -1px;
  }

  .xl\:tw-opacity-0 {
    opacity: 0;
  }

  .xl\:tw-opacity-25 {
    opacity: .25;
  }

  .xl\:tw-opacity-50 {
    opacity: .5;
  }

  .xl\:tw-opacity-75 {
    opacity: .75;
  }

  .xl\:tw-opacity-100 {
    opacity: 1;
  }

  .xl\:tw-overflow-auto {
    overflow: auto;
  }

  .xl\:tw-overflow-hidden {
    overflow: hidden;
  }

  .xl\:tw-overflow-visible {
    overflow: visible;
  }

  .xl\:tw-overflow-scroll {
    overflow: scroll;
  }

  .xl\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .xl\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .xl\:tw-p-0 {
    padding: 0;
  }

  .xl\:tw-p-1 {
    padding: .25rem;
  }

  .xl\:tw-p-2 {
    padding: .5rem;
  }

  .xl\:tw-p-3 {
    padding: .75rem;
  }

  .xl\:tw-p-4 {
    padding: 1rem;
  }

  .xl\:tw-p-5 {
    padding: 1.25rem;
  }

  .xl\:tw-p-6 {
    padding: 1.5rem;
  }

  .xl\:tw-p-8 {
    padding: 2rem;
  }

  .xl\:tw-p-10 {
    padding: 2.5rem;
  }

  .xl\:tw-p-12 {
    padding: 3rem;
  }

  .xl\:tw-p-16 {
    padding: 4rem;
  }

  .xl\:tw-p-20 {
    padding: 5rem;
  }

  .xl\:tw-p-24 {
    padding: 6rem;
  }

  .xl\:tw-p-32 {
    padding: 8rem;
  }

  .xl\:tw-p-px {
    padding: 1px;
  }

  .xl\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:tw-py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xl\:tw-px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .xl\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:tw-px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:tw-py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:tw-px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:tw-pt-0 {
    padding-top: 0;
  }

  .xl\:tw-pr-0 {
    padding-right: 0;
  }

  .xl\:tw-pb-0 {
    padding-bottom: 0;
  }

  .xl\:tw-pl-0 {
    padding-left: 0;
  }

  .xl\:tw-pt-1 {
    padding-top: .25rem;
  }

  .xl\:tw-pr-1 {
    padding-right: .25rem;
  }

  .xl\:tw-pb-1 {
    padding-bottom: .25rem;
  }

  .xl\:tw-pl-1 {
    padding-left: .25rem;
  }

  .xl\:tw-pt-2 {
    padding-top: .5rem;
  }

  .xl\:tw-pr-2 {
    padding-right: .5rem;
  }

  .xl\:tw-pb-2 {
    padding-bottom: .5rem;
  }

  .xl\:tw-pl-2 {
    padding-left: .5rem;
  }

  .xl\:tw-pt-3 {
    padding-top: .75rem;
  }

  .xl\:tw-pr-3 {
    padding-right: .75rem;
  }

  .xl\:tw-pb-3 {
    padding-bottom: .75rem;
  }

  .xl\:tw-pl-3 {
    padding-left: .75rem;
  }

  .xl\:tw-pt-4 {
    padding-top: 1rem;
  }

  .xl\:tw-pr-4 {
    padding-right: 1rem;
  }

  .xl\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:tw-pl-4 {
    padding-left: 1rem;
  }

  .xl\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:tw-pt-8 {
    padding-top: 2rem;
  }

  .xl\:tw-pr-8 {
    padding-right: 2rem;
  }

  .xl\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:tw-pl-8 {
    padding-left: 2rem;
  }

  .xl\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:tw-pt-12 {
    padding-top: 3rem;
  }

  .xl\:tw-pr-12 {
    padding-right: 3rem;
  }

  .xl\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:tw-pl-12 {
    padding-left: 3rem;
  }

  .xl\:tw-pt-16 {
    padding-top: 4rem;
  }

  .xl\:tw-pr-16 {
    padding-right: 4rem;
  }

  .xl\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:tw-pl-16 {
    padding-left: 4rem;
  }

  .xl\:tw-pt-20 {
    padding-top: 5rem;
  }

  .xl\:tw-pr-20 {
    padding-right: 5rem;
  }

  .xl\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:tw-pl-20 {
    padding-left: 5rem;
  }

  .xl\:tw-pt-24 {
    padding-top: 6rem;
  }

  .xl\:tw-pr-24 {
    padding-right: 6rem;
  }

  .xl\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:tw-pl-24 {
    padding-left: 6rem;
  }

  .xl\:tw-pt-32 {
    padding-top: 8rem;
  }

  .xl\:tw-pr-32 {
    padding-right: 8rem;
  }

  .xl\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:tw-pl-32 {
    padding-left: 8rem;
  }

  .xl\:tw-pt-px {
    padding-top: 1px;
  }

  .xl\:tw-pr-px {
    padding-right: 1px;
  }

  .xl\:tw-pb-px {
    padding-bottom: 1px;
  }

  .xl\:tw-pl-px {
    padding-left: 1px;
  }

  .xl\:tw-pointer-events-none {
    pointer-events: none;
  }

  .xl\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:tw-static {
    position: static;
  }

  .xl\:tw-fixed {
    position: fixed;
  }

  .xl\:tw-absolute {
    position: absolute;
  }

  .xl\:tw-relative {
    position: relative;
  }

  .xl\:tw-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .xl\:tw-pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xl\:tw-pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xl\:tw-pin-y {
    top: 0;
    bottom: 0;
  }

  .xl\:tw-pin-x {
    right: 0;
    left: 0;
  }

  .xl\:tw-pin-t {
    top: 0;
  }

  .xl\:tw-pin-r {
    right: 0;
  }

  .xl\:tw-pin-b {
    bottom: 0;
  }

  .xl\:tw-pin-l {
    left: 0;
  }

  .xl\:tw-resize-none {
    resize: none;
  }

  .xl\:tw-resize-y {
    resize: vertical;
  }

  .xl\:tw-resize-x {
    resize: horizontal;
  }

  .xl\:tw-resize {
    resize: both;
  }

  .xl\:tw-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .xl\:tw-shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .xl\:tw-shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .xl\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .xl\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .xl\:tw-shadow-none {
    box-shadow: none;
  }

  .xl\:hover\:tw-shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .xl\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .xl\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .xl\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .xl\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .xl\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .xl\:focus\:tw-shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  }

  .xl\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .xl\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  }

  .xl\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
  }

  .xl\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
  }

  .xl\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .xl\:tw-table-auto {
    table-layout: auto;
  }

  .xl\:tw-table-fixed {
    table-layout: fixed;
  }

  .xl\:tw-text-left {
    text-align: left;
  }

  .xl\:tw-text-center {
    text-align: center;
  }

  .xl\:tw-text-right {
    text-align: right;
  }

  .xl\:tw-text-justify {
    text-align: justify;
  }

  .xl\:tw-text-transparent {
    color: transparent;
  }

  .xl\:tw-text-primary {
    color: #ac826d;
  }

  .xl\:tw-text-text {
    color: #453f3f;
  }

  .xl\:tw-text-black {
    color: #000;
  }

  .xl\:tw-text-grey-darkest {
    color: #3d4852;
  }

  .xl\:tw-text-grey-darker {
    color: #606f7b;
  }

  .xl\:tw-text-grey-dark {
    color: #8795a1;
  }

  .xl\:tw-text-grey {
    color: #b8c2cc;
  }

  .xl\:tw-text-grey-light {
    color: #a1b3bc;
  }

  .xl\:tw-text-grey-lighter {
    color: #d8dfe3;
  }

  .xl\:tw-text-grey-lightest {
    color: #f8fafc;
  }

  .xl\:tw-text-grey-grayish {
    color: #56666c;
  }

  .xl\:tw-text-white {
    color: #fff;
  }

  .xl\:tw-text-red-darkest {
    color: #3b0d0c;
  }

  .xl\:tw-text-red-darker {
    color: #621b18;
  }

  .xl\:tw-text-red-dark {
    color: #cc1f1a;
  }

  .xl\:tw-text-red {
    color: #de5050;
  }

  .xl\:tw-text-red-light {
    color: #fed5dc;
  }

  .xl\:tw-text-red-lighter {
    color: #f9acaa;
  }

  .xl\:tw-text-red-lightest {
    color: #fcebea;
  }

  .xl\:tw-text-orange-darkest {
    color: #462a16;
  }

  .xl\:tw-text-orange-darker {
    color: #613b1f;
  }

  .xl\:tw-text-orange-dark {
    color: #de751f;
  }

  .xl\:tw-text-orange {
    color: #ffbf00;
  }

  .xl\:tw-text-orange-light {
    color: #faad63;
  }

  .xl\:tw-text-orange-lighter {
    color: #fcd9b6;
  }

  .xl\:tw-text-orange-lightest {
    color: #fff5eb;
  }

  .xl\:tw-text-yellow-darkest {
    color: #453411;
  }

  .xl\:tw-text-yellow-darker {
    color: #684f1d;
  }

  .xl\:tw-text-yellow-dark {
    color: #f2d024;
  }

  .xl\:tw-text-yellow {
    color: #f8e71c;
  }

  .xl\:tw-text-yellow-light {
    color: #fff382;
  }

  .xl\:tw-text-yellow-lighter {
    color: #fff9c2;
  }

  .xl\:tw-text-yellow-lightest {
    color: #fcfbeb;
  }

  .xl\:tw-text-green-darkest {
    color: #0f2f21;
  }

  .xl\:tw-text-green-darker {
    color: #1a4731;
  }

  .xl\:tw-text-green-dark {
    color: #1f9d55;
  }

  .xl\:tw-text-green {
    color: #38c172;
  }

  .xl\:tw-text-green-light {
    color: #51d88a;
  }

  .xl\:tw-text-green-lighter {
    color: #a2f5bf;
  }

  .xl\:tw-text-green-lightest {
    color: #e3fcec;
  }

  .xl\:tw-text-teal-darkest {
    color: #0d3331;
  }

  .xl\:tw-text-teal-darker {
    color: #20504f;
  }

  .xl\:tw-text-teal-dark {
    color: #38a89d;
  }

  .xl\:tw-text-teal {
    color: #17c7aa;
  }

  .xl\:tw-text-teal-light {
    color: #64d5ca;
  }

  .xl\:tw-text-teal-lighter {
    color: #a0f0ed;
  }

  .xl\:tw-text-teal-lightest {
    color: #e8fffe;
  }

  .xl\:tw-text-blue-darkest {
    color: #12283a;
  }

  .xl\:tw-text-blue-darker {
    color: #1c3d5a;
  }

  .xl\:tw-text-blue-dark {
    color: #2779bd;
  }

  .xl\:tw-text-blue {
    color: #2ac1e7;
  }

  .xl\:tw-text-blue-light {
    color: #d4f2fa;
  }

  .xl\:tw-text-blue-lighter {
    color: #bcdefa;
  }

  .xl\:tw-text-blue-lightest {
    color: #eff8ff;
  }

  .xl\:tw-text-indigo-darkest {
    color: #191e38;
  }

  .xl\:tw-text-indigo-darker {
    color: #2f365f;
  }

  .xl\:tw-text-indigo-dark {
    color: #5661b3;
  }

  .xl\:tw-text-indigo {
    color: #6574cd;
  }

  .xl\:tw-text-indigo-light {
    color: #7886d7;
  }

  .xl\:tw-text-indigo-lighter {
    color: #b2b7ff;
  }

  .xl\:tw-text-indigo-lightest {
    color: #e6e8ff;
  }

  .xl\:tw-text-purple-darkest {
    color: #21183c;
  }

  .xl\:tw-text-purple-darker {
    color: #382b5f;
  }

  .xl\:tw-text-purple-dark {
    color: #794acf;
  }

  .xl\:tw-text-purple {
    color: #9561e2;
  }

  .xl\:tw-text-purple-light {
    color: #a779e9;
  }

  .xl\:tw-text-purple-lighter {
    color: #d6bbfc;
  }

  .xl\:tw-text-purple-lightest {
    color: #f3ebff;
  }

  .xl\:tw-text-pink-darkest {
    color: #451225;
  }

  .xl\:tw-text-pink-darker {
    color: #6f213f;
  }

  .xl\:tw-text-pink-dark {
    color: #eb5286;
  }

  .xl\:tw-text-pink {
    color: #f66d9b;
  }

  .xl\:tw-text-pink-light {
    color: #fa7ea8;
  }

  .xl\:tw-text-pink-lighter {
    color: #ffbbca;
  }

  .xl\:tw-text-pink-lightest {
    color: #ffebef;
  }

  .xl\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .xl\:hover\:tw-text-primary:hover {
    color: #ac826d;
  }

  .xl\:hover\:tw-text-text:hover {
    color: #453f3f;
  }

  .xl\:hover\:tw-text-black:hover {
    color: #000;
  }

  .xl\:hover\:tw-text-grey-darkest:hover {
    color: #3d4852;
  }

  .xl\:hover\:tw-text-grey-darker:hover {
    color: #606f7b;
  }

  .xl\:hover\:tw-text-grey-dark:hover {
    color: #8795a1;
  }

  .xl\:hover\:tw-text-grey:hover {
    color: #b8c2cc;
  }

  .xl\:hover\:tw-text-grey-light:hover {
    color: #a1b3bc;
  }

  .xl\:hover\:tw-text-grey-lighter:hover {
    color: #d8dfe3;
  }

  .xl\:hover\:tw-text-grey-lightest:hover {
    color: #f8fafc;
  }

  .xl\:hover\:tw-text-grey-grayish:hover {
    color: #56666c;
  }

  .xl\:hover\:tw-text-white:hover {
    color: #fff;
  }

  .xl\:hover\:tw-text-red-darkest:hover {
    color: #3b0d0c;
  }

  .xl\:hover\:tw-text-red-darker:hover {
    color: #621b18;
  }

  .xl\:hover\:tw-text-red-dark:hover {
    color: #cc1f1a;
  }

  .xl\:hover\:tw-text-red:hover {
    color: #de5050;
  }

  .xl\:hover\:tw-text-red-light:hover {
    color: #fed5dc;
  }

  .xl\:hover\:tw-text-red-lighter:hover {
    color: #f9acaa;
  }

  .xl\:hover\:tw-text-red-lightest:hover {
    color: #fcebea;
  }

  .xl\:hover\:tw-text-orange-darkest:hover {
    color: #462a16;
  }

  .xl\:hover\:tw-text-orange-darker:hover {
    color: #613b1f;
  }

  .xl\:hover\:tw-text-orange-dark:hover {
    color: #de751f;
  }

  .xl\:hover\:tw-text-orange:hover {
    color: #ffbf00;
  }

  .xl\:hover\:tw-text-orange-light:hover {
    color: #faad63;
  }

  .xl\:hover\:tw-text-orange-lighter:hover {
    color: #fcd9b6;
  }

  .xl\:hover\:tw-text-orange-lightest:hover {
    color: #fff5eb;
  }

  .xl\:hover\:tw-text-yellow-darkest:hover {
    color: #453411;
  }

  .xl\:hover\:tw-text-yellow-darker:hover {
    color: #684f1d;
  }

  .xl\:hover\:tw-text-yellow-dark:hover {
    color: #f2d024;
  }

  .xl\:hover\:tw-text-yellow:hover {
    color: #f8e71c;
  }

  .xl\:hover\:tw-text-yellow-light:hover {
    color: #fff382;
  }

  .xl\:hover\:tw-text-yellow-lighter:hover {
    color: #fff9c2;
  }

  .xl\:hover\:tw-text-yellow-lightest:hover {
    color: #fcfbeb;
  }

  .xl\:hover\:tw-text-green-darkest:hover {
    color: #0f2f21;
  }

  .xl\:hover\:tw-text-green-darker:hover {
    color: #1a4731;
  }

  .xl\:hover\:tw-text-green-dark:hover {
    color: #1f9d55;
  }

  .xl\:hover\:tw-text-green:hover {
    color: #38c172;
  }

  .xl\:hover\:tw-text-green-light:hover {
    color: #51d88a;
  }

  .xl\:hover\:tw-text-green-lighter:hover {
    color: #a2f5bf;
  }

  .xl\:hover\:tw-text-green-lightest:hover {
    color: #e3fcec;
  }

  .xl\:hover\:tw-text-teal-darkest:hover {
    color: #0d3331;
  }

  .xl\:hover\:tw-text-teal-darker:hover {
    color: #20504f;
  }

  .xl\:hover\:tw-text-teal-dark:hover {
    color: #38a89d;
  }

  .xl\:hover\:tw-text-teal:hover {
    color: #17c7aa;
  }

  .xl\:hover\:tw-text-teal-light:hover {
    color: #64d5ca;
  }

  .xl\:hover\:tw-text-teal-lighter:hover {
    color: #a0f0ed;
  }

  .xl\:hover\:tw-text-teal-lightest:hover {
    color: #e8fffe;
  }

  .xl\:hover\:tw-text-blue-darkest:hover {
    color: #12283a;
  }

  .xl\:hover\:tw-text-blue-darker:hover {
    color: #1c3d5a;
  }

  .xl\:hover\:tw-text-blue-dark:hover {
    color: #2779bd;
  }

  .xl\:hover\:tw-text-blue:hover {
    color: #2ac1e7;
  }

  .xl\:hover\:tw-text-blue-light:hover {
    color: #d4f2fa;
  }

  .xl\:hover\:tw-text-blue-lighter:hover {
    color: #bcdefa;
  }

  .xl\:hover\:tw-text-blue-lightest:hover {
    color: #eff8ff;
  }

  .xl\:hover\:tw-text-indigo-darkest:hover {
    color: #191e38;
  }

  .xl\:hover\:tw-text-indigo-darker:hover {
    color: #2f365f;
  }

  .xl\:hover\:tw-text-indigo-dark:hover {
    color: #5661b3;
  }

  .xl\:hover\:tw-text-indigo:hover {
    color: #6574cd;
  }

  .xl\:hover\:tw-text-indigo-light:hover {
    color: #7886d7;
  }

  .xl\:hover\:tw-text-indigo-lighter:hover {
    color: #b2b7ff;
  }

  .xl\:hover\:tw-text-indigo-lightest:hover {
    color: #e6e8ff;
  }

  .xl\:hover\:tw-text-purple-darkest:hover {
    color: #21183c;
  }

  .xl\:hover\:tw-text-purple-darker:hover {
    color: #382b5f;
  }

  .xl\:hover\:tw-text-purple-dark:hover {
    color: #794acf;
  }

  .xl\:hover\:tw-text-purple:hover {
    color: #9561e2;
  }

  .xl\:hover\:tw-text-purple-light:hover {
    color: #a779e9;
  }

  .xl\:hover\:tw-text-purple-lighter:hover {
    color: #d6bbfc;
  }

  .xl\:hover\:tw-text-purple-lightest:hover {
    color: #f3ebff;
  }

  .xl\:hover\:tw-text-pink-darkest:hover {
    color: #451225;
  }

  .xl\:hover\:tw-text-pink-darker:hover {
    color: #6f213f;
  }

  .xl\:hover\:tw-text-pink-dark:hover {
    color: #eb5286;
  }

  .xl\:hover\:tw-text-pink:hover {
    color: #f66d9b;
  }

  .xl\:hover\:tw-text-pink-light:hover {
    color: #fa7ea8;
  }

  .xl\:hover\:tw-text-pink-lighter:hover {
    color: #ffbbca;
  }

  .xl\:hover\:tw-text-pink-lightest:hover {
    color: #ffebef;
  }

  .xl\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .xl\:focus\:tw-text-primary:focus {
    color: #ac826d;
  }

  .xl\:focus\:tw-text-text:focus {
    color: #453f3f;
  }

  .xl\:focus\:tw-text-black:focus {
    color: #000;
  }

  .xl\:focus\:tw-text-grey-darkest:focus {
    color: #3d4852;
  }

  .xl\:focus\:tw-text-grey-darker:focus {
    color: #606f7b;
  }

  .xl\:focus\:tw-text-grey-dark:focus {
    color: #8795a1;
  }

  .xl\:focus\:tw-text-grey:focus {
    color: #b8c2cc;
  }

  .xl\:focus\:tw-text-grey-light:focus {
    color: #a1b3bc;
  }

  .xl\:focus\:tw-text-grey-lighter:focus {
    color: #d8dfe3;
  }

  .xl\:focus\:tw-text-grey-lightest:focus {
    color: #f8fafc;
  }

  .xl\:focus\:tw-text-grey-grayish:focus {
    color: #56666c;
  }

  .xl\:focus\:tw-text-white:focus {
    color: #fff;
  }

  .xl\:focus\:tw-text-red-darkest:focus {
    color: #3b0d0c;
  }

  .xl\:focus\:tw-text-red-darker:focus {
    color: #621b18;
  }

  .xl\:focus\:tw-text-red-dark:focus {
    color: #cc1f1a;
  }

  .xl\:focus\:tw-text-red:focus {
    color: #de5050;
  }

  .xl\:focus\:tw-text-red-light:focus {
    color: #fed5dc;
  }

  .xl\:focus\:tw-text-red-lighter:focus {
    color: #f9acaa;
  }

  .xl\:focus\:tw-text-red-lightest:focus {
    color: #fcebea;
  }

  .xl\:focus\:tw-text-orange-darkest:focus {
    color: #462a16;
  }

  .xl\:focus\:tw-text-orange-darker:focus {
    color: #613b1f;
  }

  .xl\:focus\:tw-text-orange-dark:focus {
    color: #de751f;
  }

  .xl\:focus\:tw-text-orange:focus {
    color: #ffbf00;
  }

  .xl\:focus\:tw-text-orange-light:focus {
    color: #faad63;
  }

  .xl\:focus\:tw-text-orange-lighter:focus {
    color: #fcd9b6;
  }

  .xl\:focus\:tw-text-orange-lightest:focus {
    color: #fff5eb;
  }

  .xl\:focus\:tw-text-yellow-darkest:focus {
    color: #453411;
  }

  .xl\:focus\:tw-text-yellow-darker:focus {
    color: #684f1d;
  }

  .xl\:focus\:tw-text-yellow-dark:focus {
    color: #f2d024;
  }

  .xl\:focus\:tw-text-yellow:focus {
    color: #f8e71c;
  }

  .xl\:focus\:tw-text-yellow-light:focus {
    color: #fff382;
  }

  .xl\:focus\:tw-text-yellow-lighter:focus {
    color: #fff9c2;
  }

  .xl\:focus\:tw-text-yellow-lightest:focus {
    color: #fcfbeb;
  }

  .xl\:focus\:tw-text-green-darkest:focus {
    color: #0f2f21;
  }

  .xl\:focus\:tw-text-green-darker:focus {
    color: #1a4731;
  }

  .xl\:focus\:tw-text-green-dark:focus {
    color: #1f9d55;
  }

  .xl\:focus\:tw-text-green:focus {
    color: #38c172;
  }

  .xl\:focus\:tw-text-green-light:focus {
    color: #51d88a;
  }

  .xl\:focus\:tw-text-green-lighter:focus {
    color: #a2f5bf;
  }

  .xl\:focus\:tw-text-green-lightest:focus {
    color: #e3fcec;
  }

  .xl\:focus\:tw-text-teal-darkest:focus {
    color: #0d3331;
  }

  .xl\:focus\:tw-text-teal-darker:focus {
    color: #20504f;
  }

  .xl\:focus\:tw-text-teal-dark:focus {
    color: #38a89d;
  }

  .xl\:focus\:tw-text-teal:focus {
    color: #17c7aa;
  }

  .xl\:focus\:tw-text-teal-light:focus {
    color: #64d5ca;
  }

  .xl\:focus\:tw-text-teal-lighter:focus {
    color: #a0f0ed;
  }

  .xl\:focus\:tw-text-teal-lightest:focus {
    color: #e8fffe;
  }

  .xl\:focus\:tw-text-blue-darkest:focus {
    color: #12283a;
  }

  .xl\:focus\:tw-text-blue-darker:focus {
    color: #1c3d5a;
  }

  .xl\:focus\:tw-text-blue-dark:focus {
    color: #2779bd;
  }

  .xl\:focus\:tw-text-blue:focus {
    color: #2ac1e7;
  }

  .xl\:focus\:tw-text-blue-light:focus {
    color: #d4f2fa;
  }

  .xl\:focus\:tw-text-blue-lighter:focus {
    color: #bcdefa;
  }

  .xl\:focus\:tw-text-blue-lightest:focus {
    color: #eff8ff;
  }

  .xl\:focus\:tw-text-indigo-darkest:focus {
    color: #191e38;
  }

  .xl\:focus\:tw-text-indigo-darker:focus {
    color: #2f365f;
  }

  .xl\:focus\:tw-text-indigo-dark:focus {
    color: #5661b3;
  }

  .xl\:focus\:tw-text-indigo:focus {
    color: #6574cd;
  }

  .xl\:focus\:tw-text-indigo-light:focus {
    color: #7886d7;
  }

  .xl\:focus\:tw-text-indigo-lighter:focus {
    color: #b2b7ff;
  }

  .xl\:focus\:tw-text-indigo-lightest:focus {
    color: #e6e8ff;
  }

  .xl\:focus\:tw-text-purple-darkest:focus {
    color: #21183c;
  }

  .xl\:focus\:tw-text-purple-darker:focus {
    color: #382b5f;
  }

  .xl\:focus\:tw-text-purple-dark:focus {
    color: #794acf;
  }

  .xl\:focus\:tw-text-purple:focus {
    color: #9561e2;
  }

  .xl\:focus\:tw-text-purple-light:focus {
    color: #a779e9;
  }

  .xl\:focus\:tw-text-purple-lighter:focus {
    color: #d6bbfc;
  }

  .xl\:focus\:tw-text-purple-lightest:focus {
    color: #f3ebff;
  }

  .xl\:focus\:tw-text-pink-darkest:focus {
    color: #451225;
  }

  .xl\:focus\:tw-text-pink-darker:focus {
    color: #6f213f;
  }

  .xl\:focus\:tw-text-pink-dark:focus {
    color: #eb5286;
  }

  .xl\:focus\:tw-text-pink:focus {
    color: #f66d9b;
  }

  .xl\:focus\:tw-text-pink-light:focus {
    color: #fa7ea8;
  }

  .xl\:focus\:tw-text-pink-lighter:focus {
    color: #ffbbca;
  }

  .xl\:focus\:tw-text-pink-lightest:focus {
    color: #ffebef;
  }

  .xl\:tw-text-xsss {
    font-size: 8px;
  }

  .xl\:tw-text-xss {
    font-size: 10px;
  }

  .xl\:tw-text-xs {
    font-size: .75rem;
  }

  .xl\:tw-text-sm {
    font-size: .875rem;
  }

  .xl\:tw-text-base {
    font-size: 1rem;
  }

  .xl\:tw-text-lg {
    font-size: 1.125rem;
  }

  .xl\:tw-text-xl {
    font-size: 1.25rem;
  }

  .xl\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .xl\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .xl\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .xl\:tw-text-5xl {
    font-size: 3rem;
  }

  .xl\:tw-italic {
    font-style: italic;
  }

  .xl\:tw-roman {
    font-style: normal;
  }

  .xl\:tw-uppercase {
    text-transform: uppercase;
  }

  .xl\:tw-lowercase {
    text-transform: lowercase;
  }

  .xl\:tw-capitalize {
    text-transform: capitalize;
  }

  .xl\:tw-normal-case {
    text-transform: none;
  }

  .xl\:tw-underline {
    text-decoration: underline;
  }

  .xl\:tw-line-through {
    text-decoration: line-through;
  }

  .xl\:tw-no-underline {
    text-decoration: none;
  }

  .xl\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:hover\:tw-italic:hover {
    font-style: italic;
  }

  .xl\:hover\:tw-roman:hover {
    font-style: normal;
  }

  .xl\:hover\:tw-uppercase:hover {
    text-transform: uppercase;
  }

  .xl\:hover\:tw-lowercase:hover {
    text-transform: lowercase;
  }

  .xl\:hover\:tw-capitalize:hover {
    text-transform: capitalize;
  }

  .xl\:hover\:tw-normal-case:hover {
    text-transform: none;
  }

  .xl\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .xl\:hover\:tw-antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:hover\:tw-subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:focus\:tw-italic:focus {
    font-style: italic;
  }

  .xl\:focus\:tw-roman:focus {
    font-style: normal;
  }

  .xl\:focus\:tw-uppercase:focus {
    text-transform: uppercase;
  }

  .xl\:focus\:tw-lowercase:focus {
    text-transform: lowercase;
  }

  .xl\:focus\:tw-capitalize:focus {
    text-transform: capitalize;
  }

  .xl\:focus\:tw-normal-case:focus {
    text-transform: none;
  }

  .xl\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .xl\:focus\:tw-antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:focus\:tw-subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:tw-tracking-tight {
    letter-spacing: -0.05em;
  }

  .xl\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .xl\:tw-tracking-wide {
    letter-spacing: .05em;
  }

  .xl\:tw-select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .xl\:tw-select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .xl\:tw-align-baseline {
    vertical-align: baseline;
  }

  .xl\:tw-align-top {
    vertical-align: top;
  }

  .xl\:tw-align-middle {
    vertical-align: middle;
  }

  .xl\:tw-align-bottom {
    vertical-align: bottom;
  }

  .xl\:tw-align-text-top {
    vertical-align: text-top;
  }

  .xl\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:tw-visible {
    visibility: visible;
  }

  .xl\:tw-invisible {
    visibility: hidden;
  }

  .xl\:tw-whitespace-normal {
    white-space: normal;
  }

  .xl\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .xl\:tw-whitespace-pre {
    white-space: pre;
  }

  .xl\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:tw-break-words {
    word-wrap: break-word;
  }

  .xl\:tw-break-normal {
    word-wrap: normal;
  }

  .xl\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xl\:tw-w-1 {
    width: .25rem;
  }

  .xl\:tw-w-2 {
    width: .5rem;
  }

  .xl\:tw-w-3 {
    width: .75rem;
  }

  .xl\:tw-w-4 {
    width: 1rem;
  }

  .xl\:tw-w-5 {
    width: 1.25rem;
  }

  .xl\:tw-w-6 {
    width: 1.5rem;
  }

  .xl\:tw-w-8 {
    width: 2rem;
  }

  .xl\:tw-w-10 {
    width: 2.5rem;
  }

  .xl\:tw-w-12 {
    width: 3rem;
  }

  .xl\:tw-w-16 {
    width: 4rem;
  }

  .xl\:tw-w-24 {
    width: 6rem;
  }

  .xl\:tw-w-32 {
    width: 8rem;
  }

  .xl\:tw-w-48 {
    width: 12rem;
  }

  .xl\:tw-w-64 {
    width: 16rem;
  }

  .xl\:tw-w-auto {
    width: auto;
  }

  .xl\:tw-w-px {
    width: 1px;
  }

  .xl\:tw-w-1\/2 {
    width: 50%;
  }

  .xl\:tw-w-1\/3 {
    width: 33.33333%;
  }

  .xl\:tw-w-2\/3 {
    width: 66.66667%;
  }

  .xl\:tw-w-1\/4 {
    width: 25%;
  }

  .xl\:tw-w-3\/4 {
    width: 75%;
  }

  .xl\:tw-w-1\/5 {
    width: 20%;
  }

  .xl\:tw-w-2\/5 {
    width: 40%;
  }

  .xl\:tw-w-3\/5 {
    width: 60%;
  }

  .xl\:tw-w-4\/5 {
    width: 80%;
  }

  .xl\:tw-w-1\/6 {
    width: 16.66667%;
  }

  .xl\:tw-w-5\/6 {
    width: 83.33333%;
  }

  .xl\:tw-w-full {
    width: 100%;
  }

  .xl\:tw-w-screen {
    width: 100vw;
  }

  .xl\:tw-z-0 {
    z-index: 0;
  }

  .xl\:tw-z-10 {
    z-index: 10;
  }

  .xl\:tw-z-20 {
    z-index: 20;
  }

  .xl\:tw-z-30 {
    z-index: 30;
  }

  .xl\:tw-z-40 {
    z-index: 40;
  }

  .xl\:tw-z-50 {
    z-index: 50;
  }

  .xl\:tw-z-auto {
    z-index: auto;
  }
}
@import url("https://fonts.googleapis.com/css?family=Poppins");

html,
body {
  font-family: 'Poppins', sans-serif;
  color: #453f3f;
  background-color: #f8fafc;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  color: #453f3f;
}

a:hover {
  color: #ac826d;
}

a.active {
  color: #ac826d;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a1b3bc;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a1b3bc;
  opacity: 1; /* Firefox */
}

.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}

.page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}

.tw-table-row {
  background-color: rgba(0, 0, 0, .05);
}

.tw-bg-teal-opacity {
  background: rgba(23, 199, 170, .2);
}

.table {
  color: #453f3f;
}

table th {
  font-family: 'Poppins-Medium', sans-serif;
}

.input-datepicker {
  width: 67px;
  padding: 0;
  border-width: 0;
}

/* Login Page */

.box {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: .25rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  background-color: #fff;
}

.header {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.label {
  font-size: .75rem;
  color: #ac826d;
  display: block;
  font-weight: 700;
}

.label-black {
  color: #000;
}

.form-input-group {
  margin-bottom: 1rem;
}

.input {
  color: #56666c;
  border-radius: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  border-color: #d8dfe3;
}

.input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2ac1e7;
}

.input-red {
  border-color: #de5050;
}

.input-small {
  font-size: .75rem;
}

.btn-blue {
  color: #fff;
  background-color: #2ac1e7;
  font-size: .875rem;
  cursor: pointer;
}

.btn-blue:hover {
  color: #fff;
  background-color: #17c7aa;
}

/* End Login Page */

.btn-outline-blue {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .75rem;
  color: #de5050;
  border-color: #de5050;
  border-width: 1px;
  border-style: solid;
}

.btn-outline-blue:hover {
  background-color: #d4f2fa;
  color: #de5050;
}

.btn-outline-blue-full {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .75rem;
  color: #de5050;
  background-color: #d4f2fa;
  border-color: #de5050;
  border-width: 1px;
  border-style: solid;
}

.btn-outline-blue-full:hover {
  background-color: #2ac1e7;
  color: #fff;
}

.input-datepicker:focus {
  outline: none;
  border-radius: .25rem;
  border-color: #2ac1e7;
  border-width: 1px;
  border-style: solid;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: #de5050 !important;
}

.react-datepicker__header,
.react-datepicker {
  border-color: #d8dfe3 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #d8dfe3 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #2b3940 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  color: white !important;
}

.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 190px;
}

.dropdown-item {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-red {
  background-color: #ac826d;
  color: #fff;
  cursor: pointer;
}

.tw-bg-orange-20 {
  background: rgba(255, 191, 0, .2);
}

.tw-bg-teal-20 {
  background: rgba(23, 199, 170, .2);
}
